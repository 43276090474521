import React from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Header from 'components/Header'
import { useNurse } from 'contexts/NurseProvider'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { Button, Link, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import SwitchField from 'components/SwitchField';

const BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME || 'locumloop_dev_bot'

const NurseNotificationSettings: React.FC = () => {
  const [code, setCode] = React.useState<string>('')
  const [isSendEmailSMS, setIsSendEmailSMS] = React.useState<boolean>(false)
  const [isSendTelegram, setIsSendTelegram] = React.useState<boolean>(false)
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)
  const { nurse } = useNurse()
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()

  const save = React.useCallback(async () => {
    if (!nurse?.nurse_id || !isInitialized) return
    console.log(`Saving notification settings for nurse ${nurse.nurse_id}`, isSendEmailSMS, isSendTelegram)
    try {
      const form = {
        notify_job_posts_via_email_sms: isSendEmailSMS,
        notify_job_posts_via_telegram: isSendTelegram,
      }
      await api.editNurse(form, nurse.nurse_id)
    } catch (e: any) {
      showAlert('error', 'An Error Occurred')
    }
  }, [nurse?.nurse_id, isInitialized, isSendEmailSMS, isSendTelegram, api, showAlert])

  const fetchCode = React.useCallback(async () => {
    if (nurse?.user_id && isSendTelegram) {
      setCode(await api.generateTelegramCode(nurse.user_id))
    } else {
      setCode('')
    }
  }, [api, isSendTelegram, nurse?.user_id])

  const copyCode = () => navigator.clipboard.writeText(code)

  React.useEffect(() => {
    if (nurse && !isInitialized) {
      setIsSendEmailSMS(nurse.notify_job_posts_via_email_sms)
      setIsSendTelegram(nurse.notify_job_posts_via_telegram)
      setIsInitialized(true)
    }
  }, [nurse, isInitialized])

  React.useEffect(() => {
    fetchCode()
  }, [fetchCode])

  React.useEffect(() => {
    save()
  }, [save])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h1' text='Notification Settings'></Header>
      <SwitchField 
        value={isSendEmailSMS} 
        label='Would you like to receive email notifications when a new job post is available?'
        onChange={(value) => setIsSendEmailSMS(value)} 
      />
      <SwitchField 
        value={isSendTelegram} 
        label='Would you like to receive Telegram notifications when a new job post is available?'
        onChange={(value) => setIsSendTelegram(value)} 
      />
      { code ? (
        <Stepper orientation='vertical'>
          <Step active={true}>
            <StepLabel>
              Click to <Link href='https://telegram.org/' target='_blank'>download telegram</Link>
            </StepLabel>
          </Step>
          <Step active={true}>
            <StepLabel>
              Click to reach <Link href={`https://t.me/${BOT_NAME}`} target='_blank'>@{BOT_NAME}</Link>
            </StepLabel>
          </Step>
          <Step active={true}>
            <StepLabel>
              Click the START button and enter your identification code
            </StepLabel>
            <StepContent>
              <Alert severity='info' icon={false} action={<Button onClick={copyCode}>Copy</Button>}>
                {code}
              </Alert>
            </StepContent>
          </Step>
        </Stepper>
      ) : null}
    </Box>
  )
}

export default NurseNotificationSettings