import React from 'react'
import {
  Button,
  Box,
  MenuItem,
  Typography,
} from '@mui/material'
import { createUserWithEmailAndPassword } from "firebase/auth";
import update from 'immutability-helper';
import { plainToClass } from 'class-transformer'
import { useFirebase } from 'contexts/FirebaseProvider'
import { useAPI } from 'contexts/APIProvider'
import { NurseSignupForm } from 'types/interfaces';
import NurseSignupError from 'errors/NurseSignupError';
import Header from 'components/Header'
import FormField from 'components/FormField'
import CheckboxFormField from 'components/CheckboxFormField'
import AddressSearch from 'components/AddressSearch'
import ErrorPaper from 'components/ErrorAlert'
import NurseSignupContext from './context'
import { useAuthUser } from 'contexts/AuthUserProvider';
import { trim } from 'lodash';

const initialValues = {
  radius_miles: 20,
  agreed_contracts: false
}

const NurseSignupBasicInfo: React.FC = () => {
  const { toNextStep, emitGTMEvent } = React.useContext(NurseSignupContext)
  const { auth } = useFirebase()
  const { api } = useAPI()
  const { refreshUser } = useAuthUser()
  const [form, setForm] = React.useState<NurseSignupForm>(initialValues);
  const [error, setError] = React.useState<NurseSignupError>();
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function save() {
    try {
      setLoading(true)

      // check password mismatch
      if (form.password1 !== form.password2) {
        throw 'password_mismatch'
      }

      // Dry-Run to validate submitted Practice Data
      await api.createNurse(form, true)

      // Create user on firebase
      await createUserWithEmailAndPassword(auth, form.email || '', form.password1 || '')

      // Create practice on backend
      await api.createNurse(form, false)

      // Refresh authToken (because backend API might have set claims on firebase auth)
      // await auth.currentUser?.getIdToken(true)
      await refreshUser(true)

      // emit GTM event
      emitGTMEvent('nurse-signup-basic-info')

      // account created. proceed with next signup step
      toNextStep()

    } catch (e: any) {
      if (e === 'password_mismatch') {
        setError(plainToClass(NurseSignupError, {
          '_schema': [
            'Password Mismatch. Please retype your password'
          ]
        }))
      } else if (e instanceof NurseSignupError) {
        setError(e)
      } else if (e?.message?.startsWith('Firebase')) {
        let errorMessage = `${e.message} ${e.code}`
        if (e?.code === 'auth/email-already-in-use') {
          errorMessage = 'There is an existing account registered with this email address'
        }
        setError(plainToClass(NurseSignupError, {
          '_schema': [errorMessage]
        }))
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Basic Information'></Header>
      <FormField
        name='first_name'
        label='First Name'
        onChange={(e) => updateForm('first_name', e.target.value)}
        helperText='Please use your name as it appears on your ID and GDC Register'
        value={form.first_name ?? ''}
        errors={error?.first_name}
      />
      <FormField
        name='last_name'
        label='Last Name'
        onChange={(e) => updateForm('last_name', e.target.value)}
        helperText='Please use your name as it appears on your ID and GDC Register'
        value={form.last_name ?? ''}
        errors={error?.last_name}
      />
      <FormField
        name='email'
        label='Email'
        onChange={(e) => updateForm('email', trim(e.target.value))}
        value={form.email ?? ''}
        errors={error?.email}
      />
      <FormField
        name='password1'
        label='Password'
        type='password'
        onChange={(e) => updateForm('password1', e.target.value)}
        value={form.password1 ?? ''}
        errors={error?.password1}
      />
      <FormField
        name='password2'
        label='Retype Password'
        type='password'
        onChange={(e) => updateForm('password2', e.target.value)}
        value={form.password2 ?? ''}
        errors={error?.password2}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      <FormField
        name='phone_number'
        label='Phone Number'
        onChange={(e) => updateForm('phone_number', e.target.value)}
        value={form.phone_number ?? ''}
        errors={error?.phone_number}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='radius_miles'
        label='Willing to travel up to (miles)'
        select
        onChange={(e) => updateForm('radius_miles', e.target.value)}
        value={form.radius_miles ?? 20}
        errors={error?.radius}>
          <MenuItem value={5}>5 miles</MenuItem>
          <MenuItem value={10}>10 miles</MenuItem>
          <MenuItem value={15}>15 miles</MenuItem>
          <MenuItem value={20}>20 miles</MenuItem>
          <MenuItem value={30}>30 miles</MenuItem>
          <MenuItem value={40}>40 miles</MenuItem>
      </FormField>
      <CheckboxFormField
        name='agreed_contracts'
        label={
          <Typography variant='body2'>
            Check here to accept <a href='/legal/locumloop_nurse.pdf' target='_blank' rel='noopener noreferrer'>the contract with locumloop</a> and <a href='/legal/practice_nurse.pdf' target='_blank' rel='noopener noreferrer'>the contract with practices</a>
          </Typography>
        }
        onChange={(e) => updateForm('agreed_contracts', e.target.checked)}
        checked={form.agreed_contracts ?? false}
        errors={error?.agreed_contracts}
      />
      <ErrorPaper errors={error?.schema}></ErrorPaper>
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Next'}
      </Button>
    </Box>
  )
}

export default NurseSignupBasicInfo