import { Box, SxProps } from '@mui/material'
import NurseMapPoint from 'logic/NurseMapPoint'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import { Icon } from 'leaflet'
import React from 'react'
import Practice from 'logic/Practice'

const LONDON = {
    lat: 51.505,
    lng: -0.09
}

const nurseIcon = new Icon ({
    iconUrl: 'https://img.icons8.com/?size=100&id=122886&format=png&color=#221E52',
    iconSize : [35,35], // size of the icon
    // iconAnchor : [0,0], // point of the icon which will correspond to marker's location
    // popupAnchor : [0, 0] // point from which the popup should open relative to the iconAnchor
})

const hereIcon = new Icon ({
    iconUrl : 'https://img.icons8.com/?size=100&id=53432&format=png&color=FF0000',
    iconSize : [35, 35], // size of the icon
    // iconAnchor : [0, 0], // point of the icon which will correspond to marker's location
    // popupAnchor : [0, 0] // point from which the popup should open relative to the iconAnchor
})

interface NurseMapProps {
    practice?: Practice | null
    points?: NurseMapPoint[]
    sx?: SxProps
}

const NurseMap: React.FC<NurseMapProps> = ({ practice, points, sx }) => {
    const center = React.useMemo(() => practice?.latlng ?? LONDON, [practice])

    const markers = React.useMemo(() => {
        return points?.map((point) => (
            <Marker key={point.nurse_id} position={point.latlng} icon={nurseIcon}>
                <Tooltip>A Nurse Nearby</Tooltip>
            </Marker>
        ))
    }, [points])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, ...sx }}>
            <MapContainer center={center} zoom={13} scrollWheelZoom={false} style={{ height: '80vh' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}
                <Marker position={center} icon={hereIcon}>
                    <Tooltip>You are here</Tooltip>
                </Marker>
            </MapContainer>
        </Box>
    )
}

export default NurseMap