import { GridRowParams } from '@mui/x-data-grid'
import AdminJobTable from 'components/AdminJobTable'
import Job from 'logic/Job'
import AdminPracticeGroupDetailContext from 'pages/AdminPracticeGroupDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobDetailURL } from 'routes/urls'

const AdminPracticeGroupJobs: React.FC = () => {
  const {
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
  } = React.useContext(AdminPracticeGroupDetailContext)
  const navigate = useNavigate()
  const onRowClick = React.useCallback((params: GridRowParams<Job>) => {
    navigate(adminJobDetailURL(params.row.job_id))
  }, [navigate])
  
  return (
    <React.Fragment>
      <AdminJobTable
        onRowClick={onRowClick}
        form={jobSearchForm}
        updateForm={updateJobSearchForm}
        selection={jobSelection}
        setSelection={setJobSelection}
        columnVisibilityModel={jobColumnVisibilityModel}
        setColumnVisibilityModel={setJobColumnVisibilityModel}
        sortModel={jobSortModel}
        setSortModel={setJobSortModel}
      />
    </React.Fragment>
  )
}

export default AdminPracticeGroupJobs