import React from 'react'
import { Navigate, useLocation, useMatch, useParams } from 'react-router'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { loginURL, practiceWelcomeURL, practiceContractAgreementURL, practiceSignupURL, homeURL } from './urls'
import { useAPI } from 'contexts/APIProvider'
import Practice from 'logic/Practice'

const IsPractice: React.FC = ({ children }) => {
  const params = useParams()
  const location = useLocation()
  const { api } = useAPI()
  const { authUser, logout } = useAuthUser()
  const [practice, setPractice] = React.useState<Practice>()
  const match = useMatch({ path: '/practice/:practice_id/:rest', caseSensitive: false, end: false })

  const practiceId = React.useMemo(() => Number(params.practiceId), [params.practiceId]) 

  const fetchPractice = React.useCallback(async () => {
    if (authUser?.practiceIds.includes(practiceId)) {
      setPractice(await api.getPractice(practiceId))
    }
  }, [api, authUser, practiceId])

  React.useEffect(() => {
    fetchPractice()
  }, [fetchPractice]) 

  if (authUser === undefined) {
    return null
  } else if (authUser === null) {
    return <Navigate replace to={loginURL(location.pathname)} />
  } else if (authUser.isStaff) {
    return <React.Fragment>{children}</React.Fragment>
  } else if (authUser.practiceIds.includes(practiceId)) {
    if (!practice) {
      return null
    } else if (!practice.agreed_contracts && match?.params.rest !== 'contracts') {
      return <Navigate replace to={practiceContractAgreementURL(practiceId)} />
    } else if (!practice.enabled && match?.params.rest !== 'welcome') {
      return <Navigate replace to={practiceWelcomeURL(practiceId)} />
    } else if (!practice.practice_group_id && !practice.is_stripe_ready) {
      if (location.pathname.startsWith(practiceSignupURL()) 
        || match?.params.rest === 'billing'
        || match?.params.rest === 'setup-billing-account') {
        return <React.Fragment>{children}</React.Fragment>
      } else {
        return <Navigate replace to={practiceSignupURL()} />
      }
    }
    return <React.Fragment>{children}</React.Fragment>
  } else {
    const redirectURL = homeURL(authUser)
    if (redirectURL) {
      return <Navigate replace to={redirectURL} />
    } else {
      logout()
      return <Navigate replace to={loginURL()} />
    }
  }
}

export default IsPractice