import { Expose } from 'class-transformer';

export default class UserFormError {
  email: string[] = []
  password: string[] = []
  first_name: string[] = []
  last_name: string[] = []
  practice_ids: string[] = []
  practice_group_ids: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
}