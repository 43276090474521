import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { loginURL } from 'routes/urls'
import { useAuthUser } from 'contexts/AuthUserProvider';

const GuestTopNav: React.FC = () => {
  const navigate = useNavigate();
  const { authUser, logout } = useAuthUser()
  const logo = process.env.PUBLIC_URL + '/logo-rectangle.png'

  function goToRoot() {
    navigate('/')
  }

  const isAuthenticated = authUser !== undefined && authUser !== null

  return (
    <AppBar position="static" color='inherit'>
      <Toolbar variant='regular' disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box component='img' src={logo} alt='logo' sx={{ height: ['1.5rem', '2.5rem'], cursor: 'pointer' }} onClick={goToRoot}></Box>
        </Box>
        {isAuthenticated ? (
          <IconButton onClick={logout}>
            <PowerSettingsNewIcon />
          </IconButton>
        ) : (
          <Button variant="text" component={Link} to={loginURL()}>Login / Sign Up</Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default GuestTopNav