import React from 'react'
import { useNavigate } from "react-router-dom";
import { useAuthUser } from 'contexts/AuthUserProvider'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { useAffiliate } from 'contexts/AffiliateProvider';
import { useAPI } from 'contexts/APIProvider';

const AffiliateTopNav: React.FC = () => {
  const navigate = useNavigate();
  const { affiliate } = useAffiliate()
  const { api } = useAPI()
  const { authUser, logout } = useAuthUser()
  const [stripeURL, setStripeURL] = React.useState<string>()
  
  const fetchStripeURL = React.useCallback(async () => {
    if (!affiliate?.id) return null
    setStripeURL(await api.getAffiliateStripeDashboardLink(affiliate.id))
  }, [affiliate?.id, api])

  function goToStripe() {
    if (stripeURL) window.open(stripeURL, "_blank");
  }

  function goToRoot() {
    navigate('/')
  }

  React.useEffect(() => {
    fetchStripeURL()
  }, [fetchStripeURL])

  if (!authUser?.affiliateId) return null

  const logo = process.env.PUBLIC_URL + '/logo-rectangle.png'

  return (
    <AppBar position="static" color='inherit'>
      <Toolbar variant='regular' disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box component='img' src={logo} alt='logo' sx={{ height: ['1.5rem', '2.5rem'], cursor: 'pointer' }} onClick={goToRoot}></Box>
        </Box>
        {stripeURL ? (
          <IconButton onClick={goToStripe}>
            <CurrencyPoundIcon />
          </IconButton>
        ) : null}
        <IconButton onClick={logout}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AffiliateTopNav