import { Typography } from '@mui/material'
import React from 'react'
import { PracticeLateFeePolicy } from 'types/types'

interface PracticeLateCancellationFeeLabelProps {
  lateFees: number
  policy: PracticeLateFeePolicy
}

const PracticeLateCancellationFeeLabel: React.FC<PracticeLateCancellationFeeLabelProps> = ({ lateFees, policy }) => {
  if (policy === '24-72 hours charge 50% + 0-24 hours charge 100%') {
    return (
      <Typography>
        Please note, if you cancel this job now you will be charged a late cancellation fee. <br/>
        This is because the job will be starting soon.  <br/>
        It's important that our nurses and practices can rely on each other. <br/>
        We charge 100% if the job is starting in less than 24 hours. <br/>
        We charge 50% if the job is starting between 24-72 hours. <br/>
      </Typography>
    )
  } else {
    return (
      <Typography>
        Please note, if you cancel this job now you will be charged a late cancellation fee. <br/>
        This is because the job will be starting soon.  <br/>
        It's important that our nurses and practices can rely on each other. <br/>
        We charge 100% if the job is starting in less than 24 hours. <br/>
      </Typography>
    )
  }
}

export default PracticeLateCancellationFeeLabel