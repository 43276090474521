import AdminChart from 'components/AdminChart'
import AdminPracticeGroupDetailContext from 'pages/AdminPracticeGroupDetail/context'
import React from 'react'

const AdminPracticeGroupCharts: React.FC = () => {
  const { chartsSearchForm, updateChartsSearchForm } = React.useContext(AdminPracticeGroupDetailContext)
  return (
    <AdminChart
      form={chartsSearchForm}
      updateForm={updateChartsSearchForm}
      allowSelectPractice={false}
      allowSelectPracticeGroup={false}
    />
  )
}

export default AdminPracticeGroupCharts