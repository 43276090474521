import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { getStripeURL } from 'logic/helpers'
import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Loading from 'components/Loading'
import { Tab, Tabs } from '@mui/material'
import NursePaymentsTable from 'pages/NurseBilling/PaymentsTable'
import NurseTransactionHistory from 'pages/NurseBilling/TransactionHistory'
import NursePayoutHistory from 'pages/NurseBilling/PayoutHistory'

type TAB = 
| 'payments-in-progress' 
| 'payments-completed' 
| 'transaction-history' 
| 'payout-history' 

const NurseBilling: React.FC = () => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const [stripeURL, setStripeURL] = React.useState<string>()
  const [balance, setBalance] = React.useState<number>()
  const [selectedTab, setSelectedTab] = React.useState<TAB>('transaction-history')

  const fetchStripeURL = React.useCallback(async () => {
    if (!nurse) return
    setStripeURL(await getStripeURL(api, nurse))
  }, [nurse, api])

  const fetchBalance = React.useCallback(async () => {
    if (!nurse || nurse.stripe_step === 'NOT_STARTED' || nurse.stripe_step === 'PROCESSING') return
    setBalance(await api.getNurseBalance(nurse.nurse_id))
  }, [nurse, api])

  React.useEffect(() => {
    fetchStripeURL()
  }, [fetchStripeURL])
  
  React.useEffect(() => {
    fetchBalance()
  }, [fetchBalance])

  const isRedirectToStripeConnect = React.useMemo(() => !(nurse?.payouts_enabled ?? true), [nurse])

  function goToStripe() {
    if (stripeURL) window.open(stripeURL, "_self");
  }

  if (stripeURL === undefined || !nurse) {
    return <Loading />
  }

  if (isRedirectToStripeConnect) {
    goToStripe()
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant='h1'>Payout Pending: £{(balance || 0) / 100}</Typography>
        <Typography variant='subtitle1'>Payout to your bank on a rolling basis between 7 - 14 days</Typography>
      </Paper>
      <Button variant='contained' onClick={goToStripe}>Click here to change your bank account</Button>
      <Tabs value={selectedTab} onChange={(e, tab) => setSelectedTab(tab)}>
        <Tab label='Transaction History'  value='transaction-history' />
        <Tab label='Payments (In Progress)'  value='payments-in-progress' />
        <Tab label='Payments (Completed)'  value='payments-completed' />
        <Tab label='Payouts'  value='payout-history' />
      </Tabs>
      {selectedTab === 'payments-completed' ? (
        <NursePaymentsTable payment_statuses={[
          'practice_invoice_paid', 
          'practice_invoice_void', 
          'practice_paid',
        ]} />
      ) : selectedTab === 'payments-in-progress' ? (
        <NursePaymentsTable payment_statuses={[
          'practice_invoice_finalized', 
          'practice_invoice_payment_failed',
          'practice_payment_pending', 
          'practice_payment_requested', 
          'practice_payment_error'
        ]} />
      ) : selectedTab === 'transaction-history' ? (
        <NurseTransactionHistory />
      ) : (
        <NursePayoutHistory />
      )}
    </Box>
  )
}

export default NurseBilling