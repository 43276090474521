import { Box } from '@mui/material'
import React from 'react'
import Header from 'components/Header'
import InvoicesTable from 'components/InvoicesTable'

const AdminInvoiceList: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Invoice List'></Header>
      <InvoicesTable isAdmin={true} />
    </Box>
  )
}

export default AdminInvoiceList