import React from 'react'
import LogRocket from 'logrocket'
import { useAPI } from './APIProvider'
import { useAuthUser } from './AuthUserProvider'
import { useMatch } from 'react-router'

const LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID || ''
const GIT_SHA = process.env.REACT_APP_GIT_SHA || 'N/A'

interface ILogRocketContext {
  logRocketURL?: string
}

const LogRocketContext = React.createContext<ILogRocketContext>({} as ILogRocketContext)

const LogRocketProvider: React.FC = ({ children }) => {
  const { api } = useAPI()
  const { authUser } = useAuthUser()
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [logRocketURL, setLogRocketURL] = React.useState<string>()
  const isAdminPage = useMatch({path: '/admin', end: false})
  const isLoginPage = useMatch({path: '/public/login', end: false})

  const skipRecord = React.useMemo(() => {
    if (LOGROCKET_ID === '') {
      return true
    }
    if (isAdminPage || isLoginPage) {
      return true
    }
    if (authUser === undefined) {
      return true
    }
    if (authUser?.isStaff ?? false) {
      return true
    }
    return false
  }, [LOGROCKET_ID, isAdminPage, isLoginPage, authUser])

  React.useEffect(() => {
    if (skipRecord) return

    if (!isInitialized) {
      LogRocket.init(LOGROCKET_ID, {
        release: GIT_SHA,
        console: {
          shouldAggregateConsoleErrors: true,
        },
      })
      setIsInitialized(true)

      LogRocket.getSessionURL(sessionURL => {
        api.logRocketURL = sessionURL
        setLogRocketURL(sessionURL)
        console.info('LogRocket URL:', sessionURL)
      })
    }
  }, [skipRecord, isInitialized])

  React.useEffect(() => {
    if (isInitialized && authUser) {
      LogRocket.identify(authUser.firebaseId, {
        name: authUser.name || 'N/A',
        email: authUser.email || 'N/A',
        userId: authUser.id || 'N/A'
      })
    }
  }, [isInitialized, authUser])

  const contextValue = { logRocketURL }
  return (
    <LogRocketContext.Provider value={contextValue}>
      {children}
    </LogRocketContext.Provider>
  )
}

function useLogRocket() {
  const context = React.useContext(LogRocketContext)
  if (context === undefined) {
    throw new Error('useLogRocket must be used within a LogRocketProvider')
  }
  return context
}

export { LogRocketProvider, useLogRocket }

