import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material'

interface SwitchFieldProps {
  value?: boolean
  label: string
  onChange: (value: boolean) => void
}

const SwitchField: React.FC<SwitchFieldProps> = ({ value, label, onChange }) => {
  return (
    <FormControl>
      <FormControlLabel label={label} control={(
        <Switch 
          checked={value} 
          onChange={(_, checked) => onChange(checked)}
        />
      )} />
    </FormControl>
  )
}

export default SwitchField