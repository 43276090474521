import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import ArrowCircleLeftOutlined from '@mui/icons-material/ArrowCircleLeftOutlined'
import ArrowCircleRightOutlined from '@mui/icons-material/ArrowCircleRightOutlined'
import { Interval } from 'luxon'

interface CalendarControlProps {
  sx?: SxProps
  interval?: Interval
  toNextMonth: () => void
  toPreviousMonth: () => void
  toCurrentMonth: () => void
}

const CalendarControl: React.FC<CalendarControlProps> = ({ sx = {}, interval, toNextMonth, toPreviousMonth, toCurrentMonth }) => {
  if (interval === undefined) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...sx
    }}>
      <IconButton onClick={toPreviousMonth}>
        <ArrowCircleLeftOutlined />
      </IconButton>
      <Typography variant='body1'>{interval.start.toFormat('LLL yyyy')}</Typography>
      <IconButton onClick={toNextMonth}>
        <ArrowCircleRightOutlined />
      </IconButton>
    </Box>
  )
}

export default CalendarControl