import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNurse } from 'contexts/NurseProvider'
import { useAPI } from 'contexts/APIProvider'
import NurseProfileError from 'errors/NurseProfileError'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { nurseJobsURL } from 'routes/urls'

const NurseContractAgreement: React.FC = () => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [loading, setLoading] = React.useState<boolean>(false)

  async function agree() {
    if (nurse) {
      try {
        setLoading(true)
        await api.editNurse({ agreed_contracts: true }, nurse?.nurse_id)
        window.location.replace(nurseJobsURL(nurse.nurse_id));
      } catch (e: any) {
        if (e instanceof NurseProfileError) {
          showAlert('error', 'You must agree to our terms and conditions if you wish to work with us.')
        } else {
          console.error('unhandled exception', e)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant='h1'>We have updated our terms and conditions. </Typography>
      <Typography variant='body1'>
        Please review the following contracts: <br/>
        <a href='/legal/locumloop_nurse.pdf' target='_blank' rel='noopener noreferrer'>Agreement between nurse and locumloop</a> <br/>
        <a href='/legal/practice_nurse.pdf' target='_blank' rel='noopener noreferrer'>Agreement between nurse and practice</a> <br/>
      </Typography>
      <Button 
        variant='contained'
        onClick={agree} 
        disabled={loading}>
          {loading ? 'Please Wait ...' : 'I Agree'}
      </Button>
    </Box>
  )
}

export default NurseContractAgreement