import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FiberNew from '@mui/icons-material/FiberNew';
import Header from 'components/Header'
import { isEmpty } from 'lodash';
import { Notification } from 'logic/Notification'


interface NotificationDisplayProps {
  markRead: () => Promise<void>
  isUnread: (notification: Notification) => boolean
  getMessage: (notification: Notification) => React.ReactNode
  notifications: Notification[]
  tz: string
}

const NotificationDisplay: React.FC<NotificationDisplayProps> = ({
  markRead, isUnread, getMessage, notifications, tz
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Notifications'>
        <Button color='primary'
          onClick={markRead}>
          Mark all as read
        </Button>
      </Header>
      {isEmpty(notifications) ? (
        <Alert severity='info'>There are no notifications</Alert>
      ) : (
        <Table size='small'>
          <TableBody>
            {notifications.map((notification, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {isUnread(notification) ? (<FiberNew color='primary' />) : null}
                    {notification.date_label(tz)}
                  </Box>
                </TableCell>
                <TableCell>{getMessage(notification)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default NotificationDisplay