import { Box, Button, MenuItem } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { ChartSearchForm } from 'types/interfaces'
import { Line } from 'react-chartjs-2';
import Practice from 'logic/Practice';
import PracticeGroup from 'logic/PracticeGroup';
import AutoCompleteSelectField from './AutoCompleteSelectField';
import FormField from './FormField';
import { ChartDataset } from 'chart.js';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';

type ReportType = 
| 'job-fulfillment' 
| 'match-cancellation' 
| 'earnings' 
| 'job-cancellation-replacement' 
| 'nurse-tier'
| 'match-fulfillment-speed'
| 'match-nurse-tier'
| 'job-urgency'
| 'match-corporate'
| 'practice-signup-stage'
| 'practice-churn'

interface AdminChartProps {
    form: ChartSearchForm
    updateForm: (name: string, value: string) => void
    reportTypes?: ReportType[]
    allowSelectPractice?: boolean
    allowSelectPracticeGroup?: boolean
}

const colors = {
    green: {
        normal: '#5eb84d',
        tint_50: '#afdca6',
        shade_50: '#2f5c27',
    },
    red: {
        normal: '#e04848',
        tint_50: '#f0a4a4',
        shade_50: '#702424',
    },
    orange: {
        normal: '#FFA500',
        tint_50: '#ffd280',
        shade_50: '#805300',
    },
    yellow: {
        normal: '#fceb00',
        tint_50: '#fef580',
        shade_50: '#7e7600',
    },
    blue: {
        normal: '#007bff',
        tint_50: '#80bdff',
        shade_50: '#003e80',
    },
    purple: {
        normal: '#800080',
        tint_50: '#c080c0',
        shade_50: '#400040',
    },
    pink: {
        normal: '#ffc0cb',
        tint_50: '#ffe0e5',
        shade_50: '#806066',
    },
    brown: {
        normal: '#A52A2A',
        tint_50: '#d29595',
        shade_50: '#531515',
    },
    black: {
        normal: '#000000',
        tint_50: '#808080',
        shade_50: '#000000',
    }
};

const AdminChart: React.FC<AdminChartProps> = ({ 
    form, updateForm, 
    reportTypes = [
        'job-fulfillment', 
        'match-cancellation', 
        'earnings', 
        'job-cancellation-replacement', 
        'nurse-tier', 
        'match-fulfillment-speed', 
        'match-nurse-tier', 
        'job-urgency', 
        'match-corporate',
        'practice-signup-stage',
        'practice-churn'
    ], 
    allowSelectPractice = true, 
    allowSelectPracticeGroup = true 
}) => {
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [reportType, setReportType] = React.useState<ReportType>(reportTypes[0])
    const [practices, setPractices] = React.useState<Practice[]>([])
    const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
    const [labels, setLabels] = React.useState<string[]>([])
    const [datasets, setDatasets] = React.useState<ChartDataset<'line', number[]>[]>([])

    const fetchReport = React.useCallback(async () => {
        try {
            if (reportType === 'job-fulfillment') {
                const items = await api.getJobFulfillmentChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Filled",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.filled),
                    fill: true,
                }, {
                    label: 'Not Filled',
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.not_filled),
                    fill: true,
                }, {
                    label: 'Partially Filled',
                    data: items.map((item) => item.partially_filled),
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    fill: true,
                }])
            } else if (reportType === 'match-cancellation') {
                const items = await api.getMatchCancellationChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Not Cancelled",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.not_cancelled),
                    fill: true,
                }, {
                    label: "Cancelled by Nurse",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.cancelled_by_nurse),
                    fill: true,
                }, {
                    label: "Cancelled by Nurse (Last Minute)",
                    backgroundColor: colors.red.shade_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.cancelled_by_nurse_last_minute),
                    fill: true,
                }, {
                    label: "Cancelled by Practice",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.cancelled_by_practice),
                    fill: true,
                }, {
                    label: "Cancelled by Practice (Last Minute)",
                    backgroundColor: colors.orange.shade_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.cancelled_by_practice_last_minute),
                    fill: true,
                }, {
                    label: "Cancelled by Staff",
                    backgroundColor: colors.yellow.tint_50,
                    pointBackgroundColor: colors.yellow.normal,
                    borderColor: colors.yellow.normal,
                    data: items.map((item) => item.cancelled_by_staff),
                    fill: true,
                }, {
                    label: "Cancelled by Staff (Last Minute)",
                    backgroundColor: colors.yellow.shade_50,
                    pointBackgroundColor: colors.yellow.normal,
                    borderColor: colors.yellow.normal,
                    data: items.map((item) => item.cancelled_by_staff_last_minute),
                    fill: true,
                }])
            } else if (reportType === 'earnings') {
                const items = await api.getEarningsChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Settled",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.settled_earnings),
                    fill: true,
                }, {
                    label: "Pending",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.pending_earnings),
                    fill: true,
                }])
            } else if (reportType === 'job-cancellation-replacement') {
                const items = await api.getJobCancellationReplacementChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Job cancelled by practice eventually",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.cancelled_eventually),
                    fill: true,
                }, {
                    label: "Job filled by other nurses eventually",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.filled_eventually),
                    fill: true,
                }, {
                    label: "Job not filled eventually",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.not_filled_eventually),
                    fill: true,
                }])
            } else if (reportType === 'nurse-tier') {
                const items = await api.getNurseTierChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Good Tier Nurses",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.num_good_tier_nurses),
                    fill: true,
                }, {
                    label: "Bad Tier Nurses",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.num_bad_tier_nurses),
                    fill: true,
                }, {
                    label: "New/Unapproved Tier Nurses",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.num_new_tier_nurses),
                    fill: true,
                }])
            } else if (reportType === 'match-fulfillment-speed') {
                const items = await api.getMatchFulfillmentSpeedChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Less than 15 minutes",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.less_than_15mins),
                    fill: true,
                }, {
                    label: "From 15 minutes to 30 minutes",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.from_15mins_to_30mins),
                    fill: true,
                }, {
                    label: "From 30 minutes to 60 minutes",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.from_30mins_to_60mins),
                    fill: true,
                }, {
                    label: "More than 60 minutes",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.more_than_60mins),
                    fill: true,
                }])
            } else if (reportType === 'match-nurse-tier') {
                const items = await api.getMatchNurseTierChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Good Tier Nurses",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.good),
                    fill: true,
                }, {
                    label: "New Tier Nurses",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.new),
                    fill: true,
                }, {
                    label: "Bad Tier Nurses",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.bad),
                    fill: true,
                }])
            } else if (reportType === 'job-urgency') {
                const items = await api.getJobUrgencyChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Less than 12 hours",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.less_than_12h),
                    fill: true,
                }, {
                    label: "From 12 hours to 24 hours",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.from_12h_to_24h),
                    fill: true,
                }, {
                    label: "From 24 hours to 48 hours",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.from_24h_to_48h),
                    fill: true,
                }, {
                    label: "More than 48 hours",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.more_than_48h),
                    fill: true,
                }])
            } else if (reportType === 'match-corporate') {
                const items = await api.getMatchCorporateChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Colosseum",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.colosseum),
                    fill: true,
                }, {
                    label: "Dental Beauty Partners",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.dental_beauty_partners),
                    fill: true,
                }, {
                    label: "Damira",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.damira),
                    fill: true,
                }, {
                    label: "Perfect Smile",
                    backgroundColor: colors.yellow.tint_50,
                    pointBackgroundColor: colors.yellow.normal,
                    borderColor: colors.yellow.normal,
                    data: items.map((item) => item.perfect_smile),
                    fill: true,
                }, {
                    label: "Smile",
                    backgroundColor: colors.blue.tint_50,
                    pointBackgroundColor: colors.blue.normal,
                    borderColor: colors.blue.normal,
                    data: items.map((item) => item.smile),
                    fill: true,
                }, {
                    label: "Tooth Club",
                    backgroundColor: colors.purple.tint_50,
                    pointBackgroundColor: colors.purple.normal,
                    borderColor: colors.purple.normal,
                    data: items.map((item) => item.tooth_club),
                    fill: true,
                }, {
                    label: "Clyde Munro",
                    backgroundColor: colors.brown.tint_50,
                    pointBackgroundColor: colors.brown.normal,
                    borderColor: colors.brown.normal,
                    data: items.map((item) => item.clyde_munro),
                    fill: true,
                }, {
                    label: "Queensway",
                    backgroundColor: colors.pink.tint_50,
                    pointBackgroundColor: colors.pink.normal,
                    borderColor: colors.pink.normal,
                    data: items.map((item) => item.queensway),
                    fill: true,
                }, {
                    label: "Standalone",
                    backgroundColor: colors.black.tint_50,
                    pointBackgroundColor: colors.black.normal,
                    borderColor: colors.black.normal,
                    data: items.map((item) => item.standalone),
                    fill: true,
                }])
            } else if (reportType === 'practice-signup-stage') {
                const items = await api.getPracticeSignupStageChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Payment Setup Ready",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.payment_setup_ready),
                    fill: true,
                }, {
                    label: "Posted Job",
                    backgroundColor: colors.orange.tint_50,
                    pointBackgroundColor: colors.orange.normal,
                    borderColor: colors.orange.normal,
                    data: items.map((item) => item.posted_job),
                    fill: true,
                }, {
                    label: "Not Posted Job",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.not_posted_job),
                    fill: true,
                }])
            } else if (reportType === 'practice-churn') {
                const items = await api.getPracticeChurnChartData(form)
                setLabels(items.map((item) => `${item.year}-${item.month}`))
                setDatasets([{
                    label: "Posting Much Less",
                    backgroundColor: colors.red.normal,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.posting_much_less),
                    fill: true,
                }, {
                    label: "Posting Slightly Less",
                    backgroundColor: colors.red.tint_50,
                    pointBackgroundColor: colors.red.normal,
                    borderColor: colors.red.normal,
                    data: items.map((item) => item.posting_slightly_less),
                    fill: true,
                }, {
                    label: "Posting as Usual",
                    backgroundColor: colors.green.tint_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.posting_as_usual),
                    fill: true,
                }, {
                    label: "Posting Slightly More",
                    backgroundColor: colors.green.normal,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.posting_slightly_more),
                    fill: true,
                }, {
                    label: "Posting Much More",
                    backgroundColor: colors.green.shade_50,
                    pointBackgroundColor: colors.green.normal,
                    borderColor: colors.green.normal,
                    data: items.map((item) => item.posting_much_more),
                    fill: true,
                }])
            }
        } catch (error) {
            showAlert('error', `Error fetching ${reportType} report`)
            console.error(error)
        }
    }, [api, form, reportType, showAlert])

    const getTitle = React.useCallback((reportType: ReportType) => {
        if (reportType === 'job-fulfillment') return 'Jobs (Breakdown by Fulfillment Status)'
        if (reportType === 'match-cancellation') return 'Matches (Breakdown by Cancellation Status)'
        if (reportType === 'earnings') return 'Earnings (figures are in £)'
        if (reportType === 'job-cancellation-replacement') return 'Job cancellations (Breakdown by Replacement Status)'
        if (reportType === 'nurse-tier') return 'Nurses (Breakdown by Tier)'
        if (reportType === 'match-corporate') return 'Matches (Breakdown by Corporate)'
        if (reportType === 'job-urgency') return 'Jobs (Breakdown by Urgency)'
        if (reportType === 'match-fulfillment-speed') return 'Matches (Breakdown by Taken Speed)'
        if (reportType === 'match-nurse-tier') return 'Matches (Breakdown by Nurse Tier)'
        if (reportType === 'practice-signup-stage') return 'Independent Practices (Breakdown by Signup Stage)'
        if (reportType === 'practice-churn') return 'Practices (Breakdown by Retention)'
        return ''
    }, [])

    const fetchPractices = React.useCallback(async (searchTerm: string) => {
        if (!searchTerm) return
        setPractices(await api.listPractices({ name: searchTerm }))
      }, [api])

    const fetchPracticeGroups = React.useCallback(async (searchTerm: string) => {
        if (!searchTerm) return
        setPracticeGroups(await api.listPracticeGroup({ name: searchTerm }))
    }, [api])

    const options = React.useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: getTitle(reportType),
                },
                tooltip: {
                    mode: 'index' as const,
                },
            },
            interaction: {
                mode: 'nearest' as const,
                axis: 'x' as const,
                intersect: false
            },
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                }
            }
        }
    }, [getTitle, reportType])

    const data = React.useMemo(() => {
        return {
            labels,
            datasets
        }
    }, [labels, datasets])

    const practiceSelectOptions = React.useMemo(() => {
        return practices.map((practice) => ({
          value: practice.practice_id,
          label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
        }))
    }, [practices])

    const practiceGroupSelectOptions = React.useMemo(() => {
        return practiceGroups.map((practiceGroup) => ({
          value: practiceGroup.id,
          label: practiceGroup.name,
        })).concat([{
            value: -1,
            label: 'Standalone'
        }])
    }, [practiceGroups])
      

    React.useEffect(() => {
        fetchReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <FormField
                    select
                    name='report_type'
                    label='Type'
                    sx={{ width: 400 }}
                    onChange={(e) => setReportType(e.target.value as ReportType)}
                    value={reportType}
                >
                    {reportTypes.map((reportType) => (
                        <MenuItem key={reportType} value={reportType}>{getTitle(reportType)}</MenuItem>
                    ))}
                </FormField>
                <AutoCompleteSelectField
                    label='Select a practice'
                    sx={{ width: 250, display: allowSelectPractice ? 'block' : 'none' }}
                    options={practiceSelectOptions}
                    fetchOptions={fetchPractices}
                    value={form.practice_id ?? ''}
                    onChange={(value) => updateForm('practice_id', value)}
                />
                <AutoCompleteSelectField
                    label='Select a practice group'
                    sx={{ width: 250, display: allowSelectPracticeGroup ? 'block' : 'none' }}
                    options={practiceGroupSelectOptions}
                    fetchOptions={fetchPracticeGroups}
                    value={form.practice_group_id ?? ''}
                    onChange={(value) => updateForm('practice_group_id', value)}
                />
                <FormField
                    type='date'
                    name='from_date'
                    label='From Date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => updateForm('start_at', e.target.value)}
                    value={form.start_at ?? ''}
                />
                <FormField
                    type='date'
                    name='end_date'
                    label='End Date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => updateForm('end_at', e.target.value)}
                    value={form.end_at ?? ''}
                />
                {reportType === 'practice-churn' && (
                    <FormField
                        type='number'
                        name='trailing_months'
                        label='Lookback Period (months)'
                        onChange={(e) => updateForm('trailing_months', e.target.value)}
                        value={form.trailing_months ?? 6}
                    />
                )}
                <Button variant='contained' onClick={fetchReport}>UPDATE CHART</Button>
            </Box>
            <Box sx={{ width: 1200, height: 600, overflow: 'auto' }}>
                <Line options={options} data={data} />
            </Box>
        </Box>
    )
}


export default AdminChart

