import { Alert, Box } from '@mui/material'
import NurseDocumentFile from 'logic/NurseDocumentFile'
import React from 'react'
import { isEmpty } from 'lodash'

type DocumentDisplayProps = {
    files: NurseDocumentFile[]
}

const DocumentDisplay: React.FC<DocumentDisplayProps> = ({ files }) => {
    if (isEmpty(files)) {
        return (
            <Alert severity='warning' variant='filled'>
                No file uploaded yet.
            </Alert>
        )
    }
    
    return (
        <React.Fragment>
            {files.map((file) => (
                file.content_type === 'application/pdf' ? (
                    <Box key={file.id} component='embed' src={file.url} id={`file-${file.id}`} sx={{ width: '100%', height: '75vh' }} />
                ) : (
                    <Box key={file.id} component='img' src={file.url} id={`file-${file.id}`} sx={{ width: '100%' }} />
                )
            ))}
        </React.Fragment>
    )
}

export default DocumentDisplay