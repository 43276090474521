import { Box, Button } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'
import AdminNurseTable from 'components/AdminNurseTable'
import { useAPI } from 'contexts/APIProvider'
import { useAdmin } from 'contexts/AdminProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import Nurse from 'logic/Nurse'
import AdminJobDetailContext from 'pages/AdminJobDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminNurseDetailURL } from 'routes/urls'

const AdminJobAvailableNurses: React.FC = () => {
  const { job, fetchJob } = React.useContext(AdminJobDetailContext)
  const {
    availableNurseSearchForm,
    availableNurseSelection,
    availableNurseColumnVisibilityModel,
    availableNurseSortModel,
    updateAvailableNurseSearchForm,
    setAvailableNurseSelection,
    setAvailableNurseColumnVisibilityModel,
    setAvailableNurseSortModel,
  } = useAdmin()

  const navigate = useNavigate()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [loading, setLoading] = React.useState<boolean>(false)

  const onRowClick = React.useCallback((params: GridRowParams<Nurse>) => {
    navigate(adminNurseDetailURL(params.row.nurse_id))
  }, [navigate])

  const assignJob = React.useCallback(async () => {
    if (!job) return
    if (availableNurseSelection.length === 0) {
      showAlert('error', 'Select a nurse first')
      return
    } else if (availableNurseSelection.length > 1) {
      showAlert('error', 'Select ONE nurse only')
      return
    }

    const nurseId = Number(availableNurseSelection[0])

    try {
      setLoading(true)
      await api.addJobEmployment({ 
        job_id: job.job_id, 
        nurse_id: nurseId,
        document_shared: job.document_sharing_required,
      })
      await fetchJob()
      showAlert('success', `Job Assigned`)
    } catch (e) {
      showAlert('error', e as String)
    } finally {
      setLoading(false)
    }
  }, [api, availableNurseSelection, fetchJob, job, showAlert])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <AdminNurseTable
        filters={['name', 'stripe_status', 'basic_document_status', 'extended_compliance_document_status', 'scotland_compliance_document_status', 'waitlist', 'tiers']}
        onRowClick={onRowClick}
        actions={
          <Button variant='contained' onClick={assignJob} disabled={loading}>
            {loading ? 'Please Wait ...': 'Tick one nurse, then click here to assign job to her'}
          </Button>
        }
        form={availableNurseSearchForm}
        updateForm={updateAvailableNurseSearchForm}
        selection={availableNurseSelection}
        setSelection={setAvailableNurseSelection}
        columnVisibilityModel={availableNurseColumnVisibilityModel}
        setColumnVisibilityModel={setAvailableNurseColumnVisibilityModel}
        sortModel={availableNurseSortModel}
        setSortModel={setAvailableNurseSortModel}
      />
    </Box>
  )
}

export default AdminJobAvailableNurses