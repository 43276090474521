import { Alert, Paper } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import Header from 'components/Header'
import { useZendesk } from 'contexts/ZendeskProvider'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import React from 'react'

const AdminEmploymentStatus: React.FC = () => {
  const { employment } = React.useContext(AdminEmploymentDetailContext)
  const { openZendesk } = useZendesk()

  return (
    <Paper sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header variant='h3' text='Practice Status' />
      {employment.status_alert('practice', openZendesk)}
      <Header variant='h3' text='Nurse Status' />
      {employment.status_alert('nurse', openZendesk)}
      {employment.fulfillment_status === 'cancelled' ? (
        <React.Fragment>
          <Header variant='h3' text='Cancellation' />
          <DescriptionList labelWidth='60%' valueWidth='40%' rowGap={1} data={[{
            label: 'Cancel Date',
            value: employment.cancel_at_label('Europe/London'),
          }, {
            label: 'Cancel By',
            value: employment.cancel_by,
          }, {
            label: 'Cancel Reason',
            value: employment.cancel_reason,
          }]} />
          <Alert severity='info' variant='filled'>
            By default, all matches cancelled by the nurse (regardless of reason and urgency) will be marked for penalty. <br />
            Applying a penalty to a match affects the nurse tier. <br/>
            If % matches with penalty {'>'} 20%, the nurse will be in BAD tier. <br/>
            If % matches with penalty {'<='} 20% AND total number of completed matches {'>='} 10, the nurse will be in GOOD tier. <br/>
            If total number of completed matches {'<'} 10, the nurse will be in NEW tier. <br/>
            If for any reason you need to override this, use the "Apply Penalty" checkbox on the right. And add a note in the "Penalty Note" field.<br />
          </Alert>
        </React.Fragment>
      ) : null}
    </Paper>
  )
}

export default AdminEmploymentStatus