import { Alert, Box, Button, Chip, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import FormField from 'components/FormField'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import NurseProfileError from 'errors/NurseProfileError'
import React from 'react'
import { NurseProfileForm } from 'types/interfaces'
import update from 'immutability-helper'
import ErrorList from 'components/ErrorList'
import FileUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { useFirebase } from 'contexts/FirebaseProvider'
import { ref, uploadBytes, uploadString, StringFormat } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import APIError from 'errors/APIError'
import { approvalBooleanToString, approvalStringToBoolean } from 'logic/helpers'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'

const ACCEPTED_FILETYPES = 'image/jpeg, image/png'

const AdminNurseRightToWork: React.FC = () => {
  const { nurse, fetchNurse } = React.useContext(AdminNurseDetailContext)
  const { api } = useAPI()
  const { storage } = useFirebase()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<NurseProfileForm>({})
  const [error, setError] = React.useState<NurseProfileError>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [fileURL, setFileURL] = React.useState<string>('')

  const fetchFile = React.useCallback(async () => {
    if (!nurse) return
    try {
      setLoading(true)
      const result = await api.getNurseRightToWorkDocumentLink(nurse.nurse_id)
      setFileURL(result.url)
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
    
  }, [api, nurse, showAlert])

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const onFiledUpload = React.useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!nurse) return
    
    try {
      setLoading(true)
      const file = e.target.files?.[0]
      if (!file) return

      // upload to firebase storage
      const key = uuidv4()
      const fileRef = ref(storage, `right-to-work/${nurse.nurse_id}/${key}`)
      const metadata = {
        contentType: file.type,
        cacheControl: 'no-store',
      }
      if (typeof file === 'string') {
        await uploadString(fileRef, file, StringFormat.DATA_URL, metadata)
      } else {
        await uploadBytes(fileRef, file, metadata)
      }

      updateForm('rtw_file', key)
      showAlert('success', 'File Uploaded')
    } catch (e) {
      showAlert('error', 'Error uploading file')
      console.error(e)
    } finally {
      setLoading(false)
    }
    
  }, [nurse, showAlert, storage, updateForm])

  const save = React.useCallback(async () => {
    if (!nurse) return
    try {
      setLoading(true)
      await api.editNurse(form, nurse.nurse_id)
      await fetchNurse()
      showAlert('success', 'Right To Work status updated')
    } catch (e) {
      if (e instanceof NurseProfileError) {
        setError(e)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, fetchNurse, form, nurse, showAlert])

  React.useEffect(() => {
    if (!nurse) return
    if (!isEmpty(form)) return
    setForm(update(form, {
      rtw_sharecode: { $set: nurse.rtw_sharecode },
      rtw_dob: { $set: nurse.rtw_dob },
      rtw_file: { $set: nurse.rtw_file },
      rtw_date: { $set: nurse.rtw_date },
      rtw_reason: { $set: nurse.rtw_reason },
      rtw_approved: { $set: nurse.rtw_approved },
    }))
  }, [nurse])

  React.useEffect(() => {
    fetchFile()
  }, [fetchFile])

  if (!nurse) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Chip label={`Right To Work: ${nurse.rtw_status}`} color={nurse.rtw_status_color} />
        <Chip label={`ID Type: ${nurse.id_document_type || 'N/A'}`} />
        <Chip label={`ID Issuing Country: ${nurse.id_issuing_country || 'N/A'}`} />
        <Chip label={`Share Code: ${nurse.rtw_sharecode || 'N/A'}`} />
        <Chip label={`Date of Birth: ${nurse.rtw_dob || 'N/A'}`} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'space-between' }}>
        <Box sx={{ width: '50%' }}>
          {loading ? (
            <Loading />
          ) : fileURL ? (
            <Box component='img' src={fileURL} sx={{ width: '100%' }} />
          ) : form.rtw_file ? (
            <Alert variant='filled' severity='info'>
              The uploaded file will show up here after you click "Save"
            </Alert>
          ) : (
            <Alert variant='filled' severity='warning'>
              Picture not uploaded yet.
            </Alert>
          )}
        </Box>
        <Box sx={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
          <FormField
            name='rtw_date'
            label='Valid Until Date'
            type='date'
            onChange={(e) => updateForm('rtw_date', e.target.value)}
            value={form.rtw_date ?? ''}
            errors={error?.rtw_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <RadioGroup row
            value={approvalBooleanToString(form.rtw_approved)}
            onChange={(e, value) => updateForm('rtw_approved', approvalStringToBoolean(value))}>
            <FormControlLabel value='not-set' control={<Radio color='warning' />} label="Unset" />
            <FormControlLabel value='approved' control={<Radio color='success' />} label="Approve" />
            <FormControlLabel value='disapproved' control={<Radio color='error' />} label="Disapprove" />
          </RadioGroup>
          {form.rtw_approved === false ? (
            <FormField 
              name='rtw_reason'
              label='Disapprove Reason'
              multiline
              rows={4}
              onChange={(e) => updateForm('rtw_reason', e.target.value)}
              value={form.rtw_reason ?? ''}
              errors={error?.rtw_reason}
            />
          ) : null}
          <FormField
            name='rtw_sharecode'
            label='Share Code'
            helperText='If the nurse entered incorrectly, you can change it here.'
            onChange={(e) => updateForm('rtw_sharecode', e.target.value)}
            value={form.rtw_sharecode ?? ''}
            errors={error?.rtw_sharecode}
          />
          <FormField
            name='rtw_dob'
            label='Date of Birth'
            onChange={(e) => updateForm('rtw_dob', e.target.value)}
            value={form.rtw_dob ?? ''}
            errors={error?.rtw_dob}
          />
          <Button
            size='small'
            variant="outlined"
            endIcon={<FileUploadIcon />}
            disabled={loading}
            component="label">
            {loading ? 'Please Wait ...' : 'Upload Screenshot of Right to Work'}
            <input
              hidden
              id='right-to-work-upload'
              accept={ACCEPTED_FILETYPES}
              type="file"
              onChange={onFiledUpload} />
          </Button>
          {error?.schema ? (
            <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            disabled={loading}>
            {loading ? 'Please Wait ...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AdminNurseRightToWork