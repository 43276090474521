import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import { 
  DataGridPro, 
  GridColDef, 
  GridColumnVisibilityModel, 
  GridEventListener, 
  GridRowId, 
  GridSortModel, 
} from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { AffiliateSearchForm } from 'types/interfaces'
import Affiliate from 'logic/Affiliate';
import { Observable } from 'rxjs';

interface AdminAffiliateTableProps {
  reload$?: Observable<boolean>
  onRowClick?: GridEventListener<"rowClick">
  actions?: React.ReactNode
  form: AffiliateSearchForm
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminAffiliateTable: React.FC<AdminAffiliateTableProps> = ({
  reload$,
  onRowClick,
  actions,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [affiliates, setAffiliates] = React.useState<Affiliate[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchAffiliates = React.useCallback(async () => {
    setLoading(true)
    setAffiliates(await api.listAffiliates(form))
    setLoading(false)
  }, [api, form])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  React.useEffect(() => {
    fetchAffiliates()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchAffiliates())
    return () => sub?.unsubscribe()
  }, [fetchAffiliates, reload$])

  const columns: GridColDef<Affiliate>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'id',
    type: 'number',
  }, {
    minWidth: 100,
    headerName: 'Code',
    field: 'code',
    type: 'string',
  }, {
    minWidth: 300,
    headerName: 'Email',
    field: 'user_email',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Name',
    field: 'user_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Campaign',
    field: 'campaign_name',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Referrals',
    field: 'num_practices',
    type: 'number',
  }, {
    minWidth: 150,
    headerName: 'Metro Area',
    field: 'metro_area',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Stripe ID',
    field: 'stripe_user_id',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Payouts Enabled',
    field: 'payouts_enabled',
    type: 'boolean',
  }], [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          name='name'
          label='Practice Group Name'
          type='text'
          onChange={(e) => updateForm('name', e.target.value)}
          value={form.name}
        />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box> */}
      {loading ? (
        <Alert severity='info'>Please Wait ...</Alert>
      ) : isEmpty(affiliates) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{affiliates.length} records returned. {selection.length} selected</Alert>
          <DataGridPro 
            rows={affiliates}
            columns={columns}
            getRowId={(affiliate) => affiliate.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminAffiliateTable