import { Expose } from 'class-transformer';
import AddressFormError from 'errors/AddressFormError';

export default class PracticeBaseError extends AddressFormError {
  name: string[] = []
  email_for_inquiries: string[] = []
  email_for_notifications: string[] = []
  phone_for_inquiries: string[] = []
  phone_for_notifications: string[] = []
  start_time: string[] = []
  end_time: string[] = []
  lunch_break: string[] = []
  description: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
}