import React from 'react'
import { useAPI } from './APIProvider'
import { useParams } from 'react-router'
import { useAuthUser } from './AuthUserProvider';
import { useFirebase } from './FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { filter, map, switchMap, from, share, Subscription } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import Affiliate from 'logic/Affiliate';
import { DateTime } from 'luxon';

interface IAffiliateContext {
  affiliate?: Affiliate | null
  reloadAffiliate: () => Promise<void>
}

const AffiliateContext = React.createContext<IAffiliateContext>({} as IAffiliateContext)

const AffiliateProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { firestore } = useFirebase()
  const { api } = useAPI()
  const { authUser } = useAuthUser()
  const [affiliate, setAffiliate] = React.useState<Affiliate | null>()

  const affiliateId = React.useMemo(() => Number(params.affiliateId), [params.affiliateId]) 

  const reloadAffiliate = React.useCallback(async () => {
    if (authUser) {
      try {
        console.log('AffiliateProvider: fetchAffiliate')
        setAffiliate(await api.getAffiliate(affiliateId))
      } catch (e) {
        console.error(e)
        setAffiliate(null)
      }
    } else if (authUser === null) {
      setAffiliate(null)
    }
  }, [authUser, api, affiliateId])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("affiliate_id", "==", affiliateId)
    ), 
    [firestore, affiliateId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ), 
    [notificationQuery]
  )

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'affiliate_updated',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(reloadAffiliate))
    return () => sub.unsubscribe()
  }, [notification$, reloadAffiliate])

  React.useEffect(() => {
    reloadAffiliate()
  }, [reloadAffiliate])

  const value = { 
    affiliate, 
    reloadAffiliate,
  }
  return (
    <AffiliateContext.Provider value={value}>
      {children}
    </AffiliateContext.Provider>
  )
}

function useAffiliate() {
  const context = React.useContext(AffiliateContext)
  if (context === undefined) {
    throw new Error('useAffiliate must be used within a AffiliateProvider')
  }
  return context
}

export { AffiliateProvider, useAffiliate }