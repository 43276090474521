import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import Nurse from 'logic/Nurse'
import React from 'react'
import Check from '@mui/icons-material/Check'
import Error from '@mui/icons-material/Error'
import PendingActions from '@mui/icons-material/PendingActions'
import APIError from 'errors/APIError'
import FormField from 'components/FormField'
import { useStripe } from '@stripe/react-stripe-js'
import { Observable, filter, startWith } from 'rxjs'
import { Notification } from 'logic/Notification'
import { DateTime } from 'luxon'

interface RightToWorkProps {
  nurseId: number
  notification$: Observable<Notification>
}

const RightToWork: React.FC<RightToWorkProps> = ({ nurseId, notification$ }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const stripe = useStripe()
  const [isUK, setIsUK] = React.useState<boolean>()
  const [shareCode, setShareCode] = React.useState<string>('')
  const [dob, setDob] = React.useState<string>('')
  const [nurse, setNurse] = React.useState<Nurse>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false)

  const fetchNurse = React.useCallback(async () => {
    setNurse(await api.getNurse(nurseId))
  }, [api, nurseId])

  const submitShareCode = React.useCallback(async () => {
    try {
      setLoading(true)
      await api.editNurse({ rtw_sharecode: shareCode, rtw_dob: dob }, nurseId)
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error submitting share code')
    } finally {
      setLoading(false)
      setConfirmOpen(false)
    }
  }, [api, dob, nurseId, shareCode, showAlert])

  const verify = React.useCallback(async () => {
    if (!stripe) return
    try {
      setLoading(true)
      const client_secret = await api.getNurseVerificationSession(nurseId)
      const { error } = await stripe.verifyIdentity(client_secret);
      if (error) {
        showAlert('error', error.message)
        console.error(error)
      }
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
    
  }, [api, nurseId, showAlert, stripe])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const sub = notification$.pipe(
      filter(notif => ['nurse_updated', 'nurse_id_verification_success', 'nurse_id_verification_failed'].includes(notif.event)),
      filter(notif => notif.isAfter(timestamp)),
      startWith(true),
    ).subscribe(fetchNurse)
    return () => sub.unsubscribe()
  }, [fetchNurse, notification$])

  if (!nurse) return null

  const isUKPassport = nurse.id_document_type === 'passport' && (nurse.id_issuing_country === 'GB' || nurse.id_issuing_country === 'IE' )

  let title = ''
  let backgroundColor = ''
  let body
  let icon
  let actions
  
  if (nurse.rtw_status === 'approved') {
    title = "Right to work in the UK"
    body = <Typography variant='body2'>Your right to work in the UK has been verified!</Typography>
    backgroundColor = "success.light"
    icon = <Check fontSize='large' sx={{ color: 'success.main' }} />
  } else if (nurse.rtw_status === 'disapproved') {
    title = "Failed to verify your right to work in the UK"
    body = <Typography variant='body2'>{nurse.rtw_reason}</Typography>
    backgroundColor = "error.light"
    icon = <Error fontSize='large' sx={{ color: 'error.main' }} />
  } else if (nurse.rtw_status === 'pending-review') {
    title = "Right to work in the UK"
    body = (
      <Typography variant='body2'>
        Our staff member is verifying your share code. We'll be in touch when it's done.
      </Typography>
    )
    backgroundColor = 'warning.light'
    icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
  } else if (isUK === undefined) {
    title = "Right to work in the UK"
    body = (
      <Typography variant='body2'>
        Do you have a British or Irish Passport?
      </Typography>
    )
    backgroundColor = 'background.paper'
    icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
    actions = (
      <React.Fragment>
        <Button size='small' variant='contained' onClick={() => setIsUK(true)}>Yes</Button>
        <Button size='small' variant='contained' onClick={() => setIsUK(false)}>No</Button>
      </React.Fragment>
    )
  } else if (isUK) {
    if (nurse.verification_status === '') {
      title = "Right to work in the UK"
      body = (
        <Typography variant='body2'>
          We check your identity by matching your selfie with your identity document. Please use your British/Irish Passport
        </Typography>
      )
      backgroundColor = 'background.paper'
      icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
      actions = (
        <Button size='small' variant='contained' disabled={loading} onClick={verify}>
          { loading ? 'Please Wait ...' : 'Start Identity Check' }
        </Button>
      )
    } else if (nurse.verification_status === 'verified' && !isUKPassport) {
      title = "Right to work in the UK"
      body = (
        <Typography variant='body2'>
          Please use your British/Irish passport to perform the identity check.
        </Typography>
      )
      backgroundColor = 'background.paper'
      icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
      actions = (
        <Button size='small' variant='contained' disabled={loading} onClick={verify}>
          { loading ? 'Please Wait ...' : 'Start Identity Check' }
        </Button>
      )
    } else if (nurse.verification_error_code || nurse.verification_status === 'canceled') {
      title = "An error occurred when checking your identity"
      body = (
        <Typography variant='body2'>
          {nurse.verification_error_reason} ({nurse.verification_error_code})
        </Typography>
      )
      backgroundColor = "error.light"
      icon = <Error fontSize='large' sx={{ color: 'error.main' }} />
      actions = (
        <Button size='small' variant='contained' disabled={loading} onClick={verify}>
          { loading ? 'Please Wait ...' : 'Retry Identity Check' }
        </Button>
      )
    }
  } else {  // not UK
    if (nurse.verification_status === '') {
      title = "Right to work in the UK"
      body = (
        <Typography variant='body2'>
          We check your identity by matching your selfie with your identity document.
        </Typography>
      )
      backgroundColor = 'background.paper'
      icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
      actions = (
        <Button size='small' variant='contained' disabled={loading} onClick={verify}>
          { loading ? 'Please Wait ...' : 'Start Identity Check' }
        </Button>
      )
    } else if (nurse.verification_status === 'verified') {
      title = "Right to work in the UK"
      body = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant='body2'>
            Please watch the video below to learn how to prove your right to work in the UK.
          </Typography>
          <Box 
            component='iframe'
            src="https://www.youtube.com/embed/jVRmCUFIWB0?si=hAzeOa35qN-VopLR" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
            sx={{ 
              width: ['100%', '560px'],
              height: ['auto', '315px'],
            }}
          />
          <Button 
            sx={{ width: ['100%', '560px'] }}
            component='a' target='_blank' variant='outlined'
            href='https://www.gov.uk/prove-right-to-work/get-a-share-code-online' rel="noreferrer">
            Start Here
          </Button>
          <Typography variant='body2'>
            Once you have your share code, submit it below along with your date of birth.
          </Typography>
        </Box>
      )
      backgroundColor = 'background.paper'
      icon = null
      actions = (
        <Box sx={{ display: 'flex', gap: 1, flexDirection: ['column', 'row'], marginTop: 2 }}>
          <FormField label='Share Code' value={shareCode} onChange={(e) => setShareCode(e.target.value)} />
          <FormField 
            type='date' 
            label='Date of Birth' 
            value={dob} 
            onChange={(e) => setDob(e.target.value)} 
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button size='small' variant='contained' disabled={loading} onClick={() => setConfirmOpen(true)}>
          { loading ? 'Please Wait ...' : 'Submit share code' }
          </Button>
        </Box>
      )
    } else if (nurse.verification_error_code || nurse.verification_status === 'canceled') {
      title = "An error occurred when checking your identity"
      body = (
        <Typography variant='body2'>
          {nurse.verification_error_reason} ({nurse.verification_error_code})
        </Typography>
      )
      backgroundColor = "error.light"
      icon = <Error fontSize='large' sx={{ color: 'error.main' }} />
      actions = (
        <Button size='small' variant='contained' disabled={loading} onClick={verify}>
          { loading ? 'Please Wait ...' : 'Retry Identity Check' }
        </Button>
      )
    }
  }

  return (
    <Paper sx={{
      padding: 1,
      display: 'grid',
      gridTemplateColumns: icon ? ['1fr 4fr', '1fr 9fr'] : '0 1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
    "icon title"
    "icon content"
    "icon buttons"`,
      backgroundColor: backgroundColor,
    }}>
      <Box sx={{ gridArea: 'icon', justifySelf: 'center', alignSelf: 'center' }}>
        {icon}
      </Box>
      <Typography sx={{ gridArea: 'title' }} variant='h5'>{title}</Typography>
      <Box sx={{ gridArea: 'content' }}>{body}</Box>
      <Box sx={{ gridArea: 'buttons', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
        {actions}
      </Box>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Please confirm the following is correct
        </DialogTitle>
        <DialogContent>
          Share Code: {shareCode} <br/>
          Date of Birth: {dob} <br/>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitShareCode}>Confirm</Button>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default RightToWork