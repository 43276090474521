import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import PracticeGroupMember from 'logic/PracticeGroupMember'
import AdminPracticeGroupDetailContext from 'pages/AdminPracticeGroupDetail/context'
import React from 'react'

const AdminPracticeGroupMembers: React.FC = () => {
  const { group } = React.useContext(AdminPracticeGroupDetailContext)
  const { api } = useAPI()
  const [members, setMembers] = React.useState<PracticeGroupMember[]>([])

  const fetchMembers = React.useCallback(async () => {
    setMembers(await api.listPracticeGroupMembers({ practice_group_id: group.id }))
  }, [api, group.id])

  React.useEffect(() => {
    fetchMembers()
  }, [fetchMembers])
  
  return (
    <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member, i) => (
            <TableRow key={i}>
              <TableCell data-label='User ID'>{member.user_id}</TableCell>
              <TableCell data-label='Name'>{member.user_email}</TableCell>
              <TableCell data-label='Email'>{member.user_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  )
}

export default AdminPracticeGroupMembers