import { 
  Box, 
  Typography, 
  Tabs, 
  Tab,
  ButtonGroup,
  IconButton,
  Chip,
 } from '@mui/material'
import {
  practiceJobsURL,
  practiceBillingURL,
  practiceBlacklistURL,
  practiceProfileEditURL,
  practiceMemberListURL,
  practiceIssueLateFeeURL,
  practiceTimesheetListURL,
} from 'routes/urls'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Outlet, Link as RouterLink, useMatch, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import Loading from 'components/Loading'
import SplitButton from 'components/SplitButton';
import Practice from 'logic/Practice';
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context';
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { BlacklistedNurseSearchForm, JobEmploymentSearchForm, NurseSearchForm, JobSearchForm, ChartSearchForm, UserSearchForm } from 'types/interfaces';
import update from 'immutability-helper'


/**
 * Profile
 * Jobs
 * Matches
 * Cancellations
 * Reviews
 * Blacklisted Nurses
 * Nearby Nurses
 * Charts
 */

const AdminPracticeDetail: React.FC = () => {
  const { api } = useAPI()
  const params = useParams()
  const navigate = useNavigate()
  const [practice, setPractice] = React.useState<Practice>()
  const match = useMatch({ path: '/admin/practices/:practiceId/:rest', caseSensitive: false, end: false })

  // Jobs Tab Context State
  const [jobSearchForm, setJobSearchForm] = React.useState<JobSearchForm>({
    practice_id: Number(params.practiceId)
  })
  const [jobSelection, setJobSelection] = React.useState<GridRowId[]>([])
  const [jobColumnVisibilityModel, setJobColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    practice_name: false,
  })
  const [jobSortModel, setJobSortModel] = React.useState<GridSortModel>([])

  const updateJobSearchForm = React.useCallback((name: string, value: any) => {
    setJobSearchForm(update(jobSearchForm, { [name]: { $set: value } }))
  }, [jobSearchForm])

  // Matches Tab Context State
  const [matchesSearchForm, setMatchesSearchForm] = React.useState<JobEmploymentSearchForm>({
    practice_id: Number(params.practiceId),
  })
  const [matchesSelection, setMatchesSelection] = React.useState<GridRowId[]>([])
  const [matchesColumnVisibilityModel, setMatchesColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    practice_name: false,
  })
  const [matchesSortModel, setMatchesSortModel] = React.useState<GridSortModel>([])

  const updateMatchesSearchForm = React.useCallback((name: string, value: any) => {
    setMatchesSearchForm(update(matchesSearchForm, { [name]: { $set: value } }))
  }, [matchesSearchForm])

  // Reviews Tab Context State
  const [reviewsSearchForm, setReviewsSearchForm] = React.useState<JobEmploymentSearchForm>({
    practice_id: Number(params.practiceId),
    is_rated: true,
  })
  const [reviewsSelection, setReviewsSelection] = React.useState<GridRowId[]>([])
  const [reviewsColumnVisibilityModel, setReviewsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    practice_name: false,
    cancel_at: false,
    cancel_by: false,
    cancel_reason: false,
    nurse_num_cancellations: false,
    practice_payment_method_available: false,
    nurse_id_issuing_country: false,
    billable_duration: false,
    payment_status: false,
  })
  const [reviewsSortModel, setReviewsSortModel] = React.useState<GridSortModel>([])

  const updateReviewsSearchForm = React.useCallback((name: string, value: any) => {
    setReviewsSearchForm(update(reviewsSearchForm, { [name]: { $set: value } }))
  }, [reviewsSearchForm])

  // Cancellations Tab Context State
  const [cancellationsSearchForm, setCancellationsSearchForm] = React.useState<JobEmploymentSearchForm>({
    practice_id: Number(params.practiceId),
    is_cancelled: true,
  })
  const [cancellationsSelection, setCancellationsSelection] = React.useState<GridRowId[]>([])
  const [cancellationsColumnVisibilityModel, setCancellationsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    practice_name: false,
    review_rating: false,
    review_notes: false,
    practice_payment_method_available: false,
    nurse_id_issuing_country: false,
  })
  const [cancellationsSortModel, setCancellationsSortModel] = React.useState<GridSortModel>([])

  const updateCancellationsSearchForm = React.useCallback((name: string, value: any) => {
    setCancellationsSearchForm(update(cancellationsSearchForm, { [name]: { $set: value } }))
  }, [cancellationsSearchForm])

  // Blacklist Tab Context State
  const [blacklistsSearchForm, setBlacklistsSearchForm] = React.useState<BlacklistedNurseSearchForm>({
    practice_id: Number(params.practiceId),
  })
  const [blacklistsSelection, setBlacklistsSelection] = React.useState<GridRowId[]>([])
  const [blacklistsColumnVisibilityModel, setBlacklistsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [blacklistsSortModel, setBlacklistsSortModel] = React.useState<GridSortModel>([])

  const updateBlacklistsSearchForm = React.useCallback((name: string, value: any) => {
    setBlacklistsSearchForm(update(blacklistsSearchForm, { [name]: { $set: value } }))
  }, [blacklistsSearchForm])

  
  // Nearby Nurses Tab Context State
  const [nearbyNurseSearchForm, setNearbyNurseSearchForm] = React.useState<NurseSearchForm>({
    nearby_practice_id: Number(params.practiceId)
  })
  const [nearbyNurseSelection, setNearbyNurseSelection] = React.useState<GridRowId[]>([])
  const [nearbyNurseColumnVisibilityModel, setNearbyNurseColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [nearbyNurseSortModel, setNearbyNurseSortModel] = React.useState<GridSortModel>([])

  const updateNearbyNurseSearchForm = React.useCallback((name: string, value: any) => {
    setNearbyNurseSearchForm(update(nearbyNurseSearchForm, { [name]: { $set: value } }))
  }, [nearbyNurseSearchForm])

  // Members Tab
  const [userSearchForm, setUserSearchForm] = React.useState<UserSearchForm>({
    roles: ['practice', 'practice_group'],
    practice_id: Number(params.practiceId)
  })
  const [userSelection, setUserSelection] = React.useState<GridRowId[]>([])
  const [userColumnVisibilityModel, setUserColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [userSortModel, setUserSortModel] = React.useState<GridSortModel>([])

  const updateUserSearchForm = React.useCallback((name: string, value: any) => {
    setUserSearchForm(update(userSearchForm, { [name]: { $set: value } }))
  }, [userSearchForm])

  // Charts Tab Context State
  const [chartsSearchForm, setChartsSearchForm] = React.useState<ChartSearchForm>({ 
    practice_id: Number(params.practiceId)
  })

  const updateChartsSearchForm = React.useCallback((name: string, value: any) => {
    setChartsSearchForm(update(chartsSearchForm, { [name]: { $set: value } }))
  }, [chartsSearchForm])

  // Actions
  const fetchPractice = React.useCallback(async () => {
    setPractice(await api.getPractice(Number(params.practiceId)))
  }, [api, params.practiceId])

  const setEnableFlag = React.useCallback(async (enabled: boolean) => {
    if (!practice) return
    const data = { practice_ids: [practice?.practice_id], enabled: enabled }
    await api.enablePractices(data)
    await fetchPractice()
  }, [api, fetchPractice, practice])
  
  // Init Page
  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'profile'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchPractice()
  }, [fetchPractice])

  if (!practice) return <Loading />

  const contextValue = {
    practice,
    fetchPractice,

    // job tab
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,

    // matches tab
    matchesSearchForm,
    matchesSelection,
    matchesColumnVisibilityModel,
    matchesSortModel,
    updateMatchesSearchForm,
    setMatchesSelection,
    setMatchesColumnVisibilityModel,
    setMatchesSortModel,

    // reviews tab
    reviewsSearchForm,
    reviewsSelection,
    reviewsColumnVisibilityModel,
    reviewsSortModel,
    updateReviewsSearchForm,
    setReviewsSelection,
    setReviewsColumnVisibilityModel,
    setReviewsSortModel,

    // cancellations tab
    cancellationsSearchForm,
    cancellationsSelection,
    cancellationsColumnVisibilityModel,
    cancellationsSortModel,
    updateCancellationsSearchForm,
    setCancellationsSelection,
    setCancellationsColumnVisibilityModel,
    setCancellationsSortModel,

    // blacklists tab
    blacklistsSearchForm,
    blacklistsSelection,
    blacklistsColumnVisibilityModel,
    blacklistsSortModel,
    updateBlacklistsSearchForm,
    setBlacklistsSelection,
    setBlacklistsColumnVisibilityModel,
    setBlacklistsSortModel,

    // nearby nurses tab
    nearbyNurseSearchForm,
    nearbyNurseSelection,
    nearbyNurseColumnVisibilityModel,
    nearbyNurseSortModel,
    updateNearbyNurseSearchForm,
    setNearbyNurseSelection,
    setNearbyNurseColumnVisibilityModel,
    setNearbyNurseSortModel,

    // members tab
    userSearchForm,
    userSelection,
    userColumnVisibilityModel,
    userSortModel,
    updateUserSearchForm,
    setUserSelection,
    setUserColumnVisibilityModel,
    setUserSortModel,

    // charts tab
    chartsSearchForm,
    updateChartsSearchForm,
  }
  return (
    <AdminPracticeDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 1, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Typography variant='h1'>{ practice.practice_name }</Typography>
          <ButtonGroup size='small'>
            <IconButton 
              component={RouterLink}
              to={practiceProfileEditURL(practice.practice_id)}
              target='_blank'
            >
              <AccountCircleIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={practiceMemberListURL(practice.practice_id)}
              target='_blank'
            >
              <PeopleIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={practiceJobsURL(practice.practice_id)}
              target='_blank'
            >
              <CalendarMonthIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={practiceBlacklistURL(practice.practice_id)}
              target='_blank'
            >
              <SentimentDissatisfiedIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={practiceTimesheetListURL(practice.practice_id)}
              target='_blank'
            >
              <ReceiptLongIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={practiceBillingURL(practice.practice_id)}
              target='_blank'
            >
              <CurrencyPoundIcon />
            </IconButton>
          </ButtonGroup>
          {practice.enabled ? (
            <Chip label='Enabled' color='success' />
          ) : (
            <Chip label='Disabled' color='error' />
          )}
          {practice.is_stripe_ready ? (
            <Chip label={practice.payment_option} color='success' />
          ) : (
            <Chip label={practice.not_stripe_ready_reason} color='error' />
          )}
          {practice.practice_group_id ? (
            <Chip label={practice.practice_group_name} />
          ) : null}
          <Chip label={practice.first_member_label} />
          <Chip label={practice.phone_for_inquiries} />
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'enable-practice',
            label: 'Enable Practice',
            onClick: () => setEnableFlag(true)
          }, {
            value: 'disable-practice',
            label: 'Disable Practice',
            onClick: () => setEnableFlag(false)
          }, {
            value: 'issue-invoice-late-fee',
            label: 'Issue Invoice Late Fee',
            onClick: () => navigate(practiceIssueLateFeeURL(practice.practice_id))
          }]}/>
        </Box>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label='Profile' value='profile' onClick={() => navigate('profile')}/>
          <Tab label='Jobs' value='jobs' onClick={() => navigate('jobs')}/>
          <Tab label='Matches' value='matches' onClick={() => navigate('matches')}/>
          <Tab label='Reviews' value='reviews' onClick={() => navigate('reviews')}/>
          <Tab label='Cancellations' value='cancellations' onClick={() => navigate('cancellations')}/>
          <Tab label='Blacklisted Nurses' value='blacklist' onClick={() => navigate('blacklist')}/>
          <Tab label='Nearby Nurses' value='practices' onClick={() => navigate('nurses')}/>
          <Tab label='Members' value='members' onClick={() => navigate('members')}/>
          <Tab label='Charts' value='charts' onClick={() => navigate('charts')}/>
          <Tab label='Nurse Map' value='nurse-map' onClick={() => navigate('nurse-map')}/>
        </Tabs>
        <Outlet />
      </Box>
    </AdminPracticeDetailContext.Provider>
  )
}

export default AdminPracticeDetail