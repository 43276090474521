import React from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Header from 'components/Header'
import DescriptionList from 'components/DescriptionList'
import { isEmpty } from 'lodash'
import CustomEmail from 'components/CustomEmail'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'

const AdminNurseStripeStatus: React.FC = () => {
  const { nurse } = React.useContext(AdminNurseDetailContext)

  if (nurse === undefined) return null

  const requirements = nurse.pending_stripe_requirements
  const errors = nurse.stripe_errors

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Paper sx={{ padding: 2 }}>
        <DescriptionList data={[{
          label: 'Stripe Account ID',
          value: nurse.stripe_user_id
        }, {
          label: 'Payouts Enabled',
          value: nurse.payouts_enabled ? 'Yes' : 'No'
        }, {
          label: 'Details Submitted',
          value: nurse.details_submitted ? 'Yes' : 'No'
        }]} />
      </Paper>
      <Header variant='h3' text='Pending Stripe Requirements' />
      {isEmpty(requirements) ? (
        <Alert severity='info'>The are no pending requirements</Alert>
      ) : (
        <List>
          {requirements.map(req => <ListItem disablePadding key={req}>{req}</ListItem>)}
        </List>
      )}
      <Header variant='h3' text='Stripe Errors' />
      {isEmpty(errors) ? (
        <Alert severity='info'>The are no errors</Alert>
      ) : (
        <List>
          {errors.map(err => <ListItem disablePadding key={err}>{err}</ListItem>)}
        </List>
      )}
      <CustomEmail 
        nurse={nurse} 
        subject='Action required before you can receive payments via Stripe' 
      />
    </Box>
  )
}

export default AdminNurseStripeStatus