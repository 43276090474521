import AdminBlacklistedNurseTable from 'components/AdminBlacklistedNurseTable'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'

const AdminNurseBlacklisted: React.FC = () => {
  const {
    blacklistsSearchForm,
    blacklistsSelection,
    blacklistsColumnVisibilityModel,
    blacklistsSortModel,
    updateBlacklistsSearchForm,
    setBlacklistsSelection,
    setBlacklistsColumnVisibilityModel,
    setBlacklistsSortModel,
  } = React.useContext(AdminNurseDetailContext)
  
  return (
    <AdminBlacklistedNurseTable
      form={blacklistsSearchForm}
      updateForm={updateBlacklistsSearchForm}
      selection={blacklistsSelection}
      setSelection={setBlacklistsSelection}
      columnVisibilityModel={blacklistsColumnVisibilityModel}
      setColumnVisibilityModel={setBlacklistsColumnVisibilityModel}
      sortModel={blacklistsSortModel}
      setSortModel={setBlacklistsSortModel}
    />
  )
}

export default AdminNurseBlacklisted