import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import JobEmploymentCancelError from 'errors/JobEmploymentCancelError'
import React from 'react'
import update from 'immutability-helper';
import { useNavigate } from 'react-router'
import { JobEmploymentCancelForm } from 'types/interfaces'
import { Alert, Button, IconButton, Paper } from '@mui/material'
import Header from 'components/Header'
import { CancelOutlined } from '@mui/icons-material'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import { useJobEmployment } from 'contexts/JobEmploymentProvider';
import { nurseViewJobEmploymentURL } from 'routes/urls';
import { assign } from 'lodash';
import CheckboxFormField from 'components/CheckboxFormField';


const NurseCancelJobEmployment: React.FC = () => {
  const [form, setForm] = React.useState<JobEmploymentCancelForm>({ late_fee_accepted: false })
  const [error, setError] = React.useState<JobEmploymentCancelError>()
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()
  const navigate = useNavigate()
  const { je } = useJobEmployment()
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function submit(confirm_cancel: boolean) {
    if (!je) return
    try {
      setLoading(true)
      await api.cancelJobEmployment(je.id, assign({ confirm_cancel }, form))
      if (confirm_cancel) {
        showAlert('success', 'Job is cancelled.')
      } else {
        showAlert('success', 'Thank you for not cancelling your job. The practice will be pleased to see you soon.')
      }
      navigate(nurseViewJobEmploymentURL(je.nurse_id, je.id))
    } catch (e: any) {
      if (e instanceof JobEmploymentCancelError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  function goBack() {
    if (!je) return
    navigate(nurseViewJobEmploymentURL(je.nurse_id, je.id))
  }

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1}}>
      <Header variant='h2' text='Cancel Job'>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <FormField
        name='reason'
        label='Cancel Reason'
        multiline
        rows={4}
        onChange={(e) => updateForm('reason', e.target.value)}
        value={form.reason}
        errors={error?.reason}
      />
      { je?.late_cancel_fee_for_nurse_may_apply ? (
        <React.Fragment>
          <Alert severity='info' variant='filled'>
          There is a fee if you cancel this job which starts within 5 days from now. <br />
          0 - 24 hours, £40 fee <br />
          24 hours - 5 days, £20 fee <br />
          This is because when a job is cancelled within 5 days of the start time, it can be difficult to find a replacement nurse and there are associated costs.<br />
          If you do not go to a booked job we will suspend your account indefinitely.
          </Alert>
          <Alert severity='info' variant='filled'>
          The cancellation fee will come from your pending payouts. <br/>
          If you have no pending payouts you will receive an email from Stripe outlining deduction of the fee from a bank account in a few days time.
          </Alert>
          <CheckboxFormField
            name='notify_registration_prompts'
            label='I agree to pay the late cancellation fee'
            onChange={(e) => updateForm('late_fee_accepted', e.target.checked)}
            checked={form.late_fee_accepted ?? false}
            errors={error?.late_fee_accepted}
          />
          {error?.schema ? (
            <Alert severity='error' variant='filled'><ErrorList errors={error.schema} /></Alert>
          ) : null}
          <Button variant="contained" color="primary" onClick={() => submit(true)} disabled={loading}>
            Cancel Job and Pay the Late Fee
          </Button>
          <Button variant="outlined" color="primary" onClick={() => submit(false)} disabled={loading}>
            Don't Cancel
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {error?.schema ? (
            <Alert severity='error' variant='filled'><ErrorList errors={error.schema} /></Alert>
          ) : null}
          <Button variant="contained" color="primary" onClick={() => submit(true)} disabled={loading}>
            Cancel Job
          </Button>
        </React.Fragment>
      ) }
    </Paper>
  )
}

export default NurseCancelJobEmployment