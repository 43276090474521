import { User as FirebaseUser } from 'firebase/auth'
import { isEmpty } from 'lodash'
import { UserClaim } from '../types/interfaces'

export class User {
  constructor(readonly firebaseUser: FirebaseUser, readonly claims: UserClaim) { }

  get id() {
    return this.claims.account_id
  }
  
  get firebaseId() {
    return this.firebaseUser.uid
  }

  get email() {
    return this.firebaseUser.email
  }

  get name() {
    return this.firebaseUser.displayName
  }

  get practiceIds() {
    if (this.claims.practice_ids) {
      return this.claims.practice_ids
    } else if (this.claims.practice_group_practice_ids) {
      return this.claims.practice_group_practice_ids
    } else {
      return []
    }
  }

  get practiceGroupIds() {
    return this.claims.practice_group_ids ?? []
  }

  get nurseId() {
    return this.claims.nurse_id
  }

  get affiliateId() {
    return this.claims.affiliate_id
  }

  get isStaff() {
    return this.claims.is_staff
  }

  get isPractice() {
    return this.practiceIds !== null && this.practiceIds !== undefined && !isEmpty(this.practiceIds)
  }

  get isNurse() {
    return this.nurseId !== null && this.nurseId !== undefined
  }

  get isPracticeGroup() {
    return this.practiceGroupIds !== null && this.practiceGroupIds !== undefined && !isEmpty(this.practiceGroupIds)
  }

  get isAffiliate() {
    return this.affiliateId !== null && this.affiliateId !== undefined
  }

  get userType() {
    if (this.isStaff) {
      return 'staff'
    } else if (this.isNurse) {
      return 'nurse'
    } else if (this.isPracticeGroup) {
      return 'practice-group'
    } else if (this.isPractice) {
      return 'practice'
    } else if (this.isAffiliate) {
      return 'affiliate'
    }
    return 'guest'
  }
}