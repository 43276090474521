import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

interface SplitButtonOption {
  value: string
  label: string
  onClick: () => Promise<void> | void
}

interface SplitButtonProps {
  options: SplitButtonOption[]
}

const SplitButton: React.FC<SplitButtonProps> = ({ options }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [selectedOption, setSelectedOption] = React.useState<SplitButtonOption>(options[0])
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const togglePopper = () => setOpen(!open)

  const onButtonClicked = selectedOption.onClick
  const onDropdownSelected = (option: SplitButtonOption) => {
    setSelectedOption(option)
    togglePopper()
  }

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button onClick={selectedOption.onClick}>{selectedOption.label}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={togglePopper}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <MenuList id="split-button-menu" autoFocusItem>
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === selectedOption.value}
                  onClick={() => onDropdownSelected(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </React.Fragment>
  )

}

export default SplitButton