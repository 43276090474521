import { GridRowParams } from '@mui/x-data-grid'
import AdminPracticeGroupTable from 'components/AdminPracticeGroupTable'
import PracticeGroup from 'logic/PracticeGroup'
import AdminAffiliateDetailContext from 'pages/AdminAffiliateDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminPracticeGroupDetailURL } from 'routes/urls'

const AdminAffiliatePracticeGroups: React.FC = () => {
  const {
    practiceGroupSearchForm,
    practiceGroupSelection,
    practiceGroupColumnVisibilityModel,
    practiceGroupSortModel,
    updatePracticeGroupSearchForm,
    setPracticeGroupSelection,
    setPracticeGroupColumnVisibilityModel,
    setPracticeGroupSortModel,
  } = React.useContext(AdminAffiliateDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<PracticeGroup>) => {
    navigate(adminPracticeGroupDetailURL(params.row.id))
  }, [navigate])

  return (
    <AdminPracticeGroupTable
      onRowClick={onRowClick}
      form={practiceGroupSearchForm}
      updateForm={updatePracticeGroupSearchForm}
      selection={practiceGroupSelection}
      setSelection={setPracticeGroupSelection}
      columnVisibilityModel={practiceGroupColumnVisibilityModel}
      setColumnVisibilityModel={setPracticeGroupColumnVisibilityModel}
      sortModel={practiceGroupSortModel}
      setSortModel={setPracticeGroupSortModel}
    />
  )
}

export default AdminAffiliatePracticeGroups