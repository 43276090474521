import { Typography, Box, Chip, Link } from '@mui/material'
import { Type, Transform, plainToClass } from 'class-transformer'
import PaymentMethod from 'logic/PaymentMethod'
import { DateTime } from 'luxon'
import { PaymentRecord } from 'types/interfaces'
import { PaymentOption, PaymentMethodType, InvoiceStatus, StripePaymentIntentStatus, AdditionalChargeStatus } from 'types/types'

export default class AdditionalCharge implements PaymentRecord {
  id!: number
  employment_id!: number
  description!: string
  nurse_fees!: number
  locumloop_fees!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  // payment options
  customer_id!: string
  payment_method_id!: number
  payment_method_stripe_id!: string
  payment_method_type!: PaymentMethodType
  payment_method_brand!: string
  payment_method_exp_month!: number
  payment_method_exp_year!: number
  payment_method_last4!: string
  payment_method_sort_code!: string
  payment_method_error!: string
  pay_by_invoice!: boolean
  invoice_cycle!: string
  invoice_autopay!: boolean
  payment_option!: PaymentOption

  // if pay_by_invoice is False
  payment_intent_id!: string
  payment_intent_status!: StripePaymentIntentStatus
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  payment_intent_paid_at!: DateTime | null
  payment_intent_error!: string
  payment_intent_payment_method_id!: string

  // if pay_by_invoice is True
  invoice_id!: number
  invoice_stripe_id!: string
  invoice_hosted_url!: string
  invoice_pdf!: string
  invoice_number!: string
  invoice_receipt_number!: string
  invoice_status!: InvoiceStatus
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  invoice_paid_at!: DateTime | null
  invoice_charge_error!: string
  invoice_charge_payment_method_id!: string
  invoice_item_id!: string
  invoice_transfer_id!: string

  // annotate status
  status!: AdditionalChargeStatus

  // annotate employment
  job_id!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  job_start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  job_end_at!: DateTime
  nurse_id!: number
  nurse_full_name!: string
  practice_id!: number
  practice_name!: string
  practice_tz!: string

  // annotate permission flags
  can_update!: boolean
  cannot_update_reason!: string
  can_delete!: boolean
  cannot_delete_reason!: string

  created_at_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd') {
    return this.created_at.setZone(tz).toFormat(format)
  }

  get total_fees() {
    return this.nurse_fees + this.locumloop_fees
  }

  get nurse_fees_label() {
    return `£${(this.nurse_fees / 100).toFixed(2)}`
  }

  get locumloop_fees_label() {
    return `£${(this.locumloop_fees / 100).toFixed(2)}`
  }

  get payment_method_label() {
    if (this.pay_by_invoice && !this.invoice_autopay) {
      return 'manual'
    } else {
      const pm = plainToClass(PaymentMethod, {
        id: this.payment_method_id,
        payment_method_id: this.payment_method_stripe_id,
        type: this.payment_method_type,
        brand: this.payment_method_brand,
        exp_month: this.payment_method_exp_month,
        exp_year: this.payment_method_exp_year,
        last4: this.payment_method_last4,
        sort_code: this.payment_method_sort_code,
        error: this.payment_method_error,
      })
      return pm.label
    }
  }

  // implement Payment Record
  record_type: 'additional_charge' = 'additional_charge'
  get record_id() {
    return `additional_charge-${this.id}`
  }
  get record_path() {
    return [`payment-${this.employment_id}`, `additional_charge-${this.id}`]
  }

  get record_sort_key() {
    return [this.job_start_at.toISO(), this.created_at.toISO()]
  }
  get nurse_name() {
    return this.nurse_full_name
  }
  get timestamp() {
    return this.created_at
  }
  timestamp_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd') {
    return this.created_at_label(tz, format)
  }
  get paid_at() {
    if (this.pay_by_invoice) {
      return this.invoice_paid_at
    } else {
      return this.payment_intent_paid_at
    }
  }
  paid_at_label(tz: string, format: string = 'ccc, LLL dd') {
    return this.paid_at?.setZone(tz).toFormat(format) ?? ''
  }
  get amount() {
    return this.total_fees
  }
  get amount_label() {
    return `£${(this.amount / 100).toFixed(2)}`
  }

  get adjustment() {
    return this.total_fees
  }

  get adjustment_label() {
    return `+ £${(this.adjustment / 100).toFixed(2)}`
  }

  get fee_correction_reason() {
    return this.description
  }

  get status_label() {
    const is_payment_success = this.status === 'additional_charge_payment_success'
    const is_payment_error = this.status === 'additional_charge_payment_error'
    const is_payment_pending = this.status === 'additional_charge_payment_pending'
    const is_payment_requested = this.status === 'additional_charge_payment_requested'
    const is_invoice_pending = this.status === 'additional_charge_invoice_pending'
    const is_invoice_finalized = this.status === 'additional_charge_invoice_finalized'
    const is_invoice_paid = this.status === 'additional_charge_invoice_paid'
    const is_invoice_void = this.status === 'additional_charge_invoice_void'
    const is_invoice_payment_failed = this.status === 'additional_charge_invoice_payment_failed'

    if (is_payment_success) {
      return <Typography variant='body2'>Paid via {this.payment_method_label}</Typography>
    } else if (is_payment_error) {
      if (this.payment_method_stripe_id === this.payment_intent_payment_method_id 
        || this.payment_intent_payment_method_id === '') {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>Payment Error</Typography>
            <Chip label={this.payment_method_label} size='small' />
            <Chip label={this.payment_method_error} color='error' size='small' />
          </Box>
        )
      } else {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>Retrying Payment</Typography>
            <Chip label={this.payment_method_label} size='small' />
          </Box>
        )
      }
    } else if (is_payment_pending) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Payment Pending</Typography>
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_payment_requested) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Payment Processing</Typography>
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_invoice_pending) {
      return <Typography variant='body2'>Included in upcoming invoice</Typography>
    } else if (is_invoice_paid) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Paid via Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
        </Box>
      )
    } else if (is_invoice_payment_failed) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>
          <Link component='a' href={this.invoice_hosted_url} target="_blank">Retry Payment for Invoice #{this.invoice_number}</Link>
          <Chip label={this.invoice_charge_error} color='error' size='small' />
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_invoice_finalized) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Sent Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
          <Link component='a' href={this.invoice_hosted_url} target="_blank">Pay Invoice</Link>
        </Box>
      )
    } else if (is_invoice_void) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Voided Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
        </Box>
      )
    }
    return ''
  }
}