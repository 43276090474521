import React from 'react'
import { Role } from 'types/types';

interface INurseCalendarContext {
  roles: Role[]
}

const NurseCalendarContext = React.createContext<INurseCalendarContext>({ roles: [] } as INurseCalendarContext)

export default NurseCalendarContext
