import React from 'react'
import { Notification } from 'logic/Notification'
import { Subscription } from 'rxjs'
import { useFirebase } from 'contexts/FirebaseProvider'
import { collection, query, where, writeBatch, getDocs } from "firebase/firestore";
import NotificationDisplay from './Display'
import { usePractice } from 'contexts/PracticeProvider'

const PracticeNotificationList: React.FC = () => {
  const { firestore } = useFirebase()
  const { practice, notificationList$ } = usePractice()
  const [notifications, setNotifications] = React.useState<Notification[]>([])

  React.useEffect(() => {
    const sub = new Subscription()
    sub.add(notificationList$.subscribe(setNotifications))
    return () => sub.unsubscribe()
  }, [notificationList$])

  async function markRead() {
    const notifications = collection(firestore, 'notifications')
    const docs = await getDocs(query(notifications,
      where("practice_id", "==", practice?.practice_id),
      where("unread_by_practice", "==", true),
    ))
    const batch = writeBatch(firestore)
    docs.forEach(doc => {
      batch.update(doc.ref, { unread_by_practice: false })
    })
    batch.commit()
  }

  const tz = practice?.tz ?? 'Europe/London'
  const isUnread = (notification: Notification) => notification.unread_by_practice
  const getMessage = (notification: Notification) => notification.practice_message

  return <NotificationDisplay
    notifications={notifications}
    markRead={markRead}
    getMessage={getMessage}
    tz={tz}
    isUnread={isUnread}
  />
}

export default PracticeNotificationList