import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { 
  adminPracticeDetailURL,
  adminNurseDetailURL,
} from 'routes/urls'
import { useAPI } from 'contexts/APIProvider'
import { 
  DataGridPro, 
  GridColDef, 
  GridRenderCellParams, 
  GridValueGetterParams,
  GridColumnVisibilityModel,
  GridSortModel,
  GridEventListener,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { 
  Link,
  Alert,
  Box,
} from '@mui/material'
import { BlacklistedNurseSearchForm } from 'types/interfaces';
import BlacklistedNurse from 'logic/BlacklistedNurse';
import { Observable, of } from 'rxjs';

interface AdminBlacklistedNurseTableProps {
  reload$?: Observable<boolean>
  onRowClick?: GridEventListener<"rowClick">
  form: BlacklistedNurseSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminBlacklistedNurseTable: React.FC<AdminBlacklistedNurseTableProps> = ({
  reload$,
  onRowClick,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [nurses, setNurses] = React.useState<BlacklistedNurse[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchNurses = React.useCallback(async () => {
    setLoading(true)
    setNurses(await api.listBlacklistNurse(form))
    setLoading(false)
  }, [api, form])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  React.useEffect(() => {
    fetchNurses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchNurses())
    return () => sub?.unsubscribe()
  }, [fetchNurses, reload$])

  const columns: GridColDef<BlacklistedNurse>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<BlacklistedNurse>) => {
      return params.row.practice.practice_name
    },
    renderCell: (params: GridRenderCellParams<BlacklistedNurse>) => {
      const blacklistedNurse = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link typography='body1' component={RouterLink} to={adminPracticeDetailURL(blacklistedNurse.practice.practice_id)}>
            {blacklistedNurse.practice.practice_name}
          </Link> 
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Nurse',
    field: 'nurse_name',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<BlacklistedNurse>) => {
      return params.row.nurse_full_name
    },
    renderCell: (params: GridRenderCellParams<BlacklistedNurse>) => {
      const blacklistedNurse = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link typography='body1' component={RouterLink} to={adminNurseDetailURL(blacklistedNurse.nurse.nurse_id)}>
            {blacklistedNurse.nurse_full_name}
          </Link>
        </Box>
      )
    }
  }], [])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      {isEmpty(nurses) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{nurses.length} records returned</Alert>
          <DataGridPro 
            rows={nurses}
            columns={columns}
            getRowId={(employment) => employment.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminBlacklistedNurseTable