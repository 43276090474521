import { Alert, Box, Button, Tab, Tabs } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { usePracticeGroup } from 'contexts/PracticeGroupProvider'
import React from 'react'
import Header from 'components/Header'
import BillingAccount from 'logic/BillingAccount';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import Loading from 'components/Loading';
import PaymentsTable from 'components/PaymentsTable';
import InvoicesTable from 'components/InvoicesTable';
import { isEmpty } from 'lodash'

type TAB = 
| 'payments-in-progress'
| 'payments-completed'
| 'invoices'

const PracticeGroupPaymentHistory: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { practiceGroup } = usePracticeGroup()
  const [accounts, setAccounts] = React.useState<BillingAccount[]>()
  const [selectedAccount, setSelectedAccount] = React.useState<BillingAccount>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedTab, setSelectedTab] = React.useState<TAB>('payments-completed')

  const fetchBillingAccounts = React.useCallback(async () => {
    if (!practiceGroup) return
    try {
      setLoading(true)
      const results = await api.listBillingAccounts({ practice_group_id: practiceGroup.id })
      setAccounts(results)
      if (results.length > 0) {
        setSelectedAccount(results[0])
      }
    } catch (e) {
      showAlert('error', 'Error fetching billing accounts')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, practiceGroup, showAlert])

  React.useEffect(() => {
    fetchBillingAccounts()
  }, [fetchBillingAccounts])

  if (loading || accounts === undefined) {
    return <Loading />
  }
  
  if (isEmpty(accounts) || !selectedAccount) {
    return <Alert variant='filled' severity='info'>Payment Method not setup yet.</Alert>
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Payment History' />
      {accounts.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {accounts.map(account => (
            <Button 
              key={account.id}
              variant={account.id === selectedAccount.id ? 'contained' : 'outlined'} 
              onClick={() => setSelectedAccount(account)}>
              {account.name}
            </Button>
          ))}
        </Box>
      ) : null}
      <Tabs value={selectedTab} onChange={(e, tab) => setSelectedTab(tab)}>
        <Tab label='Payments in progress' value='payments-in-progress' />
        <Tab label='Payments completed' value='payments-completed' />
        <Tab label='Invoices' value='invoices' />
      </Tabs>
      {selectedTab === 'payments-completed' ? (
        <PaymentsTable customer_id={selectedAccount.customer_id} payment_statuses={[
          'practice_invoice_paid', 
          'practice_invoice_void', 
          'practice_paid'
        ]} />
      ) : selectedTab === 'payments-in-progress' ? (
        <PaymentsTable customer_id={selectedAccount.customer_id} payment_statuses={[
          'practice_invoice_finalized', 
          'practice_invoice_payment_failed',
          'practice_payment_pending', 
          'practice_payment_requested', 
          'practice_payment_error'
        ]} />
      ) : selectedTab === 'invoices' ? (
        <InvoicesTable billing_account_id={selectedAccount.id} />
      ) : null}
    </Box>
  )
}

export default PracticeGroupPaymentHistory