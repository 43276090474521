import { MenuItem } from '@mui/material'
import FormField from 'components/FormField'
import { useAPI } from 'contexts/APIProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'
import Practice from 'logic/Practice'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { practiceJobsURL } from 'routes/urls'

const PracticePicker: React.FC = () => {
  const { api } = useAPI()
  const { authUser } = useAuthUser()
  const params = useParams()
  const navigate = useNavigate()
  const [practices, setPractices] = React.useState<Practice[]>([])

  const selectedPracticeId = React.useMemo(() => Number(params.practiceId), [params.practiceId])

  const fetchPractices = React.useCallback(async () => {
    if (authUser) {
      setPractices(await api.listPractices())
    } else {
      setPractices([])
    }
  }, [api, authUser])

  const switchPractice = React.useCallback((newPracticeId: number) => {
    navigate(practiceJobsURL(newPracticeId))
  }, [navigate])

  React.useEffect(() => {
    fetchPractices()
  }, [fetchPractices])

  if (practices.length <= 1) return null
  
  return (
    <FormField
      size='small'
      sx={{ width: [100, 300] }}
      select
      onChange={(e) => switchPractice(Number(e.target.value))}
      value={selectedPracticeId}>
      {practices.map((practice) => (
        <MenuItem key={practice.practice_id} value={practice.practice_id}>
          {practice.practice_name}
        </MenuItem>
      ))}
    </FormField>
  )

}

export default PracticePicker