import { Alert, Box, Button, MenuItem } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { PracticeChurnReportItem, PracticeChurnReportSearchForm } from 'types/interfaces'
import { 
  DataGridPro, 
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import ReportSelectBox from 'pages/AdminReports/ReportSelectBox'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import update from 'immutability-helper'
import FormField from 'components/FormField'
import PracticeGroup from 'logic/PracticeGroup'
import AutoCompleteSelectField from 'components/AutoCompleteSelectField'
import { PracticeChurnStatus } from 'types/types'
import { DateTime } from 'luxon'
import { round } from 'lodash'

const churnStatuses: PracticeChurnStatus[] = [
  'not-enough-statistics',
  'posting-as-usual',
  'posting-much-less',
  'posting-slightly-less',
  'posting-slightly-more',
  'posting-much-more',
]

const PracticeChurnReport: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [form, setForm] = React.useState<PracticeChurnReportSearchForm>({
    target_date: DateTime.now().toFormat('yyyy-MM-dd'),
    trailing_months: 6,
    churn_status: 'posting-much-less',
  })
  const [items, setItems] = React.useState<PracticeChurnReportItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchPracticeGroups = React.useCallback(async (searchTerm: string) => {
    if (!searchTerm) return
    setPracticeGroups(await api.listPracticeGroup({ name: searchTerm }))
}, [api])

  const fetchReport = React.useCallback(async () => {
    try {
      setLoading(true)
      setItems(await api.getPracticeChurnReport(form))
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error loading report')
    } finally {
      setLoading(false)
    }
  }, [api, form, showAlert])

  React.useEffect(() => {
    fetchReport()
  }, [])

  const columns: GridColDef<PracticeChurnReportItem>[] = React.useMemo(() => [{
    minWidth: 100,
    headerName: 'Practice ID',
    field: 'practice_id',
    type: 'number',
  }, {
    minWidth: 300,
    headerName: 'Practice Name',
    field: 'practice_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Practice Group Name',
    field: 'practice_group_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Num Jobs this month',
    field: 'num_jobs',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: `Avg (stddev) last ${form.trailing_months} months`,
    field: 'avg_num_jobs',
    type: 'number',
    renderCell: (params: GridRenderCellParams<PracticeChurnReportItem>) => {
      const item = params.row
      const avg = round(item.avg_num_jobs, 2)
      const stddev = round(item.stddev_num_jobs, 2)
      return `${avg} (±${stddev})`
    }
  }, {
    minWidth: 250,
    headerName: 'Status',
    field: 'churn_status',
    type: 'string',
  }], [])

  const practiceGroupSelectOptions = React.useMemo(() => {
    return practiceGroups.map((practiceGroup) => ({
      value: practiceGroup.id,
      label: practiceGroup.name,
    })).concat([{
        value: -1,
        label: 'Independent'
    }])
  }, [practiceGroups])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practice Retention Report'>
        <ReportSelectBox />
      </Header>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          type='date'
          name='target_date'
          label='Date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => updateForm('target_date', e.target.value)}
          value={form.target_date ?? ''}/>
        <FormField
            type='number'
            name='trailing_months'
            label='Lookback Period (months)'
            onChange={(e) => updateForm('trailing_months', e.target.value)}
            value={form.trailing_months ?? ''}
        />
        <AutoCompleteSelectField
            label='Select a practice group'
            sx={{ width: 250 }}
            options={practiceGroupSelectOptions}
            fetchOptions={fetchPracticeGroups}
            value={form.practice_group_id ?? ''}
            onChange={(value) => updateForm('practice_group_id', value)}
        />
        <FormField
            select
            name='churn_status'
            label='Status'
            sx={{ width: 250 }}
            onChange={(e) => updateForm('churn_status', e.target.value)}
            value={form.churn_status ?? ''}
        >
            <MenuItem key='all' value=''>All</MenuItem>
            {churnStatuses.map((status) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
            ))}
        </FormField>
        <Button
          variant="contained"
          onClick={fetchReport}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      <Alert severity='info'>{items.length} records returned</Alert>
      <DataGridPro 
        rows={items}
        columns={columns}
        getRowId={(item) => item.practice_name}
        hideFooter
      />
    </Box>
  )
}

export default PracticeChurnReport