import { isEmpty } from 'lodash'
import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface ErrorListProps {
  errors?: string[]
}

const ErrorList: React.FC<ErrorListProps> = ({ errors = [] }) => {
  if (isEmpty(errors)) {
    return null
  }

  return (
    <List dense disablePadding>
      {errors.map((error, i) => (
        <ListItem key={i} dense disableGutters disablePadding>{error}</ListItem>
      ))}
    </List>
  )
}

export default ErrorList