import { 
  Box, 
  Typography, 
  Tabs, 
  Tab,
  ButtonGroup,
  Button,
 } from '@mui/material'
import {
  practiceGroupIssueLateFeeURL,
  practiceGroupPaymentsURL,
  practiceGroupPracticeListURL,
  practiceGroupTimesheetListURL,
} from 'routes/urls'
import { Outlet, Link as RouterLink, useMatch, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import Loading from 'components/Loading'
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { JobEmploymentSearchForm, ChartSearchForm, JobSearchForm, PracticeSearchForm } from 'types/interfaces';
import update from 'immutability-helper'
import AdminPracticeGroupDetailContext from 'pages/AdminPracticeGroupDetail/context';
import PracticeGroup from 'logic/PracticeGroup';
import { practiceGroupBillingAccountListURL } from 'routes/urls';
import SplitButton from 'components/SplitButton'

const AdminPracticeGroupDetail: React.FC = () => {
  const { api } = useAPI()
  const params = useParams()
  const navigate = useNavigate()
  const [group, setGroup] = React.useState<PracticeGroup>()
  const match = useMatch({ path: '/admin/practice-groups/:practiceGroupId/:rest', caseSensitive: false, end: false })

  // Jobs Tab Context State
  const [jobSearchForm, setJobSearchForm] = React.useState<JobSearchForm>({
    practice_group_id: Number(params.practiceGroupId)
  })
  const [jobSelection, setJobSelection] = React.useState<GridRowId[]>([])
  const [jobColumnVisibilityModel, setJobColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [jobSortModel, setJobSortModel] = React.useState<GridSortModel>([])

  const updateJobSearchForm = React.useCallback((name: string, value: any) => {
    setJobSearchForm(update(jobSearchForm, { [name]: { $set: value } }))
  }, [jobSearchForm])

  // Matches Tab Context State
  const [matchesSearchForm, setMatchesSearchForm] = React.useState<JobEmploymentSearchForm>({
    practice_group_id: Number(params.practiceGroupId)
  })
  const [matchesSelection, setMatchesSelection] = React.useState<GridRowId[]>([])
  const [matchesColumnVisibilityModel, setMatchesColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [matchesSortModel, setMatchesSortModel] = React.useState<GridSortModel>([])

  const updateMatchesSearchForm = React.useCallback((name: string, value: any) => {
    setMatchesSearchForm(update(matchesSearchForm, { [name]: { $set: value } }))
  }, [matchesSearchForm])

  // Practices Tab Context State
  const [practiceSearchForm, setPracticeSearchForm] = React.useState<PracticeSearchForm>({
    practice_group_id: Number(params.practiceGroupId)
  })
  const [practiceSelection, setPracticeSelection] = React.useState<GridRowId[]>([])
  const [practiceColumnVisibilityModel, setPracticeColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [practiceSortModel, setPracticeSortModel] = React.useState<GridSortModel>([])

  const updatePracticeSearchForm = React.useCallback((name: string, value: any) => {
    setPracticeSearchForm(update(practiceSearchForm, { [name]: { $set: value } }))
  }, [practiceSearchForm])

  // Charts Context State
  const [chartsSearchForm, setChartsSearchForm] = React.useState<ChartSearchForm>({
    practice_group_id: Number(params.practiceGroupId)
  })

  const updateChartsSearchForm = React.useCallback((name: string, value: any) => {
    setChartsSearchForm(update(chartsSearchForm, { [name]: { $set: value } }))
  }, [chartsSearchForm])

  // Init Page
  const fetchGroup = React.useCallback(async () => {
    setGroup(await api.getPracticeGroup(Number(params.practiceGroupId)))
  }, [api, params.practiceGroupId])

  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'members'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchGroup()
  }, [fetchGroup])

  if (!group) return <Loading />

  const contextValue = {
    group,
    fetchGroup,

    // job tab
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,

    // matches tab
    matchesSearchForm,
    matchesSelection,
    matchesColumnVisibilityModel,
    matchesSortModel,
    updateMatchesSearchForm,
    setMatchesSelection,
    setMatchesColumnVisibilityModel,
    setMatchesSortModel,

    // practices tab
    practiceSearchForm,
    practiceSelection,
    practiceColumnVisibilityModel,
    practiceSortModel,
    updatePracticeSearchForm,
    setPracticeSelection,
    setPracticeColumnVisibilityModel,
    setPracticeSortModel,

    // charts tab
    chartsSearchForm,
    updateChartsSearchForm,
  }
  return (
    <AdminPracticeGroupDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 1, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Typography variant='h1'>{ group.name }</Typography>
          <ButtonGroup size='small'>
            <Button 
              component={RouterLink}
              to={practiceGroupPaymentsURL(group.id)}
              target='_blank'
            >
              Payment History
            </Button>
            <Button 
              component={RouterLink}
              to={practiceGroupPracticeListURL(group.id)}
              target='_blank'
            >
              Practices
            </Button>
            <Button 
              component={RouterLink}
              to={practiceGroupBillingAccountListURL(group.id)}
              target='_blank'
            >
              Billing Accounts
            </Button>
            <Button 
              component={RouterLink}
              to={practiceGroupTimesheetListURL(group.id)}
              target='_blank'
            >
              Timesheets
            </Button>
          </ButtonGroup>
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'issue-invoice-late-fee',
            label: 'Issue Invoice Late Fee',
            onClick: () => navigate(practiceGroupIssueLateFeeURL(group.id))
          }]}/>
        </Box>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label='Members' value='members' onClick={() => navigate('members')}/>
          <Tab label='Practices' value='practices' onClick={() => navigate('practices')}/>
          <Tab label='Jobs' value='jobs' onClick={() => navigate('jobs')}/>
          <Tab label='Matches' value='matches' onClick={() => navigate('matches')}/>
          <Tab label='Charts' value='charts' onClick={() => navigate('charts')}/>
        </Tabs>
        <Outlet />
      </Box>
    </AdminPracticeGroupDetailContext.Provider>
  )
}

export default AdminPracticeGroupDetail