import { Alert, Box, Button, Typography } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { useAffiliate } from 'contexts/AffiliateProvider'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { affiliateTransactionListURL } from 'routes/urls'

const AffiliateStripeSetup: React.FC = () => {
  const { affiliate } = useAffiliate()
  const { api } = useAPI()
  const [stripeURL, setStripeURL] = React.useState<string>()

  const fetchStripeURL = React.useCallback(async () => {
    if (!affiliate?.id) return null
    if (affiliate.details_submitted) {
      setStripeURL(await api.getAffiliateStripeDashboardLink(affiliate.id))
    } else {
      setStripeURL(await api.getAffiliateStripeConnectLink(affiliate.id))
    }
  }, [affiliate?.details_submitted, affiliate?.id, api])

  function goToStripe() {
    if (stripeURL) window.open(stripeURL, "_self");
  }

  React.useEffect(() => {
    fetchStripeURL()
  }, [fetchStripeURL])

  if (!stripeURL) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Receiving Payments'></Header>
      {affiliate?.payouts_enabled ? (
        <React.Fragment>
          <Alert variant='filled' severity='success'>Your account is ready to receive payments!</Alert>
          <Button
            component={RouterLink}
            variant="contained" 
            color="primary"
            to={affiliateTransactionListURL(affiliate.id)}>
            View Transactions
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant='body1'>
            In order to receive payments, you need to set up an account with Stripe, our payments processor.
          </Typography>
          <Button
            disabled={!stripeURL}
            variant="contained" color="primary"
            onClick={goToStripe}>
            {!stripeURL ? 'Please Wait ...' : 'Payment Setup'}
          </Button>
        </React.Fragment>
      )}
    </Box>
  )
}

export default AffiliateStripeSetup