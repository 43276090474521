import { Box } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import SetupBillingAccount from 'components/SetupBillingAccount'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { usePractice } from 'contexts/PracticeProvider'
import React from 'react'
import { useNavigate } from 'react-router'
import { practiceBillingURL } from 'routes/urls'
import { PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES } from 'types/constants'
import { PaymentOption } from 'types/types'

const BillingAccountSetup: React.FC = () => {
    const { billingAccount, practice, reloadPractice } = usePractice()
    const { authUser } = useAuthUser()
    const navigate = useNavigate()

    const onSuccess = React.useCallback(() => {
        if (!practice) return
        reloadPractice()
        navigate(practiceBillingURL(practice.practice_id))
    }, [navigate, practice, reloadPractice])

    const availablePaymentOptions = React.useMemo(() => {
        const isStaff = authUser?.isStaff ?? false
        const isAutopay = !(billingAccount?.pay_by_invoice ?? false)
        if (!isStaff && isAutopay) {
            // Don't allow switching to invoicing if currently on autopay
            // In case an unvetted practice tries to switch to invoicing
            // Allow admins to override this.
            return ['pay_eod_with_card'] as PaymentOption[]
        }
        return PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES
    }, [authUser?.isStaff, billingAccount?.pay_by_invoice])

    if (!practice) return <Loading />

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Header text='Setup Billing Account' />
            <SetupBillingAccount
                practice={practice}
                billingAccount={billingAccount ?? undefined}
                onSuccess={onSuccess}
                availablePaymentOptions={availablePaymentOptions}
            />
        </Box>
    )
}

export default BillingAccountSetup