import { Alert, Box, Button } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import TimeSheetChange from 'logic/TimeSheetChange'
import React from 'react'
import { useNavigate } from 'react-router'
import { TimeSheetChangeForm } from 'types/interfaces'
import TimeSheetChangeError from 'errors/TimeSheetChangeError'
import update from 'immutability-helper'
import { practiceViewTimeSheetURL, nurseViewTimeSheetURL } from 'routes/urls'
import { isEmpty } from 'lodash'
import Loading from 'components/Loading'
import Header from 'components/Header'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import { useParams } from 'react-router'


const TimeSheetEditChange: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { showAlert } = useSnackBarAlert()
  const { practice } = usePractice()
  const { nurse } = useNurse()
  const { api } = useAPI()
  const [form, setForm] = React.useState<TimeSheetChangeForm>({})
  const [error, setError] = React.useState<TimeSheetChangeError>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tc, setTc] = React.useState<TimeSheetChange>()

  const usertype = React.useMemo(() => {
    if (practice) return 'practice'
    if (nurse) return 'nurse'
    return null
  }, [nurse, practice])

  const successURL = React.useMemo(() => {
    if (!tc) return null
    if (practice) return practiceViewTimeSheetURL(practice.practice_id, tc.job_employment_id)
    if (nurse) return nurseViewTimeSheetURL(nurse.nurse_id, tc.job_employment_id)
    return null
  }, [tc, nurse, practice])

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function fetchTC() {
    try {
      setTc(await api.getTimeSheetChange(Number(params.tcId)))
    } catch (e) {
      console.error(e)
    }
  }

  async function save() {
    if (!tc) return
    if (!successURL) return
    try {
      setLoading(true)
      await api.updateTimeSheetChange(tc.id, form)
      showAlert('success', 'TimeSheet change updated')
      navigate(successURL)
    } catch (e) {
      if (e instanceof TimeSheetChangeError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  async function prepopulateForm() {
    if (!tc) return
    if (!usertype) return
    if (!isEmpty(form)) return
    const tz = usertype === 'practice' ? tc.practice_tz : tc.nurse_tz
    setForm((form) => update(form, {
      new_start_time: { $set: tc.new_start_at.setZone(tz).toFormat('HH:mm') },
      new_end_time: { $set: tc.new_end_at.setZone(tz).toFormat('HH:mm') },
      new_lunch_break: { $set: tc.new_lunch_break.as('minute') },
      comments: { $set: tc.comments },
    }))
  }

  React.useEffect(() => {
    fetchTC()
  }, [params.tcId])


  React.useEffect(() => {
    prepopulateForm()
  }, [tc, usertype])

  if (isEmpty(form)) {
    return null
  }

  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h1' text='Edit Timesheet Change Request'></Header>
      <FormField
        name='new_start_time'
        label='Start Time'
        type='time'
        onChange={(e) => updateForm('new_start_time', e.target.value)}
        value={form.new_start_time}
        errors={error?.new_start_time}
      />
      <FormField
        name='new_end_time'
        label='End Time'
        type='time'
        onChange={(e) => updateForm('new_end_time', e.target.value)}
        value={form.new_end_time}
        errors={error?.new_end_time}
      />
      <FormField
        name='new_lunch_break'
        label='Lunch Break (minutes)'
        type='number'
        onChange={(e) => updateForm('new_lunch_break', e.target.value)}
        value={form.new_lunch_break}
        errors={error?.new_lunch_break}
      />
      <FormField
        name='comments'
        label='Reason'
        multiline
        rows={4}
        onChange={(e) => updateForm('comments', e.target.value)}
        value={form.comments}
        errors={error?.comments}
      />
      {error?.schema ? (
        <Alert severity='error' variant='filled'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Update'}
      </Button>
      <Button
        variant="outlined" color="primary" disabled={loading}
        onClick={() => successURL ? navigate(successURL) : null}>
        Cancel
      </Button>
    </Box>
  )
}

export default TimeSheetEditChange