import { Type, Transform, Expose } from 'class-transformer'
import { chain } from 'lodash'
import { DateTime } from 'luxon'
import { practiceJobListByDateURL, practiceViewJobURL } from 'routes/urls'
import { JobFulfillmentStatus, JobPaymentStatus, JobTimesheetStatus } from 'types/types'

interface PracticeDayJob {
  job_id: number
  headcount: number
  filled: number
  outstanding: number
  fulfillment_status: JobFulfillmentStatus
  payment_status: JobPaymentStatus
  timesheet_status: JobTimesheetStatus
  payment_required: number
  payment_success: number
  payment_failed: number
  timesheet_change_pending: number
}

export class PracticeDay {
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  date!: DateTime
  weeknum!: number
  weekdaynum!: number
  jobs: PracticeDayJob[] = []

  @Expose()
  get key() {
    return `${this.weeknum}-${this.weekdaynum}`
  }

  @Expose()
  get dayOfMonth() {
    return this.date.isValid ? this.date.day : null
  }

  @Expose()
  get isPast() {
    const today = DateTime.utc().toFormat('yyyy-LL-dd')
    const date = this.date.toFormat('yyyy-LL-dd')
    return date < today
  }

  get has_jobs() {
    return this.jobs.length > 0
  }

  clickURL(practiceId: number) {
    if (this.jobs.length === 1) {
      return practiceViewJobURL(practiceId, this.jobs[0].job_id)
    } else if (this.jobs.length > 1) {
      return practiceJobListByDateURL(practiceId, this.date.toFormat('yyyy-LL-dd'))
    } else {
      return null
    }
  }

  get total_filled() {
    return (
      chain(this.jobs)
      .map(j => j.filled)
      .sum()
      .value()
    )
  }

  get total_headcount() {
    return (
      chain(this.jobs)
      .map(j => j.headcount)
      .sum()
      .value()
    )
  }

  get timesheet_pending_approval() {
    return (
      chain(this.jobs)
      .map(j => j.timesheet_status)
      .some(s => ['timesheet_pending_approval'].includes(s))
      .value()
    )
  }

  get payment_failed() {
    return (
      chain(this.jobs)
      .map(j => j.payment_status)
      .some(s => ['practice_payment_failed', 'practice_invoice_void'].includes(s))
      .value()
    )
  }

  get payment_success() {
    return (
      chain(this.jobs)
      .map(j => j.payment_status)
      .every(s => ['practice_payment_success', 'practice_invoice_paid'].includes(s))
      .value()
    )
  }

  get payment_pending() {
    return (
      chain(this.jobs)
      .map(j => j.payment_status)
      .some(s => ['practice_invoice_finalized', 'practice_payment_pending'].includes(s))
      .value()
    )
  }

  get cancelled() {
    return (
      chain(this.jobs)
      .map(j => j.fulfillment_status)
      .every(s => s === 'cancelled')
      .value()
    )
  }

  get completely_filled() {
    return (
      chain(this.jobs)
      .map(j => j.fulfillment_status)
      .every(s => s === 'filled')
      .value()
    )
  }

  @Expose()
  get canAddJob() {
    return !this.has_jobs && !this.isPast && this.date.isValid
  }

  @Expose()
  get label() {
    if (this.has_jobs) {
      if (this.timesheet_pending_approval) {
        return 'timesheet changed'
      } else if (this.payment_failed) {
        return 'payment failed'
      } else if (this.payment_success) {
        return 'paid'
      } else if (this.payment_pending) {
        return 'pending payment'
      } else if (this.cancelled) {
        return 'cancelled'
      } else {
        return `${this.total_filled}/${this.total_headcount} Filled`
      }
    }
    return ''
  }

  @Expose()
  get backgroundColor() {
    if (!this.date.isValid) {
      return 'primary.light'
    } else if (this.has_jobs) {
      if (this.timesheet_pending_approval || this.payment_failed || this.cancelled) {
        return 'error.light'
      } else if (!this.completely_filled || this.payment_pending) {
        return 'warning.light'
      } else if (this.completely_filled || this.payment_success) {
        return 'success.light'
      } else {
        return 'background.paper'
      }
    } else {
      if (this.isPast) {
        return 'background.paper'
      } else {
        return 'background.default'
      }
    }
  }
}