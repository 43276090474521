import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import AddCircle from '@mui/icons-material/AddCircle'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Outlet, useMatch } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { usePractice } from 'contexts/PracticeProvider'
import Calendar from 'components/Calendar'
import CalendarDay from 'components/Calendar/Day'
import { switchMap, map, share, catchError } from 'rxjs'
import { docData } from 'rxfire/firestore';
import { doc } from "firebase/firestore";
import { PracticeDay } from 'logic/PracticeDay'
import useCalendar from 'hooks/useCalendar'
import { useFirebase } from 'contexts/FirebaseProvider'
import { chain, isEmpty } from 'lodash'
import { plainToClass } from 'class-transformer'
import { getEmptyCalendar } from 'logic/helpers'
import PracticeCalendarDay from './CalendarDay'
import { practiceEditDefaultJobOptionsURL } from 'routes/urls'
import Loading from 'components/Loading'

const PracticeJobs: React.FC = () => {
  const matchIndexPage = useMatch('/practice/:practiceId/jobs')
  const { firestore } = useFirebase()
  const { practice } = usePractice()
  const { interval, interval$, toNextMonth, toPreviousMonth, toCurrentMonth } = useCalendar(practice?.tz)
  const [days, setDays] = React.useState<PracticeDay[]>([])
  const [useDefaultJobOptions, setUseDefaultJobOptions] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!practice) {
      return
    }

    const source$ = interval$.pipe(
      switchMap(interval => {
        const path = `practices/${practice.firebase_id}/calendars/${interval.start.year}#${interval.start.month}`
        const ref = doc(firestore, path)
        return docData(ref).pipe(
          map(data => isEmpty(data) ? getEmptyCalendar(interval.start.year, interval.start.month) : data),
          catchError(async (e) => {
            console.error(e)
            return getEmptyCalendar(interval.start.year, interval.start.month)
          }),
        )
      }),
      map(data => (
        chain(data)
          .omit('permission')
          .values()
          .sortBy('weeknum', 'weekdaynum')
          .map(day => plainToClass(PracticeDay, day))
          .value()
      )),
      share(),
    )

    const sub = source$.subscribe(setDays)
    return () => sub.unsubscribe()
  }, [practice])

  if (!practice) return null

  if (isEmpty(days)) {
    return <Loading />
  }

  const isIndexPage = matchIndexPage !== null
  const checkBox = <Checkbox onChange={(e, checked) => setUseDefaultJobOptions(checked)} />
  const editJobOptionsURL = practiceEditDefaultJobOptionsURL(practice.practice_id)
  const checkBoxLabel = (<Box>
    Add Job <Link component={RouterLink} to={editJobOptionsURL}>{practice.defaultJobOptionLabel}</Link>
  </Box>)

  return (
    <Box sx={{
      display: ['block', isIndexPage ? 'block' : 'grid'],
      gridTemplateColumns: '1fr 1.5fr',
      gridTemplateAreas: `'left right'`,
      gap: 4,
    }}>
      <Box sx={{
        gridArea: 'left',
        display: [isIndexPage ? 'flex' : 'none', 'flex'],
        flexDirection: 'column',
        gap: 1,
        maxWidth: ['auto', isIndexPage ? '40%' : 'auto'],
        margin: [0, isIndexPage ? 'auto' : 0]
      }}>
        <Paper sx={{ padding: 1 }}>
          <Box sx={{ display: 'flex' }} >Click <AddCircle sx={{ px: 1 }} /> to add a job post.</Box>
          <FormControlLabel control={checkBox} label={checkBoxLabel} />
        </Paper>
        <Calendar
          interval={interval}
          toNextMonth={toNextMonth}
          toPreviousMonth={toPreviousMonth}
          toCurrentMonth={toCurrentMonth}>
          {days.map(day => (
            <CalendarDay
              key={day.key}
              weekDayNumber={day.weekdaynum}
              weekNumber={day.weeknum}
              dayOfMonth={day.dayOfMonth?.toString() || ''}
              sx={{ backgroundColor: day.backgroundColor }}>
              <PracticeCalendarDay day={day} useDefaultJobOptions={useDefaultJobOptions} />
            </CalendarDay>
          ))}
        </Calendar>
      </Box>
      <Box sx={{ gridArea: 'right' }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  )
}

export default PracticeJobs