import { Box, Button, Card, CardActions, CardContent, Chip, Typography, useTheme, Link } from '@mui/material'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link as RouterLink } from 'react-router-dom'
import { practiceSignupEditJobURL } from 'routes/urls'
import Job from 'logic/Job'
import React from 'react'
import { Color } from 'types/types';
import { useZendesk } from 'contexts/ZendeskProvider';

interface JobCardProps {
  job: Job
  cancelJob: (job: Job) => Promise<void>
}

const JobCard: React.FC<JobCardProps> = ({ job, cancelJob }) => {
  const theme = useTheme()
  const { openZendesk } = useZendesk()

  let backgroundColor = theme.palette.background.paper
  let statusLabel = ''
  let statusColor: Color = 'primary'
  switch (job.status_for_practice_signup_page) {
    case 'filled':
      backgroundColor = theme.palette.success.light
      statusColor = 'success'
      statusLabel = 'We have found a nurse for you!'
      break
    case 'expired':
      backgroundColor = theme.palette.error.light
      statusColor = 'error'
      statusLabel = 'Sorry we cannot match a nurse with you.'
      break
    case 'finding-nurse':
      backgroundColor = theme.palette.warning.light
      statusColor = 'warning'
      statusLabel = 'We are finding a nurse for you.'
      break
    case 'not-published':
      backgroundColor = theme.palette.warning.light
      statusColor = 'warning'
      statusLabel = 'Not published until payment option is selected.'
      break
    default:
      break;
  }

  const chatLink = (
    <Link color='secondary' onClick={() => openZendesk()} sx={{ cursor: 'pointer' }} >
      here
    </Link>
  )

  return (
    <Card key={job.job_id} sx={{ backgroundColor: backgroundColor }}>
      <CardContent sx={{
        display: 'grid',
        gridTemplateColumns: [
          'auto',
          'auto auto 1fr',
        ],
        gridTemplateRows: 'auto',
        gridTemplateAreas: [`
            'icon'
            'date'
            'chips'
            'status'`, `
            'icon date chips'
            'icon status status'`
        ],
        // gridTemplateColumns: 'auto auto 1fr',
        // gridTemplateRows: 'auto auto',
        // gridTemplateAreas: `
        // 'icon date chips'
        // 'icon status status'`,
        gap: 1,
        padding: 1,
        alignItems: 'center',
      }}>
        <Box sx={{ gridArea: 'date', alignSelf: 'center' }}>
          <Typography variant='h2'>{job.date_label(job.practice_tz)}</Typography>
        </Box>
        <Box sx={{ gridArea: 'chips', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Chip variant='outlined' size='small' label={job.time_label(job.practice_tz)} />
          <Chip variant='outlined' size='small' label={job.lunch_break_label} />
          <Chip variant='outlined' size='small' label={job.job_role} />
          <Chip variant='filled' color={statusColor} size='small' label={statusLabel} />
        </Box>
        <Box sx={{ gridArea: 'status' }}>
          {job.status_for_practice_signup_page === 'filled' ? (
            <Typography variant='body1'>
              A nurse has accepted your job! <br/>
              A payment card is required now. <br/>
              If you need to speak to your principal or finance department and need more time to enter your payment details, please reach out to us. <br/>
              Please note, we will only charge your card after a nurse completes the job. <br/>
              If you need anything, please message us by clicking {chatLink}, emailing us at hello@locumloop.com, 
              or calling us on 07360 272 394. We're always here to help. <br/>
            </Typography>
          ) : job.status_for_practice_signup_page === 'expired' ? (
            <Typography variant='body1'>
              Sorry we couldn't find you a match this time. <br/>
              Hundreds of nurses are joining locumloop each month. Whilst we weren't able to find anyone available in your area this time, 
              please check back next time you're hiring as we're growing quickly.  <br/>
              If you need anything, please message us by clicking {chatLink}, emailing us at hello@locumloop.com, 
              or calling us on 07360 272 394. We're always here to help. <br/>
            </Typography>
          ) : job.status_for_practice_signup_page === 'finding-nurse' ? (
            <Typography variant='body1'>
              Thanks for posting a job with locumloop! <br/>
              We are matching you with nurses in your area. Jobs are often filled within 15-45 minutes. <br/>
              Please note, a payment card is required once a nurse accepts your job. We will only charge your card after a nurse completes the job. <br/>
              If you need anything, please message us by clicking {chatLink}, emailing us at hello@locumloop.com, 
              or calling us on 07360 272 394. We're always here to help. <br/>
            </Typography>
          ) : job.status_for_practice_signup_page === 'not-published' ? (
            <Typography variant='body1'>
              We offer both card payments and invoicing. <br />
              Card payments are taken when a job is completed. Invoice payments are due within 7 days to help you manage your cash flow. <br />
              If you need anything please click {chatLink} or call us on 07360 272 394.<br/>
            </Typography>
          ) : null}
        </Box>
        <Box sx={{
          gridArea: 'icon',
          paddingX: [0, 1],
          display: 'flex',
          justifyContent: 'center',
        }}>
          {job.status_for_practice_signup_page === 'filled' ? (
            <CheckCircleIcon fontSize='large' color='success' />
          ) : job.status_for_practice_signup_page === 'expired' ? (
            <CancelIcon fontSize='large' color='error' />
          ) : (
            <PendingActionsIcon fontSize='large' color='warning' />
          )}
        </Box>
      </CardContent>
      <CardActions sx={{ pt: 0 }}>
        {job.fulfillment_status === 'not_filled' ? (
          <React.Fragment>
            <Button
              component={RouterLink}
              color='warning'
              to={practiceSignupEditJobURL(job.job_id)}
              size="small">
              Edit
            </Button>
            <Button
              onClick={() => cancelJob(job)}
              color='warning'
              size="small"
            >
              Cancel
            </Button>
          </React.Fragment>
        ) : null}
      </CardActions>
    </Card>
  )
}

export default JobCard