import { Type } from 'class-transformer'
import Nurse from "logic/Nurse"
import Practice from "logic/Practice"

export default class BlacklistedNurse {
  id!: number
  
  @Type(() => Nurse)
  nurse!: Nurse
  
  @Type(() => Practice)
  practice!: Practice

  get nurse_full_name() {
    return this.nurse.full_name
  }
}