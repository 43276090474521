import React from 'react'
import { Navigate, useLocation, useMatch, useParams } from 'react-router'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { homeURL, loginURL } from './urls'
import { nurseWelcomeURL, nurseContractAgreementURL } from 'routes/urls';
import { useAPI } from 'contexts/APIProvider';
import Nurse from 'logic/Nurse';

const IsNurse: React.FC = ({ children }) => {
  const { api } = useAPI()
  const location = useLocation()
  const params = useParams()
  const { authUser, logout } = useAuthUser()
  const [nurse, setNurse] = React.useState<Nurse>()
  const match = useMatch({ path: '/nurse/:nurse_id/:rest', caseSensitive: false, end: false })

  const nurseId = React.useMemo(() => Number(params.nurseId), [params.nurseId]) 

  const fetchNurse = React.useCallback(async () => {
    if (authUser?.nurseId === nurseId) {
      setNurse(await api.getNurse(nurseId))
    }
  }, [api, authUser?.nurseId, nurseId])

  React.useEffect(() => {
    fetchNurse()
  }, [fetchNurse]) 

  if (authUser === undefined) {
    return null
  } else if (authUser === null) {
    return <Navigate replace to={loginURL(location.pathname)} />
  } else if (authUser.isStaff) {
    return <React.Fragment>{children}</React.Fragment>
  } else if (authUser.nurseId === nurseId) {
    if (!nurse) {
      return null
    } else if (!nurse.agreed_contracts && match?.params.rest !== 'contracts') {
      return <Navigate replace to={nurseContractAgreementURL(nurseId)} />
    } else if (!nurse.enabled && match?.params.rest !== 'welcome') {
      return <Navigate replace to={nurseWelcomeURL(nurseId)} />
    } else {
      return <React.Fragment>{children}</React.Fragment>
    }
  } else {
    const redirectURL = homeURL(authUser)
    if (redirectURL) {
      return <Navigate replace to={redirectURL} />
    } else {
      logout()
      return <Navigate replace to={loginURL()} />
    }
  }
}

export default IsNurse