import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

type ErrorListProps = {
  errors?: string[]
}

type MultiSelectProps = {
  options: string[]
  label: string
  width?: number
} & SelectProps & ErrorListProps

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const { errors, options, label, width } = props

  return (
    <FormControl size='small' sx={{ width: width ?? 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple autoWidth {...props}>
        {options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </Select>
      {!isEmpty(errors) ? (
        <FormHelperText>
          <ErrorList errors={errors} />
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default MultiSelect