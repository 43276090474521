import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  SxProps
} from '@mui/material'
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

export interface CheckboxSelectOption {
  value: string | number
  label: string
  disabled?: boolean
}

interface CheckboxSelectFieldProps {
  value?: string | number
  options: CheckboxSelectOption[]
  onChange: (value: string | number) => void
  errors?: string[]
  sx?: SxProps
}

const CheckboxSelectField: React.FC<CheckboxSelectFieldProps> = ({ value, options, onChange, errors, sx }) => {
  return (
    <FormControl sx={sx}>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <FormControlLabel label={option.label} control={(
            <Checkbox 
              checked={value === option.value} 
              onChange={() => onChange(option.value)}
              disabled={option.disabled ?? false}
            />
          )} />
        </React.Fragment>
      ))}
      {!isEmpty(errors) ? (
        <Alert severity='error'><ErrorList errors={errors} /></Alert>
      ) : null}
    </FormControl>
  )
}

export default CheckboxSelectField