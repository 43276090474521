import { Typography, Box, Chip, Link } from '@mui/material'
import { Type, Transform, plainToClass } from 'class-transformer'
import PaymentMethod from 'logic/PaymentMethod'
import { DateTime } from 'luxon'
import { PaymentRecord } from 'types/interfaces'
import { RefundStatus, RefundSourceTransactionStatus, StripeRefundStatus, StripeCreditNoteStatus, PaymentMethodType, InvoiceStatus, PaymentOption } from 'types/types'

export default class Refund implements PaymentRecord {
    id!: number
    employment_id!: number
    description!: string
    nurse_fees!: number
    locumloop_fees!: number

    @Type(() => DateTime)
    @Transform(({ value }) => DateTime.fromISO(value))
    created_at!: DateTime

    refund_id!: string
    refund_status!: StripeRefundStatus
    refund_balance_transaction_id!: string
    refund_charge_id!: string
    refund_payment_intent_id!: string
    refund_failure_balance_transaction_id!: string
    refund_failure_reason!: string
    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    refund_paid_at!: DateTime | null
    application_fee_refund_id!: string

    recovery_transfer_id!: string
    recovery_transfer_balance_transaction_id!: string
    recovery_transfer_destination_payment_id!: string
    credit_note_id!: string
    credit_note_status!: StripeCreditNoteStatus
    credit_note_pdf!: string
    credit_note_refund_id!: string
    credit_note_refund_status!: StripeRefundStatus
    credit_note_refund_failure_reason!: string
    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    credit_note_refund_paid_at!: DateTime | null

    // annotate status
    source_transaction_status!: RefundSourceTransactionStatus
    status!: RefundStatus

    // annotate employment
    job_id!: number

    @Type(() => DateTime)
    @Transform(({ value }) => DateTime.fromISO(value))
    job_start_at!: DateTime

    @Type(() => DateTime)
    @Transform(({ value }) => DateTime.fromISO(value))
    job_end_at!: DateTime

    nurse_id!: number
    nurse_full_name!: string
    practice_id!: number
    practice_name!: string
    practice_tz!: string

    // annotate permission flags
    can_update!: boolean
    cannot_update_reason!: string
    can_delete!: boolean
    cannot_delete_reason!: string

    // payment options
    customer_id!: string
    payment_method_id!: number
    payment_method_stripe_id!: string
    payment_method_type!: PaymentMethodType
    payment_method_brand!: string
    payment_method_exp_month!: number
    payment_method_exp_year!: number
    payment_method_last4!: string
    payment_method_sort_code!: string
    payment_method_error!: string
    pay_by_invoice!: boolean
    invoice_cycle!: string
    invoice_autopay!: boolean
    payment_option!: PaymentOption

    // annotate invoice
    invoice_id!: number
    invoice_stripe_id!: string
    invoice_hosted_url!: string
    invoice_pdf!: string
    invoice_number!: string
    invoice_receipt_number!: string
    invoice_status!: InvoiceStatus
    invoice_charge_error!: string
    invoice_charge_payment_method_id!: string

    created_at_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd') {
        return this.created_at.setZone(tz).toFormat(format)
    }

    get total_fees() {
        return this.nurse_fees + this.locumloop_fees
    }

    get nurse_fees_label() {
        return `£${(this.nurse_fees / 100).toFixed(2)}`
    }

    get locumloop_fees_label() {
        return `£${(this.locumloop_fees / 100).toFixed(2)}`
    }

    get payment_method_label() {
        if (this.pay_by_invoice && !this.invoice_autopay) {
            return 'manual'
        } else {
            const pm = plainToClass(PaymentMethod, {
                id: this.payment_method_id,
                payment_method_id: this.payment_method_stripe_id,
                type: this.payment_method_type,
                brand: this.payment_method_brand,
                exp_month: this.payment_method_exp_month,
                exp_year: this.payment_method_exp_year,
                last4: this.payment_method_last4,
                sort_code: this.payment_method_sort_code,
                error: this.payment_method_error,
            })
            return pm.label
        }
    }

    // implement PaymentRecord
    record_type: 'refund' = 'refund'

    get record_id() {
        return `refund-${this.id}`
    }

    get record_path() {
        return [`payment-${this.employment_id}`, `refund-${this.id}`]
    }

    get record_sort_key() {
        return [this.job_start_at.toISO(), this.created_at.toISO()]
    }

    get nurse_name() {
        return this.nurse_full_name
    }

    get timestamp() {
        return this.created_at
    }

    timestamp_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd') {
        return this.created_at_label(tz, format)
    }

    get paid_at() {
        if (this.refund_id) {
            return this.refund_paid_at
        } else if (this.credit_note_refund_id) {
            return this.credit_note_refund_paid_at
        }
        return null
    }
    
    paid_at_label(tz: string, format: string = 'ccc, LLL dd') {
        return this.paid_at?.setZone(tz).toFormat(format) ?? ''
    }

    get amount() {
        return this.total_fees
    }

    get amount_label() {
        return `£${(this.amount / 100).toFixed(2)}`
    }

    get adjustment() {
        return this.total_fees
    }
    
    get adjustment_label() {
        return `- £${(this.adjustment / 100).toFixed(2)}`
    }

    get fee_correction_reason() {
        return this.description
    }

    get status_label() {
        const is_source_transaction_in_progress = (
            this.status === ''
            || this.status === 'source_transaction_pending'
            || this.status === 'source_transaction_payment_requested'
            || this.status === 'source_transaction_payment_pending'
            || this.status === 'source_transaction_invoice_finalized'
        )
        const is_source_transaction_completed = (
            this.status === 'source_transaction_payment_success'
            || this.status === 'source_transaction_invoice_paid'
        )
        const is_source_transaction_failed = (
            this.status === 'source_transaction_payment_error'
            || this.status === 'source_transaction_invoice_void'
            || this.status === 'source_transaction_invoice_payment_failed'
        )
        const is_refund_in_progress = (
            this.status === 'refund_pending'
            || this.status === 'refund_requested'
            || this.status === 'credit_note_for_refund_processing'
        )
        const is_refund_commpleted = (
            this.status === 'refund_paid'
            || this.status === 'credit_note_for_refund_paid'
        )
        const is_refund_failed = (
            this.status === 'refund_error'
            || this.status === 'credit_note_for_refund_error'
        )
        const is_recovering_funds_from_nurse = (
            this.status === 'nurse_funds_recovery_pending'
        )
        const is_credit_note_pending = (
            this.status === 'credit_note_for_balance_reduction_pending'
            || this.status === 'credit_note_for_refund_pending'
        )
        const is_credit_note_issued = (
            this.status === 'credit_note_for_balance_reduction_issued'
            || this.status === 'credit_note_for_refund_issued'
        )
        const is_credit_note_void = (
            this.status === 'credit_note_for_balance_reduction_void'
            || this.status === 'credit_note_for_refund_void'
        )

        if (is_source_transaction_in_progress) {
            return (
                <Typography variant='body2'>
                    Waiting for initial payment to complete before refunding
                </Typography>
            )
        } else if (is_source_transaction_completed) {
            return (
                <Typography variant='body2'>
                    Initial payment completed. Issuing refund
                </Typography>
            )
        } else if (is_source_transaction_failed) {
            return (
                <Typography variant='body2'>
                    Initial payment failed. Refund will not be issued
                </Typography>
            )
        } else if (is_refund_in_progress) {
            return (
                <Typography variant='body2'>
                    Refund in progress
                </Typography>
            )
        } else if (is_refund_commpleted) {
            if (this.status === 'refund_paid') {
                return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography variant='body2'>Refund Completed</Typography>
                        <Chip label={this.payment_method_label} size='small' />
                    </Box>
                )
            } else {  // credit_note_for_refund_paid
                return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography variant='body2'>Refund Completed</Typography>
                        <Link component='a' href={this.credit_note_pdf} target="_blank">#{this.invoice_number}</Link>
                    </Box>
                )
            }
        } else if (is_refund_failed) {
            return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography variant='body2'>Refund Failed</Typography>
                    {this.refund_failure_reason ? (
                        <Chip label={this.refund_failure_reason} color='error' size='small' />
                    ) : null}
                    {this.credit_note_refund_failure_reason ? (
                        <Chip label={this.credit_note_refund_failure_reason} color='error' size='small' />
                    ) : null}
                </Box>
            )
        } else if (is_recovering_funds_from_nurse) {
            return (
                <Typography variant='body2'>
                    Recovering funds from nurse for refund
                </Typography>
            )
        } else if (is_credit_note_pending) {
            return (
                <Typography variant='body2'>
                    Issuing credit note for refund
                </Typography>
            )
        } else if (is_credit_note_issued) {
            return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography variant='body2'>Credit Note Issued</Typography>
                    <Link component='a' href={this.credit_note_pdf} target="_blank">Invoice #{this.invoice_number}</Link>
                </Box>
            )
        } else if (is_credit_note_void) {
            return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography variant='body2'>Credit Note Voided</Typography>
                    <Link component='a' href={this.credit_note_pdf} target="_blank">Invoice #{this.invoice_number}</Link>
                </Box>
            )
        }
        return ''
    }
}