import { Box } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { adminAffiliateDetailURL } from 'routes/urls'
import Affiliate from 'logic/Affiliate'
import AdminAffiliateTable from 'components/AdminAffiliateTable'

const AdminAffiliateList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    affiliateSearchForm, 
    affiliateSelection, 
    affiliateColumnVisibilityModel, 
    affiliateSortModel, 
    updateAffiliateSearchForm, 
    setAffiliateSelection, 
    setAffiliateColumnVisibilityModel, 
    setAffiliateSortModel 
  } = useAdmin()

  const onRowClick = React.useCallback((params: GridRowParams<Affiliate>) => {
    navigate(adminAffiliateDetailURL(params.row.id))
  }, [navigate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Affiliate List'></Header>
      <AdminAffiliateTable
        onRowClick={onRowClick}
        form={affiliateSearchForm}
        updateForm={updateAffiliateSearchForm}
        selection={affiliateSelection}
        setSelection={setAffiliateSelection}
        columnVisibilityModel={affiliateColumnVisibilityModel}
        setColumnVisibilityModel={setAffiliateColumnVisibilityModel}
        sortModel={affiliateSortModel}
        setSortModel={setAffiliateSortModel}
      />
    </Box>
  )
}

export default AdminAffiliateList