import React from 'react'
import Practice from 'logic/Practice';
import Job from 'logic/Job';
import { Notification } from 'logic/Notification';
import { NursePaygradeStats } from 'types/interfaces';
import BillingAccount from 'logic/BillingAccount';

interface IPracticeSignupContext {
  practice?: Practice | null
  billingAccount?: BillingAccount | null
  jobs?: Job[]
  step?: number
  toNextStep: () => void
  toPreviousStep: () => void
  emitGTMEvent: (event: string) => void
  reloadJobs: () => Promise<void>
  paymentMethodAttached: Promise<Notification>
  paygradeStats?: NursePaygradeStats
}

const PracticeSignupContext = React.createContext<IPracticeSignupContext>({} as IPracticeSignupContext)

export default PracticeSignupContext