import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import update from 'immutability-helper';
import FormField from 'components/FormField'
import Header from 'components/Header'
import { useNurse } from 'contexts/NurseProvider'
import { useAPI } from 'contexts/APIProvider'
import { NurseProfileForm, LoqateAddress } from 'types/interfaces'
import NurseProfileError from 'errors/NurseProfileError'
import AddressSearch from 'components/AddressSearch'
import ErrorPaper from 'components/ErrorAlert'
import { nurseProfileURL } from 'routes/urls'
import { useNavigate } from 'react-router';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';

const NurseProfileEdit: React.FC = () => {
  const [form, setForm] = React.useState<NurseProfileForm>({});
  const [error, setError] = React.useState<NurseProfileError>();
  const { nurse, reloadNurse } = useNurse()
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  function prepopulateForm() {
    if (nurse) {
      setForm((form) => update(form, {
        'address': { $set: nurse.address },
        'latitude': { $set: nurse.latitude },
        'longitude': { $set: nurse.longitude },
        'line1': { $set: nurse.line1 },
        'line2': { $set: nurse.line2 },
        'city': { $set: nurse.city },
        'county': { $set: nurse.county },
        'country': { $set: nurse.country },
        'postcode': { $set: nurse.postcode },
        'first_name': { $set: nurse.first_name },
        'last_name': { $set: nurse.last_name },
        'phone_number': { $set: nurse.phone_number },
        'radius_miles': { $set: nurse.radius_miles || 20 },
      }))
    }
  }

  async function save() {
    if (nurse) {
      try {
        setLoading(true)
        await api.editNurse(form, nurse?.nurse_id)
        reloadNurse()
        showAlert('success', 'Profile Updated')
        navigate(nurseProfileURL(nurse.nurse_id))
      } catch (e: any) {
        if (e instanceof NurseProfileError) {
          setError(e)
        } else {
          console.error('unhandled exception', e)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  React.useEffect(() => {
    prepopulateForm()
  }, [nurse])

  if (!nurse) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h1' text='Edit Profile'></Header>
      <FormField
        name='first_name'
        label='First Name'
        onChange={(e) => updateForm('first_name', e.target.value)}
        value={form.first_name}
        errors={error?.first_name}
      />
      <FormField
        name='last_name'
        label='Last Name'
        onChange={(e) => updateForm('last_name', e.target.value)}
        value={form.last_name}
        errors={error?.last_name}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      <FormField
        name='phone_number'
        label='Phone Number'
        onChange={(e) => updateForm('phone_number', e.target.value)}
        value={form.phone_number}
        errors={error?.phone_number}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='radius_miles'
        label='Willing to travel up to (miles)'
        onChange={(e) => updateForm('radius_miles', e.target.value)}
        value={form.radius_miles}
        errors={error?.radius}
      />
      <ErrorPaper errors={error?.schema}></ErrorPaper>
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Save'}
      </Button>
      <Button
        variant="outlined" color="primary" disabled={loading}
        component="a" href={nurseProfileURL(nurse.nurse_id)}>
        Cancel
      </Button>
    </Box>
  )
}

export default NurseProfileEdit