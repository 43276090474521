import { Box, Button, Typography } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import { useNavigate } from 'react-router'
import { nurseJobsURL } from 'routes/urls'

const NurseWaitlistCPD: React.FC = () => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()

  const submit = React.useCallback(async () => {
    if (!nurse) return
    try {
      await api.nurseWaitlist({ nurse_ids: [nurse.nurse_id], waitlist: 'cpd', action: 'join' })
      showAlert('success', 'You have been put on the CPD waiting list.')
      navigate(nurseJobsURL(nurse.nurse_id))
    } catch (e) {
      showAlert('error', 'Error joining CPD waiting list')
    }
  }, [api, navigate, nurse, showAlert])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Join CPD Waiting List' />
      <Typography>
        Was your last CPR or infection control training completed more than a year ago? <br/>
        We've partnered with the wonderful Dentinal Tubules. locumloop will pay for your free CPD. <br/>
        This lets you keep up to date and take more jobs with us. <br/>
        Just click "Join waiting list" below and we'll send you an email invite as soon as possible. <br/>
      </Typography>
      <Button variant='contained' color='primary' onClick={submit}>Join Waiting List</Button>
    </Box>
  )
}

export default NurseWaitlistCPD