import React from 'react'
import { AffiliateSearchForm, JobEmploymentSearchForm, JobSearchForm, NurseSearchForm, PracticeGroupSearchForm, PracticeSearchForm, UserSearchForm } from 'types/interfaces'
import update from 'immutability-helper'
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import { DateTime } from 'luxon'

interface IAdminContext {
  userSearchForm: UserSearchForm
  userSelection: GridRowId[]
  userColumnVisibilityModel: GridColumnVisibilityModel
  userSortModel: GridSortModel
  updateUserSearchForm: (name: string, value: any) => void
  setUserSelection: (value: GridRowId[]) => void
  setUserColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setUserSortModel: (value: GridSortModel) => void
  
  nurseSearchForm: NurseSearchForm
  nurseSelection: GridRowId[]
  nurseColumnVisibilityModel: GridColumnVisibilityModel
  nurseSortModel: GridSortModel
  updateNurseSearchForm: (name: string, value: any) => void
  setNurseSelection: (value: GridRowId[]) => void
  setNurseColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setNurseSortModel: (value: GridSortModel) => void

  practiceSearchForm: PracticeSearchForm
  practiceSelection: GridRowId[]
  practiceColumnVisibilityModel: GridColumnVisibilityModel
  practiceSortModel: GridSortModel
  updatePracticeSearchForm: (name: string, value: any) => void
  setPracticeSelection: (value: GridRowId[]) => void
  setPracticeColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setPracticeSortModel: (value: GridSortModel) => void

  jobSearchForm: JobSearchForm
  jobSelection: GridRowId[]
  jobColumnVisibilityModel: GridColumnVisibilityModel
  jobSortModel: GridSortModel
  updateJobSearchForm: (name: string, value: any) => void
  setJobSelection: (value: GridRowId[]) => void
  setJobColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setJobSortModel: (value: GridSortModel) => void

  employmentSearchForm: JobEmploymentSearchForm
  employmentSelection: GridRowId[]
  employmentColumnVisibilityModel: GridColumnVisibilityModel
  employmentSortModel: GridSortModel
  updateEmploymentSearchForm: (name: string, value: any) => void
  setEmploymentSelection: (value: GridRowId[]) => void
  setEmploymentColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setEmploymentSortModel: (value: GridSortModel) => void

  practiceGroupSearchForm: PracticeGroupSearchForm
  practiceGroupSelection: GridRowId[]
  practiceGroupColumnVisibilityModel: GridColumnVisibilityModel
  practiceGroupSortModel: GridSortModel
  updatePracticeGroupSearchForm: (name: string, value: any) => void
  setPracticeGroupSelection: (value: GridRowId[]) => void
  setPracticeGroupColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setPracticeGroupSortModel: (value: GridSortModel) => void

  affiliateSearchForm: AffiliateSearchForm
  affiliateSelection: GridRowId[]
  affiliateColumnVisibilityModel: GridColumnVisibilityModel
  affiliateSortModel: GridSortModel
  updateAffiliateSearchForm: (name: string, value: any) => void
  setAffiliateSelection: (value: GridRowId[]) => void
  setAffiliateColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setAffiliateSortModel: (value: GridSortModel) => void

  // available nurses tab under job details
  availableNurseSearchForm: NurseSearchForm
  availableNurseSelection: GridRowId[]
  availableNurseColumnVisibilityModel: GridColumnVisibilityModel
  availableNurseSortModel: GridSortModel
  updateAvailableNurseSearchForm: (name: string, value: any) => void
  setAvailableNurseSelection: (value: GridRowId[]) => void
  setAvailableNurseColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setAvailableNurseSortModel: (value: GridSortModel) => void
}

const AdminContext = React.createContext<IAdminContext>({} as IAdminContext)

const AdminProvider: React.FC = ({ children }) => {
  const [userSearchForm, setUserSearchForm] = React.useState<UserSearchForm>({
    roles: ['practice', 'practice_group']
  })
  const [userSelection, setUserSelection] = React.useState<GridRowId[]>([])
  const [userColumnVisibilityModel, setUserColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [userSortModel, setUserSortModel] = React.useState<GridSortModel>([])
  const [nurseSearchForm, setNurseSearchForm] = React.useState<NurseSearchForm>({})
  const [nurseSelection, setNurseSelection] = React.useState<GridRowId[]>([])
  const [nurseColumnVisibilityModel, setNurseColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [nurseSortModel, setNurseSortModel] = React.useState<GridSortModel>([])
  const [practiceSearchForm, setPracticeSearchForm] = React.useState<PracticeSearchForm>({})
  const [practiceSelection, setPracticeSelection] = React.useState<GridRowId[]>([])
  const [practiceColumnVisibilityModel, setPracticeColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [practiceSortModel, setPracticeSortModel] = React.useState<GridSortModel>([])
  const [jobSearchForm, setJobSearchForm] = React.useState<JobSearchForm>({
    from_date: DateTime.now().toFormat('yyyy-MM-dd')
  })
  const [jobSelection, setJobSelection] = React.useState<GridRowId[]>([])
  const [jobColumnVisibilityModel, setJobColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [jobSortModel, setJobSortModel] = React.useState<GridSortModel>([])
  const [employmentSearchForm, setEmploymentSearchForm] = React.useState<JobEmploymentSearchForm>({
    from_date: DateTime.now().toFormat('yyyy-MM-dd')
  })
  const [employmentSelection, setEmploymentSelection] = React.useState<GridRowId[]>([])
  const [employmentColumnVisibilityModel, setEmploymentColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [employmentSortModel, setEmploymentSortModel] = React.useState<GridSortModel>([])
  const [practiceGroupSearchForm, setPracticeGroupSearchForm] = React.useState<PracticeGroupSearchForm>({})
  const [practiceGroupSelection, setPracticeGroupSelection] = React.useState<GridRowId[]>([])
  const [practiceGroupColumnVisibilityModel, setPracticeGroupColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [practiceGroupSortModel, setPracticeGroupSortModel] = React.useState<GridSortModel>([])
  const [affiliateSearchForm, setAffiliateSearchForm] = React.useState<AffiliateSearchForm>({})
  const [affiliateSelection, setAffiliateSelection] = React.useState<GridRowId[]>([])
  const [affiliateColumnVisibilityModel, setAffiliateColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [affiliateSortModel, setAffiliateSortModel] = React.useState<GridSortModel>([])

  // Available Nurses tab under job details
  const [availableNurseSearchForm, setAvailableNurseSearchForm] = React.useState<NurseSearchForm>({})
  const [availableNurseSelection, setAvailableNurseSelection] = React.useState<GridRowId[]>([])
  const [availableNurseColumnVisibilityModel, setAvailableNurseColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [availableNurseSortModel, setAvailableNurseSortModel] = React.useState<GridSortModel>([])

  const updateUserSearchForm = React.useCallback((name: string, value: any) => {
    setUserSearchForm(update(userSearchForm, { [name]: { $set: value } }))
  }, [userSearchForm])
  
  const updateNurseSearchForm = React.useCallback((name: string, value: any) => {
    setNurseSearchForm(update(nurseSearchForm, { [name]: { $set: value } }))
  }, [nurseSearchForm])

  const updatePracticeSearchForm = React.useCallback((name: string, value: any) => {
    setPracticeSearchForm(update(practiceSearchForm, { [name]: { $set: value } }))
  }, [practiceSearchForm])

  const updateJobSearchForm = React.useCallback((name: string, value: any) => {
    setJobSearchForm(update(jobSearchForm, { [name]: { $set: value } }))
  }, [jobSearchForm])

  const updateEmploymentSearchForm = React.useCallback((name: string, value: any) => {
    setEmploymentSearchForm(update(employmentSearchForm, { [name]: { $set: value } }))
  }, [employmentSearchForm])

  const updatePracticeGroupSearchForm = React.useCallback((name: string, value: any) => {
    setPracticeGroupSearchForm(update(practiceGroupSearchForm, { [name]: { $set: value } }))
  }, [practiceGroupSearchForm])

  const updateAffiliateSearchForm = React.useCallback((name: string, value: any) => {
    setAffiliateSearchForm(update(affiliateSearchForm, { [name]: { $set: value } }))
  }, [affiliateSearchForm])

  const updateAvailableNurseSearchForm = React.useCallback((name: string, value: any) => {
    setAvailableNurseSearchForm(update(availableNurseSearchForm, { [name]: { $set: value } }))
  }, [availableNurseSearchForm])
  
  const value = {
    userSearchForm,
    userSelection,
    userColumnVisibilityModel,
    userSortModel,
    updateUserSearchForm,
    setUserSelection,
    setUserColumnVisibilityModel,
    setUserSortModel,
    nurseSearchForm,
    nurseSelection,
    nurseColumnVisibilityModel,
    nurseSortModel,
    updateNurseSearchForm,
    setNurseSelection,
    setNurseColumnVisibilityModel,
    setNurseSortModel,
    practiceSearchForm,
    practiceSelection,
    practiceColumnVisibilityModel,
    practiceSortModel,
    updatePracticeSearchForm,
    setPracticeSelection,
    setPracticeColumnVisibilityModel,
    setPracticeSortModel,
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
    employmentSearchForm,
    employmentSelection,
    employmentColumnVisibilityModel,
    employmentSortModel,
    updateEmploymentSearchForm,
    setEmploymentSelection,
    setEmploymentColumnVisibilityModel,
    setEmploymentSortModel,
    practiceGroupSearchForm,
    practiceGroupSelection,
    practiceGroupColumnVisibilityModel,
    practiceGroupSortModel,
    updatePracticeGroupSearchForm,
    setPracticeGroupSelection,
    setPracticeGroupColumnVisibilityModel,
    setPracticeGroupSortModel,
    affiliateSearchForm,
    affiliateSelection,
    affiliateColumnVisibilityModel,
    affiliateSortModel,
    updateAffiliateSearchForm,
    setAffiliateSelection,
    setAffiliateColumnVisibilityModel,
    setAffiliateSortModel,
    availableNurseSearchForm,
    availableNurseSelection,
    availableNurseColumnVisibilityModel,
    availableNurseSortModel,
    updateAvailableNurseSearchForm,
    setAvailableNurseSelection,
    setAvailableNurseColumnVisibilityModel,
    setAvailableNurseSortModel,
  }
  return <AdminContext.Provider value={value}> {children} </AdminContext.Provider>
}

function useAdmin() {
  const context = React.useContext(AdminContext)
  if (context === undefined) {
    throw new Error('useAdmin must be used within a AdminContext')
  }
  return context
}

export { AdminProvider, useAdmin }