import React from 'react'
import { useAPI } from './APIProvider'
import { useFirebase } from './FirebaseProvider'
import Nurse from '../logic/Nurse'
import { collectionChanges, collectionData } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { useParams } from 'react-router'
import { useAuthUser } from './AuthUserProvider';
import { filter, map, switchMap, from, share, Observable, shareReplay, Subscription } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import { chain } from 'lodash'
import { DateTime } from 'luxon'

interface INurseContext {
  nurse?: Nurse | null
  reloadNurse: () => Promise<void>
  notification$: Observable<Notification>
  notificationList$: Observable<Notification[]>
  unreadCount$: Observable<number>
}

const NurseContext = React.createContext<INurseContext>({} as INurseContext)

const NurseProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { authUser } = useAuthUser()
  const [nurse, setNurse] = React.useState<Nurse | null>()
  const { api } = useAPI()
  const { firestore } = useFirebase()

  const nurseId = React.useMemo(() => Number(params.nurseId), [params.nurseId]) 

  const reloadNurse = React.useCallback(async () => {
    console.log('NurseProvider: reloadNurse()', authUser)
    if (authUser) {
      try {
        setNurse(await api.getNurse(nurseId))
      } catch (e) {
        console.error(e)
        setNurse(null)
      }
    } else if (authUser === null) {
      setNurse(null)
    }
  }, [api, nurseId, authUser])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("nurse_id", "==", nurseId)
    ), 
    [firestore, nurseId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ), 
    [notificationQuery]
  )

  // these are the notifications that we display on the notifications page.
  // not all notifications in notification$ will be displayed.
  // because some of them are only used to trigger reloadNurse
  const notificationList$ = React.useMemo(
    () => collectionData(notificationQuery).pipe(
      map(docs => docs.map(doc => plainToClass(Notification, doc))),
      map(notifications => {
        return chain(notifications)
          .filter(n => n.nurse_message !== null)
          .sortBy('timestamp')
          .reverse()
          .value()
      }),
      shareReplay(1),
    ),
    [notificationQuery]
  )

  const unreadCount$ = React.useMemo(
    () => notificationList$.pipe(
      map(notifications => chain(notifications).filter(n => n.unread_by_nurse).size().value()),
    ),
    [notificationList$]
  )

  React.useEffect(() => {
    reloadNurse()
  }, [reloadNurse])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'job_employment_cancelled', // the red dots on the nurse page needs to be reloaded
      'nurse_updated',
      'nurse_document_created',
      'nurse_document_deleted',
      'nurse_affiliate_created',
      'nurse_id_verification_success',
      'nurse_id_verification_failed',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(reloadNurse))
    return () => sub.unsubscribe()
  }, [notification$, reloadNurse])

  const value = { 
    nurse, 
    reloadNurse, 
    notification$, 
    notificationList$, 
    unreadCount$,
  }
  return (
    <NurseContext.Provider value={value}>
      {children}
    </NurseContext.Provider>
  )
}

function useNurse() {
  const context = React.useContext(NurseContext)
  if (context === undefined) {
    throw new Error('useNurse must be used within a NurseProvider')
  }
  return context
}

export { NurseProvider, useNurse }