import { Box, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NurseProfileError from 'errors/NurseProfileError'
import React from 'react'
import { NurseProfileForm } from 'types/interfaces'
import update from 'immutability-helper'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import FormField from 'components/FormField'
import CheckboxFormField from 'components/CheckboxFormField'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'

interface AccountUpdateProps {
  open: boolean
  setOpen: (value: boolean) => void
}

const AccountUpdate: React.FC<AccountUpdateProps> = ({ open, setOpen }) => {
  const { nurse, fetchNurse } = React.useContext(AdminNurseDetailContext)
  
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<NurseProfileForm>({})
  const [error, setError] = React.useState<NurseProfileError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const submit = async () => {
    if (!nurse) return
    try {
      setLoading(true)
      await api.editNurse(form, nurse.nurse_id)
      await fetchNurse()
      setOpen(false)
      showAlert('success', "Nurse updated successfully")
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error updating nurse')
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!nurse) return
    if (!isEmpty(form)) return
    setForm(update(form, {
      num_cancellations: { $set: nurse.num_cancellations ?? 0 },
      require_late_cancellation_fee: { $set: nurse.require_late_cancellation_fee ?? true },
    }))
  }, [nurse])

  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Account Update</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormField 
          label='Red Dots'
          value={form.num_cancellations ?? 0}
          onChange={(e) => updateForm('num_cancellations', Number(e.target.value))}
          errors={error?.num_cancellations}
        />
        <CheckboxFormField
          label='£20 Fee required for late cancellation'
          onChange={(e) => updateForm('require_late_cancellation_fee', e.target.checked)}
          checked={form.require_late_cancellation_fee ?? false}
          errors={error?.require_late_cancellation_fee}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={submit} disabled={loading}>
          { loading ? 'Please Wait ...' : 'Update Account' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AccountUpdate