import React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import SavingsIcon from '@mui/icons-material/Savings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {
  practiceJobsURL,
  practiceBillingURL,
  practiceSavingsURL,
  practiceTimesheetListURL,
} from 'routes/urls'
import { usePractice } from 'contexts/PracticeProvider';
import { useMatch, useNavigate } from 'react-router';

type Actions = 'jobs' | 'savings' | 'payments' | 'timesheets'

const PracticeBottomNav: React.FC = () => {
  const [action, setAction] = React.useState<Actions | null>('jobs');
  const { practice } = usePractice()
  const navigate = useNavigate()

  const match_practice_page_jobs = useMatch({path: '/practice/:practiceId/jobs', end: false})
  const match_practice_page_billing = useMatch({path: '/practice/:practiceId/billing', end: false})
  const match_practice_page_savings = useMatch({path: '/practice/:practiceId/savings', end: false})
  const match_practice_page_timesheets = useMatch({path: '/practice/:practiceId/timesheets', end: false})

  const onClick = React.useCallback((event: React.SyntheticEvent, selectedAction: Actions) => {
    if (!practice) return
    setAction(selectedAction);
    if (selectedAction === 'jobs') {
      navigate(practiceJobsURL(practice.practice_id))
    } else if (selectedAction === 'payments') {
      navigate(practiceBillingURL(practice.practice_id))
    } else if (selectedAction === 'savings') {
      navigate(practiceSavingsURL(practice.practice_id))
    } else if (selectedAction === 'timesheets') {
      navigate(practiceTimesheetListURL(practice.practice_id))
    }
  }, [navigate, practice])

  React.useEffect(() => {
    if (match_practice_page_jobs) {
      setAction('jobs')
    } else if (match_practice_page_billing) {
      setAction('payments')
    } else if (match_practice_page_savings) {
      setAction('savings')
    } else if (match_practice_page_timesheets) {
      setAction('timesheets')
    } else {
      setAction(null)
    }
  }, [match_practice_page_billing, match_practice_page_savings, match_practice_page_jobs, match_practice_page_timesheets])

  return (
    <BottomNavigation 
      sx={{ 
        justifyContent: 'space-evenly', 
        paddingRight: ['70px', 0] // allow space for chat button on mobile
      }}
      value={action}
      onChange={onClick}>
      <BottomNavigationAction label="Jobs" value="jobs" icon={<CalendarMonthIcon />} />
      <BottomNavigationAction label="Timesheets" value="timesheets" icon={<ReceiptLongIcon />} />
      <BottomNavigationAction label="Savings" value="savings" icon={<SavingsIcon />} />
      <BottomNavigationAction label="Payments" value="payments" icon={<CurrencyPoundIcon />} />
    </BottomNavigation>
  )
}

export default PracticeBottomNav