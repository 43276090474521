import React from 'react'
import { isEmpty } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { Button, MenuItem } from '@mui/material'
import { AffiliateTransactionSearchForm } from 'types/interfaces'
import FormField from 'components/FormField'
import { 
  DataGridPro, 
  GridColDef, 
  GridValueGetterParams,
  GridColumnVisibilityModel,
  GridSortModel,
  GridEventListener,
  GridRowId,
  GridValueFormatterParams,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { 
  Alert,
  Box,
} from '@mui/material'
import JobEmployment from 'logic/JobEmployment';
import { round } from 'lodash';
import { Observable, of } from 'rxjs';

type FilterOptions = 
| 'affiliate_payment_status'

interface AdminAffiliateTransactionTableProps {
  filters?: FilterOptions[]
  reload$?: Observable<boolean>
  actions?: React.ReactNode
  onRowClick?: GridEventListener<"rowClick">
  form: AffiliateTransactionSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminAffiliateTransactionTable: React.FC<AdminAffiliateTransactionTableProps> = ({
  filters = [
    'affiliate_payment_status',
  ],
  reload$,
  actions,
  onRowClick,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [transactions, setTransactions] = React.useState<JobEmployment[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchTransactions = React.useCallback(async () => {
    setLoading(true)
    setTransactions(await api.listAffiliateTransactions(form))
    setLoading(false)
  }, [api, form])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  React.useEffect(() => {
    fetchTransactions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchTransactions())
    return () => sub?.unsubscribe()
  }, [fetchTransactions, reload$])

  const columns: GridColDef<JobEmployment>[] = React.useMemo(() => [
    {
      minWidth: 50,
      headerName: 'ID',
      field: 'id',
      type: 'number',
    },
    {
      flex: 1,
      headerName: 'Affiliate',
      field: 'affiliate_name',
      type: 'string',
    },
    {
      flex: 1,
      headerName: 'Practice',
      field: 'practice_name',
      type: 'string',
    },
    {
      flex: 1,
      headerName: 'Nurse',
      field: 'nurse_name',
      type: 'string'
    },
    {
      flex: 1,
      headerName: 'Job Taken At',
      field: 'job_taken_at',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
        return params.row.job_taken_at.toJSDate()
      },
    },
    {
      flex: 1,
      headerName: 'Affiliate Period Start',
      field: 'affiliate_period_start',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
        return params.row.affiliate_period_start?.toJSDate()
      },
    },
    {
      flex: 1,
      headerName: 'Affiliate Period End',
      field: 'affiliate_period_end',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
        return params.row.affiliate_period_end?.toJSDate()
      },
    },
    {
      flex: 1,
      headerName: 'Onboarded',
      field: 'affiliate_practice_count',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value} practice`;
      },
    },
    {
      flex: 1,
      headerName: 'Affiliate Rate',
      field: 'affiliate_rate',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value * 100}%`;
      },
    },
    {
      flex: 1,
      headerName: 'Locumloop Fees',
      field: 'locumloop_fees',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `£${round(params.value / 100, 2)}`;
      },
    },
    {
      flex: 1,
      headerName: 'Affiliate Fees',
      field: 'affiliate_fees',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `£${round(params.value / 100, 2)}`;
      },
    },
    {
      flex: 1,
      headerName: 'Affiliate Paid At',
      field: 'affiliate_paid_at',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
        return params.row.affiliate_paid_at?.toJSDate()
      },
    },
  ], [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {filters.includes('affiliate_payment_status') ? (
          <FormField
            sx={{ width: 200 }}
            name='affiliate_payment_status'
            label='Payment Status'
            select
            onChange={(e) => updateForm('affiliate_payment_status', e.target.value)}
            value={form.affiliate_payment_status ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              {['paid', 'not_paid'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </FormField>
        ): null}
        <Button
          variant="contained"
          onClick={fetchTransactions}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {isEmpty(transactions) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{transactions.length} records returned</Alert>
          <DataGridPro 
            rows={transactions}
            columns={columns}
            getRowId={(transaction) => transaction.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
            // toolbar
            slots={{
              toolbar: GridToolbar
            }}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminAffiliateTransactionTable