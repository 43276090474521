import { Alert, Tooltip, Link, Box, Typography, Chip } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Expose, Type, Transform, plainToClass } from 'class-transformer'
import { DateTime, Duration } from 'luxon'
import { 
  JobEmploymentFulfillmentStatus, 
  JobEmploymentTimesheetStatus, 
  JobEmploymentPaymentStatus, 
  JobEmploymentAffiliatePaymentStatus, 
  PaymentOption, 
  PaymentMethodType, 
  InvoiceStatus, 
  StripePaymentIntentStatus, 
  NurseDocumentDateStatus, 
  Role, 
  PracticeLateFeePolicy, 
  PayoutStatus
} from 'types/types'
import { nurseBillingURL, practiceBillingURL } from 'routes/urls'
import React from 'react'
import { PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING, HYGIENIST_HOURLY_RATE } from 'types/constants'
import PaymentMethod from 'logic/PaymentMethod'
import Refund from 'logic/Refund'
import AdditionalCharge from 'logic/AddditionalCharge'
import { PaymentRecord } from 'types/interfaces'
import { chain, get } from 'lodash'

export default class JobEmployment implements PaymentRecord {
  id!: number
  job_id!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  job_posted_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  job_taken_at!: DateTime

  job_role!: Role

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  end_at!: DateTime

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  lunch_break!: Duration

  headcount!: number
  description!: string

  // payment options
  customer_id!: string
  payment_method_id!: number
  payment_method_stripe_id!: string
  payment_method_type!: PaymentMethodType
  payment_method_brand!: string
  payment_method_exp_month!: number
  payment_method_exp_year!: number
  payment_method_last4!: string
  payment_method_sort_code!: string
  payment_method_error!: string
  pay_by_invoice!: boolean
  invoice_cycle!: string
  invoice_autopay!: boolean
  payment_option!: PaymentOption
  is_ready_to_process_payment!: boolean
  not_ready_to_process_payment_reason!: string

  // practice payment (not invoiced)
  practice_payment_id!: string
  practice_payment_amount!: number | null
  practice_payment_status!: StripePaymentIntentStatus
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  practice_payment_paid_at!: DateTime | null
  practice_payment_error!: string
  practice_payment_payment_method_id!: string
  practice_payment!: any

  // practice payment (invoiced)
  invoice_id!: number
  invoice_stripe_id!: string
  invoice_hosted_url!: string
  invoice_pdf!: string
  invoice_number!: string
  invoice_receipt_number!: string
  invoice_status!: InvoiceStatus
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  invoice_paid_at!: DateTime | null
  invoice_charge_error!: string
  invoice_charge_payment_method_id!: string
  invoice_item_id!: string
  invoice_transfer_id!: string

  // late cancel fee for nurse
  nurse_late_fee_charge_id!: string
  nurse_late_fee_charge_amount!: number | null
  nurse_late_fee_charge_status!: string

  // Estimated payment dates
  @Type(() => DateTime)
  @Transform(({ value }) => (value as string[]).map((v) => DateTime.fromISO(v)))
  estimated_payment_date!: DateTime[]

  @Type(() => DateTime)
  @Transform(({ value }) => (value as string[]).map((v) => DateTime.fromISO(v)))
  estimated_payment_date_for_new_payment_option!: DateTime[]

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  billable_duration!: Duration

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  charge_practice_after!: DateTime

  hourly_rate!: number | null
  nurse_fees!: number | null
  locumloop_fees!: number | null

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  cancel_at!: DateTime | null
  cancel_by!: 'practice' | 'nurse' | 'staff'
  cancel_reason!: string

  nurse_id!: number
  nurse_email!: string
  nurse_first_name!: string
  nurse_last_name!: string
  nurse_tz: string = 'Europe/London'
  nurse_phone_number!: string
  nurse_gdc_number!: string
  nurse_address!: string
  nurse_num_cancellations!: number
  nurse_id_issuing_country!: string
  nurse_paygrade_rate!: number
  practice_id!: number
  practice_name!: string
  practice_tz: string = 'Europe/London'
  practice_phone_number!: string
  practice_address!: string
  practice_group_id!: number
  practice_group_name!: string

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  countdown!: Duration

  late_cancel_fee_for_nurse_may_apply!: boolean
  is_late_cancellation_by_nurse!: boolean

  total_practice_fees!: number
  fulfillment_status!: JobEmploymentFulfillmentStatus
  payment_status!: JobEmploymentPaymentStatus
  timesheet_status!: JobEmploymentTimesheetStatus

  can_update!: boolean
  can_cancel!: boolean
  can_propose_timesheet_change!: boolean
  can_review!: boolean
  can_view_documents!: boolean
  cannot_update_reason!: string
  cannot_cancel_reason!: string
  cannot_propose_timesheet_change_reason!: string
  cannot_review_reason!: string
  cannot_view_documents_reason!: string
  can_refund!: boolean
  cannot_refund_reason!: string
  can_make_additional_charge!: boolean
  cannot_make_additional_charge_reason!: string

  latest_timesheet_change_id!: number
  latest_timesheet_change_comments!: string
  differences!: string[]

  custom_status_practice!: string
  custom_status_nurse!: string
  admin_notes!: string

  // Affiliate Info
  affiliate_practice_count!: number | null
  affiliate_rate!: number | null
  affiliate_fees!: number | null
  affiliate_campaign_id!: number | null
  affiliate_campaign_name!: string | null
  affiliate_id!: number | null
  affiliate_name!: string | null
  affiliate_nurse_id!: number | null
  affiliate_payment_status!: JobEmploymentAffiliatePaymentStatus
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  affiliate_period_start!: DateTime | null
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  affiliate_period_end!: DateTime | null
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  affiliate_paid_at!: DateTime | null

  // review
  review_rating!: number | null
  review_notes!: string

  // refunds and additional charges
  // not populated by default
  // only populated when listed with prefetch_refunds and prefetch_additional_charges set to true
  @Type(() => Refund)
  refunds!: Refund[]

  @Type(() => AdditionalCharge)
  additional_charges!: AdditionalCharge[]

  // net fees (inclusive of Refunds and Additional Charges)
  net_nurse_fees!: number
  net_locumloop_fees!: number
  net_total_fees!: number

  // nurse document status
  gdc!: NurseDocumentDateStatus
  indemnity_insurance!: NurseDocumentDateStatus
  hepatitis_b_vaccination!: NurseDocumentDateStatus
  hepatitis_c!: NurseDocumentDateStatus
  tb!: NurseDocumentDateStatus
  hiv!: NurseDocumentDateStatus
  dbs!: NurseDocumentDateStatus
  pvg!: NurseDocumentDateStatus
  infection_control!: NurseDocumentDateStatus
  cpr!: NurseDocumentDateStatus
  resume!: NurseDocumentDateStatus

  // penalty
  penalty!: boolean
  penalty_note!: string

  // late cancellation policy
  practice_late_cancellation_policy!: PracticeLateFeePolicy

  // payout (nurse)
  payout_id!: string
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  payout_arrival_at!: DateTime | null
  payout_status!: PayoutStatus
  payout_bank_name!: string
  payout_bank_last4!: string

  // payout (affiliate)
  affiliate_payout_id!: string
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  affiliate_payout_arrival_at!: DateTime | null
  affiliate_payout_status!: PayoutStatus
  affiliate_payout_bank_name!: string
  affiliate_payout_bank_last4!: string

  @Expose()
  get can_reapply() {
    return this.fulfillment_status === 'cancelled' && DateTime.utc() <= this.start_at
  }

  @Expose()
  get can_fee_correction() {
    return this.can_refund || this.can_make_additional_charge
  }

  @Expose()
  get date_iso() {
    return this.start_at.toISODate()
  }

  get is_ended() {
    return this.end_at < DateTime.utc()
  }

  get practice_label() {
    return this.practice_group_name ? `${this.practice_name} (${this.practice_group_name})` : this.practice_name
  }

  date_label(tz: string, format: string = 'ccc, LLL dd') {
    return this.start_at.setZone(tz).toFormat(format)
  }

  time_label(tz: string) {
    return this.start_at.setZone(tz).toFormat('h:mm a') + ' - ' + this.end_at.setZone(tz).toFormat('h:mm a')
  }

  charge_practice_after_label(tz: string) {
    return this.charge_practice_after.setZone(tz).toFormat('h:mm a')
  }

  cancel_at_label(tz: string) {
    return this.cancel_at?.setZone(tz).toFormat('ccc, LLL dd, h:mm a') ?? 'N/A'
  }

  posted_at_label(tz: string) {
    return this.job_posted_at.setZone(tz).toFormat('ccc, LLL dd, h:mm a') ?? 'N/A'
  }

  taken_at_label(tz: string) {
    return this.job_taken_at.setZone(tz).toFormat('ccc, LLL dd, h:mm a') ?? 'N/A'
  }

  start_at_label(tz: string) {
    return this.start_at.setZone(tz).toFormat('ccc, LLL dd, h:mm a') ?? 'N/A'
  }

  end_at_label(tz: string) {
    return this.end_at.setZone(tz).toFormat('ccc, LLL dd, h:mm a') ?? 'N/A'
  }

  start_time(tz: string) {
    return this.start_at.setZone(tz).toFormat('HH:mm')
  }

  end_time(tz: string) {
    return this.end_at.setZone(tz).toFormat('HH:mm')
  }

  get nurse_paygrade_label() {
    // if payment initiated, use this.hourly_rate (i.e. paygrade at the moment of payment)
    // otherwise, use this.nurse_paygrade_rate (i.e. nurse latest paygrade)
    if (this.payment_status !== '' && this.hourly_rate) {
      return `£${(this.hourly_rate / 100).toFixed(2)}/hr`
    } else if (this.job_role === 'hygienist') {
      return `£${HYGIENIST_HOURLY_RATE}/hr`
    } else {
      return `£${this.nurse_paygrade_rate}/hr`
    }
  }

  get invoice_label() {
    if (!this.invoice_number) return 'pending'
    return `#${this.invoice_number} (Stripe ID: ${this.invoice_stripe_id})`
  }

  get nurse_late_fee_charge_label() {
    if (!this.nurse_late_fee_charge_id) {
      return 'Not Charged'
    } else {
      return `Status: ${this.nurse_late_fee_charge_status}\n Reference: ${this.nurse_late_fee_charge_id}`
    }
  }

  @Expose()
  get lunch_break_label() {
    return `${this.lunch_break.as('minutes')} minutes lunch break`
  }

  @Expose()
  get billable_duration_label() {
    let dd = this.billable_duration.shiftTo('hours', 'minutes')
    return `${dd['hours']} hours ${dd['minutes']} minutes`
  }

  @Expose()
  get payment_schedule_for_nurse() {
    return (
      chain(this.estimated_payment_date ?? [])
      .map(date => date.toFormat('LLL dd'))
      .join(' to ')
      .value()
    )
  }

  @Expose()
  get nurse_payment_status_label() {
    if (this.payment_status === 'practice_paid' || this.payment_status === 'practice_invoice_paid') {
      let nurse_fees = (this.nurse_fees ?? 0) / 100
      let rate = (this.hourly_rate ?? 0) / 100
      let hours = this.billable_duration.as('hours')
      return `Paid £${nurse_fees} (£${rate} per hour, for ${hours} hours)`
    } else if (this.payment_status === 'practice_payment_error') {
      return 'There is a payment error from the practice side. We will reach out to the practice to resolve this as soon as possible'
    } else if (this.payment_status === 'practice_invoice_void' || this.payment_status === 'practice_invoice_payment_failed') {
      return 'The practice failed to pay the invoice. We will reach out to the practice to resolve this as soon as possible'
    } else if (this.payment_status === 'practice_invoice_finalized') {
      return 'The practice has been invoiced. We will chase the practice to settle the invoice.'
    } else if (this.payment_status === 'practice_payment_requested') {
      return 'We have charged the practice. Payment is in progress.'
    } else if (this.payment_option) {
      return `Payment Schedule: ${this.payment_schedule_for_nurse}`
    } else {
      return 'N/A'
    }
  }

  @Expose()
  get payment_error_label() {
    if (this.payment_method_error) {
      return this.payment_method_error
    } else if (this.invoice_charge_error) {
      return this.invoice_charge_error
    } else if (this.practice_payment?.last_payment_error?.message) {
      return this.practice_payment?.last_payment_error?.message
    } else {
      return ''
    }
  }

  @Expose()
  get nurse_name() {
    return `${this.nurse_first_name} ${this.nurse_last_name}`
  }

  @Expose()
  get hourly_rate_label() {
    return this.hourly_rate !== null ? `£${this.hourly_rate / 100} per hour` : 'Not Set Yet'
  }

  @Expose()
  get nurse_fees_label() {
    return this.net_nurse_fees !== null ? `£${this.net_nurse_fees / 100}` : 'Not Set Yet'
  }

  @Expose()
  get countdown_label() {
    return this.countdown.shiftTo('days', 'hours').toHuman({ unitDisplay: 'short' })
  }

  @Expose()
  get cancel_countdown_label() {
    if (this.cancel_at?.isValid) {
      const countdown = this.start_at.diff(this.cancel_at, ['days'])
      return countdown.toHuman({ unitDisplay: 'short' })
    } else {
      return ''
    }
  }

  @Expose()
  get locumloop_fees_label() {
    return this.net_locumloop_fees !== null ? `£${this.net_locumloop_fees / 100}` : 'Not Set Yet'
  }

  @Expose()
  get total_fees_label() {
    return this.net_total_fees !== null ? `£${this.net_total_fees / 100}` : 'Not Set Yet'
  }

  @Expose()
  get affiliate_fees_label() {
    return this.affiliate_fees !== null ? `£${this.affiliate_fees / 100}` : 'N/A'
  }

  @Expose()
  get affiliate_payment_status_label() {
    if (this.affiliate_paid_at) {
      const payout_date = this.affiliate_payout_arrival_at?.toFormat('LLL dd, yyyy') ?? 'TBD'
      if (this.affiliate_payout_status === 'paid') {
        return `Paid to ${this.affiliate_payout_bank_name} ending in ${this.affiliate_payout_bank_last4} on ${payout_date}`
      } else if (this.affiliate_payout_status === 'in_transit') {
        return `In transit to ${this.affiliate_payout_bank_name} ending in ${this.affiliate_payout_bank_last4}. Estimated arrival on ${payout_date}`
      } else {
        return 'Pending Payment'
      }
    } else {
      return 'Pending Payment'
    }
  }

  @Expose()
  get documents(): { name: string, status: NurseDocumentDateStatus }[] {
    const documentTypes = [
      'gdc',
      'indemnity_insurance',
      'hepatitis_b_vaccination',
      'hepatitis_c',
      'tb',
      'hiv',
      'dbs',
      'pvg',
      'infection_control',
      'cpr',
      'resume',
    ]
    return documentTypes.map(documentType => ({
      name: documentType,
      status: get(this, documentType),
    }))
  }

  @Expose()
  get documents_expired() {
    return (
      chain(this.documents)
      .filter((doc) => ['gdc', 'indemnity_insurance', 'cpr', 'infection_control'].includes(doc.name))  // only these documents have expiry dates
      .filter((doc) => doc.status === 'expired')
      .map((doc) => doc.name)
      .value()
    )
  }

  @Expose()
  get documents_missing_date() {
    return (
      chain(this.documents)
      .filter((doc) => ['gdc', 'indemnity_insurance', 'cpr', 'infection_control'].includes(doc.name))  // only these documents have expiry dates
      .filter((doc) => doc.status === 'date missing')
      .map((doc) => doc.name)
      .value()
    )
  }

  @Expose()
  get payment_method_label() {
    if (this.pay_by_invoice && !this.invoice_autopay) {
      return 'manual'
    } else {
      const pm = plainToClass(PaymentMethod, {
        id: this.payment_method_id,
        payment_method_id: this.payment_method_stripe_id,
        type: this.payment_method_type,
        brand: this.payment_method_brand,
        exp_month: this.payment_method_exp_month,
        exp_year: this.payment_method_exp_year,
        last4: this.payment_method_last4,
        sort_code: this.payment_method_sort_code,
        error: this.payment_method_error,
      })
      return pm.label
    }
  }

  @Expose()
  get fulfillment_status_color() {
    switch (this.fulfillment_status) {
      case 'cancelled':
        return 'error'
      case 'completed':
        return 'success'
      case 'in_progress':
        return 'warning'
      case 'scheduled':
      default:
        return 'primary'
    }
  }

  @Expose()
  get payment_status_color() {
    switch (this.payment_status) {
      case 'practice_payment_pending':
      case 'practice_payment_requested':
      case 'practice_invoice_finalized':
        return 'warning'
      case 'practice_paid':
      case 'practice_invoice_paid':
        return 'success'
      case 'practice_payment_error':
      case 'practice_invoice_void':
      case 'practice_invoice_payment_failed':
        return 'error'
      default:
        return 'primary'
    }
  }

  @Expose()
  get timesheet_status_color() {
    switch (this.timesheet_status) {
      case 'timesheet_pending_nurse_approval':
        return 'error'
      case 'timesheet_pending_practice_approval':
        return 'error'
      case 'timesheet_disapproved':
        return 'error'
      case 'timesheet_approved':
        return 'success'
      default:
        return 'primary'
    }
  }

  @Expose()
  status_alert(usertype: 'practice' | 'nurse', openZendesk: () => void) {
    const chatLink = (
      <Link color='secondary' onClick={() => openZendesk()} sx={{ cursor: 'pointer' }} >
        Click here if you need further assistance.
      </Link>
    )

    if (usertype === 'nurse') {
      if (this.custom_status_nurse) {
        return (
          <Alert severity='info' variant='filled'>
            {this.custom_status_nurse}
          </Alert>
        )
      } else if (this.timesheet_status === 'timesheet_pending_practice_approval') {
        return (
          <Alert severity='info' variant='standard'>
            You have proposed the following changes to the timesheet.<br />
            <ul>
              {this.differences.map((difference) => (
                <li key={difference}>{difference}</li>
              ))}
            </ul>
            We have notified the practice to review your changes. No payments will be made until the practice accepts your proposal. <br />
            {chatLink}
          </Alert>
        )
      } else if (this.timesheet_status === 'timesheet_pending_nurse_approval') {
        return (
          <Alert severity='warning' variant='filled' sx={{ color: 'black' }}>
            The practice has proposed the following changes to the timesheet.<br />
            <ul>
              {this.differences.map((difference) => (
                <li key={difference}>{difference}</li>
              ))}
            </ul>
            {this.latest_timesheet_change_comments ? (
              <React.Fragment>
                The practice left the following comments: <br />
                <Box sx={{ whiteSpace: 'pre-line', fontSize: '1.2rem' }}>
                  {this.latest_timesheet_change_comments}
                </Box>
                <br />
              </React.Fragment>
            ) : null}
            No payments will be made until mutual agreement is reached. <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'scheduled') {
        return (
          <Alert severity='info' variant='standard'>
            You are booked for this job. <br />
            Payment Schedule: {this.payment_schedule_for_nurse}<br />
            <Tooltip title={
              <ul>
                <li>ID Document (e.g. Driver License, Passport)</li>
                <li>GDC Certificate</li>
                <li>Indemnity Insurance</li>
                <li>Hepatitis B Vaccination</li>
                <li>DBS Check</li>
              </ul>
            }>
              <Link color='secondary'>Remember to bring these documents to work</Link>
            </Tooltip>
            <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'in_progress') {
        return (
          <Alert severity='info' variant='standard'>
            The job has started.<br />
            If your working hours have changed, remember to submit a timesheet change request BEFORE the end of day.<br />
            To request changes to the timesheet, click "View Timesheet"<br />
            Payment Schedule: {this.payment_schedule_for_nurse}<br />
            <Tooltip title={
              <ul>
                <li>ID Document (e.g. Driver License, Passport)</li>
                <li>GDC Certificate</li>
                <li>Indemnity Insurance</li>
                <li>Hepatitis B Vaccination</li>
                <li>DBS Check</li>
              </ul>
            }>
              <Link color='secondary'>Remember to bring these documents to work</Link>
            </Tooltip>
            <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'completed') {
        if (this.payment_status === 'practice_paid' || this.payment_status === 'practice_invoice_paid') {
          return (
            <Alert severity='info' variant='standard'>
              The job has completed. You have been paid {this.nurse_fees_label}<br />
              Your earnings will be paid out to your bank account on a 7 day rolling basis.<br />
              <Link color='secondary' component={RouterLink} to={nurseBillingURL(this.nurse_id)}>
                Click here to open the billing page for more details.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_payment_error' 
        || this.payment_status === 'practice_invoice_void'
        || this.payment_status === 'practice_invoice_payment_failed') {
          return (
            <Alert severity='error' variant='filled'>
              There has been an issue with the processing of your payment for this job. <br />
              We will contact the practice get this resolved as soon as possible.<br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_payment_requested') {
          return (
            <Alert severity='info' variant='standard'>
              The job has completed. We are processing your payment of {this.nurse_fees_label}<br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_invoice_finalized') {
          return (
            <Alert severity='info' variant='standard'>
              We have invoiced the practice for this job. We will chase the practice to settle the invoice.<br />
              {chatLink}
            </Alert>
          )
        } else if (!this.pay_by_invoice) {  // job completed but not paid. payment option is not invoice
          return (
            <Alert severity='info' variant='standard'>
              The job has completed. We'll process your payment at {this.charge_practice_after_label(this.nurse_tz)}<br />
              If you need to request changes to the timesheet, click "View Timesheet"<br />
              {chatLink}
            </Alert>
          )
        } else {  // job completed but not paid. payment option is invoice
          return (
            <Alert severity='info' variant='standard'>
              Payment Schedule: {this.payment_schedule_for_nurse}<br />
              If you need to request changes to the timesheet, click "View Timesheet"<br />
              {chatLink}
            </Alert>
          )
        }
      } else if (this.fulfillment_status === 'cancelled') {
        if (this.cancel_by === 'nurse') {
          return (
            <Alert severity='info' variant='filled'>
              You have cancelled this job.<br />
            </Alert>
          )
        } else {  // cancelled by practice or staff
          return (
            <Alert severity='error' variant='filled'>
              Unfortunately your job has been cancelled by the practice.<br />
            </Alert>
          )
        }
      }
    } else {  // usertype === practice
      if (this.custom_status_practice) {
        return (
          <Alert severity='info' variant='filled'>
            {this.custom_status_practice}
          </Alert>
        )
      } else if (this.timesheet_status === 'timesheet_pending_nurse_approval') {
        return (
          <Alert severity='info' variant='filled'>
            You have proposed the following changes to the timesheet.<br />
            <ul>
              {this.differences.map((difference) => (
                <li key={difference}>{difference}</li>
              ))}
            </ul>
            We have notified the nurse to review your changes. No payments will be made until the nurse accepts your proposal. <br />
            {chatLink}
          </Alert>
        )
      } else if (this.timesheet_status === 'timesheet_pending_practice_approval') {
        return (
          <Alert severity='warning' variant='filled' sx={{ color: 'black' }}>
            The nurse has proposed the following changes to the timesheet.<br />
            <ul>
              {this.differences.map((difference) => (
                <li key={difference}>{difference}</li>
              ))}
            </ul>
            {this.latest_timesheet_change_comments ? (
              <React.Fragment>
                The nurse left the following comments: <br />
                <Box sx={{ whiteSpace: 'pre-line', fontSize: '1.2rem' }}>
                  {this.latest_timesheet_change_comments}
                </Box>
                <br />
              </React.Fragment>
            ) : null}
            No payments will be made until mutual agreement is reached. <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'scheduled') {
        return (
          <Alert severity='info' variant='filled'>
            You won't be charged until the job completes. <br />
            Payment Schedule: {PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[this.payment_option]['practice']}<br />
            If you need to adjust the hours, click "View Timesheet"<br />
            Please plan your hours ahead of time as the nurse will need to approve the changes.<br />
            <Tooltip title={
              <ul>
                <li>ID Document (e.g. Driver License, Passport)</li>
                <li>GDC Certificate</li>
                <li>Indemnity Insurance</li>
                <li>Hepatitis B Vaccination</li>
                <li>DBS Check</li>
              </ul>
            }>
              <Link color='secondary'>Please check the following when the nurse arrives at your practice.</Link>
            </Tooltip>
            <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'in_progress') {
        return (
          <Alert severity='info' variant='filled'>
            The job has started.<br />
            Payment Schedule: {PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[this.payment_option]['practice']}<br />
            If you need to adjust the hours please discuss with the nurse and ask her to submit a timesheet change request.<br />
            <Tooltip title={
              <ul>
                <li>ID Document (e.g. Driver License, Passport)</li>
                <li>GDC Certificate</li>
                <li>Indemnity Insurance</li>
                <li>Hepatitis B Vaccination</li>
                <li>DBS Check</li>
              </ul>
            }>
              <Link color='secondary'>Please check the following when the nurse arrives at your practice.</Link>
            </Tooltip>
            <br />
            {chatLink}
          </Alert>
        )
      } else if (this.fulfillment_status === 'completed') {
        if (this.payment_status === 'practice_paid') {
          return (
            <Alert severity='info' variant='filled'>
              The job has completed. You have been charged {this.total_fees_label}<br />
              <Link color='secondary' component={RouterLink} to={practiceBillingURL(this.practice_id)}>
                Click here to open the billing page for more details.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_invoice_paid') {
          return (
            <Alert severity='info' variant='filled'>
              The job has completed. You have paid {this.total_fees_label} via invoice #{this.invoice_number}<br />
              <Link color='secondary' component='a' href={this.invoice_pdf} target='_blank'>
                Click here to view the invoice.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_invoice_finalized' && this.invoice_autopay) {
          return (
            <Alert severity='info' variant='filled'>
              The job has completed. We have invoiced you for {this.total_fees_label}.<br />
              We will be charged automatically via {this.payment_method_label}<br />
              <Link color='secondary' component='a' href={this.invoice_pdf} target='_blank'>
                Click here to view the invoice.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_invoice_finalized' && !this.invoice_autopay) {
          return (
            <Alert severity='info' variant='filled'>
              The job has completed. We have invoiced you for {this.total_fees_label}.<br />
              <Link color='secondary' component='a' href={this.invoice_hosted_url} target='_blank'>
                Click here to pay the invoice.
              </Link> <br />
              <Link color='secondary' component='a' href={this.invoice_pdf} target='_blank'>
                Or click here to download the invoice.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_payment_error' || this.payment_status === 'practice_invoice_payment_failed') {
          return (
            <Alert severity='error' variant='filled'>
              There has been an error in your payment<br />
              {this.payment_error_label ? (
                <strong>{this.payment_error_label}</strong>
              ) : null}
              <br />
              <Link color='secondary' component={RouterLink} to={practiceBillingURL(this.practice_id)}>
                Click here to update your payment card
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_invoice_void') {
          return (
            <Alert severity='error' variant='filled'>
              The invoice #{this.invoice_number} for this job has been voided. <br />
              <Link color='secondary' component='a' href={this.invoice_pdf} target='_blank'>
                Click here to view the invoice.
              </Link> <br />
              {chatLink}
            </Alert>
          )
        } else if (this.payment_status === 'practice_payment_requested') {
          return (
            <Alert severity='info' variant='filled'>
              The job has completed. We are processing your payment of {this.total_fees_label}<br />
              {chatLink}
            </Alert>
          )
        } else if (!this.pay_by_invoice) {  // job completed but not paid. payment option is not invoice
          return (
            <Alert severity='info' variant='standard'>
              The job has completed. You will be charged at {this.charge_practice_after_label(this.practice_tz)}<br />
              If you need to request changes to the timesheet, click "View Timesheet"<br />
              {chatLink}
            </Alert>
          )
        } else {  // job completed but not paid. payment option is invoice
          return (
            <Alert severity='info' variant='standard'>
              Payment Schedule: {PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[this.payment_option]['practice']}<br />
              If you need to request changes to the timesheet, click "View Timesheet"<br />
              {chatLink}
            </Alert>
          )
        }
      } else if (this.fulfillment_status === 'cancelled') {
        if (this.cancel_by === 'nurse') {
          return (
            <Alert severity='error' variant='filled'>
              Unfortunately, the nurse is no longer available to take your job.<br />
              We are working to find another nurse who is available on this day.<br />
            </Alert>
          )
        } else {
          return (
            <Alert severity='info' variant='filled'>
              You have cancelled this job.<br />
              {this.net_total_fees ? (
                <React.Fragment>
                  As this job was cancelled at short notice, the late cancellation fee {this.total_fees_label} will be charged.<br />
                </React.Fragment>
              ) : null}
            </Alert>
          )
        }
      }
    }

    return null
  }

  // implement PaymentRecord
  record_type: 'payment' = 'payment'

  get employment_id() {
    return this.id
  }

  get record_id() {
    return `payment-${this.id}`
  }

  get record_path() {
    return [`payment-${this.id}`]
  }

  get record_sort_key() {
    return [this.start_at.toISO(), this.start_at.toISO()]
  }

  get timestamp() {
    return this.start_at
  }

  timestamp_label(tz: string, format: string = 'ccc, LLL dd') {
    return this.date_label(tz, format)
  }

  get paid_at() {
    if (this.pay_by_invoice) {
      return this.invoice_paid_at
    } else {
      return this.practice_payment_paid_at
    }
  }

  paid_at_label(tz: string, format: string = 'ccc, LLL dd') {
    return this.paid_at?.setZone(tz).toFormat(format) ?? ''
  }

  get amount() {
    return this.total_practice_fees
  }

  get amount_label() {
    return `£${(this.amount / 100).toFixed(2)}`
  }

  get adjustment() {
    const total_refunds = this.refunds.reduce((acc, refund) => acc + refund.amount, 0)
    const total_additional_charges = this.additional_charges.reduce((acc, additional_charge) => acc + additional_charge.amount, 0)
    return - total_refunds + total_additional_charges
  }

  get adjustment_label() {
    return `£${(this.adjustment / 100).toFixed(2)}`
  }

  get status() {
    return this.payment_status
  }

  get fee_correction_reason() {
    return ''
  }

  get status_label() {
    const is_payment_success = this.payment_status === 'practice_paid'
    const is_payment_error = this.payment_status === 'practice_payment_error'
    const is_payment_pending = (this.payment_status === 'practice_payment_pending' && !this.pay_by_invoice)
    const is_payment_requested = this.payment_status === 'practice_payment_requested'
    const is_invoice_pending = (this.payment_status === 'practice_payment_pending' && this.pay_by_invoice)
    const is_invoice_finalized = this.payment_status === 'practice_invoice_finalized'
    const is_invoice_paid = this.payment_status === 'practice_invoice_paid'
    const is_invoice_void = this.payment_status === 'practice_invoice_void'
    const is_invoice_payment_failed = this.payment_status === 'practice_invoice_payment_failed'

    if (is_payment_success) {
      return <Typography variant='body2'>Paid via {this.payment_method_label}</Typography>
    } else if (is_payment_error) {
      if (this.payment_method_stripe_id === this.practice_payment_payment_method_id 
        || this.practice_payment_payment_method_id === '') {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>Payment Error</Typography>
            <Chip label={this.payment_method_label} size='small' />
            <Chip label={this.payment_error_label} color='error' size='small' />
          </Box>
        )
      } else {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>Retrying Payment</Typography>
            <Chip label={this.payment_method_label} size='small' />
          </Box>
        )
      }
    } else if (is_payment_pending) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Payment Pending</Typography>
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_payment_requested) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Payment Processing</Typography>
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_invoice_pending) {
      return <Typography variant='body2'>Included in upcoming invoice</Typography>
    } else if (is_invoice_paid) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant='body2'>Paid via Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
        </Box>
      )
    } else if (is_invoice_payment_failed) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>
          <Link component='a' href={this.invoice_hosted_url} target="_blank">Retry Payment for Invoice #{this.invoice_number}</Link>
          <Chip label={this.invoice_charge_error} color='error' size='small' />
          <Chip label={this.payment_method_label} size='small' />
        </Box>
      )
    } else if (is_invoice_finalized) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>
          <Typography variant='body2'>Sent Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
          <Link component='a' href={this.invoice_hosted_url} target="_blank">Pay Invoice</Link>
        </Box>
      )
    } else if (is_invoice_void) {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>
          <Typography variant='body2'>Voided Invoice #{this.invoice_number}</Typography>
          <Link component='a' href={this.invoice_pdf} target="_blank">View Invoice</Link>
        </Box>
      )
    }
    return ''
  }

  get fulfillment_status_label() {
    if (this.fulfillment_status === 'scheduled') {
      return 'Booked'
    } else if (this.fulfillment_status === 'in_progress') {
      return 'In Progress'
    } else if (this.fulfillment_status === 'completed') {
      return 'Completed'
    } else if (this.fulfillment_status === 'cancelled') {
      return 'Cancelled'
    } else {
      return ''
    }
  }

  get payment_status_label() {
    if (['practice_paid', 'practice_invoice_paid'].includes(this.payment_status)) {
      return 'Paid'
    } else if (['practice_payment_error', 'practice_invoice_void', 'practice_invoice_payment_failed'].includes(this.payment_status)) {
      return 'Payment Failed'
    } else if (['practice_payment_pending', 'practice_payment_requested'].includes(this.payment_status)) {
      return 'Payment Pending'
    } else if (['practice_invoice_finalized'].includes(this.payment_status)) {
      return 'Invoice Sent To Practice'
    } else {
      return ''
    }
  }

  get timesheet_status_label() {
    if (['timesheet_pending_nurse_approval', 'timesheet_pending_practice_approval'].includes(this.timesheet_status)) {
      return 'Timesheet Pending Approval'
    } else if (['timesheet_approved'].includes(this.timesheet_status)) {
      return 'Timesheet Approved'
    } else if (['timesheet_disapproved'].includes(this.timesheet_status)) {
      return 'Timesheet Disapproved'
    } else {
      return ''
    }
  }
}