import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminPracticeGroupDetailContext from 'pages/AdminPracticeGroupDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminPracticeGroupMatches: React.FC = () => {
  const {
    matchesSearchForm,
    matchesSelection,
    matchesColumnVisibilityModel,
    matchesSortModel,
    updateMatchesSearchForm,
    setMatchesSelection,
    setMatchesColumnVisibilityModel,
    setMatchesSortModel,
  } = React.useContext(AdminPracticeGroupDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      onRowClick={onRowClick}
      form={matchesSearchForm}
      updateForm={updateMatchesSearchForm}
      selection={matchesSelection}
      setSelection={setMatchesSelection}
      columnVisibilityModel={matchesColumnVisibilityModel}
      setColumnVisibilityModel={setMatchesColumnVisibilityModel}
      sortModel={matchesSortModel}
      setSortModel={setMatchesSortModel}
    />
  )
}

export default AdminPracticeGroupMatches