import { useAuthUser } from 'contexts/AuthUserProvider'
import React from 'react'
import GuestTopNav from 'layouts/TopNav/Guest'
import AdminTopNav from 'layouts/TopNav/Admin'
import NurseTopNav from 'layouts/TopNav/Nurse'
import PracticeTopNav from 'layouts/TopNav/Practice'
import NurseBottomNav from 'layouts/BottomNav/Nurse'
import { Box } from '@mui/material'
import PracticeBottomNav from 'layouts/BottomNav/Practice'
import { useMatch } from 'react-router'
import PracticeGroupTopNav from 'layouts/TopNav/PracticeGroup'
import AffiliateTopNav from 'layouts/TopNav/Affiliate'

interface LayoutProps {
  showBottomNav?: boolean
  mainContentPaddingX?: number
}

const Layout: React.FC<LayoutProps> = ({ showBottomNav = true, mainContentPaddingX, children }) => {
  const { authUser } = useAuthUser()
  const match_nurse = useMatch({path: '/nurse', end: false})
  const match_practice = useMatch({path: '/practice', end: false})

  const topNav = React.useMemo(() => {
    if (authUser?.userType === 'staff') {
      return <AdminTopNav />
    } else if (authUser?.userType === 'nurse') {
      return <NurseTopNav />
    } else if (authUser?.userType === 'practice') {
      return <PracticeTopNav />
    } else if (authUser?.userType === 'practice-group') {
      return <PracticeGroupTopNav />
    } else if (authUser?.userType === 'affiliate') {
      return <AffiliateTopNav />
    } else {
      return <GuestTopNav />
    }
  }, [authUser?.userType])

  const bottomNav = React.useMemo(() => {
    if (!showBottomNav) {
      return null
    } else if (authUser?.userType === 'nurse') {
      return <NurseBottomNav />
    } else if (authUser?.userType === 'practice') {
      return <PracticeBottomNav />
    } else if (authUser?.userType === 'staff') {
      if (match_nurse) {
        return <NurseBottomNav />
      } else if (match_practice) {
        return <PracticeBottomNav />
      }
    } else if (authUser?.userType === 'practice-group') {
      if (match_practice) {
        return <PracticeBottomNav />
      }
    } else {
      return null
    }
  }, [authUser?.userType, match_nurse, match_practice, showBottomNav])

  const gridTemplateRows = React.useMemo(() => {
    if (bottomNav) {
      return "auto 1fr auto"
    } else {
      return "auto 1fr"
    }
  }, [bottomNav])

  const gridTemplateAreas = React.useMemo(() => {
    if (bottomNav) {
      return `
      "topnav"
      "maincontent"
      "bottomnav"`
    } else {
      return `
      "topnav"
      "maincontent"`
    }
  }, [bottomNav])

  return (
    <Box sx={{
      display: "grid",
      gap: 1,
      gridTemplateRows: gridTemplateRows,
      gridTemplateAreas: gridTemplateAreas,
      height: window.innerHeight
    }}>
      <Box sx={{
        gridArea: 'topnav'
      }}>
        {topNav}
      </Box>
      <Box sx={{
        gridArea: 'maincontent',
        px: mainContentPaddingX ? mainContentPaddingX : [1, 1, 5, 10, 15],
        overflowY: 'scroll'
      }}>
        {children}
      </Box>
      {bottomNav ? (
        <Box sx={{
          gridArea: 'bottomnav'
        }}>
          {bottomNav}
        </Box>
      ) : null}
    </Box>
  )
}

export default Layout