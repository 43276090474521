import { Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'

export class QueuedEmail {
  id!: number
  subject!: string
  template!: string
  context!: any
  to!: string[]
  cc!: string[]
  bcc!: string[]

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  sent_at!: DateTime

  get created_at_label() {
    return this.created_at.toFormat('LLL dd yyyy h:mm a')
  }

  get sent_at_label() {
    return this.sent_at.toFormat('LLL dd yyyy h:mm a')
  }
}