import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

type PracticeInfo = {
  id: number
  name: string
}

type PracticeGroupInfo = {
  id: number
  name: string
}

export default class UserInfo {
  id!: number
  firebase_id!: string
  zendesk_user_id!: string
  email!: string
  first_name!: string
  last_name!: string
  full_name!: string
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  date_joined!: DateTime
  
  is_staff!: boolean
  
  // annotate_roles
  user_is_nurse!: boolean
  user_is_affiliate!: boolean
  user_is_practice!: boolean
  user_is_practice_group!: boolean
  user_is_practice_group_practice!: boolean
  
  // annotate_practices
  practice_info!: PracticeInfo[]
  
  // annotate_practice_groups
  practice_group_info!: PracticeGroupInfo[]
  
  // annotate permission flags
  user_can_delete!: boolean
  user_cannot_delete_reason!: string

  get practice_ids() {
    return this.practice_info.map(({ id }) => id)
  }

  get practice_group_ids() {
    return this.practice_group_info.map(({ id }) => id)
  }
}

