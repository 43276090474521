import {
  createTheme,
  experimental_sx as sx,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    demand: PaletteOptions['primary']
  }
  interface PaletteColor {
    low?: string
    mid?: string
    high?: string
  }
  interface SimplePaletteColorOptions {
    low?: string
    mid?: string
    high?: string
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#63c8d0',
    },
    secondary: {
      main: '#221E52',
      light: '#a7a5ba',
    },
    error: {
      main: '#cc4b37',
      light: '#f7e4e1'
    },
    warning: {
      main: '#ffae00',
      light: '#fff3d9'
    },
    success: {
      main: '#3adb76',
      light: '#e1faea'
    },
    background: {
      default: '#fefefe',
      paper: '#e8f7f8'
    },
    demand: {
      main: 'yellow',
      low: 'yellow',
      mid: 'yellow',
      high: 'yellow'
    }
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.7rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 400,
    }
  },
  spacing: 10,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: sx({ px: 1, py: 0 })
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: sx({
          '.MuiPaper-root &': {
            backgroundColor: '#fefefe'
          }
        })
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        })
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            display: 'flex', 
            flexDirection: 'column',
            gap: 1,
            border: '1px solid lightgray'
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            border: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'end',
            '&::before': {
              content: 'attr(data-label)',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }
          },
          '& ul': {
            margin: 0
          }
        })
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: 50,
            minWidth: 50,
          }
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          background: 'white',
          border: 'solid thick black',
          width: '60%',
          [theme.breakpoints.down('sm')]: {
            width: '95%',
          }
        })
      }
    }
  }
});

export default theme