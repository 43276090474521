import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

class BillingAccountInfo {
  id!: number
  name!: string
}

export default class PracticeGroup {
  id!: number
  name!: string

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  billing_accounts!: BillingAccountInfo[]
}