import React from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router'
import Header from 'components/Header'
import JobEmploymentTable from 'components/JobEmploymentTable'

const PracticeGroupJobEmploymentList: React.FC = () => {
    const params = useParams()
    const practiceGroupId = React.useMemo(() => Number(params.practiceGroupId), [params.practiceGroupId])
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
            <Header text='Timesheets' />
            <JobEmploymentTable practice_group_id={practiceGroupId} canSearchByPractice={true} />
        </Box>
    )
}

export default PracticeGroupJobEmploymentList