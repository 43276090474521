import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import { isEmpty } from 'lodash'
import Header from 'components/Header'
import { DOCUMENTATIONS_LABEL_MAPPING } from 'types/constants'
import ErrorList from 'components/ErrorList'
import CustomEmail from 'components/CustomEmail'
import useDocumentVerify from './hooks/useDocumentVerify'
import DocumentDisplay from './components/DocumentDisplay'
import VerifyForm from './components/VerifyForm'
import PageSelector from './components/PageSelector'

const AdminNurseDocumentVerify: React.FC = () => {
    const { 
        docType, 
        files, 
        nurse, 
        form, 
        updateForm, 
        error,
        loading, 
        deleting, 
        save, 
        deleteFile, 
        scrollToFile, 
        futureBookings 
    } = useDocumentVerify()

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            gridTemplateAreas: `'image form'`,
            gap: 2
        }}>
            <Box sx={{ gridArea: 'image', alignSelf: 'center' }}>
                <DocumentDisplay files={files} />
            </Box>
            <Box sx={{ gridArea: 'form', display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Header variant='h3' text={DOCUMENTATIONS_LABEL_MAPPING[docType]}></Header>
                <VerifyForm form={form} updateForm={updateForm} error={error} docType={docType} />
                {error?.schema ? (
                    <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
                ) : null}
                <Button variant="contained" color="primary" onClick={save} disabled={loading}>
                    {loading ? 'Please Wait ...' : 'Update Document Status'}
                </Button>
                {!form.approved && !isEmpty(futureBookings) ? (
                    <Alert variant='filled' severity='warning'>
                        This nurse has jobs booked in the future. <br />
                        If you disapprove this nurse, remember to cancel those bookings and find replacements for those jobs.
                    </Alert>
                ) : null}
                <PageSelector files={files} scrollToFile={scrollToFile} deleteFile={deleteFile} deleting={deleting} />
                <CustomEmail
                    nurse={nurse}
                    subject='Action required before we can approve your account'
                />
            </Box>
        </Box>
    )
}

export default AdminNurseDocumentVerify