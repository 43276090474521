import Practice from 'logic/Practice'
import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import { 
  DataGridPro, 
  GridColDef, 
  GridColumnVisibilityModel, 
  GridEventListener, 
  GridRenderCellParams, 
  GridRowId, 
  GridSortModel, 
  GridValueGetterParams 
} from '@mui/x-data-grid-pro';
import FormField from 'components/FormField'
import { isEmpty } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { PracticeSearchForm } from 'types/interfaces'
import { adminPracticeDetailURL } from 'routes/urls'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Observable} from 'rxjs'
import PracticeGroup from 'logic/PracticeGroup'
import ExportOnlyToolBar from 'components/ExportToolbar'

interface AdminPracticeTableProps {
  reload$?: Observable<boolean>
  onRowClick?: GridEventListener<"rowClick">
  actions?: React.ReactNode
  form: PracticeSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminPracticeTable: React.FC<AdminPracticeTableProps> = ({
  onRowClick,
  actions,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
  reload$,
}) => {
  const { api } = useAPI()
  const [practices, setPractices] = React.useState<Practice[]>([])
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchPractices = React.useCallback(async () => {
    setLoading(true)
    setPractices(await api.listPractices(form))
    setLoading(false)
  }, [api, form])

  const fetchPracticeGroups = React.useCallback(async () => {
    setPracticeGroups(await api.listPracticeGroup())
  }, [api])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  React.useEffect(() => {
    fetchPracticeGroups()
  }, [fetchPracticeGroups])

  // React.useEffect(() => {
  //   fetchPractices()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchPractices())
    return () => sub?.unsubscribe()
  }, [fetchPractices, reload$])

  const columns: GridColDef<Practice>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'practice_id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Name',
    field: 'practice_name',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return (
        <Link typography='body1' component={RouterLink} to={adminPracticeDetailURL(practice.practice_id)}>
          {practice.practice_name}
        </Link>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Group',
    field: 'practice_group_name',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      return params.row.practice_group_name || 'Standalone'
    }
  }, {
    minWidth: 300,
    headerName: 'First Member',
    field: 'first_member_label',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Inquiry Phone',
    field: 'phone_for_inquiries',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Location',
    field: 'location_label',
    type: 'string',
  }, {
    minWidth: 50,
    headerName: 'Distance',
    field: 'distance_miles',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      return params.row.distance_label
    }
  }, {
    minWidth: 50,
    headerName: 'Enabled',
    field: 'enabled',
    type: 'boolean',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return (
        <Chip 
          size='small' 
          color={practice.enabled ? 'success' : 'error'}
          label={practice.enabled ? 'Yes' : 'No'} />
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Jobs (upcoming)',
    field: 'jobs_upcoming',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return `${practice.jobs_upcoming} Jobs (${practice.jobs_taken_upcoming} taken)`
    }
  }, {
    minWidth: 200,
    headerName: 'Jobs (posted last 30 days)',
    field: 'jobs_last_30_days',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return `${practice.jobs_last_30_days} Jobs (${practice.jobs_taken_last_30_days} taken)`
    }
  }, {
    minWidth: 200,
    headerName: 'Jobs (posted last 90 days)',
    field: 'jobs_last_90_days',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return `${practice.jobs_last_90_days} Jobs (${practice.jobs_taken_last_90_days} taken)`
    }
  }, {
    minWidth: 200,
    headerName: 'Jobs (total)',
    field: 'jobs_total',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return `${practice.jobs_total} Jobs (${practice.jobs_taken_total} taken)`
    }
  }, {
    minWidth: 200,
    headerName: 'Ready to Process Payment',
    field: 'is_stripe_ready',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      if (practice.is_stripe_ready) {
        return 'Yes'
      } else {
        return practice.not_stripe_ready_reason
      }
    }
  }, {
    minWidth: 250,
    headerName: 'Payment Option',
    field: 'payment_option',
    type: 'string',
  },{
    minWidth: 300,
    headerName: 'SignUp At',
    field: 'created_at',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<Practice>) => {
      return params.row.created_at.toISO()
    },
    renderCell: (params: GridRenderCellParams<Practice>) => {
      const practice = params.row
      return (
        <Chip 
          size='small' 
          color={practice.signup_at_color}
          label={practice.signup_at_label} />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Affiliate',
    field: 'affiliate_name',
    type: 'string',
  }], [])

  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          name='name'
          label='Practice Name'
          type='text'
          onChange={(e) => updateForm('name', e.target.value)}
          value={form.name}
        />
        <FormField
          select
          sx={{ width: 200 }}
          name='practice_group'
          label='Practice Group'
          onChange={(e) => updateForm('practice_group_id', e.target.value)}
          value={form.practice_group_id ?? ''}>
          <MenuItem value=''>Not Specified</MenuItem>
          <MenuItem value='-999'>Standalone</MenuItem>
          {practiceGroups.map((group) => (
            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
          ))}
        </FormField>
        <FormField
          sx={{ width: 150 }}
          name='payment_card'
          label='Payment Card'
          select
          onChange={(e) => {
            if (e.target.value === 'provided') {
              updateForm('is_stripe_ready', true)
            } else if (e.target.value === 'not-provided') {
              updateForm('is_stripe_ready', false)
            } else {
              updateForm('is_stripe_ready', undefined)
            }
          }}
          value={form.is_stripe_ready}
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='provided'>Provided</MenuItem>
          <MenuItem value='not-provided'>Not Provided</MenuItem>
        </FormField>
        <FormField
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
          name='from_date'
          label='Signup From Date'
          type='date'
          onChange={(e) => updateForm('from_date', e.target.value)}
          value={form.from_date}
        />
        <FormField
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
          name='to_date'
          label='Signup To Date'
          type='date'
          onChange={(e) => updateForm('to_date', e.target.value)}
          value={form.to_date}
        />
        <Button
          variant="contained"
          onClick={fetchPractices}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {loading ? (
        <Alert severity='info'>Please Wait ...</Alert>
      ) : isEmpty(practices) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{practices.length} records returned. {selection.length} selected</Alert>
          {/* <Box sx={{ height: '70vh', width: '98vw' }}> */}
          <DataGridPro 
            rows={practices}
            columns={columns}
            getRowId={(practice) => practice.practice_id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
            slots={{ toolbar: ExportOnlyToolBar }}
          />
          {/* </Box> */}
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminPracticeTable