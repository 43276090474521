import { Box, Button, Typography } from '@mui/material'
import CheckboxFormField from 'components/CheckboxFormField'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useNurse } from 'contexts/NurseProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { nurseAffiliatePageURL } from 'routes/urls'

const NurseAffiliateSignup: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { reloadNurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { refreshUser } = useAuthUser()
  const [agreed, setAgreed] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const save = React.useCallback(async () => {
    if (!params.nurseId) return
    if (!agreed) {
      showAlert('error', 'You must agree to our contracts first.')
      return
    }
    try {
      setLoading(true)
      const nurseId = Number(params.nurseId)
      const affiliate = await api.createNurseAffiliate(nurseId)
      await refreshUser(true)
      await reloadNurse()
      navigate(nurseAffiliatePageURL(nurseId, affiliate.id))
    } catch (e) {
      showAlert('error', 'Affiliate signup failed')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [agreed, api, navigate, params.nurseId, refreshUser, reloadNurse, showAlert])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header variant='h3' text='Sign Up as an Affiliate' />
      <Typography>Simple steps to earning more with locumloop's affiliate scheme:</Typography>
      <Typography>
      1. When you sign up you will receive a unique affiliate code. <br/>
      2. Tell practices about locumloop and give them your code. <br/>
      3. If a practice signs up using your code you will earn 10% of the daily fee we charge to that practice. <br/>
      4. You will receive payouts for all jobs that the practice posts for up to 2 years after their first job post! <br/>
      </Typography>
      <CheckboxFormField
        label={
          <Typography variant='body2'>
            Check here to accept <a href='/legal/affiliate.pdf' target='_blank' rel='noopener noreferrer'>locumloop's affiliate agreement</a>
          </Typography>
        }
        onChange={(e) => setAgreed(e.target.checked)}
        checked={agreed}
      />
      <Button variant="contained" onClick={save} disabled={loading}>
        { loading ? 'Please Wait ...' : 'Signup as Affiliate' }
      </Button>
    </Box>
  )
}

export default NurseAffiliateSignup