import { get } from "lodash";

export default class APIError {
  constructor(readonly statusCode: number, readonly statusText: string, readonly body: any = {}) {}

  get message() {
    if (typeof this.body === 'string') {
      return this.body
    } else {
      return get(this.body, 0, this.statusText)
    }
  }
}