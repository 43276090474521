import React from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { nurseWaitlistCPDURL, loginURL } from 'routes/urls';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';

const NurseWaitlistCPDRedirect: React.FC = () => {
  const { authUser, logout } = useAuthUser()
  const location = useLocation()
  const { showAlert } = useSnackBarAlert()

  if (authUser === undefined) {
    return null
  } else if (authUser?.nurseId) {
    return <Navigate replace to={nurseWaitlistCPDURL(authUser.nurseId)} />
  } else if (authUser) {
    logout()
    showAlert('error', 'Only nurses can join the CPD training waiting list')
    return null
  } else {
    return <Navigate replace to={loginURL(location.pathname)} />
  }
}

export default NurseWaitlistCPDRedirect