import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class Invitation {
  id!: number
  code!: string
  practice_id!: number
  practice_name!: string
  position!: string
  to!: string
  created_by_id!: number
  created_by_name!: string
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  sent_at!: DateTime | null

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  accepted_at!: DateTime | null
}