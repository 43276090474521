import React from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  PRACTICE_STEP_BASIC,
  PRACTICE_STEP_POST_JOBS,
  PRACTICE_STEP_BILLING,
  PRACTICE_STEP_COMPLETED,
} from 'types/constants'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useFirebase } from 'contexts/FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { map, from, share, switchMap, NEVER, filter, Subscription, firstValueFrom } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { Notification } from 'logic/Notification'
import { useAPI } from 'contexts/APIProvider';
import Practice from 'logic/Practice';
import PracticeSignupContext from './context';
import PracticeSignupSummary from './Summary';
import PracticeSignupBasicInfoEdit from './BasicInfoEdit';
import PracticeSignupBasicInfo from './BasicInfo';
import PracticeSignupPaymentDetails from './PaymentDetails';
import Job from 'logic/Job';
import { Outlet } from 'react-router';
import { NursePaygradeStats } from 'types/interfaces';
import { DateTime } from 'luxon';
import BillingAccount from 'logic/BillingAccount';

const PracticeSignup: React.FC = () => {
  const sendDataToGTM = useGTMDispatch()
  const { authUser } = useAuthUser()
  const { api } = useAPI()
  const { firestore } = useFirebase()
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [step, setStep] = React.useState<number>();
  const [emittedGTMEvents] = React.useState<Set<string>>(new Set());
  const [practice, setPractice] = React.useState<Practice | null>()
  const [billingAccount, setBillingAccount] = React.useState<BillingAccount | null>()
  const [jobs, setJobs] = React.useState<Job[]>()
  const [paygradeStats, setPaygradeStats] = React.useState<NursePaygradeStats>()
  
  const practiceId = React.useMemo(() => {
    if (authUser === undefined) {
      return undefined
    } else if (authUser === null) {
      return null
    } else {
      return authUser.practiceIds?.[0]
    }
  }, [authUser]) 
  
  const fetchPractice = React.useCallback(async () => {
    if (practiceId) {
      setPractice(await api.getPractice(practiceId))
    } else if (practiceId === null) {
      setPractice(null)
    }
  }, [api, practiceId])

  const fetchBillingAccount = React.useCallback(async () => {
    if (practice && practice.billing_account_id) {
      setBillingAccount(await api.getBillingAccount(practice.billing_account_id))
    } else {
      setBillingAccount(null)
    }
  }, [api, practice])

  const fetchJobs = React.useCallback(async () => {
    if (!practice) return
    setJobs(await api.listJobs({
      practice_id: practice.practice_id,
      fulfillment_statuses: ['filled', 'not_filled', 'partially_filled'],
    }))
  }, [api, practice])

  const fetchPaygradeStats = React.useCallback(async () => {
    if (authUser && practice && !paygradeStats) {
      setPaygradeStats(await api.getNursePaygradeStats())
    }
  }, [api, authUser, paygradeStats, practice])

  const notificationQuery = React.useMemo(() => {
    if (!authUser || !practiceId) return null
    return query(
      collection(firestore, 'notifications'),
      where("practice_id", "==", practiceId)
    )
  }, [authUser, firestore, practiceId])

  const notification$ = React.useMemo(() => {
    if (!notificationQuery) return NEVER
    return collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    )
  }, [notificationQuery])

  const paymentMethodAttached = React.useMemo(() => {
    return firstValueFrom(notification$.pipe(
      filter(notification => notification.event === 'payment_method_attached')
    ))
  }, [notification$])

  React.useEffect(() => {
    fetchPractice()
  }, [fetchPractice])

  React.useEffect(() => {
    fetchBillingAccount()
  }, [fetchBillingAccount])

  React.useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  React.useEffect(() => {
    fetchPaygradeStats()
  }, [fetchPaygradeStats])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'practice_updated',
      'job_cancelled',
      'job_taken',
      'job_created',
      'job_updated',
      'payment_method_attached',
      'payment_method_detached',
      'payment_method_updated',
      'billing_account_created',
      'billing_account_updated',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(fetchPractice))
    return () => sub.unsubscribe()
  }, [fetchPractice, notification$])

  React.useEffect(() => {
    if (step === undefined) {
      if (practice === null) {
        setStep(PRACTICE_STEP_BASIC)
      } else if (practice !== undefined) {
        setStep(practice.signup_step)
      }
      // don't do anything if practice is still undefined (still loading usertoken from browser storage)
    }
  }, [practice])

  const toNextStep = React.useCallback(() => {
    if (step !== undefined) setStep(step < PRACTICE_STEP_COMPLETED ? step + 1 : step)
  }, [step])

  const toPreviousStep = React.useCallback(() => {
    if (step !== undefined) setStep(step > 0 ? step - 1 : step)
  }, [step])

  const emitGTMEvent = React.useCallback((event: string) => {
    if (!emittedGTMEvents.has(event)) {
      console.log('Emit GTM Event', event)
      sendDataToGTM({ event })
      emittedGTMEvents.add(event)
    }
  }, [emittedGTMEvents, sendDataToGTM])

  const mode = React.useMemo(() => {
    if (practice === null) {
      return 'add'
    } else if (practice) {
      return 'edit'
    } else {
      return undefined
    }
  }, [practice])

  const contextValue = { 
    practice, 
    billingAccount,
    jobs, 
    step, 
    toNextStep, 
    toPreviousStep, 
    emitGTMEvent, 
    reloadJobs: fetchJobs, 
    paymentMethodAttached, 
    paygradeStats,
  }

  return (
    <PracticeSignupContext.Provider value={contextValue}>
      {step === undefined || mode === undefined ? (
        <p>Please Wait ... </p>
      ) : (
        <React.Fragment>
          <Box sx={{
            px: [0, 2],
            py: [0, 2],
            backgroundColor: ['transparent', 'background.paper'],
            boxShadow: [0, 2],
            marginTop: 2
          }}>
            <Stepper activeStep={step} orientation={isDesktop ? 'horizontal' : 'vertical'}>
                <Step key='basicInfo'>
                  <StepLabel>Basic Info</StepLabel>
                  {!isDesktop ? mode === 'edit' ? (
                    <StepContent>
                      <PracticeSignupBasicInfoEdit />
                    </StepContent>
                  ) : (  // is Create Practice
                    <StepContent>
                      <PracticeSignupBasicInfo />
                    </StepContent>
                  ) : null}
                </Step>
                <Step key='postJobs'>
                  <StepLabel>Post a Job</StepLabel>
                  {!isDesktop ? (
                    <StepContent>
                      <Outlet />
                    </StepContent>
                  ) : null}
                </Step>
                <Step key='paymentDetails'>
                  <StepLabel>Payment</StepLabel>
                  {!isDesktop ? (
                    <StepContent>
                      <PracticeSignupPaymentDetails />
                    </StepContent>
                  ) : null}
                </Step>
                <Step key='completed'>
                  <StepLabel>Booking Completed</StepLabel>
                  {!isDesktop ? (
                    <StepContent>
                      <PracticeSignupSummary />
                    </StepContent>
                  ) : null}
                </Step>
              </Stepper>
          </Box>
          {isDesktop ? step === PRACTICE_STEP_BASIC ? mode === 'edit' ? (
            <PracticeSignupBasicInfoEdit />
          ) : (  // is Create Practice
            <PracticeSignupBasicInfo />
          ) : step === PRACTICE_STEP_POST_JOBS ? (
            <Outlet />
          ) : step === PRACTICE_STEP_BILLING ? (
            <PracticeSignupPaymentDetails />
          ) : step === PRACTICE_STEP_COMPLETED ? (
            <PracticeSignupSummary />
          ) : null : null}
        </React.Fragment>
      )}
    </PracticeSignupContext.Provider>
  )
}

export default PracticeSignup