import React from 'react'
import { useFirebase } from './FirebaseProvider'
import { user } from 'rxfire/auth'
import { User } from '../logic/User'
import { User as FirebaseUser, signOut } from 'firebase/auth'
import { UserClaim } from '../types/interfaces'
import { Observable, shareReplay, switchMap } from 'rxjs'
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router'
import { loginURL } from 'routes/urls'

interface IAuthUserContext {
  authUser?: User | null
  refreshUser: (forceRefresh?: boolean) => Promise<User | null>
  logout: () => Promise<void>
  authUser$: Observable<User | null>
}

const AuthUserContext = React.createContext<IAuthUserContext>({} as IAuthUserContext)

const AuthUserProvider: React.FC = ({ children }) => {
  const { auth } = useFirebase()
  const [authUser, setAuthUser] = React.useState<User | null>()
  const navigate = useNavigate()

  // get User from firebase IndexedDB
  const getUser = async (firebaseUser: FirebaseUser | null, forceRefresh?: boolean) => {
    if (firebaseUser) {
      const tokenResult = await firebaseUser.getIdTokenResult(forceRefresh)
      const expiry = DateTime.fromRFC2822(tokenResult.expirationTime)
      if (expiry < DateTime.now()) {
        console.log(`authToken expired at ${expiry.toISO()}`)
        return null
      } else {
        return new User(firebaseUser, tokenResult?.claims as UserClaim)
      }
    } else {
      console.log('AuthUserProvider.getUser() firebaseUser is false', firebaseUser)
      return null
    }
  }

  // sync context User with firebase IndexedDB
  const refreshUser = async (forceRefresh?: boolean) => {
    const authUser = await getUser(auth.currentUser, forceRefresh)
    setAuthUser(authUser)
    return authUser
  }

  const logout = async () => {
    await signOut(auth)
    await refreshUser(true)
    navigate(loginURL())
  }

  // stream of User
  const authUser$ = React.useMemo(() => user(auth).pipe(
    switchMap(user => getUser(user)),
    shareReplay(1),
  ), [])

  // on page init, load user from firebase IndexedDB
  React.useEffect(() => {
    const sub = authUser$.subscribe(user => {
      if (authUser === undefined) setAuthUser(user)
    })
    return () => sub.unsubscribe()
  }, [])

  React.useEffect(() => {
    console.log('authUser', authUser)
  }, [authUser])

  const value = { authUser, refreshUser, logout, authUser$ }
  return <AuthUserContext.Provider value={value}> {children} </AuthUserContext.Provider>
}

function useAuthUser() {
  const context = React.useContext(AuthUserContext)
  if (context === undefined) {
    throw new Error('useAuthUser must be used within a AuthUserProvider')
  }
  return context
}

export { AuthUserProvider, useAuthUser }