import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import Nurse from 'logic/Nurse'
import React from 'react'
import { ChartSearchForm, JobEmploymentSearchForm, PracticeSearchForm } from 'types/interfaces'

interface IAdminNurseDetailContext {
  nurse: Nurse
  fetchNurse: () => Promise<void>

  // job tab
  jobSearchForm: JobEmploymentSearchForm
  jobSelection: GridRowId[]
  jobColumnVisibilityModel: GridColumnVisibilityModel
  jobSortModel: GridSortModel
  updateJobSearchForm: (name: string, value: any) => void
  setJobSelection: (value: GridRowId[]) => void
  setJobColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setJobSortModel: (value: GridSortModel) => void

  // reviews tab
  reviewsSearchForm: JobEmploymentSearchForm
  reviewsSelection: GridRowId[]
  reviewsColumnVisibilityModel: GridColumnVisibilityModel
  reviewsSortModel: GridSortModel
  updateReviewsSearchForm: (name: string, value: any) => void
  setReviewsSelection: (value: GridRowId[]) => void
  setReviewsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setReviewsSortModel: (value: GridSortModel) => void

  // cancellations tab
  cancellationsSearchForm: JobEmploymentSearchForm
  cancellationsSelection: GridRowId[]
  cancellationsColumnVisibilityModel: GridColumnVisibilityModel
  cancellationsSortModel: GridSortModel
  updateCancellationsSearchForm: (name: string, value: any) => void
  setCancellationsSelection: (value: GridRowId[]) => void
  setCancellationsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setCancellationsSortModel: (value: GridSortModel) => void

  // blacklists tab
  blacklistsSearchForm: JobEmploymentSearchForm
  blacklistsSelection: GridRowId[]
  blacklistsColumnVisibilityModel: GridColumnVisibilityModel
  blacklistsSortModel: GridSortModel
  updateBlacklistsSearchForm: (name: string, value: any) => void
  setBlacklistsSelection: (value: GridRowId[]) => void
  setBlacklistsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setBlacklistsSortModel: (value: GridSortModel) => void

  // nearby practice tab
  nearbyPracticeSearchForm: PracticeSearchForm
  nearbyPracticeSelection: GridRowId[]
  nearbyPracticeColumnVisibilityModel: GridColumnVisibilityModel
  nearbyPracticeSortModel: GridSortModel
  updateNearbyPracticeSearchForm: (name: string, value: any) => void
  setNearbyPracticeSelection: (value: GridRowId[]) => void
  setNearbyPracticeColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setNearbyPracticeSortModel: (value: GridSortModel) => void

  // charts tab
  chartsSearchForm: ChartSearchForm
  updateChartsSearchForm: (name: string, value: any) => void
}

const AdminNurseDetailContext = React.createContext<IAdminNurseDetailContext>({} as IAdminNurseDetailContext)

export default AdminNurseDetailContext