import IssueInvoiceLateFee from 'pages/IssueInvoiceLateFee/form'
import React from 'react'
import { useParams } from 'react-router-dom'

const PracticeGroupIssueInvoiceLateFee: React.FC = () => {
    const params = useParams()
    const practiceGroupId = React.useMemo(() => Number(params.practiceGroupId), [params.practiceGroupId])
    return <IssueInvoiceLateFee practice_group_id={practiceGroupId} />
}

export default PracticeGroupIssueInvoiceLateFee