import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router';
import { practiceGroupBillingAccountListURL } from 'routes/urls'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { usePracticeGroup } from 'contexts/PracticeGroupProvider';
import Header from 'components/Header';
import SetupBillingAccount from 'components/SetupBillingAccount';
import Loading from 'components/Loading';
import { PAYMENT_OPTIONS_FOR_CORPORATE_PRACTICES } from 'types/constants';

const PracticeGroupBillingAccountAdd: React.FC = () => {
  const { practiceGroup } = usePracticeGroup()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()

  const onSuccess = React.useCallback(() => {
    if (!practiceGroup) return
    showAlert('success', 'Billing account added successfully')
    navigate(practiceGroupBillingAccountListURL(practiceGroup.id))
  }, [navigate, practiceGroup, showAlert])

  if (!practiceGroup) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Add Billing Account' />
      <SetupBillingAccount
        practiceGroup={practiceGroup}
        onSuccess={onSuccess}
        bacsDebitSuccessURL={practiceGroupBillingAccountListURL(practiceGroup.id)}
        availablePaymentOptions={PAYMENT_OPTIONS_FOR_CORPORATE_PRACTICES}
      />
    </Box>
  )
}

export default PracticeGroupBillingAccountAdd