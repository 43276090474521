import { Box, Chip, Typography, Tabs, Tab, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { Outlet, useMatch, useNavigate, useParams } from 'react-router'
import SplitButton from 'components/SplitButton'
import { nurseViewJobEmploymentURL, practiceViewJobURL } from 'routes/urls'
import JobEmployment from 'logic/JobEmployment'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import AdminEmploymentManageForm from 'pages/AdminEmploymentDetail/JobForm'
import FeeCorrectionForm from 'pages/AdminEmploymentDetail/FeeCorrectionForm'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'

const AdminEmploymentDetail: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const params = useParams()
  const navigate = useNavigate()
  const [employment, setEmployment] = React.useState<JobEmployment>()
  const match = useMatch({ path: '/admin/employments/:employmentId/:rest', caseSensitive: false, end: false })

  const fetchEmployment = React.useCallback(async () => {
    setEmployment(await api.getJobEmployment(Number(params.employmentId)))
  }, [api, params.employmentId])

  const cancelJobEmployment = React.useCallback(async () => {
    if (!employment) return
    if (!employment.can_cancel) {
      showAlert('error', employment.cannot_cancel_reason)
      return
    }
    try {
      await api.cancelJobEmploymentByAdmin(employment.id)
      await fetchEmployment()
      showAlert('info', (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>The match has been canceled.</Typography>
          <Typography>All fees is set to zero. If you still need to charge the fee, please reset it in the form below.</Typography>
          <Typography>No notifications have been sent to the nurse/practice. Please do so manually.</Typography>
          <Typography>
            The position is still open and other nurses may take it. If you need to close the job or reduce the headcount. 
            <Link component={RouterLink} to={practiceViewJobURL(employment.practice_id, employment.job_id)}>
              Click here to edit/cancel the job.
            </Link>
          </Typography>
        </Box>
      ))
    } catch (e) {
      showAlert('error', 'Failed to cancel the match')
      console.error(e)
    }
  }, [api, employment, fetchEmployment, showAlert])

  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'profile'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchEmployment()
  }, [fetchEmployment])

  if (!employment) return <Loading />

  const contextValue = {
    employment,
    fetchEmployment,
  }

  const title = `${employment.practice_name} / ${employment.nurse_name} / ${employment.date_label(employment.practice_tz)}`

  return (
    <AdminEmploymentDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Typography variant='h1'>{title}</Typography>
          <Chip 
            label={employment.fulfillment_status} 
            color={employment.fulfillment_status_color}/>
          {employment.payment_status ? (
            <Chip 
              label={employment.payment_status} 
              color={employment.payment_status_color} />
          ) : null}
          {employment.timesheet_status ? (
            <Chip
              label={employment.timesheet_status}
              color={employment.timesheet_status_color}/>
          ) : null}
          {employment.practice_group_name ? (
            <Chip label={employment.practice_group_name} />
          ) : null}
          <Chip label={`Role: ${employment.job_role}`} />
          <Chip label={`Nurse Phone: ${employment.nurse_phone_number}`} />
          <Chip label={`Practice Phone: ${employment.practice_phone_number}`} />
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'view-as-practice',
            label: 'View as practice',
            onClick: () => navigate(practiceViewJobURL(employment.practice_id, employment.job_id))
          }, {
            value: 'view-as-nurse',
            label: 'View as nurse',
            onClick: () => navigate(nurseViewJobEmploymentURL(employment.nurse_id, employment.id))
          }, {
            value: 'cancel-as-admin',
            label: 'Cancel as Admin',
            onClick: () => cancelJobEmployment()
          }]} />
        </Box>
        <Box sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gridTemplateColumns: '1fr 400px',
          gridTemplateAreas: `
          'tabs form'
          'outlet form'`,
          gap: 1
        }}>
          <Tabs
            sx={{ gridArea: 'tabs' }}
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto">
            <Tab label='People' value='people' onClick={() => navigate('people')} />
            <Tab label='Hours' value='hours' onClick={() => navigate('hours')} />
            <Tab label='Status' value='status' onClick={() => navigate('status')} />
            <Tab label='Payments' value='payments' onClick={() => navigate('payments')} />
          </Tabs>
          <Box sx={{ gridArea: 'outlet' }}>
            <Outlet />
          </Box>
          <Box sx={{ gridArea: 'form' }}>
            {employment.can_update ? (
              <AdminEmploymentManageForm />
            ) : employment.can_fee_correction ? (
              <FeeCorrectionForm />
            ) : null}
          </Box>
        </Box>
      </Box>
    </AdminEmploymentDetailContext.Provider>
  )
}

export default AdminEmploymentDetail