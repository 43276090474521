import {
  Box,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Alert,
} from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import Loading from 'components/Loading'
import JobEmployment from 'logic/JobEmployment'
import React from 'react'
import { PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING } from 'types/constants'
import { PaymentOption } from 'types/types'

interface ImpactedBookingsProps {
  bookings?: JobEmployment[]
  oldPaymentOption?: PaymentOption
  newPaymentOption?: PaymentOption
}

const ImpactedBookings: React.FC<ImpactedBookingsProps> = ({ bookings, oldPaymentOption, newPaymentOption }) => {  
  const getPaymentScheduleChange = React.useCallback((booking: JobEmployment) => {
    if (!newPaymentOption) return null
    return (
      <Box sx={{ display: 'flex', gap: 1, alignItems: ['flex-end', 'center'], flexDirection: ['column', 'row'] }}>
        <Chip label={PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[newPaymentOption]['practice']} size='small' />
        <Typography variant='body2'>New payment schedule will be applied to this job.</Typography>
      </Box>
    )
  }, [newPaymentOption])

  if (!bookings || !oldPaymentOption || !newPaymentOption) return <Loading />
  
  return (
    <React.Fragment>
      <Typography>Please confirm the following changes to your payment preference</Typography>
      <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
        label: 'From',
        value: (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <Chip label={PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[oldPaymentOption]['practice']} size='small' />
          </Box>
        ),
      }, {
        label: 'To',
        value: (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <Chip label={PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[newPaymentOption]['practice']} size='small' />
          </Box>
        ),
      }]} />
      <Alert variant='filled' severity="info">
        Please note that your current bookings may be affected by your change in payment preference. Please review the list below.
      </Alert>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nurse</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Payment Schedule Changes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking) => (
            <TableRow key={booking.id}>
              <TableCell data-label='ID'>
                {booking.id}
              </TableCell>
              <TableCell data-label='Nurse'>
                {booking.nurse_name}
              </TableCell>
              <TableCell data-label='Date'>
                {booking.date_label(booking.practice_tz)}
              </TableCell>
              <TableCell data-label='Changes'>
                {getPaymentScheduleChange(booking)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default ImpactedBookings