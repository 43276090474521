import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { useZendesk } from 'contexts/ZendeskProvider';

export default function useApproveTimeSheetChange() {
  const { api } = useAPI()
  const { showAlert, closeAlert } = useSnackBarAlert()
  const { openZendesk } = useZendesk()
  const [approveTimeSheetChangeInProgress, setApproveTimeSheetChangeInProgress] = React.useState<boolean>(false)

  const approveTimeSheetChange = React.useCallback(async (tcId: number) => {
    try {
      setApproveTimeSheetChangeInProgress(true)
      closeAlert()
      await api.approveTimeSheetChange(tcId)
      showAlert('success', 'Your timesheet has been updated with the proposed change')
    } catch (e) {
      showAlert('error', 'An error has occurred when approving this timesheet change.')
      console.error('uncaught exception', e)
    } finally {
      setApproveTimeSheetChangeInProgress(false)
    }
  }, [api, closeAlert, showAlert])

  const rejectTimeSheetChange = React.useCallback(() => {
    openZendesk()
  }, [openZendesk])

  return { approveTimeSheetChangeInProgress, approveTimeSheetChange, rejectTimeSheetChange }
}