export default class NurseMapPoint {
    nurse_id!: number
    latitude!: number
    longitude!: number

    get latlng() {
        return {
            lat: this.latitude,
            lng: this.longitude
        }
    }
}
