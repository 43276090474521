import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import { JobSearchForm, JobEmploymentSearchForm, PracticeSearchForm, ChartSearchForm } from 'types/interfaces'
import React from 'react'
import PracticeGroup from 'logic/PracticeGroup'

interface IAdminPracticeGroupDetailContext {
  group: PracticeGroup
  fetchGroup: () => Promise<void>

  // job tab
  jobSearchForm: JobSearchForm
  jobSelection: GridRowId[]
  jobColumnVisibilityModel: GridColumnVisibilityModel
  jobSortModel: GridSortModel
  updateJobSearchForm: (name: string, value: any) => void
  setJobSelection: (value: GridRowId[]) => void
  setJobColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setJobSortModel: (value: GridSortModel) => void

  // matches tab
  matchesSearchForm: JobEmploymentSearchForm
  matchesSelection: GridRowId[]
  matchesColumnVisibilityModel: GridColumnVisibilityModel
  matchesSortModel: GridSortModel
  updateMatchesSearchForm: (name: string, value: any) => void
  setMatchesSelection: (value: GridRowId[]) => void
  setMatchesColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setMatchesSortModel: (value: GridSortModel) => void

  // practices tab
  practiceSearchForm: PracticeSearchForm
  practiceSelection: GridRowId[]
  practiceColumnVisibilityModel: GridColumnVisibilityModel
  practiceSortModel: GridSortModel
  updatePracticeSearchForm: (name: string, value: any) => void
  setPracticeSelection: (value: GridRowId[]) => void
  setPracticeColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setPracticeSortModel: (value: GridSortModel) => void

  // charts tab
  chartsSearchForm: ChartSearchForm
  updateChartsSearchForm: (name: string, value: any) => void

}

const AdminPracticeGroupDetailContext = React.createContext<IAdminPracticeGroupDetailContext>({} as IAdminPracticeGroupDetailContext)

export default AdminPracticeGroupDetailContext