import React from 'react'
import { Paper, Button, Alert } from '@mui/material'
import Header from 'components/Header'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import Nurse from 'logic/Nurse'
import update from 'immutability-helper';
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { CustomEmailForm } from 'types/interfaces'
import CustomEmailError from 'errors/CustomEmailError'

interface CustomEmailProps {
  nurse: Nurse
  subject?: string
  body?: string
}

const CustomEmail: React.FC<CustomEmailProps> = ({ nurse, subject = '', body = '' }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<CustomEmailForm>({
    recipient_type: 'nurse',
    recipient_id: nurse.nurse_id,
    subject, body
  });
  const [error, setError] = React.useState<CustomEmailError>();
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function save() {
    try {
      setLoading(true)
      const inst = await api.sendCustomEmail(form)
      showAlert('success', 'Email Sent')
    } catch (e) {
      if (e instanceof CustomEmailError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text='Send Email'></Header>
      <FormField
        name='subject'
        label='Subject'
        onChange={(e) => updateForm('subject', e.target.value)}
        value={form.subject}
        errors={error?.subject}
      />
      <FormField
        name='body'
        label='Message'
        multiline
        rows={4}
        onChange={(e) => updateForm('body', e.target.value)}
        value={form.body}
        errors={error?.body}
      />
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Send Email'}
      </Button>
    </Paper>
  )
}

export default CustomEmail