import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import NurseSignupContext from './context';
import Header from 'components/Header'
import { useNavigate } from 'react-router';
import { nurseJobsURL } from 'routes/urls'
import RightToWork from 'components/RightToWork';

const NurseSignupIDCheck: React.FC = () => {
  const { toNextStep, toPreviousStep, nurse, emitGTMEvent, notification$ } = React.useContext(NurseSignupContext)
  const navigate = useNavigate()
  
  const onSkip = React.useCallback(() => {
    if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
  }, [navigate, nurse])

  const onNext = React.useCallback(() => {
    emitGTMEvent('nurse-id-check')
    toNextStep()
  }, [emitGTMEvent, toNextStep])

  const canNext = React.useMemo(() => {
    if (!nurse) return false
    return nurse.rtw_status === 'approved' || nurse.rtw_status === 'pending-review'
  }, [nurse])

  if (!nurse) return null
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Right to Work'></Header>
      <RightToWork nurseId={nurse.nurse_id} notification$={notification$} />
      {canNext ? (
        <Button variant="contained" color="primary" onClick={onNext}>Next</Button>
      ) : null}
      <Button variant="outlined" color="primary" onClick={toPreviousStep}>Back</Button>
      <Button variant="outlined" color="primary" onClick={onSkip}>Skip</Button>
    </Box>
  )
}

export default NurseSignupIDCheck