import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import Job from 'logic/Job'
import React from 'react'
import { JobEmploymentSearchForm } from 'types/interfaces'

interface IAdminJobDetailContext {
  job: Job
  fetchJob: () => Promise<void>

  // matches tab
  matchSearchForm: JobEmploymentSearchForm
  matchSelection: GridRowId[]
  matchColumnVisibilityModel: GridColumnVisibilityModel
  matchSortModel: GridSortModel
  updateMatchSearchForm: (name: string, value: any) => void
  setMatchSelection: (value: GridRowId[]) => void
  setMatchColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setMatchSortModel: (value: GridSortModel) => void
}

const AdminJobDetailContext = React.createContext<IAdminJobDetailContext>({} as IAdminJobDetailContext)

export default AdminJobDetailContext