import React from 'react'
import AffiliateTransactionList from 'pages/AffiliateTransactionList'
import { AffiliateProvider } from 'contexts/AffiliateProvider'

const NurseAffiliatePage: React.FC = () => {
  return (
    <AffiliateProvider>
      <AffiliateTransactionList />
    </AffiliateProvider>
  )
}

export default NurseAffiliatePage