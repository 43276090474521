import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { Alert, Button, Typography } from '@mui/material'
import { usePractice } from 'contexts/PracticeProvider'
import { Link as RouterLink } from 'react-router-dom'
import DescriptionList from 'components/DescriptionList'
import { practiceBillingAccountSetupURL } from 'routes/urls'
import Loading from 'components/Loading'

const BillingAccountInfo: React.FC = () => {
  const { practice, billingAccount } = usePractice()

  if (!practice || billingAccount === undefined) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {!billingAccount ? (
        <Alert severity='info'>
          You have not specified your payment method yet. <br />
          <Button 
            component={RouterLink} 
            to={practiceBillingAccountSetupURL(practice.practice_id)}>
              Click here to setup payment method
          </Button>
        </Alert>
      ) : (
        <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
            label: 'Billing Name',
            value: billingAccount.name,
          }, {
            label: 'Billing Email',
            value: billingAccount.email,
          }, {
            label: 'Payment Option',
            value: billingAccount.paymentOptionLabel,
          }, {
            label: 'Payment Method',
            value: billingAccount.payment_method_label
          }]} />
          {!billingAccount.is_ready_to_process_payment ? (
            <Alert severity='error' variant='filled'>
              {billingAccount.not_ready_to_process_payment_reason}
              {billingAccount.payment_method_error ? (
                <Typography>Last Payment Error: {billingAccount.payment_method_error}</Typography>
              ) : null}
            </Alert>
          ) : null}
          <Button 
            component={RouterLink}
            variant='contained'
            to={practiceBillingAccountSetupURL(practice.practice_id)}>
              Click here to change payment option
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export default BillingAccountInfo