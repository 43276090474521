import { Box, Button, Typography } from '@mui/material'
import { AuthError, createUserWithEmailAndPassword } from "firebase/auth";
import FormField from 'components/FormField'
import AcceptInvitationFormError from 'errors/AcceptInvitationFormError'
import React from 'react'
import update from 'immutability-helper'
import { trim, isEmpty } from 'lodash'
import { AcceptInvitationForm } from 'types/interfaces'
import { SignUpForm } from 'types/interfaces'
import { useAPI } from 'contexts/APIProvider'
import { useFirebase } from 'contexts/FirebaseProvider';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser } from 'contexts/AuthUserProvider';
import { plainToClass } from 'class-transformer';
import { FirebaseError } from 'firebase/app';
import SignupError from 'errors/SignupError';
import Invitation from 'logic/Invitation';
import Loading from 'components/Loading';
import { practiceJobsURL } from 'routes/urls';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';

const PracticeInvitedSignup: React.FC = () => {
  const { api } = useAPI()
  const { auth } = useFirebase()
  const { showAlert } = useSnackBarAlert()
  const params = useParams()
  const navigate = useNavigate()
  const { refreshUser } = useAuthUser()
  const [invitation, setInvitation] = React.useState<Invitation>()
  const [invitationForm, setInvitationForm] = React.useState<AcceptInvitationForm>({ code: params.code })
  const [signupForm, setSignupForm] = React.useState<SignUpForm>({})
  const [invitationError, setInvitationError] = React.useState<AcceptInvitationFormError>()
  const [signupError, setSignupError] = React.useState<SignupError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchInvitation = React.useCallback(async () => {
    const code = params.code
    if (!code) return
    try {
      setInvitation(await api.getInvitation(code))
    } catch (e) {
      showAlert('error', 'Invalid Invitation Code')
    }
  }, [api, params.code, showAlert])

  const updateInvitationForm = React.useCallback((name: string, value: any) => {
    setInvitationForm(update(invitationForm, { [name]: { $set: value } }))
    if (invitationError) setInvitationError(update(invitationError, { [name]: { $set: [] } }))
  }, [invitationError, invitationForm])

  const updateSignupForm = React.useCallback((name: string, value: any) => {
    setSignupForm(update(signupForm, { [name]: { $set: value } }))
    if (signupError) setSignupError(update(signupError, { [name]: { $set: [] } }))
  }, [signupError, signupForm])

  const validateSignupForm = React.useCallback(() => {
    const data: any = {}
    if (!signupForm.email) {
      data.email = ['This field is required']
    }
    if (!signupForm.password1) {
      data.password = ['This field is required']
    }
    if (!signupForm.password2) {
      data.password2 = ['This field is required']
    }
    if (signupForm.password1 !== signupForm.password2) {
      data.password1 = ['Password Mismatch']
      data.password2 = ['Password Mismatch']
    }
    if (!isEmpty(data)) {
      throw plainToClass(SignupError, data)
    }
  }, [signupForm.email, signupForm.password1, signupForm.password2])

  const save = React.useCallback(async () => {
    const code = params.code
    if (!code) return
    if (!invitation) return
    
    try {
      setLoading(true)
      
      // validate signup form
      validateSignupForm()

      // check invitation code
      await api.acceptInvitation(invitationForm, true)

      // Create user on firebase
      await createUserWithEmailAndPassword(auth, signupForm.email || '', signupForm.password1 || '')

      // Accept invitation
      await api.acceptInvitation(invitationForm, false)

      // Refresh authToken (because backend API might have set claims on firebase auth)
      await refreshUser(true)

      // redirect to jobs page
      navigate(practiceJobsURL(invitation?.practice_id))
    } catch (e) {
      if (e instanceof SignupError) {
        setSignupError(e)
      } else if (e instanceof AcceptInvitationFormError) {
        setInvitationError(e)
      } else if (e instanceof FirebaseError) {
        const err = e as AuthError
        let errMessage = `${err.message} ${err.code}`
        setSignupError(plainToClass(SignupError, {
          '_schema': [errMessage]
        }))
      } else {
        console.error(e)
      }
    } finally {
      setLoading(false)
    }
  }, [api, auth, invitation, invitationForm, navigate, params.code, refreshUser, signupForm.email, signupForm.password1, validateSignupForm])

  React.useEffect(() => {
    fetchInvitation()
  }, [])

  if (!invitation) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography>You have been invited to join the {invitation.practice_name} on locumloop</Typography>
      <FormField
        name='first_name'
        label='First Name'
        onChange={(e) => updateInvitationForm('first_name', e.target.value)}
        value={invitationForm.first_name}
        errors={invitationError?.first_name}
      />
      <FormField
        name='last_name'
        label='Last Name'
        onChange={(e) => updateInvitationForm('last_name', e.target.value)}
        value={invitationForm.last_name}
        errors={invitationError?.last_name}
      />
      <FormField
        name='email'
        label='Email'
        onChange={(e) => updateSignupForm('email', trim(e.target.value))}
        value={signupForm.email}
        errors={signupError?.email}
      />
      <FormField
        name='password1'
        label='Password'
        type='password'
        onChange={(e) => updateSignupForm('password1', e.target.value)}
        value={signupForm.password1}
        errors={signupError?.password1}
      />
      <FormField
        name='password2'
        label='Retype Password'
        type='password'
        onChange={(e) => updateSignupForm('password2', e.target.value)}
        value={signupForm.password2}
        errors={signupError?.password2}
      />
      <Button variant='contained' color='primary' disabled={loading} onClick={save}>
        {loading ? 'Please Wait ...' : 'Sign Up'}
      </Button>
    </Box>
  )
}

export default PracticeInvitedSignup