import { GridRowParams } from '@mui/x-data-grid'
import AdminAffiliateTransactionTable from 'components/AdminAffiliateTransactionTable'
import JobEmployment from 'logic/JobEmployment'
import AdminAffiliateDetailContext from 'pages/AdminAffiliateDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminAffiliateTransactions: React.FC = () => {
  const {
    transactionsSearchForm,
    transactionsSelection,
    transactionsColumnVisibilityModel,
    transactionsSortModel,
    updateTransactionsSearchForm,
    setTransactionsSelection,
    setTransactionsColumnVisibilityModel,
    setTransactionsSortModel,
  } = React.useContext(AdminAffiliateDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  return (
    <AdminAffiliateTransactionTable
      onRowClick={onRowClick}
      form={transactionsSearchForm}
      updateForm={updateTransactionsSearchForm}
      selection={transactionsSelection}
      setSelection={setTransactionsSelection}
      columnVisibilityModel={transactionsColumnVisibilityModel}
      setColumnVisibilityModel={setTransactionsColumnVisibilityModel}
      sortModel={transactionsSortModel}
      setSortModel={setTransactionsSortModel}
    />
  )
}

export default AdminAffiliateTransactions