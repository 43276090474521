import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Button,
} from '@mui/material'
import { downloadFile } from 'logic/helpers'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import { NurseTransaction } from 'logic/NurseTransaction'
import React from 'react'

const NurseTransactionHistory: React.FC = () => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [records, setRecords] = React.useState<NurseTransaction[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchRecords = React.useCallback(async () => {
    if (!nurse || nurse.stripe_step === 'NOT_STARTED' || nurse.stripe_step === 'PROCESSING') return
    try {
      setLoading(true)
      setRecords(await api.listNurseTransactions(nurse.nurse_id))
    } catch (e) {
      showAlert('error', 'Error fetching transaction history')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, nurse, showAlert])

  const downloadTransactions = React.useCallback(async () => {
    if (!nurse) return
    const result = await api.exportNurseTransactions(nurse.nurse_id)
    downloadFile(result)
  }, [api, nurse])

  React.useEffect(() => {
    fetchRecords()
  }, [fetchRecords])

  if (loading || !nurse) {
    return <Loading />
  } else if (isEmpty(records)) {
    return <Alert severity='info'>No Records Found</Alert>
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Button variant='outlined' sx={{ width: '200px' }} onClick={downloadTransactions}>Download CSV</Button>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record, i) => (
            <TableRow key={i}>
              <TableCell data-label='Date'>{record.date_label(nurse.tz)}</TableCell>
              <TableCell data-label='Status'>{record.status}</TableCell>
              <TableCell data-label='Description'>{record.description}</TableCell>
              <TableCell data-label='Amount'>{record.amount_label}</TableCell>
              <TableCell data-label='Balance'>{record.balance_label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default NurseTransactionHistory