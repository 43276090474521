import React from 'react'
import { Box, MenuItem, Alert, Button } from '@mui/material'
import { useJobEmployment } from 'contexts/JobEmploymentProvider'
import useNurseDocuments from 'hooks/useNurseDocuments'
import FormField from 'components/FormField'
import Loading from 'components/Loading'
import { get } from 'lodash'
import { FILE_LABEL_MAPPING } from 'types/constants'
import { DocumentFileType } from 'types/types'
import { useZendesk } from 'contexts/ZendeskProvider'

const ReviewDocuments: React.FC = () => {
  const { je } = useJobEmployment()
  const { openZendesk } = useZendesk()
  const {selection, setSelection, selectedFiles, loading} = useNurseDocuments(je?.nurse_id)
  
  if (loading || !je) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormField select value={selection} onChange={(e) => setSelection(e.target.value as DocumentFileType)}>
        <MenuItem value='gdc'>
          {get(FILE_LABEL_MAPPING, 'gdc')}
        </MenuItem>
        <MenuItem value='indemnity_insurance'>
          {get(FILE_LABEL_MAPPING, 'indemnity_insurance')}
        </MenuItem>
        <MenuItem value='immunizations'>
          {get(FILE_LABEL_MAPPING, 'immunizations')}
        </MenuItem>
        <MenuItem value='dbs'>
          {get(FILE_LABEL_MAPPING, 'dbs')}
        </MenuItem>
        <MenuItem value='infection_control'>
          {get(FILE_LABEL_MAPPING, 'infection_control')}
        </MenuItem>
        <MenuItem value='cpr'>
          {get(FILE_LABEL_MAPPING, 'cpr')}
        </MenuItem>
      </FormField>
      <Alert variant='filled' severity='info'>
        Please review the documents as soon as possible. <br/>
        Declining a nurse close to the start time of a job may mean we don't have enough time to find a replacement. <br/>
        Late cancellation fees may apply. <br/>
      </Alert>
      <Button variant='contained' color='primary' onClick={() => openZendesk()}>
        To decline the nurse, click here to speak with us
      </Button>
      {selectedFiles.map((file) => (
        file.content_type === 'application/pdf' ? (
          <Box 
            key={file.id} 
            component='embed' 
            src={file.url} 
            sx={{ height: '75vh', width: '100%' }} 
          />
        ) : (
          <Box 
            key={file.id} 
            component='img' 
            src={file.url} 
            onContextMenu={(e: any) => e.preventDefault()} 
            sx={{ width: '100%' }}
          />
        )
      ))}
    </Box>
  )
}

export default ReviewDocuments