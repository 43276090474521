import AdminBlacklistedNurseTable from 'components/AdminBlacklistedNurseTable'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'

const AdminPracticeBlacklisted: React.FC = () => {
  const {
    blacklistsSearchForm,
    blacklistsSelection,
    blacklistsColumnVisibilityModel,
    blacklistsSortModel,
    updateBlacklistsSearchForm,
    setBlacklistsSelection,
    setBlacklistsColumnVisibilityModel,
    setBlacklistsSortModel,
  } = React.useContext(AdminPracticeDetailContext)
  
  return (
    <AdminBlacklistedNurseTable
      form={blacklistsSearchForm}
      updateForm={updateBlacklistsSearchForm}
      selection={blacklistsSelection}
      setSelection={setBlacklistsSelection}
      columnVisibilityModel={blacklistsColumnVisibilityModel}
      setColumnVisibilityModel={setBlacklistsColumnVisibilityModel}
      sortModel={blacklistsSortModel}
      setSortModel={setBlacklistsSortModel}
    />
  )
}

export default AdminPracticeBlacklisted