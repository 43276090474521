import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material'

export interface DescriptionListItem {
  label: string
  value: React.ReactNode
  sx?: SxProps
}

interface DescriptionListProps {
  data: DescriptionListItem[]
  labelWidth?: string
  valueWidth?: string
  columnGap?: number
  rowGap?: number
  sx?: SxProps
}

const DescriptionList: React.FC<DescriptionListProps> = ({ data, labelWidth = '30%', valueWidth = '70%', columnGap = 1, rowGap = 0, sx = {} }) => {
  return (
    <Box sx={{
      display: ['flex', 'grid'],
      flexDirection: 'column',
      gridTemplateColumns: `${labelWidth} ${valueWidth}`,
      gridTemplateRows: 'auto',
      columnGap: [0, columnGap],
      rowGap: [0, rowGap],
      ...sx
    }}>
      {data.map(datum => (
        <React.Fragment key={datum.label}>
          <Box sx={{ justifySelf: 'start', alignSelf: 'flex-start' }}>
            <Typography sx={{ fontWeight: 'bold' }}>{datum.label}</Typography>
          </Box>
          <Box sx={{ alignSelf: 'flex-start' }}>
            {React.isValidElement(datum.value) ? datum.value : (
              <Typography sx={datum.sx}>{datum.value}</Typography>
            )}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default DescriptionList