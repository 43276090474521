import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import UserFormError from 'errors/UserFormError'
import React from 'react'
import { useNavigate } from 'react-router'
import { UserForm } from 'types/interfaces'
import AdminUserForm from './form'
import update from 'immutability-helper'
import { useSearchParams } from 'react-router-dom'

const AdminUserCreate: React.FC = () => {
    const [searchParams] = useSearchParams()
    const { api } = useAPI()
    const { showAlert} = useSnackBarAlert()
    const navigate = useNavigate()
    const [form, setForm] = React.useState<UserForm>({
        practice_group_ids: searchParams.has('practice_group_id') ? [Number(searchParams.get('practice_group_id'))] : [],
        practice_ids: searchParams.has('practice_id') ? [Number(searchParams.get('practice_id'))] : [],
    })
    const [error, setError] = React.useState<UserFormError>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const updateForm = React.useCallback((name: string, value: any) => {
        setForm(update(form, { [name]: { $set: value } }))
        if (error) setError(update(error, { [name]: { $set: [] } }))
      }, [error, form])

    const submit = React.useCallback(async () => {
        try {
            setLoading(true)
            await api.createUser(form)
            showAlert('success', 'User created successfully!')
            navigate(-1)
        } catch (error) {
            if (error instanceof UserFormError) {
                setError(error)
            }
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [form, api, showAlert, navigate])
    
    return (
        <AdminUserForm title='Create User' 
            form={form} 
            updateForm={updateForm} 
            submit={submit} 
            loading={loading} 
            error={error} 
        />
    )
}

export default AdminUserCreate