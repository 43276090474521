import JobEmployment from 'logic/JobEmployment'
import React from 'react'
import { isEmpty, uniqBy } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { Button, Chip, Typography } from '@mui/material'
import { JobEmploymentSearchForm } from 'types/interfaces'
import { chain } from 'lodash'
import MultiSelect from 'components/MultiSelect'
import FormField from 'components/FormField'
import ExportOnlyToolBar from 'components/ExportToolbar'
import { 
  DataGridPro, 
  GridColDef, 
  GridRenderCellParams, 
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { 
  Alert,
  Box,
} from '@mui/material'
import { DateTime } from 'luxon';
import update from 'immutability-helper'
import AutoCompleteSelectField from './AutoCompleteSelectField';
import Practice from 'logic/Practice';

interface JobEmploymentTableProps {
  nurse_id?: number
  practice_id?: number
  practice_group_id?: number
  canSearchByPractice?: boolean
}

const JobEmploymentTable: React.FC<JobEmploymentTableProps> = ({ nurse_id, practice_id, practice_group_id, canSearchByPractice = false }) => {
  const { api } = useAPI()
  const [form, setForm] = React.useState<JobEmploymentSearchForm>({ 
    nurse_id, 
    practice_id, 
    practice_group_id,
    from_date: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
    to_date: DateTime.now().plus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd'),
  })
  const [employments, setEmployments] = React.useState<JobEmployment[]>([])
  const [practices, setPractices] = React.useState<Practice[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchJobEmployments = React.useCallback(async () => {
    setLoading(true)
    const results = chain(await api.listJobEmployments(form)).sortBy('countdown').value()
    setEmployments(results)
    setLoading(false)
  }, [api, form])

  const fetchPractices = React.useCallback(async (searchTerm: string = '') => {
    const practices = []
    if (form.practice_id) {
        const results = await api.listPractices({ practice_ids: [form.practice_id], is_enabled: true, practice_group_id })
        practices.push(...results)
    }
    if (searchTerm) {
        const results = await api.listPractices({ name: searchTerm, is_enabled: true })
        practices.push(...results)
    }
    setPractices(uniqBy(practices, 'practice_id'))
  }, [api, form.practice_id, practice_group_id])

  const practiceSelectOptions = React.useMemo(() => {
    return practices.map((practice) => ({
      value: practice.practice_id,
      label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
    }))
  }, [practices])

  React.useEffect(() => {
    fetchJobEmployments()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColDef<JobEmployment>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'Match ID',
    field: 'id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Status',
    field: 'fulfillment_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Chip 
          size='small' 
          label={employment.fulfillment_status} 
          color={employment.fulfillment_status_color}
        />
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Payment',
    field: 'payment_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Chip 
          size='small' 
          label={employment.payment_status || 'pending'} 
          color={employment.payment_status_color} 
        />
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Nurse',
    field: 'nurse_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Start At',
    field: 'start_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.start_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.start_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'End At',
    field: 'end_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.end_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.end_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'Lunch Break',
    field: 'lunch_break_label',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Billable Hours',
    field: 'billable_duration',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.billable_duration_label
    },
  }, {
    minWidth: 50,
    headerName: 'Cancel By',
    field: 'cancel_by',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Hourly Rate',
    field: 'hourly_rate_label',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Nurse Fees',
    field: 'nurse_fees_label',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Locumloop Fees',
    field: 'locumloop_fees_label',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Total Fees',
    field: 'total_fees_label',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Payment Error',
    field: 'payment_error_label',
    type: 'string',
  }], [])

  const fulfillment_statuses = [
    'scheduled', 
    'in_progress', 
    'completed', 
    'cancelled'
  ]
  const payment_statuses = [
    'practice_paid',
    'practice_payment_error',
    'practice_payment_pending',
    'practice_payment_requested',
    'practice_invoice_finalized',
    'practice_invoice_paid',
    'practice_invoice_void',
    'practice_invoice_payment_failed',
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <AutoCompleteSelectField
          label='Filter by practice'
          sx={{ width: 250, display: canSearchByPractice ? 'block' : 'none' }}
          options={practiceSelectOptions}
          fetchOptions={fetchPractices}
          value={form.practice_id ?? ''}
          onChange={(value) => updateForm('practice_id', value)}
        />
        <MultiSelect
          name='fulfillment_statuses'
          label='Status'
          width={150}
          options={fulfillment_statuses}
          onChange={(e) => updateForm('fulfillment_statuses', e.target.value)}
          value={form.fulfillment_statuses ?? []}
        />
        <MultiSelect
          name='payment_statuses'
          label='Payment'
          width={150}
          options={payment_statuses}
          onChange={(e) => updateForm('payment_statuses', e.target.value)}
          value={form.payment_statuses ?? []}
        />
        <FormField
          type='date'
          name='from_date'
          label='Start Date'
          InputLabelProps={{ shrink: true }}
          onChange={(e) => updateForm('from_date', e.target.value)}
          value={form.from_date ?? ''}/>
        <FormField
          type='date'
          name='to_date'
          label='End Date'
          InputLabelProps={{ shrink: true }}
          onChange={(e) => updateForm('to_date', e.target.value)}
          value={form.to_date ?? ''}/>
        <Button
          variant="contained"
          onClick={fetchJobEmployments}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      {isEmpty(employments) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{employments.length} records returned.</Alert>
          <DataGridPro 
            rows={employments}
            columns={columns}
            getRowId={(employment) => employment.id}
            loading={loading}
            hideFooter
            slots={{ toolbar: ExportOnlyToolBar }}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default JobEmploymentTable