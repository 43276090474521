import React from 'react'
import Nurse from 'logic/Nurse';
import { Observable } from 'rxjs';
import { Notification } from 'logic/Notification';

interface INurseSignupContext {
  nurse?: Nurse | null
  step?: number
  toNextStep: () => void
  toPreviousStep: () => void
  emitGTMEvent: (event: string) => void
  notification$: Observable<Notification>
}

const NurseSignupContext = React.createContext<INurseSignupContext>({} as INurseSignupContext)

export default NurseSignupContext