import { Box, Chip } from '@mui/material'
import NurseDocumentFile from 'logic/NurseDocumentFile'
import React from 'react'   

type PageSelectorProps = {
    files: NurseDocumentFile[]
    scrollToFile: (fileId: number) => void
    deleteFile: (fileId: number) => void
    deleting: boolean
}

const PageSelector: React.FC<PageSelectorProps> = ({ files, scrollToFile, deleteFile, deleting }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
            {files.map((file) => (
                <Chip
                    key={file.id}
                    label={`Page ${file.page_num}`}
                    onClick={() => scrollToFile(file.id)}
                    onDelete={() => deleteFile(file.id)}
                    disabled={deleting}
                />
            ))}
        </Box>
    )
}

export default PageSelector