import React from 'react'
import { Severity } from 'types/types'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar';

interface ISnackBarAlertContext {
  showAlert: (severity: Severity, content: React.ReactNode) => void
  closeAlert: () => void
}

const SnackBarAlertContext = React.createContext<ISnackBarAlertContext>({} as ISnackBarAlertContext)

const SnackBarAlertProvider: React.FC = ({ children }) => {
  const [severity, setSeverity] = React.useState<Severity>('info')
  const [content, setContent] = React.useState<React.ReactNode | null>(null)

  const showAlert = React.useCallback((severity: Severity, content: React.ReactNode) => {
    setSeverity(severity)
    setContent(content)
  }, [])

  const closeAlert = React.useCallback(() => {
    setContent(null)
  }, [])

  const contextValue = { showAlert, closeAlert }

  return (
    <SnackBarAlertContext.Provider value={contextValue}>
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={content !== null}
          autoHideDuration={6000}
          onClose={closeAlert}>
          <Alert onClose={closeAlert} severity={severity} variant='filled'>
            {content}
          </Alert>
        </Snackbar>
        {children}
      </React.Fragment>
    </SnackBarAlertContext.Provider>
  )
}

function useSnackBarAlert() {
  const context = React.useContext(SnackBarAlertContext)
  if (context === undefined) {
    throw new Error('useSnackBarAlert must be used within a SnackBarAlertProvider')
  }
  return context
}

export { SnackBarAlertProvider, useSnackBarAlert }