import React from 'react'
import { Navigate, useLocation, useMatch, useParams } from 'react-router'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { affiliateStripeSetupURL, homeURL, loginURL } from './urls'
import Affiliate from 'logic/Affiliate'
import { useAPI } from 'contexts/APIProvider'

const IsAffiliate: React.FC = ({ children }) => {
  const { authUser, logout } = useAuthUser()
  const { api } = useAPI()
  const location = useLocation()
  const params = useParams()
  const [affiliate, setAffiliate] = React.useState<Affiliate>()
  const match = useMatch({ path: '/affiliate/:affiliate_id/:rest', caseSensitive: false, end: false })

  const affiliateId = React.useMemo(() => Number(params.affiliateId), [params.affiliateId]) 

  const fetchAffiliate = React.useCallback(async () => {
    if (authUser?.affiliateId === affiliateId) {
      setAffiliate(await api.getAffiliate(affiliateId))
    }
  }, [affiliateId, api, authUser?.affiliateId])

  React.useEffect(() => {
    fetchAffiliate()
  }, [fetchAffiliate]) 

  if (authUser === undefined) {
    return null
  } else if (authUser === null) {
    return <Navigate replace to={loginURL(location.pathname)} />
  } else if (authUser.isStaff) {
    return <React.Fragment>{children}</React.Fragment>
  } else if (authUser.affiliateId === affiliateId) {
    if (!affiliate) {
      return null
    } else if (!affiliate.details_submitted && match?.params.rest !== 'stripe-setup') {
      return <Navigate replace to={affiliateStripeSetupURL(affiliateId)} />
    } else {
      return <React.Fragment>{children}</React.Fragment>
    }
  } else {
    const redirectURL = homeURL(authUser)
    if (redirectURL) {
      return <Navigate replace to={redirectURL} />
    } else {
      logout()
      return <Navigate replace to={loginURL()} />
    }
  }
}

export default IsAffiliate