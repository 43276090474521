import { Autocomplete, SxProps, TextField } from '@mui/material'
import { debounce } from 'lodash'
import React from 'react'

interface AutoCompleteSelectOption {
    value: any
    label: string
}

interface AutoCompleteSelectFieldProps {
    label: string
    options: AutoCompleteSelectOption[]
    fetchOptions: (searchText: string) => Promise<void>
    value?: any
    onChange: (value: string) => void
    sx?: SxProps
}

const AutoCompleteSelectField: React.FC<AutoCompleteSelectFieldProps> = ({ label, options, fetchOptions, value, onChange, sx }) => {
    const [searchTerm, setSearchTerm] = React.useState<string>('')

    const debouncedFetchOptions = React.useMemo(() => {
        return debounce(fetchOptions, 500)
    }, [fetchOptions])

    const selectedOption = React.useMemo(() => {
        return options?.find(option => option.value === value)
    }, [options, value])

    React.useEffect(() => {
        debouncedFetchOptions(searchTerm)
    }, [debouncedFetchOptions, searchTerm])
    
    return (
        <Autocomplete
            freeSolo
            // autoComplete
            // includeInputInList
            // filterSelectedOptions
            filterOptions={(x) => x}
            sx={sx}
            options={options ?? []}
            renderInput={(params) => <TextField {...params} label={label} />}
            value={selectedOption ?? ''}
            onChange={(event: any, selectedOption: any) => {
                onChange(selectedOption?.value ?? '')
            }}
            inputValue={searchTerm ?? ''}
            onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
            }}
        />
    )
}

export default AutoCompleteSelectField