import React from 'react'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
  nurseNotificationSettings,
  nurseNotificationURL,
  nurseProfileURL,
} from 'routes/urls'
import { useNurse } from 'contexts/NurseProvider';
import { List, ListItem, ListItemButton, ListItemText, Paper, Popper } from '@mui/material';

const NurseTopNav: React.FC = () => {
  const { nurse, unreadCount$ } = useNurse()
  const { logout } = useAuthUser()
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = React.useState<number>(0)
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
  const logo = process.env.PUBLIC_URL + '/logo-rectangle.png'

  React.useEffect(() => {
    const sub = unreadCount$?.subscribe(setUnreadCount)
    return () => sub?.unsubscribe()
  }, [])

  function goToRoot() {
    navigate('/')
  }

  function toggleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchor(anchor ? null : event.currentTarget)
  }

  function goToPage(page: 'notification' | 'profile' | 'notification-settings') {
    if (!nurse) return
    setAnchor(null)
    const nurseId = nurse.nurse_id
    if (page === 'notification') {
      navigate(nurseNotificationURL(nurseId))
    } else if (page === 'profile') {
      navigate(nurseProfileURL(nurseId))
    } else if (page === 'notification-settings') {
      navigate(nurseNotificationSettings(nurseId))
    }
  }

  const open = Boolean(anchor)

  return (
    <AppBar position="static" color='inherit'>
      <Toolbar variant='regular' disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box component='img' src={logo} alt='logo' sx={{ height: ['1.5rem', '2.5rem'], cursor: 'pointer' }} onClick={goToRoot}></Box>
        </Box>
        <IconButton onClick={() => goToPage('notification')}>
          {unreadCount > 0 ? (
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          ) : (
            <NotificationsIcon />
          )}
        </IconButton>
        <IconButton onClick={toggleMenu}>
          <AccountCircleIcon />
        </IconButton>
        <IconButton onClick={logout}>
          <PowerSettingsNewIcon />
        </IconButton>
        <Popper open={open} anchorEl={anchor} placement='bottom-end'>
          <Paper elevation={4}>
            <List>
              <ListItem disablePadding onClick={_ => goToPage('profile')}>
                <ListItemButton>
                  <ListItemText primary='Profile' />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={_ => goToPage('notification-settings')}>
                <ListItemButton>
                  <ListItemText primary='Notification Settings' />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Popper>
      </Toolbar>
    </AppBar>
  )
}

export default NurseTopNav