import { useFirebase } from 'contexts/FirebaseProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { sendPasswordResetEmail } from "firebase/auth";
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Header from 'components/Header'
import FormField from 'components/FormField'
import React from 'react'

const ResetPassword: React.FC = () => {
  const { auth } = useFirebase()
  const { showAlert } = useSnackBarAlert()
  const [email, setEmail] = React.useState<string>('')
  const [sending, setSending] = React.useState<boolean>(false)

  async function save() {
    try {
      setSending(true)
      await sendPasswordResetEmail(auth, email)
      showAlert('success', 'An email has been sent to you. Please follow the instructions on the email to reset your password.')
    } catch (e: any) {
      const message = `Error resetting password - ${e.message}`
      showAlert('error', message)
    } finally {
      setSending(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Reset Password' />
      <Alert severity='info'>Provide your email address and we will send you a password reset email</Alert>
      <FormField
        name='email'
        label='Email'
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <Button variant="contained" color="primary" onClick={save} disabled={sending}>
        {sending ? 'Please Wait ...' : 'Send Reset Password Email'}
      </Button>
    </Box>
  )
}

export default ResetPassword