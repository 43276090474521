import { Box, Button, Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { Link as RouterLink } from 'react-router-dom'
import Invitation from 'logic/Invitation'
import PracticeMember from 'logic/PracticeMember'
import React from 'react'
import SendInvitation from 'pages/PracticeMemberList/SendInvitation'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import APIError from 'errors/APIError'
import { useAuthUser } from 'contexts/AuthUserProvider'

const PracticeMemberList: React.FC = () => {
  const { practice } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { authUser } = useAuthUser()
  const [members, setMembers] = React.useState<PracticeMember[]>([])
  const [invitations, setInvitations] = React.useState<Invitation[]>([])
  const [open, setOpen] = React.useState<boolean>(false)

  const fetchMembers = React.useCallback(async () => {
    if (!practice) return
    setMembers(await api.listPracticeMembers({ practice_id: practice.practice_id }))
  }, [api, practice])

  const fetchInvitations = React.useCallback(async () => {
    if (!practice) return
    setInvitations(await api.listInvitations({ practice_id: practice.practice_id, accepted: false }))
  }, [api, practice])

  const deleteInvitation = React.useCallback(async (invitation: Invitation) => {
    try {
      await api.deleteInvitation(invitation.code)
      await fetchInvitations()
      showAlert('success', 'Invitation Deleted')
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    }
  }, [api, fetchInvitations, showAlert])

  const deleteMember = React.useCallback(async (member: PracticeMember) => {
    try {
      await api.deletePracticeMember(member.id)
      await fetchMembers()
      showAlert('success', 'Member Deleted')
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    }
  }, [api, fetchMembers, showAlert])

  React.useEffect(() => {
    fetchInvitations()
  }, [fetchInvitations])

  React.useEffect(() => {
    fetchMembers()
  }, [fetchMembers])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Members'>
        <Button variant='contained' sx={{ marginBottom: 1 }} onClick={() => setOpen(true)}>Invite New Member</Button>
      </Header>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Position</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((invitation) => (
              <TableRow key={`invitation-${invitation.id}`}>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {invitation.to}
                    <Chip label='Invitation Sent' color='primary' />
                  </Box>
                </TableCell>
                <TableCell>{invitation.position}</TableCell>
                <TableCell>
                  <IconButton onClick={() => deleteInvitation(invitation)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {members.map((member) => (
              <TableRow>
                <TableCell>{member.user_email} ({member.user_name})</TableCell>
                <TableCell>{member.position}</TableCell>
                <TableCell height={40}>
                  {member.user_id !== authUser?.id ? (
                    <React.Fragment>
                      <IconButton size='small' component={RouterLink} to=''>
                        <EditIcon />
                      </IconButton>
                      <IconButton size='small' onClick={() => deleteMember(member)}>
                        <DeleteIcon />
                      </IconButton>
                    </React.Fragment>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SendInvitation open={open} setOpen={setOpen} reloadInvitations={fetchInvitations} />
    </Box>
  )
}

export default PracticeMemberList