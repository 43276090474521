import { Box } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'
import JobEmployment from 'logic/JobEmployment'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'

const AdminEmploymentList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    employmentSearchForm,
    employmentSelection,
    employmentColumnVisibilityModel,
    employmentSortModel,
    updateEmploymentSearchForm,
    setEmploymentSelection,
    setEmploymentColumnVisibilityModel,
    setEmploymentSortModel,
  } = useAdmin()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Matches List' />
      <AdminJobEmploymentTable
        onRowClick={onRowClick}
        form={employmentSearchForm}
        updateForm={updateEmploymentSearchForm}
        selection={employmentSelection}
        setSelection={setEmploymentSelection}
        columnVisibilityModel={employmentColumnVisibilityModel}
        setColumnVisibilityModel={setEmploymentColumnVisibilityModel}
        sortModel={employmentSortModel}
        setSortModel={setEmploymentSortModel}
      />
    </Box>
  )
}

export default AdminEmploymentList