import { Type, Transform, plainToClass } from 'class-transformer'
import PaymentMethod from 'logic/PaymentMethod'
import { DateTime } from 'luxon'
import { PaymentMethodType, PaymentOption } from 'types/types'

export default class BillingAccount {
    id!: number

    @Type(() => DateTime)
    @Transform(({ value }) => DateTime.fromISO(value))
    created_at!: DateTime

    name!: string
    email!: string
    customer_id!: string
    payment_method_id!: number
    practice_group_id!: number | null
    pay_by_invoice!: boolean
    invoice_cycle!: string
    invoice_autopay!: boolean
    payment_option!: PaymentOption

    // annotated payment method fields
    payment_method_stripe_id!: string
    payment_method_type!: PaymentMethodType
    payment_method_brand!: string
    payment_method_exp_month!: number
    payment_method_exp_year!: number
    payment_method_last4!: string
    payment_method_sort_code!: string
    payment_method_error!: string

    // annotated is_ready_to_process_payment
    is_ready_to_process_payment!: boolean
    not_ready_to_process_payment_reason!: string

    get paymentOptionLabel() {
        switch (this.payment_option) {
            case 'pay_eod_with_card':
                return 'Autopay with card at end of day when job completes'
            case 'pay_eod_with_bacs_debit':
                return 'Autopay with direct debit at end of day when job completes'
            case 'invoice_weekly_autopay_with_card':
                return 'Invoiced weekly (autopay with card)'
            case 'invoice_weekly_autopay_with_bacs_debit':
                return 'Invoiced weekly (autopay with direct debit)'
            case 'invoice_weekly_pay_manually':
                return 'Invoiced weekly (manual payment)'
            case 'invoice_biweekly_autopay_with_card':
                return 'Invoiced bi-weekly (autopay with card)'
            case 'invoice_biweekly_autopay_with_bacs_debit':
                return 'Invoiced bi-weekly (autopay with direct debit)'
            case 'invoice_biweekly_pay_manually':
                return 'Invoiced bi-weekly (manual payment)'
            case 'invoice_monthly_autopay_with_card':
                return 'Invoiced monthly (autopay with card)'
            case 'invoice_monthly_autopay_with_bacs_debit':
                return 'Invoiced monthly (autopay with direct debit)'
            case 'invoice_monthly_pay_manually':
                return 'Invoiced monthly (manual payment)'
            default:
                return 'N/A'
        }
    }

    get payment_method_label() {
        if (this.pay_by_invoice && !this.invoice_autopay) {
            return 'manual'
        } else {
            const pm = plainToClass(PaymentMethod, {
                id: this.payment_method_id,
                payment_method_id: this.payment_method_stripe_id,
                type: this.payment_method_type,
                brand: this.payment_method_brand,
                exp_month: this.payment_method_exp_month,
                exp_year: this.payment_method_exp_year,
                last4: this.payment_method_last4,
                sort_code: this.payment_method_sort_code,
                error: this.payment_method_error,
            })
            return pm.label
        }
    }
}