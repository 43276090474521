import { Alert, Box, Button, Link } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { isEmpty } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router'
import { practiceSignupAddJobURL } from 'routes/urls'
import PracticeSignupContext from '../context'
import Job from 'logic/Job';
import JobCard from 'pages/PracticeSignup/JobPosting/JobCard'

const PracticeSignupJobList: React.FC = () => {
	const { toNextStep, toPreviousStep, jobs, reloadJobs } = React.useContext(PracticeSignupContext)
  const { api } = useAPI()
  const navigate = useNavigate()

  const toAddJobPage = () => navigate(practiceSignupAddJobURL())

  const cancelJob = async (job: Job) => {
    await api.cancelJob(job.job_id, { reason: 'Cancel job during practice signup.' })
    await reloadJobs()
  }
  
  React.useEffect(() => {
    if (jobs === undefined) return
    if (isEmpty(jobs)) {
      toAddJobPage()
    }
  }, [jobs])

  if (jobs === undefined) return <Loading />
  
  return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2, overflowY: 'auto' }}>
      <Header variant='h3' text='Job Posts'></Header>
      {jobs.map(job => <JobCard key={job.job_id} job={job} cancelJob={cancelJob} />)}
      <Button variant="contained" color="primary" onClick={toNextStep}>Select Payment</Button>
      <Button variant="outlined" color="primary" onClick={toAddJobPage}>Add Another Job</Button>
      <Button variant="outlined" color="primary" onClick={toPreviousStep}>Back</Button>
    </Box>
	)
}

export default PracticeSignupJobList