import { Button } from '@mui/material'
import AdminUserTable from 'components/AdminUserTable'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { adminUserCreateURL } from 'routes/urls'

const AdminPracticeMembers: React.FC = () => {
  const {
    practice,
    userSearchForm, 
    userSelection, 
    userColumnVisibilityModel, 
    userSortModel, 
    updateUserSearchForm, 
    setUserSelection, 
    setUserColumnVisibilityModel, 
    setUserSortModel 
  } = React.useContext(AdminPracticeDetailContext)

  return (
    <AdminUserTable
      form={userSearchForm}
      updateForm={updateUserSearchForm}
      selection={userSelection}
      setSelection={setUserSelection}
      columnVisibilityModel={userColumnVisibilityModel}
      setColumnVisibilityModel={setUserColumnVisibilityModel}
      sortModel={userSortModel}
      setSortModel={setUserSortModel}
      allowSelectPractice={false}
      allowSelectPracticeGroup={false}
      actions={
        <Button variant='contained' component={RouterLink} to={adminUserCreateURL(practice.practice_id)}>
          Create User
        </Button>
      }
    />
  )
}

export default AdminPracticeMembers

