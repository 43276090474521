import {
  Box,
  Alert,
  Link,
  MenuItem,
  Button,
} from '@mui/material'
import { 
  DataGridPro, 
  GridColDef,
  GridRenderCellParams, 
  GridRowId,
  GridValueGetterParams, 
} from '@mui/x-data-grid-pro';
import {Link as RouterLink} from 'react-router-dom'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import Invoice from 'logic/Invoice'
import React from 'react'
import { InvoiceSearchForm } from 'types/interfaces'
import update from 'immutability-helper'
import FormField from 'components/FormField'
import PracticeGroup from 'logic/PracticeGroup'
import { adminBillingAccountDetailURL, adminInvoiceLateFeeListURL } from 'routes/urls'
import ExportOnlyToolBar from 'components/ExportToolbar';

interface InvoicesTableProps {
  billing_account_id?: number
  isAdmin?: boolean
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({ billing_account_id, isAdmin = false }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [selection, setSelection] = React.useState<GridRowId[]>([])
  const [form, setForm] = React.useState<InvoiceSearchForm>({ billing_account_id })
  const [records, setRecords] = React.useState<Invoice[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchPracticeGroups = React.useCallback(async () => {
    setPracticeGroups(await api.listPracticeGroup())
  }, [api])

  const fetchRecords = React.useCallback(async () => {
    try {
      setLoading(true)
      setRecords(await api.listInvoices(form))
    } catch (e) {
      showAlert('error', 'Error fetching invoices')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, form, showAlert])

  const getInvoiceActions = React.useCallback((record: Invoice) => {
    if (record.status === 'open') {
      return (
        <Box sx={{display: 'flex', gap: 1}}>
          <Link component='a' href={record.invoice_pdf} target="_blank">View</Link>
          <Link component='a' href={record.hosted_invoice_url} target="_blank">
            {record.charge_error ? 'Retry Payment' : 'Pay'}
          </Link>
        </Box>
      )
    } else {
      return (
        <Link component='a' href={record.invoice_pdf} target="_blank">View</Link>
      )
    }
  }, [])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  const columns: GridColDef<Invoice>[] = React.useMemo(() => {
    const adminColumns: GridColDef<Invoice>[] = [{
      minWidth: 150,
      headerName: 'Name',
      field: 'billing_name',
      type: 'string',
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        const record = params.row
        return (
          <Link component={RouterLink} to={adminBillingAccountDetailURL(record.billing_account_id)}>
            { record.billing_name }
          </Link>
        )
      }
    }, {
      minWidth: 150,
      headerName: 'Email',
      field: 'billing_email',
      type: 'string',
    }, {
      minWidth: 100,
      headerName: 'Practice Group',
      field: 'practice_group_name',
      type: 'string',
    }, {
      minWidth: 150,
      headerName: 'Late Fees',
      field: 'late_fees_label',
      type: 'string',
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        const record = params.row
        if (record.has_late_fees) {
          return (
            <Link component={RouterLink} to={adminInvoiceLateFeeListURL(record.id)}>
              { record.late_fees_label }
            </Link>
          )
        }
        return 'N/A'
      }
    }]
    const userColumns: GridColDef<Invoice>[] = [{
      minWidth: 100,
      headerName: 'Invoice',
      field: 'invoice_number',
      type: 'string',
    }, {
      minWidth: 300,
      headerName: 'Description',
      field: 'description',
      type: 'string',
    }, {
      minWidth: 50,
      headerName: 'Sent At',
      field: 'created_at',
      type: 'dateTime',
      valueGetter: (params: GridValueGetterParams<Invoice>) => {
        return params.row.created_at?.toJSDate()
      },
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        const record = params.row
        return record.created_at_label()
      }
    }, {
      minWidth: 50,
      headerName: 'Amount Due',
      field: 'amount_label',
    }, {
      minWidth: 50,
      headerName: 'Status',
      field: 'status_label',
      type: 'string',
    }, {
      minWidth: 100,
      headerName: 'Actions',
      field: 'actions',
      type: 'string',
      renderCell: (params: GridRenderCellParams<Invoice>) => {
        const record = params.row
        return getInvoiceActions(record)
      }
    }]
    return isAdmin ? adminColumns.concat(userColumns) : userColumns
  }, [isAdmin, getInvoiceActions])

  React.useEffect(() => {
    fetchRecords()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing_account_id])

  React.useEffect(() => {
    fetchPracticeGroups()
  }, [fetchPracticeGroups])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        {isAdmin ? (
          <FormField
            select
            name='practice_group'
            label='Practice Group'
            sx={{ width: 150 }}
            onChange={(e) => updateForm('practice_group_id', e.target.value)}
            value={form.practice_group_id ?? ''}>
            <MenuItem value=''>Not Specified</MenuItem>
            <MenuItem value='-999'>Standalone</MenuItem>
            {practiceGroups.map((group) => (
              <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
          </FormField>
        ) : null}
        <FormField
          select
          name='status'
          label='Status'
          sx={{ width: 150 }}
          onChange={(e) => updateForm('status', e.target.value)}
          value={form.status ?? ''}>
          <MenuItem value=''>Not Specified</MenuItem>
          <MenuItem value='draft'>draft</MenuItem>
          <MenuItem value='open'>open</MenuItem>
          <MenuItem value='paid'>paid</MenuItem>
          <MenuItem value='uncollectible'>uncollectible</MenuItem>
          <MenuItem value='void'>void</MenuItem>
        </FormField>
        <Button
          variant="contained"
          onClick={fetchRecords}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      {loading ? (
        <Loading />
      ) : isEmpty(records) ? (
        <Alert severity='info'>No Records Found</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{records.length} records returned. {selection.length} selected</Alert>
          <DataGridPro 
            rows={records}
            columns={columns}
            getRowId={(record) => record.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            loading={loading}
            hideFooter
            slots={{ toolbar: ExportOnlyToolBar }}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default InvoicesTable