import { Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { useJobEmployment } from 'contexts/JobEmploymentProvider'
import { useNurse } from 'contexts/NurseProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import useApproveTimeSheetChange from 'hooks/useApproveTimeSheetChange'
import TimeSheetChange from 'logic/TimeSheetChange'
import React from 'react'
import { useNavigate } from 'react-router'
import DescriptionList from 'components/DescriptionList'
import { isEmpty } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { nurseEditTimeSheetChangeURL, nurseProposeTimeSheetChangeURL, nurseViewJobEmploymentURL, practiceEditTimeSheetChangeURL, practiceProposeTimeSheetChangeURL, practiceViewJobURL } from 'routes/urls'
import Loading from 'components/Loading'

const TimeSheetView: React.FC = () => {
  const { api } = useAPI()
  const { practice } = usePractice()
  const { nurse } = useNurse()
  const { approveTimeSheetChangeInProgress, approveTimeSheetChange, rejectTimeSheetChange } = useApproveTimeSheetChange()
  const { je } = useJobEmployment()
  const navigate = useNavigate()
  const { showAlert } = useSnackBarAlert()
  const [TCs, setTCs] = React.useState<TimeSheetChange[]>([])
  const [deleteTimeSheetInProgress, setDeleteTimeSheetInProgress] = React.useState<boolean>(false)

  const usertype = React.useMemo(() => {
    if (practice) return 'practice'
    if (nurse) return 'nurse'
    return null
  }, [nurse, practice])

  const fetchTCs = React.useCallback(async () => {
    if (je === undefined) return
    setTCs(await api.listTimeSheetChanges({ je_id: je.id }))
  }, [je])

  React.useEffect(() => {
    fetchTCs()
  }, [fetchTCs])

  const deleteTimeSheetChange = async (tcId: number) => {
    try {
      setDeleteTimeSheetInProgress(true)
      await api.deleteTimeSheetChange(tcId)
      showAlert('success', 'Timesheet change request is cancelled')
    } catch (e) {
      showAlert('error', 'An error has occurred when deleting your timesheet change request')
    } finally {
      setDeleteTimeSheetInProgress(false)
    }
  }

  const editURL = (tcId: number) => {
    if (!je || !usertype) return ''
    if (usertype === 'practice') {
      return practiceEditTimeSheetChangeURL(je.practice_id, je.id, tcId)
    } else {
      return nurseEditTimeSheetChangeURL(je.nurse_id, je.id, tcId)
    }
  }

  const proposeURL = () => {
    if (!je || !usertype) return ''
    if (usertype === 'practice') {
      return practiceProposeTimeSheetChangeURL(je.practice_id, je.id)
    } else {
      return nurseProposeTimeSheetChangeURL(je.nurse_id, je.id)
    }
  }

  const backURL = () => {
    if (!je || !usertype) return ''
    if (usertype === 'practice') {
      return practiceViewJobURL(je.practice_id, je.job_id)
    } else {
      return nurseViewJobEmploymentURL(je.nurse_id, je.id)
    }
  }

  if (!usertype || !je) return null
  const tz = usertype === 'practice' ? je.practice_tz : je.nurse_tz
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header variant='h3' text={`Timesheet for ${je.nurse_name} (${je.date_label(tz)})`}>
        <IconButton onClick={_ => navigate(backURL())}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
          label: 'Time',
          value: je.time_label(tz),
        }, {
          label: 'Lunch Break',
          value: je.lunch_break_label,
        }, {
          label: 'Billable Hours',
          value: je.billable_duration_label,
        }]} />
      </Paper>
      {je.can_propose_timesheet_change ? (
        <Button 
          variant='contained' 
          color='primary' 
          component={RouterLink} 
          to={proposeURL()}
        >
          Propose Timesheet Change
        </Button>
      ) : null}
      {approveTimeSheetChangeInProgress || deleteTimeSheetInProgress ? (
        <Loading />
      ) : !isEmpty(TCs) ? (
        <React.Fragment>
          <Header variant='h3' text='Timesheet Changes'></Header>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Proposed By</TableCell>
                  <TableCell>Changes</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TCs.map((tc) => (
                  <TableRow key={tc.id}>
                    <TableCell data-label='Date'>
                      {tc.created_at_label(tz)}
                    </TableCell>
                    <TableCell data-label='Proposed By'>
                      {tc.created_by}
                    </TableCell>
                    <TableCell data-label='Changes'>
                      <ul>
                        {tc.differences.map(difference => (
                          <li key={difference}>{difference}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell data-label='Status'>
                      {tc.status_label}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {tc.can_approve ? (
                          <React.Fragment>
                            <Button 
                              size='small' 
                              variant='contained' 
                              color='success' 
                              onClick={() => approveTimeSheetChange(tc.id)}
                            >
                              Approve
                            </Button>
                            <Button 
                              size='small' 
                              variant='contained' 
                              color='error' 
                              onClick={() => rejectTimeSheetChange()}
                            >
                              Reject
                            </Button>
                          </React.Fragment>
                        ) : null}
                        {tc.can_update ? (
                          <Button
                            size='small'
                            variant='outlined'
                            color='primary'
                            component={RouterLink}
                            to={editURL(tc.id)}
                          >
                            Edit
                          </Button>
                        ) : null}
                        {tc.can_delete ? (
                          <Button
                            size='small'
                            variant='outlined'
                            color='error'
                            onClick={() => deleteTimeSheetChange(tc.id)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
        ) : null}
    </Box>
  )
}

export default TimeSheetView