import { Box, Button } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { PracticeReportItem, PracticeReportSearchForm } from 'types/interfaces'
import { 
  DataGridPro, 
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { PropertyPath, get, partialRight } from 'lodash'
import ReportSelectBox from 'pages/AdminReports/ReportSelectBox'
import { DateTime, Duration } from 'luxon'
import FormField from 'components/FormField'
import update from 'immutability-helper'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'

const renderCell = (params: GridRenderCellParams<PracticeReportItem>, total_field: PropertyPath) => {
  const item = params.row
  const total = get(item, total_field, 0)
  const pct = total > 0 ? (params.value / total * 100).toFixed(2) : 0
  return `${params.value} (${pct}%)`
}

const formatDuration = (params: GridValueFormatterParams<string>) => {
  if (params.value == null) {
    return 'N/A';
  }
  return (
    Duration
    .fromMillis(1000 * Number(params.value))
    .shiftTo('days', 'hours', 'minutes')
    .toHuman({ unitDisplay: 'short' })
  )
}

const PracticeReport: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<PracticeReportSearchForm>({
    start_at: DateTime.now().set({ day: 1 }).toISODate(),
    end_at: DateTime.now().toISODate(),
  })
  const [items, setItems] = React.useState<PracticeReportItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchReport = React.useCallback(async () => {
    try {
      setLoading(true)
      setItems(await api.getPracticeReport(form))
    } catch (e) {
      showAlert('error', 'Error loading report')
    } finally {
      setLoading(false)
    }
  }, [api, form, showAlert])

  React.useEffect(() => {
    fetchReport()
  }, [])

  const columns: GridColDef<PracticeReportItem>[] = React.useMemo(() => [{
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Practice Group',
    field: 'practice_group',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'City',
    field: 'city',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'County',
    field: 'county',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Metro Area',
    field: 'metro_area',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Publish to Taken (avg)',
    field: 'avg_publish_taken_interval',
    valueFormatter: formatDuration
  }, {
    minWidth: 200,
    headerName: 'Taken to Start (avg)',
    field: 'avg_taken_start_interval',
    valueFormatter: formatDuration
  }, {
    minWidth: 100,
    headerName: 'Jobs (Total)',
    field: 'jobs_total',
    type: 'number',
  }, {
    minWidth: 100,
    headerName: 'Jobs (Total Not Cancelled By Practice)',
    field: 'jobs_not_cancelled_by_practice',
    type: 'number',
  }, {
    minWidth: 100,
    headerName: 'Jobs (Filled)',
    field: 'jobs_filled',
    type: 'number',
    renderCell: partialRight(renderCell, 'jobs_total'),
  }, {
    minWidth: 150,
    headerName: 'Jobs (Not Filled)',
    field: 'jobs_not_filled',
    type: 'number',
    renderCell: partialRight(renderCell, 'jobs_total'),
  }, {
    minWidth: 150,
    headerName: 'Jobs (Partially Filled)',
    field: 'jobs_partially_filled',
    type: 'number',
    renderCell: partialRight(renderCell, 'jobs_total'),
  }, {
    minWidth: 200,
    headerName: 'Jobs (Cancelled By Practice)',
    field: 'jobs_cancelled_by_practice',
    type: 'number',
    renderCell: partialRight(renderCell, 'jobs_total'),
  }, {
    minWidth: 150,
    headerName: 'Nurses (Total)',
    field: 'nurses_total',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Nurses (Basic Approved)',
    field: 'nurses_basic_approved',
    type: 'number',
    renderCell: partialRight(renderCell, 'nurses_total'),
  }, {
    minWidth: 200,
    headerName: 'Nurses (Extended Approved)',
    field: 'nurses_extended_approved',
    type: 'number',
    renderCell: partialRight(renderCell, 'nurses_total'),
  }], [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practices'>
        <ReportSelectBox />
      </Header>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          type='date'
          name='start_at'
          label='From Job Date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => updateForm('start_at', e.target.value)}
          value={form.start_at ?? ''}/>
        <FormField
          type='date'
          name='end_at'
          label='To Job Date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => updateForm('end_at', e.target.value)}
          value={form.end_at ?? ''}/>
        <FormField
          name='city'
          label='City'
          onChange={(e) => updateForm('city', e.target.value)}
          value={form.city ?? ''}/>
        <FormField
          name='metro_area'
          label='Metro Area'
          onChange={(e) => updateForm('metro_area', e.target.value)}
          value={form.metro_area ?? ''}/>
        <FormField
          name='practice_group'
          label='Practice Group'
          sx={{ width: 400 }}
          placeholder='Enter "standalone" for standalone practices.'
          onChange={(e) => updateForm('practice_group', e.target.value)}
          value={form.practice_group ?? ''}/>
        <Button
          variant="contained"
          onClick={fetchReport}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      <DataGridPro 
        rows={items}
        columns={columns}
        getRowId={(item) => item.practice_id}
      />
    </Box>
  )
}

export default PracticeReport