import { Expose } from 'class-transformer';

export default class BillingAccountFormError {
  name: string[] = []
  email: string[] = []
  payment_option: string[] = []
  practice_id: string[] = []
  practice_group_id: string[] = []
  payment_method_id: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
}