import Loading from 'components/Loading';
import React from 'react'

interface SetupBacsDebitProps {
    url: string
}

const SetupBacsDebit: React.FC<SetupBacsDebitProps> = ({ url }) => {
    window.location.replace(url);
    return <Loading />
}

export default SetupBacsDebit