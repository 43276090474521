import { Box, Paper, Typography, MenuItem, Alert, Link } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { assign, isEmpty } from 'lodash'
import JobEmployment from 'logic/JobEmployment'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import FormField from 'components/FormField'
import update from 'immutability-helper'
import { AffiliateTransactionSearchForm } from 'types/interfaces'
import { useAffiliate } from 'contexts/AffiliateProvider'
import { affiliateUniqueSignupLink } from 'routes/urls'
import { useFirebase } from 'contexts/FirebaseProvider'
import { ref, getDownloadURL } from "firebase/storage";

const AffiliateTransactionList: React.FC = () => {
  const { api } = useAPI()
  const { storage } = useFirebase()
  const { affiliate } = useAffiliate()
  const [transactions, setTransactions] = React.useState<JobEmployment[]>([])
  const [form, setForm] = React.useState<AffiliateTransactionSearchForm>({})
  const [marketingMaterialURL, setMarketingMaterialURL] = React.useState<string>('')

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchTransactions = React.useCallback(async () => {
    if (!affiliate) return
    setTransactions(await api.listAffiliateTransactions(assign({ affiliate_user_id: affiliate.user_id }, form)))
  }, [affiliate, api, form])

  const fetchMarketingMaterialURL = React.useCallback(async () => {
    if (!affiliate) return
    const url = await getDownloadURL(ref(storage, `affiliates/${affiliate.code}.jpg`))
    const blobURL = URL.createObjectURL(await fetch(url).then(r => r.blob()))
    setMarketingMaterialURL(blobURL)
  }, [affiliate, storage])

  React.useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  React.useEffect(() => {
    fetchMarketingMaterialURL()
  }, [fetchMarketingMaterialURL])

  if (!affiliate) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Paper sx={{ padding: 2, display: 'flex', flexDirection: ['column', 'row'], gap: [1, 5] }}>
        <Box>
          <Typography>Your affiliate code</Typography>
          <Typography fontWeight='bold'>{ affiliate.code }</Typography>
        </Box>
        {/* <Box>
          <Typography>Your unique signup link</Typography>
          <Typography fontWeight='bold'>{ affiliateUniqueSignupLink(affiliate.code) }</Typography>
        </Box> */}
      </Paper>
      {affiliate.nurse_id ? (
        <React.Fragment>
          <Typography>
            Simple steps to earning more with locumloop's affiliate scheme:
          </Typography>
          <Typography>
            1. Tell practices about locumloop and give them your unique affiliate code. <br/>
            2. If a practice signs up using your code you will earn 10% of the daily fee we charge to that practice. <br/>
            3. You will receive payouts for all jobs that the practice posts for up to 2 years after their first job post! <br/>
          </Typography>
          {marketingMaterialURL ? (
            <Typography>
              Helpful Hint: <Link 
                component='a' 
                href={marketingMaterialURL}
                target='_blank'
                download='locumloop marketing material'>
                Download our marketing material
              </Link> and send it to practices with your affiliate code to increase the chance of them signing up. <br />
            </Typography>
          ) : null}
        </React.Fragment>
      ) : null}
      <Header variant='h1' text='Your Earnings'>
        <FormField
          sx={{ width: 200, marginBottom: 1 }}
          name='affiliate_payment_status'
          label='Payment Status'
          select
          onChange={(e) => updateForm('affiliate_payment_status', e.target.value)}
          value={form.affiliate_payment_status ?? ''}>
            <MenuItem value='paid'>
              Paid
            </MenuItem>
            <MenuItem value='not_paid'>
              Not Paid
            </MenuItem>
        </FormField>
      </Header>
      {!isEmpty(transactions) ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Practice</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((je) => (
              <TableRow key={je.id}>
                <TableCell data-label='Date'>{je.date_label('Europe/London')}</TableCell>
                <TableCell data-label='Practice'>{je.practice_name}</TableCell>
                <TableCell data-label='Amount'>{je.affiliate_fees_label}</TableCell>
                <TableCell data-label='Payment Status'>{je.affiliate_payment_status_label}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Alert severity='info' variant='filled'>
          No records found.
        </Alert>
      )}
    </Box>
  )
}

export default AffiliateTransactionList