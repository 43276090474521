import { Paper, TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Box, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import DescriptionList from 'components/DescriptionList'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import React from 'react'
import { isEmpty } from 'lodash'
import TimeSheetChange from 'logic/TimeSheetChange'
import { useAPI } from 'contexts/APIProvider'
import { practiceEditTimeSheetChangeURL, nurseEditTimeSheetChangeURL } from 'routes/urls'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'

const AdminEmploymentHours: React.FC = () => {
  const { employment, fetchEmployment } = React.useContext(AdminEmploymentDetailContext)
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [TCs, setTCs] = React.useState<TimeSheetChange[]>([])

  const fetchTCs = React.useCallback(async () => {
    if (!employment) return
    setTCs(await api.listTimeSheetChanges({ je_id: employment.id }))
  }, [api, employment])

  const editURL = (tc: TimeSheetChange) => {
    if (tc.created_by === 'practice') {
      return practiceEditTimeSheetChangeURL(employment.practice_id, employment.id, tc.id)
    } else {
      return nurseEditTimeSheetChangeURL(employment.nurse_id, employment.id, tc.id)
    }
  }

  const deleteTC = React.useCallback(async (tc: TimeSheetChange) => {
    try {
      await api.deleteTimeSheetChange(tc.id)
      await fetchTCs()
    } catch (e) {
      showAlert('error', 'An error has occurred when deleting timesheet change request')
      console.error(e)
    }
  }, [api, fetchTCs, showAlert])
  
  const approveTC = React.useCallback(async (tc: TimeSheetChange) => {
    try {
      await api.approveTimeSheetChange(tc.id)
      await fetchTCs()
      await fetchEmployment()
    } catch (e) {
      showAlert('error', 'An error has occurred when approving timesheet change request')
      console.error(e)
    }
  }, [api, fetchEmployment, fetchTCs, showAlert])

  const rejectTC = React.useCallback(async (tc: TimeSheetChange) => {
    try {
      await api.disapproveTimeSheetChange(tc.id)
      await fetchTCs()
    } catch (e) {
      showAlert('error', 'An error has occurred when rejecting timesheet change request')
      console.error(e)
    }
  }, [api, fetchTCs, showAlert])

  React.useEffect(() => {
    fetchTCs()
  }, [fetchTCs])
  
  return (
    <Paper sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='60%' valueWidth='40%' rowGap={1} data={[{
        label: 'Posted At',
        value: employment.posted_at_label(employment.practice_tz),
      }, {
        label: 'Taken At',
        value: employment.taken_at_label(employment.practice_tz),
      }, {
        label: 'Job Date',
        value: employment.date_label(employment.practice_tz),
      }, {
        label: 'Job Time',
        value: employment.time_label(employment.practice_tz),
      }, {
        label: 'Lunch Break',
        value: employment.lunch_break_label,
      }, {
        label: 'Lunch Break (current)',
        value: employment.lunch_break_label,
      }]} />
      {!isEmpty(TCs) ? (
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Proposed By</TableCell>
                <TableCell>Changes</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TCs.map((tc) => (
                <TableRow key={tc.id}>
                  <TableCell data-label='Date'>
                    {tc.created_at_label(employment.practice_tz)}
                  </TableCell>
                  <TableCell data-label='Proposed By'>
                    {tc.created_by}
                  </TableCell>
                  <TableCell data-label='Changes'>
                    <ul>
                      {tc.differences.map(difference => (
                        <li key={difference}>{difference}</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell data-label='Comments' sx={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}>
                    {tc.comments}
                  </TableCell>
                  <TableCell data-label='Status'>
                    {tc.status_label}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      {tc.can_approve ? (
                        <Button 
                          size='small' 
                          variant='contained' 
                          color='success' 
                          onClick={() => approveTC(tc)}
                        >
                          Approve
                        </Button>
                      ) : null}
                      {tc.can_reject ? (
                        <Button 
                          size='small' 
                          variant='contained' 
                          color='error' 
                          onClick={() => rejectTC(tc)}
                        >
                          Reject
                        </Button>
                      ) : null}
                      {tc.can_update ? (
                        <Button
                          size='small'
                          variant='outlined'
                          color='primary'
                          component={RouterLink}
                          to={editURL(tc)}
                        >
                          Edit
                        </Button>
                      ) : null}
                      {tc.can_delete ? (
                        <Button
                          size='small'
                          variant='outlined'
                          color='error'
                          onClick={() => deleteTC(tc)}
                        >
                          Delete
                        </Button>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Paper>
  )
}

export default AdminEmploymentHours