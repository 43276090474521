import React from 'react'
import Button from '@mui/material/Button'
import update from 'immutability-helper';
import FormField from 'components/FormField'
import Header from 'components/Header'
import { usePractice } from 'contexts/PracticeProvider'
import { useAPI } from 'contexts/APIProvider'
import { PracticeProfileForm } from 'types/interfaces'
import PracticeProfileError from 'errors/PracticeProfileError'
import ErrorPaper from 'components/ErrorAlert'
import { practiceJobsURL } from 'routes/urls'
import { useNavigate } from 'react-router';
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import { isEmpty } from 'lodash';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';

const PracticeEditDefaultJobOptions: React.FC = () => {
  const [form, setForm] = React.useState<PracticeProfileForm>({});
  const [error, setError] = React.useState<PracticeProfileError>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const { practice, reloadPractice } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  function prepopulateForm() {
    if (practice) {
      setForm((form) => update(form, {
        'start_time': { $set: practice.start_time },
        'end_time': { $set: practice.end_time },
        'lunch_break': { $set: practice.lunch_break.as('minute') },
        'description': { $set: practice.description },
      }))
    }
  }

  async function save() {
    if (practice) {
      try {
        setLoading(true)
        await api.editPractice(form, practice?.practice_id)
        reloadPractice()
        showAlert('success', 'Updated default job options')
        navigate(practiceJobsURL(practice.practice_id))
      } catch (e: any) {
        if (e instanceof PracticeProfileError) {
          setError(e)
        } else {
          console.error('unhandled exception', e)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  function goBack() {
    if (practice) navigate(practiceJobsURL(practice.practice_id))
  }

  React.useEffect(() => {
    prepopulateForm()
  }, [practice])

  if (isEmpty(form)) {
    return null
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text='Default Job Options'>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <FormField
        name='start_time'
        label='Start Time'
        type='time'
        onChange={(e) => updateForm('start_time', e.target.value)}
        value={form.start_time}
        errors={error?.start_time}
      />
      <FormField
        name='end_time'
        label='End Time'
        type='time'
        onChange={(e) => updateForm('end_time', e.target.value)}
        value={form.end_time}
        errors={error?.end_time}
      />
      <FormField
        name='lunch_break'
        label='Lunch Break (minutes)'
        type='number'
        onChange={(e) => updateForm('lunch_break', e.target.value)}
        value={form.lunch_break}
        errors={error?.lunch_break}
      />
      <FormField
        name='description'
        label='Description'
        multiline
        rows={2}
        onChange={(e) => updateForm('description', e.target.value)}
        value={form.description}
        errors={error?.description}
      />
      <ErrorPaper errors={error?.schema}></ErrorPaper>
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Confirm Changes'}
      </Button>
      <Button
        variant="outlined" color="primary" disabled={loading}
        onClick={goBack}>
        Cancel
      </Button>
    </Paper>
  )
}

export default PracticeEditDefaultJobOptions