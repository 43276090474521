import { Expose } from 'class-transformer'

export default class SavingsReport {
  hours_billed !: number
  total_spent !: number
  reference_spent !: number
  reference_diff !: number

  @Expose()
  get reference_diff_label() {
    const diffLabel = Math.abs(this.reference_diff).toFixed(2)
    if (this.reference_diff >= 0) {
      return `overspent £${diffLabel}`
    } else {
      return `saved £${diffLabel}`
    }
  }
}