import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material'
import InvitationFormError from 'errors/InvitationFormError'
import React from 'react'
import { InvitationForm } from 'types/interfaces'
import update from 'immutability-helper'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { assign, trim } from 'lodash'
import FormField from 'components/FormField'
import { practicePositions } from 'types/constants'

interface SendInvitationProps {
  open: boolean
  setOpen: (value: boolean) => void
  reloadInvitations: () => Promise<void>
}

const SendInvitation: React.FC<SendInvitationProps> = ({ open, setOpen, reloadInvitations }) => {
  const { practice } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<InvitationForm>({})
  const [error, setError] = React.useState<InvitationFormError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const save = React.useCallback(async () => {
    if (!practice) return
    try {
      setLoading(true)
      await api.createInvitation(assign({}, form, { practice_id: practice.practice_id }))
      await reloadInvitations()
      setOpen(false)
      showAlert('success', 'Invitation Sent')
    } catch (e) {
      if (e instanceof InvitationFormError) {
        setError(e)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, form, practice, reloadInvitations, setOpen, showAlert])
  
  return (
    <Dialog open={open} onClose={_ => setOpen(false)}>
      <DialogTitle>Invite New Member</DialogTitle>
      <DialogContent sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 1,
        width: 500,
      }}>
        <FormField
          select
          name='position'
          label='Position'
          onChange={(e) => updateForm('position', e.target.value)}
          value={form.position}
          errors={error?.position}>
          {practicePositions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormField>
        <FormField
          name='to'
          label='Email'
          onChange={(e) => updateForm('to', trim(e.target.value))}
          value={form.to}
          errors={error?.to}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          disabled={loading}
          onClick={save}
        >
          { loading ? 'Please Wait ...' : 'Send Invite' }
        </Button>
        <Button
          variant='outlined'
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendInvitation