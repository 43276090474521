import { Expose } from 'class-transformer'
import Lock from '@mui/icons-material/Lock'
import LockOpen from '@mui/icons-material/LockOpen'

export default class Achievement {
  min_hours!: number
  max_hours!: number
  rate!: number
  next_rate!: number
  is_unlocked!: boolean
  is_current!: boolean
  hours_worked!: number

  @Expose()
  get percentage_achieved() {
    return Math.round(this.hours_achieved / (this.max_hours - this.min_hours) * 100)
  }

  @Expose()
  get hours_achieved() {
    if (this.hours_worked > this.max_hours) {
      return this.max_hours - this.min_hours
    } else if (this.hours_worked > this.min_hours) {
      return this.hours_worked - this.min_hours
    } else {
      return 0
    }
  }

  @Expose()
  get hours_remaining() {
    return this.hours_worked < this.max_hours ? this.max_hours - this.hours_worked : 0;
  }

  @Expose()
  get borderColor() {
    return this.is_unlocked ? 'primary.main' : 'secondary.main'
  }

  @Expose()
  get lockIcon() {
    return this.is_unlocked ? <LockOpen color='primary' /> : <Lock color='secondary' />
  }

  @Expose()
  get rate_label() {
    return `£${this.rate.toFixed(2)}`
  }

  @Expose()
  get next_rate_label() {
    return `£${this.next_rate.toFixed(2)}`
  }
}