import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { useNavigate } from 'react-router'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom'
import { nurseSignupURL, nurseSuspensionPageURL, nurseViewJobEmploymentURL, nurseWaitlistCPDURL } from 'routes/urls'
import TakeJobError from 'errors/TakeJobError';
import ErrorList from 'components/ErrorList';
import Job from 'logic/Job';
import { DOCUMENTATIONS_LABEL_MAPPING } from 'types/constants';
import { useDialog } from 'contexts/DialogProvider';
import { Box } from '@mui/material';
import DocumentUpload from 'components/DocumentUpload';
import { get } from 'lodash';
import RightToWork from 'components/RightToWork';

export default function useTakeJob() {
  const { nurse, notification$ } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { showDialog, closeDialog } = useDialog()
  const navigate = useNavigate()
  const [takeJobInProgress, setTakeJobInProgress] = React.useState<boolean>(false)

  async function performTakeJob(job: Job, documentShared: boolean) {
    if (!nurse) return
    
    // inside the dialogs, the content won't refresh based on takeJobInProgress
    // so just closing the dialog, the parent page will show a loading sign.
    closeDialog()

    if (nurse.nurse_role === 'hygienist' && !job.hygienist_required) {
      if (!window.confirm('This is a dental nurse job. It pays less than a hygienist job. Are you sure you want to take it?')) {
        return
      }
    }

    try {
      setTakeJobInProgress(true)
      const jeId = await api.addJobEmployment({ 
        job_id: job.job_id, 
        nurse_id: nurse.nurse_id,
        document_shared: documentShared,
      })
      const alertContent = <Typography>You have taken the job!</Typography>
      showAlert('success', alertContent)
      navigate(nurseViewJobEmploymentURL(nurse.nurse_id, jeId))
    } catch (e) {
      if (e instanceof TakeJobError) {
        const alertContent = <ErrorList errors={e.errorlist}></ErrorList>
        showAlert('error', alertContent)
      } else {
        console.error('uncaught exception', e)
        showAlert('error', e as string)
      }
    } finally {
      setTakeJobInProgress(false)
    }
  }

  const goToCPDWaitlist = (nurseId: number) => {
    navigate(nurseWaitlistCPDURL(nurseId))
    closeDialog()
  }

  function takeJob(job: Job) {
    if (!nurse) return

    if (nurse.is_suspended) {
      navigate(nurseSuspensionPageURL(nurse.nurse_id))
      return
    }

    if (!nurse.approved) {
      const url = nurseSignupURL()
      showDialog(
        '',
        <Typography>
          Your account is not fully setup yet.
        </Typography>,
        <Button
          variant='contained'
          color='primary'
          component={RouterLink}
          to={url}>
          Continue Signup
        </Button>
      )
      return
    }

    if (job.extended_compliance_required && !nurse.extended_compliance_approved) {
      showDialog(
        'Extended Compliance Required',
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>This job requires the following documents</Typography>
          <DocumentUpload dense={true} nurseId={nurse.nurse_id} fileType='infection_control' />
          <DocumentUpload dense={true} nurseId={nurse.nurse_id} fileType='cpr' />
          <RightToWork nurseId={nurse.nurse_id} notification$={notification$} />
          <Typography>
            We offer free CPR and Infection Control CPD. <br />
            <Link onClick={() => goToCPDWaitlist(nurse.nurse_id)}>Click here for more information</Link>
          </Typography>
        </Box>
      )
      return
    }

    if (job.scotland_compliance_required && !nurse.scotland_compliance_approved) {
      showDialog(
        'Scotland Compliance Required',
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>This job requires the following documents</Typography>
          <DocumentUpload dense={true} nurseId={nurse.nurse_id} fileType='dbs' />
          <DocumentUpload dense={true} nurseId={nurse.nurse_id} fileType='immunizations' />
        </Box>
      )
      return
    }

    if (job.hygienist_required && !nurse.hygienist_approved) {
      showDialog(
        'Hygienist Required',
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>You need to be a registered hygienist/therapist to take this job.</Typography>
          <DocumentUpload dense={true} nurseId={nurse.nurse_id} fileType='resume' />
        </Box>
      )
      return
    }

    if (job.document_sharing_required) {
      showDialog(
        'The practice would like to check your documents for compliance',
        <Typography>
          We will share with the practice the following documents on your profile.<br/>
          <ul>
            <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'gdc')}</li>
            <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'indemnity_insurance')}</li>
            <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'hepatitis_b_vaccination')}</li>
            <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'dbs')}</li>
            {job.extended_compliance_required ? (
              <React.Fragment>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'infection_control')}</li>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'cpr')}</li>
              </React.Fragment>
            ) : null}
            {job.scotland_compliance_required ? (
              <React.Fragment>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'hepatitis_c')}</li>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'tb')}</li>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'hiv')}</li>
                <li>{get(DOCUMENTATIONS_LABEL_MAPPING, 'pvg')}</li>
              </React.Fragment>
            ) : null}
          </ul>
          There is a £20 - £40 fee for cancelling a job within 5 days of the start time. <br />
          This helps us to ensure practices are satisfied and we can provide you with more jobs. <br />
          Cancellation may also result in suspension or permanent removal of your account.
        </Typography>,
        <Button 
          variant='contained' 
          color='primary'
          disabled={takeJobInProgress}
          onClick={_ => performTakeJob(job, true)}>
          { takeJobInProgress ? 'Please Wait ...' : "Agree" }
        </Button>
      )
    } else {
      showDialog(
        `
        There is a £20 - £40 fee for cancelling a job within 5 days of the start time.
        This helps us to ensure practices are satisfied and we can provide you with more jobs.
        Cancellation may also result in suspension or permanent removal of your account.
        Are you sure you want to take this job?
        `,
        null,
        <Button 
          variant='contained' 
          color='primary'
          disabled={takeJobInProgress}
          onClick={_ => performTakeJob(job, false)}>
          { takeJobInProgress ? 'Please Wait ...' : "Yes" }
        </Button>
      )
    }

    return
  }
  return { takeJobInProgress, takeJob }
}