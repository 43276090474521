import { Box, Button, Paper, Typography, Link } from '@mui/material'
import Loading from 'components/Loading'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { practiceJobsURL } from 'routes/urls'
import PracticeSignupContext from './context'
import { useZendesk } from 'contexts/ZendeskProvider'

const PracticeSignupSummary: React.FC = () => {
  const { practice } = React.useContext(PracticeSignupContext)
  const { openZendesk } = useZendesk()

  if (!practice) return <Loading />

  return (
    <Box sx={{ 
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2
    }}>
      <Box component='img' src={process.env.PUBLIC_URL + '/thumbsup.png'} alt="thumbsup" sx={{
        maxWidth: [100]
      }}></Box>
      <Typography>
        You've successfully booked your first job(s) with locumloop! We're excited to be working with you! <br />
        If you need a hand please <Link onClick={() => openZendesk()} sx={{ cursor: 'pointer' }}>click here to chat to us</Link> or feel free to call us on 07745 522 577. <br />
        Click the button below to access your account.
      </Typography>
      <Button
        variant='contained'
        component={RouterLink}
        to={practiceJobsURL(practice.practice_id)}
      >
        Continue to account
      </Button>
    </Box>
  )
}

export default PracticeSignupSummary