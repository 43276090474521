import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  SxProps
} from '@mui/material'
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

export interface CheckboxSelectOption {
  value: string | number
  label: string
  disabled?: boolean
}

interface CheckboxMultiSelectFieldProps {
  values?: (string | number)[]
  options: CheckboxSelectOption[]
  onChange: (values: (string | number)[]) => void
  errors?: string[]
  sx?: SxProps
}

const CheckboxMultiSelectField: React.FC<CheckboxMultiSelectFieldProps> = ({ values, options, onChange, errors, sx }) => {
  const [selectedOptions, setSelectedOptions] = React.useState<CheckboxSelectOption[]>([])

  const updateSelectedOption = React.useCallback((option: CheckboxSelectOption, checked: boolean) => {
    setSelectedOptions(prev => checked ? [...prev, option] : prev.filter(o => o.value !== option.value))
  }, [])

  const isSelected = React.useCallback((option: CheckboxSelectOption) => {
    const selectedValues = selectedOptions.map(o => o.value)
    return selectedValues.includes(option.value)
  }, [selectedOptions])

  React.useEffect(() => {
    onChange(selectedOptions.map(o => o.value))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  React.useEffect(() => {
    const hasOptions = options.length > 0
    const hasValues = values && values.length > 0
    if (hasOptions && hasValues && isEmpty(selectedOptions)) {
      const selected = options.filter(o => values?.includes(o.value))
      setSelectedOptions(selected)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, values])
  
  return (
    <FormControl sx={sx}>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <FormControlLabel label={option.label} control={(
            <Checkbox 
              checked={isSelected(option)} 
              onChange={(_, checked) => updateSelectedOption(option, checked)}
              disabled={option.disabled ?? false}
            />
          )} />
        </React.Fragment>
      ))}
      {!isEmpty(errors) ? (
        <Alert severity='error'><ErrorList errors={errors} /></Alert>
      ) : null}
    </FormControl>
  )
}

export default CheckboxMultiSelectField