import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AutoCompleteSelectField from 'components/AutoCompleteSelectField';
import { useAPI } from 'contexts/APIProvider';
import { usePractice } from 'contexts/PracticeProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import APIError from 'errors/APIError';
import Nurse from 'logic/Nurse';
import React from 'react'

interface AddNurseToBlacklistProps {
  open: boolean
  onClose: () => void
  reloadBlacklist: () => Promise<void>
}

const AddNurseToBlacklist: React.FC<AddNurseToBlacklistProps> = ({ open, onClose, reloadBlacklist }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { practice } = usePractice()
  const [nurses, setNurses] = React.useState<Nurse[]>([])
  const [selectedNurseId, setSelectedNurseId] = React.useState<number>()

  const fetchNurses = React.useCallback(async (searchTerm: string) => {
    if (!searchTerm) return
    setNurses(await api.listNurses({ name: searchTerm }))
  }, [api])

  const submit = React.useCallback(async () => {
    if (!practice || !selectedNurseId) return
    try {
      await api.addBlacklistNurse({
        practice_id: practice.practice_id,
        nurse_id: selectedNurseId,
      })
      await reloadBlacklist()
      showAlert('success', `The nurse is blacklisted`)
      onClose()
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    }
  }, [api, onClose, practice, reloadBlacklist, selectedNurseId, showAlert])


  const nurseSelectOptions = React.useMemo(() => {
    return nurses.map((nurse) => ({
      value: nurse.nurse_id,
      label: nurse.enabled ? nurse.full_name : `${nurse.full_name} (disabled)`,
    }))
  }, [nurses])
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Add Nurse to Blacklist
      </DialogTitle>
      <DialogContent>
        <AutoCompleteSelectField
          label='Select a nurse'
          sx={{ width: '100%' }}
          options={nurseSelectOptions}
          fetchOptions={fetchNurses}
          value={selectedNurseId}
          onChange={(value) => setSelectedNurseId(Number(value))}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={submit}>
          Add to Blacklist
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddNurseToBlacklist