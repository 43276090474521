import { GridRowParams } from '@mui/x-data-grid'
import AdminPracticeTable from 'components/AdminPracticeTable'
import Practice from 'logic/Practice'
import AdminAffiliateDetailContext from 'pages/AdminAffiliateDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminPracticeDetailURL } from 'routes/urls'

const AdminAffiliatePractices: React.FC = () => {
  const {
    practiceSearchForm,
    practiceSelection,
    practiceColumnVisibilityModel,
    practiceSortModel,
    updatePracticeSearchForm,
    setPracticeSelection,
    setPracticeColumnVisibilityModel,
    setPracticeSortModel,
  } = React.useContext(AdminAffiliateDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<Practice>) => {
    navigate(adminPracticeDetailURL(params.row.practice_id))
  }, [navigate])

  return (
    <AdminPracticeTable
      onRowClick={onRowClick}
      form={practiceSearchForm}
      updateForm={updatePracticeSearchForm}
      selection={practiceSelection}
      setSelection={setPracticeSelection}
      columnVisibilityModel={practiceColumnVisibilityModel}
      setColumnVisibilityModel={setPracticeColumnVisibilityModel}
      sortModel={practiceSortModel}
      setSortModel={setPracticeSortModel}
    />
  )
}

export default AdminAffiliatePractices