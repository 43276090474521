import { chain, isEmpty } from 'lodash';

export default class AddressFormError {
  address: string[] = []
  latitude: string[] = []
  longitude: string[] = []
  line1: string[] = []
  line2: string[] = []
  city: string[] = []
  county: string[] = []
  country: string[] = []
  postcode: string[] = []

  get has_errors(): boolean {
    return (
      chain(this)
      .values()
      .filter(errors => !isEmpty(errors))
      .some()
      .value()
    )
  }
}