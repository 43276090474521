import { Alert, Box, Typography, Button } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { useNurse } from 'contexts/NurseProvider'
import { useZendesk } from 'contexts/ZendeskProvider'
import React from 'react'

const NurseSuspensionPage: React.FC = () => {
  const { nurse } = useNurse()
  const { openZendesk } = useZendesk()

  if (!nurse) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Your account is currently suspended' />
      <Typography>
        Your account is currently suspended because of the following reason.
      </Typography>
      <Alert variant='filled' color='info'>
        {nurse?.penalty_note}
      </Alert>
      <Button variant='outlined' onClick={() => openZendesk()} sx={{ cursor: 'pointer' }} >
        Click here if you wish to contest our decision 
      </Button>
    </Box>
  )
}

export default NurseSuspensionPage