import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminNurseCancellations: React.FC = () => {
  const {
    cancellationsSearchForm,
    cancellationsSelection,
    cancellationsColumnVisibilityModel,
    cancellationsSortModel,
    updateCancellationsSearchForm,
    setCancellationsSelection,
    setCancellationsColumnVisibilityModel,
    setCancellationsSortModel,
  } = React.useContext(AdminNurseDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      exclude_filters={[
        'fulfillment_statuses',
        'payment_statuses',
        'timesheet_statuses',
        'nurse',
      ]}
      onRowClick={onRowClick}
      form={cancellationsSearchForm}
      updateForm={updateCancellationsSearchForm}
      selection={cancellationsSelection}
      setSelection={setCancellationsSelection}
      columnVisibilityModel={cancellationsColumnVisibilityModel}
      setColumnVisibilityModel={setCancellationsColumnVisibilityModel}
      sortModel={cancellationsSortModel}
      setSortModel={setCancellationsSortModel}
    />
  )
}

export default AdminNurseCancellations