import { Box, Chip, Typography, Tabs, Tab } from '@mui/material'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import Job from 'logic/Job'
import React from 'react'
import { Outlet, useMatch, useNavigate, useParams } from 'react-router'
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid';
import update from 'immutability-helper'
import { JobEmploymentSearchForm } from 'types/interfaces'
import AdminJobDetailContext from 'pages/AdminJobDetail/context'
import SplitButton from 'components/SplitButton'
import { practiceViewJobURL } from 'routes/urls'
import { useAdmin } from 'contexts/AdminProvider'

/**
 * Title (Colosseum / 12 Dec 2021)
 * Chips:
 *  fulfillment status, payment status, timesheet status, practice contact info, extended/basic compliance, practice payment method available
 * Actions:
 *  view as practice
 * 
 * Profile (timestamps, lunchbreak, description, duration)
 * Available Nurses (action: assign nurse)
 * Matches
 */

const AdminJobDetail: React.FC = () => {
  const { api } = useAPI()
  const params = useParams()
  const navigate = useNavigate()
  const [job, setJob] = React.useState<Job>()
  const match = useMatch({ path: '/admin/jobs/:jobId/:rest', caseSensitive: false, end: false })
  const { updateAvailableNurseSearchForm } = useAdmin()

  // Matches
  const [matchSearchForm, setMatchSearchForm] = React.useState<JobEmploymentSearchForm>({
    job_id: Number(params.jobId)
  })
  const [matchSelection, setMatchSelection] = React.useState<GridRowId[]>([])
  const [matchColumnVisibilityModel, setMatchColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    practice_name: false,
  })
  const [matchSortModel, setMatchSortModel] = React.useState<GridSortModel>([])

  const updateMatchSearchForm = React.useCallback((name: string, value: any) => {
    setMatchSearchForm(update(matchSearchForm, { [name]: { $set: value } }))
  }, [matchSearchForm])

  // Init Page
  const fetchJob = React.useCallback(async () => {
    setJob(await api.getJob(Number(params.jobId)))
  }, [api, params.jobId])

  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'profile'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchJob()
  }, [fetchJob])

  React.useEffect(() => {
    updateAvailableNurseSearchForm('available_for_job_id', Number(params.jobId))
  }, [params.jobId, updateAvailableNurseSearchForm])

  if (!job) return <Loading />

  const contextValue = {
    job,
    fetchJob,
    matchSearchForm,
    matchSelection,
    matchColumnVisibilityModel,
    matchSortModel,
    updateMatchSearchForm,
    setMatchSelection,
    setMatchColumnVisibilityModel,
    setMatchSortModel,
  }
  
  return (
    <AdminJobDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 1, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Typography variant='h1'>{ job.practice_label } / { job.date_label(job.practice_tz) }</Typography>
          <Chip label={job.job_role} color='primary' />
          <Chip label={job.fulfillment_status} color={job.fulfillment_status_color} />
          {job.payment_status ? (
            <Chip
              label={job.payment_status}
              color={job.payment_status_color} />
          ) : null}
          {job.timesheet_status ? (
            <Chip
              label={job.timesheet_status}
              color={job.timesheet_status_color} />
          ) : null}
          {job.extended_compliance_required ? (
            <Chip label='Extended Compliance Required' />
          ) : null}
          {job.scotland_compliance_required ? (
            <Chip label='Scotland Compliance Required' />
          ) : null}
          {job.hygienist_required ? (
            <Chip label='Hygienist Required' />
          ) : null}
          {!job.published_at ? (
            <Chip label='Not Published' color='error' />
          ) : null}
          {!job.practice_payment_method_available ? (
            <Chip label='Not Ready to Process Payments!' color='error' />
          ) : (
            <Chip label={job.payment_option} color='info' />
          )}
          <Chip label={`Open to Tiers: ${job.opened_to_tiers_label}`} color='info' />
          <Chip label={`Practice Manager: ${job.first_member_label}`} />
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'view-as-practice',
            label: 'View as practice',
            onClick: () => navigate(practiceViewJobURL(job.practice_id, job.job_id))
          }]} />
        </Box>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label='Profile' value='profile' onClick={() => navigate('profile')}/>
          <Tab label='Available Nurses' value='nurses' onClick={() => navigate('nurses')}/>
          <Tab label='Matches' value='matches' onClick={() => navigate('matches')}/>
        </Tabs>
        <Outlet />
      </Box>
    </AdminJobDetailContext.Provider>
  )
}

export default AdminJobDetail