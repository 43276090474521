import Nurse from 'logic/Nurse'
import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import { 
  DataGridPro, 
  GridColDef, 
  GridColumnVisibilityModel, 
  GridEventListener, 
  GridRenderCellParams, 
  GridRowId, 
  GridSortModel, 
  GridValueFormatterParams, 
  GridValueGetterParams 
} from '@mui/x-data-grid-pro';
import FormField from 'components/FormField'
import { isEmpty } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { NurseSearchForm } from 'types/interfaces'
import Practice from 'logic/Practice'
import { Tooltip } from '@mui/material'
import AutoCompleteSelectField from 'components/AutoCompleteSelectField'
import ExportOnlyToolBar from 'components/ExportToolbar'
import { Observable } from 'rxjs'
import MultiSelect from './MultiSelect'
import { NurseTier } from 'types/types'

type FilterOptions = 
| 'name'
| 'postcode'
| 'nearby_practice_id'
| 'stripe_status'
| 'basic_document_status'
| 'extended_compliance_document_status'
| 'scotland_compliance_document_status'
| 'waitlist'
| 'tiers'

interface AdminNurseTableProps {
  filters?: FilterOptions[]
  reload$?: Observable<boolean>
  actions?: React.ReactNode
  onRowClick?: GridEventListener<"rowClick">
  form: NurseSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminNurseTable: React.FC<AdminNurseTableProps> = ({
  filters = [
    'name',
    'postcode',
    'nearby_practice_id',
    'stripe_status',
    'basic_document_status',
    'extended_compliance_document_status',
    'scotland_compliance_document_status',
    'waitlist',
    'tiers',
  ],
  reload$,
  onRowClick,
  actions,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [practices, setPractices] = React.useState<Practice[]>([])
  const [nurses, setNurses] = React.useState<Nurse[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchNurses = React.useCallback(async () => {
    setLoading(true)
    setNurses(await api.listNurses(form))
    setLoading(false)
  }, [api, form])

  const fetchPractices = React.useCallback(async (searchTerm: string) => {
    if (!searchTerm) return
    setPractices(await api.listPractices({ name: searchTerm }))
  }, [api])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  // Admins are always searching for specific nurse, so don't need to load all nurses at once initially
  // React.useEffect(() => {
  //   fetchNurses()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchNurses())
    return () => sub?.unsubscribe()
  }, [fetchNurses, reload$])

  const practiceSelectOptions = React.useMemo(() => {
    return practices.map((practice) => ({
      value: practice.practice_id,
      label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
    }))
  }, [practices])

  const columns: GridColDef<Nurse>[] = React.useMemo(() => [
  {
    minWidth: 50,
    headerName: 'ID',
    field: 'nurse_id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Name',
    field: 'full_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Email',
    field: 'email',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Phone',
    field: 'phone_number',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Tier',
    field: 'nurse_tier',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return <Chip color={nurse.tier_color} size='small' label={nurse.nurse_tier} />
    }
  }, {
    minWidth: 200,
    headerName: 'Location',
    field: 'location_label',
    type: 'string',
  }, {
    minWidth: 50,
    headerName: 'Distance',
    field: 'distance_miles',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.distance_label
    }
  }, {
    minWidth: 50,
    headerName: 'Travel Radius',
    field: 'radius_miles',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.travel_radius_label
    }
  }, {
    minWidth: 150,
    headerName: 'Role',
    field: 'nurse_role',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.nurse_role
    }
  }, {
    minWidth: 150,
    headerName: 'Hours Worked',
    field: 'hours_worked',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip 
          size='small' 
          color={nurse.hours_worked >= 70 ? 'success' : 'error'}
          label={`${nurse.hours_worked} hours`}
        />
      )
    }
  }, {
    minWidth: 150,
    headerName: 'Jobs Worked',
    field: 'jobs_worked',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return `${params.value} jobs`;
    },
  }, {
    minWidth: 50,
    headerName: 'Rate',
    field: 'paygrade_rate',
    type: 'number',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.paygrade_label
    },
  }, {
    minWidth: 50,
    headerName: 'Enabled',
    field: 'enabled',
    type: 'string',
    valueFormatter: (params: GridValueFormatterParams<boolean>) => {
      return params.value ? 'Yes': 'No'
    }
  }, {
    minWidth: 150,
    headerName: 'Affiliate Code',
    field: 'affiliate_code',
    type: 'string,'
  }, {
    minWidth: 300,
    headerName: 'SignUp At',
    field: 'created_at',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<Nurse>) => {
      return params.row.created_at.toISO()
    },
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip 
          size='small' 
          color={nurse.signup_at_color}
          label={nurse.signup_at_label}
        />
      )
    }
  }, {
    minWidth: 150,
    headerName: 'First Job',
    field: 'date_of_first_job',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<Nurse>) => {
      return params.row.date_of_first_job?.toISO() ?? ''
    },
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      if (nurse.date_of_first_job) {
        return (
          <Chip 
            size='small' 
            color={nurse.first_job_color}
            label={nurse.first_job_label}
          />
        )
      } else {
        return 'No Jobs Yet'
      }
    }
  }, {
    minWidth: 50,
    headerName: 'Red Dots',
    field: 'num_cancellations',
    type: 'number'
  }, {
    minWidth: 150,
    headerName: 'Blacklists',
    field: 'blacklist_count',
    type: 'number'
  }, {
    minWidth: 180,
    headerName: 'Cancellations (last month)',
    field: 'cancel_count_last_period',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.cancel_rate_last_period_label
    }
  }, {
    minWidth: 180,
    headerName: 'Cancellations (overall)',
    field: 'cancel_count_overall',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      return params.row.cancel_rate_overall_label
    }
  }, {
    minWidth: 150,
    headerName: 'Rating (excellent)',
    field: 'ratings_excellent',
    type: 'number',
  }, {
    minWidth: 150,
    headerName: 'Rating (good)',
    field: 'ratings_good',
    type: 'number',
  }, {
    minWidth: 150,
    headerName: 'Rating (average)',
    field: 'ratings_average',
    type: 'number',
  }, {
    minWidth: 150,
    headerName: 'Rating (poor)',
    field: 'ratings_poor',
    type: 'number',
  }, {
    minWidth: 100,
    headerName: 'Ready (basic)',
    field: 'approved',
    type: 'boolean'
  }, {
    minWidth: 100,
    headerName: 'Ready (extended compliance)',
    field: 'extended_compliance_approved',
    type: 'boolean'
  }, {
    minWidth: 100,
    headerName: 'Ready (Scotland)',
    field: 'scotland_compliance_approved',
    type: 'boolean'
  }, {
    minWidth: 100,
    headerName: 'Ready (hygienist)',
    field: 'hygienist_approved',
    type: 'boolean'
  }, {
    minWidth: 100,
    headerName: 'GDC',
    field: 'gdc_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('gdc')}
          color={nurse.document_status_color(nurse.gdc_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Indemnity',
    field: 'indemnity_insurance_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('indemnity_insurance')}
          color={nurse.document_status_color(nurse.indemnity_insurance_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Hep B',
    field: 'hepatitis_b_vaccination_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('hepatitis_b_vaccination')}
          color={nurse.document_status_color(nurse.hepatitis_b_vaccination_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Hep C',
    field: 'hepatitis_c_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('hepatitis_c')}
          color={nurse.document_status_color(nurse.hepatitis_c_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'TB',
    field: 'tb_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('tb')}
          color={nurse.document_status_color(nurse.tb_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'HIV',
    field: 'hiv_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('hiv')}
          color={nurse.document_status_color(nurse.hiv_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'DBS',
    field: 'dbs_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('dbs')}
          color={nurse.document_status_color(nurse.dbs_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'PVG',
    field: 'pvg_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('pvg')}
          color={nurse.document_status_color(nurse.pvg_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Infection Control',
    field: 'infection_control_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('infection_control')}
          color={nurse.document_status_color(nurse.infection_control_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'CPR',
    field: 'cpr_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('cpr')}
          color={nurse.document_status_color(nurse.cpr_status)}
        />
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Resume',
    field: 'resume_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.document_type_status_label('resume')}
          color={nurse.document_status_color(nurse.resume_status)}
        />
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Documents (out of date)',
    field: 'documents_expired',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<Nurse>) => {
      return params.row.documents_expired.length
    },
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {nurse.documents_expired.map((doc) => (
            <Chip key={doc} size='small' label={doc} color='error'/>
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Documents (missing date)',
    field: 'documents_missing_date',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<Nurse>) => {
      return params.row.documents_missing_date.length
    },
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {nurse.documents_missing_date.map((doc) => (
            <Chip key={doc} size='small' label={doc} color='error'/>
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Stripe',
    field: 'payouts_enabled',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
            size='small'
            label={nurse.payouts_enabled ? 'ready' : 'not ready'}
            color={nurse.stripe_status_color}
          />
      )
    }
  }, {
    minWidth: 150,
    headerName: 'Right To Work',
    field: 'rtw_status',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.rtw_status}
          color={nurse.rtw_status_color}
        />
      )
    }
  }, {
    minWidth: 150,
    headerName: 'ID (status)',
    field: 'verification_status',
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      if (nurse.verification_error_code) {
        return (
          <Tooltip title={`${nurse.verification_error_reason} (${nurse.verification_error_code})`}>
            <Chip color='error' size='small' label='error' />
          </Tooltip>
        )
      } else if (nurse.verification_status === '') {
        return <Chip color='warning' size='small' label='not submitted' />
      } else {
        return <Chip size='small' label={nurse.verification_status} />
      }
    }
  }, {
    minWidth: 100,
    headerName: 'ID (type)',
    field: 'id_document_type',
  }, {
    minWidth: 100,
    headerName: 'ID (Country)',
    field: 'id_issuing_country',
  }, {
    minWidth: 150,
    headerName: 'ID (Verified Name)',
    field: 'verified_full_name',
  }, {
    minWidth: 100,
    headerName: 'Penalty',
    field: 'penalty',
    sort: false,
    renderCell: (params: GridRenderCellParams<Nurse>) => {
      const nurse = params.row
      return (
        <Chip
          size='small'
          label={nurse.is_suspended ? 'suspended' : 'none'}
          color={nurse.is_suspended ? 'error': 'default'}
        />
      )
    }
  }, {
    minWidth: 50,
    headerName: 'Earnings',
    field: 'earnings_label',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Joined Waitlist At (CPD)',
    field: 'cpd_waitlisted_at',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<Nurse>) => {
      return params.row.cpd_waitlisted_label('Europe/London')
    },
  }], [])

  const tiers: NurseTier[] = [
    'bad',
    'good',
    'new',
    'unapproved',
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, alignItems: 'center' }}>
        {filters.includes('name') ? (
          <FormField
            name='name'
            label='Name'
            type='text'
            sx={{ width: 200 }}
            onChange={(e) => updateForm('name', e.target.value)}
            value={form.name}
          />
        ) : null}
        {filters.includes('postcode') ? (
          <FormField
            name='postcode'
            label='Postcode'
            type='text'
            sx={{ width: 200 }}
            onChange={(e) => updateForm('postcode', e.target.value)}
            value={form.postcode}
          />
        ) : null}
        {filters.includes('nearby_practice_id') ? (
          <AutoCompleteSelectField
            label='Nearby Practice'
            sx={{ width: 200 }}
            options={practiceSelectOptions}
            fetchOptions={fetchPractices}
            value={form.nearby_practice_id}
            onChange={(value) => updateForm('nearby_practice_id', value)}
          />
        ) : null}
        {filters.includes('basic_document_status') ? (
          <FormField
            sx={{ width: 200 }}
            name='basic_document_status'
            label='Basic Documents'
            select
            onChange={(e) => updateForm('basic_document_status', e.target.value)}
            value={form.basic_document_status ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              {['missing_some', 'missing_all', 'pending_verify', 'disapproved', 'approved'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </FormField>
        ) : null}
        {filters.includes('extended_compliance_document_status') ? (
          <FormField
            sx={{ width: 200 }}
            name='extended_compliance_document_status'
            label='Extended Documents'
            select
            onChange={(e) => updateForm('extended_compliance_document_status', e.target.value)}
            value={form.extended_compliance_document_status ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              {['missing_some', 'missing_all', 'pending_verify', 'disapproved', 'approved'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </FormField>
        ) : null}
        {filters.includes('scotland_compliance_document_status') ? (
          <FormField
            sx={{ width: 200 }}
            name='scotland_compliance_document_status'
            label='Scotland Documents'
            select
            onChange={(e) => updateForm('scotland_compliance_document_status', e.target.value)}
            value={form.scotland_compliance_document_status ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              {['missing_some', 'missing_all', 'pending_verify', 'disapproved', 'approved'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </FormField>
        ) : null}
        {filters.includes('stripe_status') ? (
          <FormField
            sx={{ width: 150 }}
            name='stripe_status'
            label='Stripe Status'
            select
            onChange={(e) => updateForm('stripe_status', e.target.value)}
            value={form.stripe_status ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              {['enabled', 'submitted', 'not-submitted'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </FormField>
        ) : null}
        {filters.includes('tiers') ? (
          <MultiSelect
            name='tiers'
            label='Tier'
            width={100}
            options={tiers}
            onChange={(e) => updateForm('tiers', e.target.value)}
            value={form.tiers ?? []}
          />
        ): null}
        {filters.includes('waitlist') ? (
          <FormField
            sx={{ width: 150 }}
            name='waitlist'
            label='Waiting List'
            select
            onChange={(e) => updateForm('waitlist', e.target.value)}
            value={form.waitlist ?? ''}>
              <MenuItem value=''>Not Selected</MenuItem>
              <MenuItem value='cpd'>CPD</MenuItem>
          </FormField>
        ) : null}
        <Button
          variant="contained"
          onClick={() => fetchNurses()}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {loading ? (
        <Alert severity='info'>Please Wait ...</Alert>
      ) : isEmpty(nurses) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{nurses.length} records returned. {selection.length} selected</Alert>
          {/* <Box sx={{ height: '70vh', width: '98vw' }}> */}
          <DataGridPro 
            rows={nurses}
            columns={columns}
            getRowId={(nurse) => nurse.nurse_id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowClick={onRowClick}
            hideFooter
            slots={{ toolbar: ExportOnlyToolBar }}
          />
          {/* </Box> */}
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminNurseTable