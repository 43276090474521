import React from 'react'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/DriveFolderUpload'
import IUpload from './IUpload'

const ACCEPTED_FILETYPES = 'image/jpeg, image/png, application/pdf'

const FileUpload: React.FC<IUpload> = ({ fileType, save }) => {
  function onFiledUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (file) save(file, file.type || '')
  }

  const uploadRef = `${fileType}-upload`

  return (
    <Button
      size='small'
      variant="contained"
      endIcon={<FileUploadIcon />}
      component="label">
      Upload File
      <input
        hidden
        id={uploadRef}
        accept={ACCEPTED_FILETYPES}
        type="file"
        onChange={onFiledUpload} />
    </Button>
  )
}

export default FileUpload