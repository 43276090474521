import BillingAccountFormError from 'errors/BillingAccountFormError'
import React from 'react'
import { BillingAccountForm } from 'types/interfaces'
import update from 'immutability-helper'
import PaymentMethod from 'logic/PaymentMethod'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Radio,
  MenuItem,
  Alert,
} from '@mui/material'
import FormField from 'components/FormField'
import { PaymentOption } from 'types/types'
import { chain, isEmpty, trim } from 'lodash'
import {
  PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING,
} from 'types/constants'
import ErrorList from 'components/ErrorList'

export const ADD_NEW = -99999

interface BillingAccountSetupFormProps {
  form: BillingAccountForm
  setForm: (form: BillingAccountForm) => void
  error?: BillingAccountFormError
  updateForm: (name: string, value: any) => void
  paymentMethods: PaymentMethod[]
  availablePaymentOptions: PaymentOption[]
}

const BillingAccountSetupForm: React.FC<BillingAccountSetupFormProps> = ({ 
  form, 
  setForm, 
  error, 
  updateForm, 
  paymentMethods, 
  availablePaymentOptions
}) => {
  const updatePaymentOption = React.useCallback((value: PaymentOption) => {
    setForm(update(form, { 
      payment_option: { $set: value },
      payment_method_id: { $set: ADD_NEW }
    }))
  }, [form, setForm])

  const cards = React.useMemo(() => {
    return (
      chain(paymentMethods)
      .filter((paymentMethod) => paymentMethod.type === 'card')
      .value()
    )
  }, [paymentMethods])

  const bacsDebits = React.useMemo(() => {
    return (
      chain(paymentMethods)
      .filter((paymentMethod) => paymentMethod.type === 'bacs_debit')
      .value()
    )
  }, [paymentMethods])

  const isSelectCards = React.useMemo(() => {
    if (!form.payment_option) return false
    return !isEmpty(cards) && [
      'pay_eod_with_card',
      'invoice_weekly_autopay_with_card',
      'invoice_biweekly_autopay_with_card',
      'invoice_monthly_autopay_with_card',
    ].includes(form.payment_option)
  }, [form.payment_option, cards])

  const isSelectBacsDebits = React.useMemo(() => {
    if (!form.payment_option) return false
    return !isEmpty(bacsDebits) && [
      'pay_eod_with_bacs_debit',
      'invoice_weekly_autopay_with_bacs_debit',
      'invoice_biweekly_autopay_with_bacs_debit',
      'invoice_monthly_autopay_with_bacs_debit',
    ].includes(form.payment_option)
  }, [form.payment_option, bacsDebits])
  
  return (
    <React.Fragment>
      <FormField
        name='name'
        label='Billing Name'
        onChange={(e) => updateForm('name', e.target.value)}
        value={form.name ?? ''}
        errors={error?.name}
      />
      <FormField
        name='email'
        label='Billing Email'
        onChange={(e) => updateForm('email', trim(e.target.value))}
        value={form.email ?? ''}
        errors={error?.email}
      />
      <Typography>Select a payment option from below:</Typography>
      <TableContainer component={Box}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Payment Option</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availablePaymentOptions.map((option) => (
              <TableRow key={option}>
                <TableCell data-label='Selected'>
                  <Radio
                    checked={form.payment_option === option}
                    onChange={() => updatePaymentOption(option)}
                  />
                </TableCell>
                <TableCell data-label="Payment Option">
                  {PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING[option]['practice']}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isSelectCards ? (
        <FormField
          name='payment_method_id'
          label='Select Payment Card'
          select
          onChange={(e) => updateForm('payment_method_id', e.target.value)}
          value={form.payment_method_id || ''}>
          <MenuItem value={ADD_NEW}>Add new card</MenuItem>
          {cards.map((card) => (
            <MenuItem key={card.id} value={card.id}>
              {card.label}
            </MenuItem>
          ))}
        </FormField>
      ) : isSelectBacsDebits ? (
        <FormField
          name='payment_method_id'
          label='Select Direct Debit Account'
          select
          onChange={(e) => updateForm('payment_method_id', e.target.value)}
          value={form.payment_method_id || ''}>
          <MenuItem value={ADD_NEW}>Add new direct debit account</MenuItem>
          {bacsDebits.map((bacsDebit) => (
            <MenuItem key={bacsDebit.id} value={bacsDebit.id}>
              {bacsDebit.label}
            </MenuItem>
          ))}
        </FormField>
      ) : null}
      {error?.schema ? (
        <Alert severity='error' variant='filled'>
          <ErrorList errors={error.schema} />
        </Alert>
      ) : null}
    </React.Fragment>
  )
}

export default BillingAccountSetupForm