import React from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  STEP_BASIC,
  STEP_DOCUMENTS,
  STEP_IDCHECK,
  STEP_BILLING,
  STEP_SUMMARY,
} from 'types/constants'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useFirebase } from 'contexts/FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import NurseSignupBasicInfo from './BasicInfo';
import NurseSignupDocuments from './Documents';
import NurseSignupStripeSetup from './StripeSetup';
import NurseSignupSummary from './Summary';
import NurseSignupContext from './context'
import { map, from, share, switchMap, NEVER, filter, Subscription, tap } from 'rxjs';
import { plainToClass } from 'class-transformer';
import NurseSignupBasicInfoEdit from './BasicInfoEdit';
import Nurse from 'logic/Nurse';
import { Notification } from 'logic/Notification'
import { useAPI } from 'contexts/APIProvider';
import NurseSignupIDCheck from 'pages/NurseSignup/IDCheck';
import { DateTime } from 'luxon';

const NurseSignup: React.FC = () => {
  const sendDataToGTM = useGTMDispatch()
  const { authUser } = useAuthUser()
  const { api } = useAPI()
  const { firestore } = useFirebase()
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [step, setStep] = React.useState<number>();
  const [emittedGTMEvents] = React.useState<Set<string>>(new Set());
  const [nurse, setNurse] = React.useState<Nurse | null>()

  const nurseId = React.useMemo(() => {
    if (authUser === undefined) {
      return undefined
    } else if (authUser === null) {
      return null
    } else {
      return authUser.nurseId
    }
  }, [authUser]) 
  
  const fetchNurse = React.useCallback(async () => {
    console.log('NurseSignup: fetchNurse')
    if (nurseId) {
      setNurse(await api.getNurse(nurseId))
    } else if (nurseId === null) {
      setNurse(null)
    }
  }, [api, nurseId])

  const notificationQuery = React.useMemo(() => {
    if (!authUser || !nurseId) return null
    return query(
      collection(firestore, 'notifications'),
      where("nurse_id", "==", nurseId)
    )
  }, [authUser, firestore, nurseId])

  const notification$ = React.useMemo(() => {
    if (!notificationQuery) return NEVER
    return collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    )
  }, [notificationQuery])

  React.useEffect(() => {
    fetchNurse()
  }, [fetchNurse])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'nurse_updated',
      'nurse_document_created',
      'nurse_document_deleted',
      'nurse_id_verification_success',
      'nurse_id_verification_failed',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(fetchNurse))
    return () => sub.unsubscribe()
  }, [fetchNurse, notification$])

  React.useEffect(() => {
    if (step === undefined) {
      if (nurse === null) {
        setStep(STEP_BASIC)
      } else if (nurse !== undefined) {
        setStep(nurse.signup_step)
      }
      // don't do anything if nurse is still undefined (still loading usertoken from browser storage)
    }
  }, [nurse])

  const toNextStep = React.useCallback(() => {
    if (step !== undefined) setStep(step < STEP_SUMMARY ? step + 1 : step)
  }, [step])

  const toPreviousStep = React.useCallback(() => {
    if (step !== undefined) setStep(step > 0 ? step - 1 : step)
  }, [step])

  const emitGTMEvent = React.useCallback((event: string) => {
    if (!emittedGTMEvents.has(event)) {
      sendDataToGTM({ event })
      emittedGTMEvents.add(event)
    }
  }, [emittedGTMEvents, sendDataToGTM])

  const mode = React.useMemo(() => {
    if (nurse === null) {
      return 'add'
    } else if (nurse) {
      return 'edit'
    } else {
      return undefined
    }
  }, [nurse])

  const contextValue = { nurse, step, toNextStep, toPreviousStep, emitGTMEvent, notification$ }

  return (
    <NurseSignupContext.Provider value={contextValue}>
      {step === undefined || mode === undefined ? (
        <p>Please Wait ... </p>
      ) : step === STEP_SUMMARY ? (
        <NurseSignupSummary />
      ) : (
        <React.Fragment>
          <Box sx={{
            padding: [0, 2],
            backgroundColor: ['transparent', 'background.paper'],
            boxShadow: [0, 2],
            marginTop: 2
          }}>
            <Stepper activeStep={step} orientation={isDesktop ? 'horizontal' : 'vertical'}>
              <Step key='basicInfo'>
                <StepLabel>Basic Info</StepLabel>
                {!isDesktop ? mode === 'edit' ? (
                  <StepContent>
                    <NurseSignupBasicInfoEdit />
                  </StepContent>
                ) : (  // is Create Nurse
                  <StepContent>
                    <NurseSignupBasicInfo />
                  </StepContent>
                ) : null}
              </Step>
              <Step key='documents'>
                <StepLabel>Documents</StepLabel>
                {!isDesktop ? (
                  <StepContent>
                    <NurseSignupDocuments />
                  </StepContent>
                ) : null}
              </Step>
              <Step key='id-check'>
                <StepLabel>Right to Work</StepLabel>
                {!isDesktop ? (
                  <StepContent>
                    <NurseSignupIDCheck />
                  </StepContent>
                ) : null}
              </Step>
              <Step key='stripe'>
                <StepLabel>Stripe Setup</StepLabel>
                {!isDesktop ? (
                  <StepContent>
                    <NurseSignupStripeSetup />
                  </StepContent>
                ) : null}
              </Step>
            </Stepper>
          </Box>
          {isDesktop ? step === STEP_BASIC ? mode === 'edit' ? (
            <NurseSignupBasicInfoEdit />
          ) : (  // is Create Nurse
            <NurseSignupBasicInfo />
          ) : step === STEP_DOCUMENTS ? (
            <NurseSignupDocuments />
          ) : step === STEP_IDCHECK ? (
            <NurseSignupIDCheck />
          ) : step === STEP_BILLING ? (
            <NurseSignupStripeSetup />
          ) : null : null}
        </React.Fragment>
      )}
    </NurseSignupContext.Provider>
  )
}

export default NurseSignup