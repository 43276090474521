import React from 'react'
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Header from 'components/Header'
import update from 'immutability-helper';
import { AddJobForm, EstimateCost } from 'types/interfaces'
import AddJobError from 'errors/AddJobError'
import { usePractice } from 'contexts/PracticeProvider';
import { practiceViewJobURL, practiceJobsURL } from 'routes/urls'
import useAddJob from 'hooks/useAddJob';
import { isEmpty } from 'lodash';
import JobForm from 'components/JobForm';
import { useAPI } from 'contexts/APIProvider';
import { DateTime } from 'luxon';
import JobFormFooter from 'components/JobFormFooter';

const PracticeAddJob: React.FC = () => {
  const { practice, paygradeStats } = usePractice()
  const navigate = useNavigate()
  const { api } = useAPI()
  const { addJob } = useAddJob()
  const [searchParams] = useSearchParams()
  const [form, setForm] = React.useState<AddJobForm>({} as AddJobForm);
  const [error, setError] = React.useState<AddJobError>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [estimatedCost, setEstimatedCost] = React.useState<EstimateCost | null>()

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [form, error])

  const fetchEstimatedCost = React.useCallback(async () => {
    if (practice && form.date && form.start_time && form.end_time && form.lunch_break !== undefined && form.headcount) {
      setEstimatedCost(await api.getEstimatedCost(
        practice?.practice_id,
        {
          date: form.date,
          start_time: form.start_time,
          end_time: form.end_time,
          lunch_break: form.lunch_break,
          headcount: form.headcount,
          role: form.hygienist_required ? 'hygienist' : 'general nurse'
        }
      ))
    }
  }, [practice, form.date, form.start_time, form.end_time, form.lunch_break, form.headcount, form.hygienist_required, api])

  const save = React.useCallback(async () => {
    try {
      setLoading(true)
      const jobId = await addJob(form)
      if (jobId !== undefined && practice) {
        navigate(practiceViewJobURL(practice.practice_id, jobId))
      }
    } catch (e) {
      if (e instanceof AddJobError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }, [addJob, form, navigate, practice])

  const prepopulateForm = React.useCallback(() => {
    if (practice) {
      setForm((form) => update(form, {
        practice_id: { $set: practice.practice_id },
        date: { $set: searchParams.get('date') || '' },
        start_time: { $set: practice.start_time },
        end_time: { $set: practice.end_time },
        lunch_break: { $set: practice.lunch_break.as('minute') },
        headcount: { $set: 1 },
        description: { $set: practice.description }
      }))
    }
  }, [practice, searchParams])

  const lateCancelFeeMayApply = React.useMemo(() => {
    if (!form.date) return false
    return DateTime.fromFormat(form.date, 'yyyy-MM-dd').diffNow('hours').hours <= 72
  }, [form.date])

  function goBack() {
    if (practice) navigate(practiceJobsURL(practice?.practice_id))
  }


  React.useEffect(() => {
    prepopulateForm()
  }, [prepopulateForm])

  React.useEffect(() => {
    fetchEstimatedCost()
  }, [fetchEstimatedCost])

  if (!practice || isEmpty(form)) {
    return null
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text='Post a Job'>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <JobForm
        form={form}
        error={error}
        updateForm={updateForm}
        mode='add'
        paygradeStats={paygradeStats}
        estimatedCost={estimatedCost}
      />
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Post Job'}
      </Button>
      <Button
        variant="outlined" color="primary" disabled={loading}
        onClick={goBack}>
        Cancel
      </Button>
      { paygradeStats ? <JobFormFooter practice={practice} paygradeStats={paygradeStats} /> : null}
    </Paper>
  )
}

export default PracticeAddJob