import { Expose, Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class PaymentMethod {
  id!: number
  payment_method_id!: string
  type!: 'card' | 'bacs_debit'
  brand!: string
  exp_month!: string
  exp_year!: string
  last4!: string
  sort_code!: string
  error!: string
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  @Expose()
  get label() {
    if (this.type === 'card') {
      const expiry = `${this.exp_month}/${this.exp_year}`
      return `${this.brand} ending with ${this.last4} (expires: ${expiry})`
    } else {
      return `Direct Debit **** ${this.last4}`
    }
    
  }

  @Expose()
  get expiry_label() {
    if (this.type === 'card') {
      return `${this.exp_month}/${this.exp_year}`
    } else {
      return 'N/A'
    }
  }
}