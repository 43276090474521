import { GridRowParams } from '@mui/x-data-grid'
import AdminNurseTable from 'components/AdminNurseTable'
import Nurse from 'logic/Nurse'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminNurseDetailURL } from 'routes/urls'

const AdminPracticeNearbyNurses: React.FC = () => {
  const {
    nearbyNurseSearchForm,
    nearbyNurseSelection,
    nearbyNurseColumnVisibilityModel,
    nearbyNurseSortModel,
    updateNearbyNurseSearchForm,
    setNearbyNurseSelection,
    setNearbyNurseColumnVisibilityModel,
    setNearbyNurseSortModel,
  } = React.useContext(AdminPracticeDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<Nurse>) => {
    navigate(adminNurseDetailURL(params.row.nurse_id))
  }, [navigate])

  return (
    <AdminNurseTable
      filters={['name', 'stripe_status', 'basic_document_status', 'extended_compliance_document_status', 'scotland_compliance_document_status', 'waitlist', 'tiers']}
      onRowClick={onRowClick}
      form={nearbyNurseSearchForm}
      updateForm={updateNearbyNurseSearchForm}
      selection={nearbyNurseSelection}
      setSelection={setNearbyNurseSelection}
      columnVisibilityModel={nearbyNurseColumnVisibilityModel}
      setColumnVisibilityModel={setNearbyNurseColumnVisibilityModel}
      sortModel={nearbyNurseSortModel}
      setSortModel={setNearbyNurseSortModel}
    />
  )
}

export default AdminPracticeNearbyNurses