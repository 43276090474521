import React from 'react'
import { useAPI } from './APIProvider'
import { useFirebase } from './FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { useParams } from 'react-router'
import { filter, map, switchMap, from, share, Observable, Subscription } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import JobEmployment from 'logic/JobEmployment';
import { DateTime } from 'luxon';

interface IJobEmploymentContext {
  je?: JobEmployment
  jeId: number
  reloadJobEmployment: () => Promise<void>
  notification$?: Observable<Notification>
  loading: boolean
}

const JobEmploymentContext = React.createContext<IJobEmploymentContext>({} as IJobEmploymentContext)

const JobEmploymentProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { api } = useAPI()
  const { firestore } = useFirebase()
  const [je, setJe] = React.useState<JobEmployment>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const jeId = React.useMemo(() => Number(params.jeId), [params.jeId])

  const reloadJobEmployment = React.useCallback(async () => {
    try {
      setLoading(true)
      console.log('JobEmploymentProvider: fetchJobEmployment')
      setJe(await api.getJobEmployment(jeId))
    } catch (e) {
      console.error(e)
      setJe(undefined)  // use null?
    } finally {
      setLoading(false)
    }
  }, [api, jeId])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("je_id", "==", jeId)
    ),
    [jeId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ),
    [notificationQuery]
  )

  React.useEffect(() => {
    reloadJobEmployment()
  }, [reloadJobEmployment])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'job_taken',
      'job_employment_cancelled',
      'job_employment_updated',
      'timesheet_change_proposed',
      'timesheet_change_updated',
      'timesheet_change_deleted',
      'timesheet_change_approved',
      'timesheet_change_disapproved',
      'payment_requested',
      'payment_success',
      'payment_failed',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(reloadJobEmployment))
    return () => sub.unsubscribe()
  }, [notification$, reloadJobEmployment])

  const value = { je, jeId, reloadJobEmployment, notification$, loading }
  return (
    <JobEmploymentContext.Provider value={value}>
      {children}
    </JobEmploymentContext.Provider>
  )
}

function useJobEmployment() {
  return React.useContext(JobEmploymentContext)
}

export { JobEmploymentProvider, useJobEmployment }