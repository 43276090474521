import { Alert, Box } from '@mui/material'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import BillingAccount from 'logic/BillingAccount'
import CashBalanceTransaction from 'logic/CashBalanceTransasction'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash'
import ExportOnlyToolBar from 'components/ExportToolbar'

const AdminBillingAccountDetail: React.FC = () => {
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const params = useParams()
    const [billingAccount, setBillingAccount] = React.useState<BillingAccount>()
    const [transactions, setTransactions] = React.useState<CashBalanceTransaction[]>([])
    const [loading, setLoading] = React.useState(false)

    const billingAccountId = React.useMemo(() => Number(params.billingAccountId), [params.billingAccountId])

    const fetchBillingAccount = React.useCallback(async () => {
        try {
            const result = await api.getBillingAccount(billingAccountId)
            setBillingAccount(result)
        } catch (error) {
            showAlert('error', 'Failed to fetch billing account')
        }
    }, [api, billingAccountId, showAlert])

    const fetchTransactions = React.useCallback(async () => {
        try {
            setLoading(true)
            const result = await api.listCashBalanceTransactions({ billing_account_id: billingAccountId })
            setTransactions(result)
        } catch (error) {
            showAlert('error', 'Failed to fetch transactions')
        } finally {
            setLoading(false)
        }
    }, [api, billingAccountId, showAlert])

    React.useEffect(() => {
        fetchTransactions()
    }, [fetchTransactions])

    React.useEffect(() => {
        fetchBillingAccount()
    }, [fetchBillingAccount])

    const columns: GridColDef<CashBalanceTransaction>[] = React.useMemo(() => [{
        flex: 1,
        headerName: 'Date',
        field: 'transaction_created_at',
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams<CashBalanceTransaction>) => {
            return params.row.transaction_created_at?.toJSDate()
        },
        renderCell: (params: GridRenderCellParams<CashBalanceTransaction>) => {
            return params.row.transaction_date_label()
        }
    }, {
        flex: 1,
        headerName: 'Practice Group',
        field: 'practice_group_name',
        type: 'string',
    }, {
        flex: 1,
        headerName: 'Email',
        field: 'billing_account_email',
        type: 'string',
    }, {
        flex: 1,
        headerName: 'Type',
        field: 'transaction_type',
        type: 'string',
    }, {
        flex: 1,
        headerName: 'Reference',
        field: 'reference',
        type: 'string',
    }, {
        flex: 1,
        headerName: 'Applied to Invoice',
        field: 'invoice_number',
        type: 'string',
    }, {
        minWidth: 100,
        headerName: 'Amount',
        field: 'amount',
        type: 'number',
        renderCell: (params: GridRenderCellParams<CashBalanceTransaction>) => {
            return params.row.amount_label
        }
    }, {
        minWidth: 100,
        headerName: 'Ending Balance',
        field: 'ending_balance',
        type: 'number',
        renderCell: (params: GridRenderCellParams<CashBalanceTransaction>) => {
            return params.row.ending_balance_label
        }
    }], [])

    if (!billingAccount) return <Loading />

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
            <Header text={billingAccount.name} />
            {loading ? (
                <Alert severity='info'>Please Wait ...</Alert>
            ) : isEmpty(transactions) ? (
                <Alert severity='info'>No Records Yet</Alert>
            ) : (
                <React.Fragment>
                    <Alert severity='info'>{transactions.length} records returned</Alert>
                    <DataGridPro
                        rows={transactions}
                        columns={columns}
                        getRowId={(transaction) => transaction.transaction_id}
                        loading={loading}
                        slots={{ toolbar: ExportOnlyToolBar }}
                    />
                </React.Fragment>
            )}
        </Box>
    )
}

export default AdminBillingAccountDetail