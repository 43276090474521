import { Box } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { adminPracticeDetailURL } from 'routes/urls'
import AdminPracticeTable from 'components/AdminPracticeTable'
import Practice from 'logic/Practice'

const AdminPracticeList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    practiceSearchForm, 
    practiceSelection, 
    practiceColumnVisibilityModel, 
    practiceSortModel, 
    updatePracticeSearchForm, 
    setPracticeSelection, 
    setPracticeColumnVisibilityModel, 
    setPracticeSortModel 
  } = useAdmin()

  const onRowClick = React.useCallback((params: GridRowParams<Practice>) => {
    navigate(adminPracticeDetailURL(params.row.practice_id))
  }, [navigate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practice List' />
      <AdminPracticeTable
        onRowClick={onRowClick}
        form={practiceSearchForm}
        updateForm={updatePracticeSearchForm}
        selection={practiceSelection}
        setSelection={setPracticeSelection}
        columnVisibilityModel={practiceColumnVisibilityModel}
        setColumnVisibilityModel={setPracticeColumnVisibilityModel}
        sortModel={practiceSortModel}
        setSortModel={setPracticeSortModel}
      />
    </Box>
  )
}

export default AdminPracticeList