import React from 'react';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { FirebaseProvider } from 'contexts/FirebaseProvider'
import { APIProvider } from 'contexts/APIProvider'
import { AuthUserProvider } from 'contexts/AuthUserProvider'
import { SnackBarAlertProvider } from 'contexts/SnackBarAlertProvider'
import Router from 'routes/Router'
import theme from 'theme';
import { DialogProvider } from 'contexts/DialogProvider';
import { ChartJSProvider } from 'contexts/ChartJSProvider';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { FacebookPixelProvider } from 'contexts/FacebookPixelProvider'
import { LogRocketProvider } from 'contexts/LogRocketProvider';
import { ZendeskProvider } from 'contexts/ZendeskProvider';
import { LinkedInProvider } from 'contexts/LinkedInProvider';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY || '');
const GTM_ID = process.env.REACT_APP_GTM_ID || ''

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* <ErrorBoundary FallbackComponent={ErrorPage}> */}
      <BrowserRouter>
        <FirebaseProvider>
          <APIProvider>
            <Elements stripe={stripe}>
              <ChartJSProvider>
                <AuthUserProvider>
                  <SnackBarAlertProvider>
                    <DialogProvider>
                      <GTMProvider state={{ id: GTM_ID }}>
                        <LogRocketProvider>
                          {/* <FacebookPixelProvider> */}
                            {/* <LinkedInProvider> */}
                              <ZendeskProvider>
                                <Router></Router>
                              </ZendeskProvider>
                            {/* </LinkedInProvider> */}
                          {/* </FacebookPixelProvider> */}
                        </LogRocketProvider>
                      </GTMProvider>
                    </DialogProvider>
                  </SnackBarAlertProvider>
                </AuthUserProvider>
              </ChartJSProvider>
            </Elements>
          </APIProvider>
        </FirebaseProvider>
      </BrowserRouter>
      {/* </ErrorBoundary> */}
    </ThemeProvider>
  );
}

export default App;
