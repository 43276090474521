import React from 'react'
import { useAPI } from './APIProvider'
import { useFirebase } from './FirebaseProvider'
import Practice from '../logic/Practice'
import { collectionChanges, collectionData } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { useParams } from 'react-router'
import { useAuthUser } from './AuthUserProvider';
import { filter, map, switchMap, from, share, Observable, shareReplay, Subscription, firstValueFrom } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import { chain } from 'lodash'
import { NursePaygradeStats } from 'types/interfaces'
import { DateTime } from 'luxon'
import BillingAccount from 'logic/BillingAccount'

interface IPracticeContext {
  practice?: Practice | null
  billingAccount?: BillingAccount | null
  reloadPractice: () => Promise<void>
  notification$: Observable<Notification>
  notificationList$: Observable<Notification[]>
  unreadCount$: Observable<number>
  paygradeStats?: NursePaygradeStats
  paymentMethodAttached: Promise<Notification>
  paymentMethodDetached: Promise<Notification>
}

const PracticeContext = React.createContext<IPracticeContext>({} as IPracticeContext)

const PracticeProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { authUser } = useAuthUser()
  const [practice, setPractice] = React.useState<Practice | null>()
  const [billingAccount, setBillingAccount] = React.useState<BillingAccount | null>()
  const [paygradeStats, setPaygradeStats] = React.useState<NursePaygradeStats>()
  const { api } = useAPI()
  const { firestore } = useFirebase()

  const practiceId = React.useMemo(() => Number(params.practiceId), [params.practiceId]) 

  const reloadPractice = React.useCallback(async () => {
    if (authUser) {
      try {
        console.log('PracticeProvider: fetchPractice')
        setPractice(await api.getPractice(practiceId))
      } catch (e) {
        console.error(e)
        setPractice(null)
      }
    } else if (authUser === null) {
      setPractice(null)
    }
  }, [api, practiceId, authUser])

  const fetchPaygradeStats = React.useCallback(async () => {
    if (authUser) {
      setPaygradeStats(await api.getNursePaygradeStats())
    }
  }, [api, authUser])

  const fetchBillingAccount = React.useCallback(async () => {
    console.log('fetchBillingAccount', practice?.billing_account_id)
    if (practice?.billing_account_id === null) {
      setBillingAccount(null)
    } else if (practice?.billing_account_id) {
      setBillingAccount(await api.getBillingAccount(practice.billing_account_id))
    }
  }, [api, practice?.billing_account_id])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("practice_id", "==", practiceId)
    ), 
    [firestore, practiceId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ), 
    [notificationQuery]
  )

  // these are the notifications that we display on the notifications page.
  // not all notifications in notification$ will be displayed.
  // because some of them are only used to trigger reloadPractice
  const notificationList$ = React.useMemo(
    () => collectionData(notificationQuery).pipe(
      map(docs => docs.map(doc => plainToClass(Notification, doc))),
      map(notifications => {
        return chain(notifications)
          .filter(n => n.practice_message !== null)
          .sortBy('timestamp')
          .reverse()
          .value()
      }),
      shareReplay(1),
    ),
    [notificationQuery]
  )

  const paymentMethodAttached = React.useMemo(() => {
    const timestamp = DateTime.now()
    return firstValueFrom(notification$.pipe(
      filter(notification => notification.event === 'payment_method_attached'),
      filter(notification => notification.isAfter(timestamp)),
    ))
  }, [notification$])

  const paymentMethodDetached = React.useMemo(() => {
    const timestamp = DateTime.now()
    return firstValueFrom(notification$.pipe(
      filter(notification => notification.event === 'payment_method_detached'),
      filter(notification => notification.isAfter(timestamp)),
    ))
  }, [notification$])

  const unreadCount$ = React.useMemo(
    () => notificationList$.pipe(
      map(notifications => chain(notifications).filter(n => n.unread_by_practice).size().value()),
    ),
    [notificationList$]
  )

  React.useEffect(() => {
    reloadPractice()
  }, [reloadPractice])

  React.useEffect(() => {
    fetchPaygradeStats()
  }, [fetchPaygradeStats])

  React.useEffect(() => {
    fetchBillingAccount()
  }, [fetchBillingAccount])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'payment_method_attached',
      'payment_method_detached',
      'payment_method_updated',
      'billing_account_created',
      'billing_account_updated',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(reloadPractice))
    return () => sub.unsubscribe()
  }, [notification$, reloadPractice])

  const value = { 
    practice, 
    billingAccount,
    reloadPractice, 
    notification$, 
    notificationList$, 
    unreadCount$,
    paygradeStats,
    paymentMethodAttached,
    paymentMethodDetached,
  }
  return (
    <PracticeContext.Provider value={value}>
      {children}
    </PracticeContext.Provider>
  )
}

function usePractice() {
  const context = React.useContext(PracticeContext)
  if (context === undefined) {
    throw new Error('usePractice must be used within a PracticeProvider')
  }
  return context
}

export { PracticeProvider, usePractice }