import React from 'react'
import Alert from '@mui/material/Alert'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import CheckboxSelectField from 'components/CheckboxSelectField'
import { AddJobForm, EditJobForm } from 'types/interfaces'
import AddJobError from 'errors/AddJobError'
import EditJobError from 'errors/EditJobError'
import { round } from 'lodash'
import { NursePaygradeStats } from 'types/interfaces'
import { EstimateCost } from 'types/interfaces'
import { Link, Typography } from '@mui/material'
import { useZendesk } from 'contexts/ZendeskProvider'

type JobFormType = AddJobForm | EditJobForm
type JobError = AddJobError | EditJobError

interface JobFormProps {
  form: JobFormType
  error?: JobError
  updateForm: (name: string, value: any) => void
  mode: 'add' | 'edit'
  paygradeStats?: NursePaygradeStats | null
  estimatedCost?: EstimateCost | null
  canRequestSpecificNurse?: boolean
}

const JobForm: React.FC<JobFormProps> = ({ form, error, updateForm, mode, paygradeStats, estimatedCost, canRequestSpecificNurse = true }) => {
  const { openZendesk } = useZendesk()
  
  const isAddJobForm = (form: JobFormType): form is AddJobForm => {
    return mode === 'add'
  }

  const isAddJobError = (error?: JobError): error is AddJobError => {
    return mode === 'add'
  }

  const updateRole = React.useCallback((value: 'nurse' | 'hygienist') => {
    updateForm('hygienist_required', value === 'hygienist')
  }, [updateForm])

  const role = React.useMemo(() => form.hygienist_required ? 'hygienist' : 'nurse', [form.hygienist_required])
  
  return (
    <React.Fragment>
      <CheckboxSelectField 
        onChange={(value) => updateRole(value as 'nurse' | 'hygienist')}
        value={role}
        sx={{ flexDirection: 'row' }}
        options={[{
          value: 'nurse',
          label: 'General Nurse',
        }, {
          value: 'hygienist',
          label: 'Hygienist (Coming soon)',
          disabled: true,
        }]} 
        errors={error?.hygienist_required}
      />
      {isAddJobForm(form) && isAddJobError(error) ? (
        <FormField
          name='date'
          label='Date'
          type='date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => updateForm('date', e.target.value)}
          value={form.date ?? ''}
          errors={error?.date}
        />
      ) : null}
      <FormField
        name='start_time'
        label='Start Time'
        type='time'
        onChange={(e) => updateForm('start_time', e.target.value)}
        value={form.start_time ?? ''}
        errors={error?.start_time}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormField
        name='end_time'
        label='End Time'
        type='time'
        onChange={(e) => updateForm('end_time', e.target.value)}
        value={form.end_time ?? ''}
        errors={error?.end_time}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormField
        name='lunch_break'
        label='Lunch Break (minutes)'
        type='number'
        onChange={(e) => updateForm('lunch_break', e.target.value)}
        value={form.lunch_break ?? ''}
        errors={error?.lunch_break}
      />
      <FormField
        name='description'
        label='Description'
        multiline
        rows={2}
        placeholder="Include information useful to the nurse, such as parking availability, time of lunch break etc."
        onChange={(e) => updateForm('description', e.target.value)}
        value={form.description ?? ''}
        errors={error?.description}
      />
      <FormField
        name='headcount'
        label='Number of Nurses Required'
        type='number'
        onChange={(e) => updateForm('headcount', e.target.value)}
        value={form.headcount ?? 1}
        errors={error?.headcount}
      />
      {error?.schema ? (
        <Alert severity='error' variant='filled'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      { paygradeStats && estimatedCost ? (
        <Alert severity='info' variant='filled'>
          <Typography>Your total price: <strong>£{round(estimatedCost.median_total, 2)} per day</strong> ({round(estimatedCost.billable_hours, 1)} hours total)</Typography>
        </Alert>
      ) : null }
      { canRequestSpecificNurse ? (
        <Link color='secondary' onClick={() => openZendesk()} sx={{ cursor: 'pointer' }} >
          If you want to request a specific nurse, click here to chat to us, we can arrange that for you.
        </Link>
      ) : null }
    </React.Fragment>
  )
}

export default JobForm