import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminPracticeCancellations: React.FC = () => {
  const {
    cancellationsSearchForm,
    cancellationsSelection,
    cancellationsColumnVisibilityModel,
    cancellationsSortModel,
    updateCancellationsSearchForm,
    setCancellationsSelection,
    setCancellationsColumnVisibilityModel,
    setCancellationsSortModel,
  } = React.useContext(AdminPracticeDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      exclude_filters={[
        'practice',
        'practice_group',
      ]}
      onRowClick={onRowClick}
      form={cancellationsSearchForm}
      updateForm={updateCancellationsSearchForm}
      selection={cancellationsSelection}
      setSelection={setCancellationsSelection}
      columnVisibilityModel={cancellationsColumnVisibilityModel}
      setColumnVisibilityModel={setCancellationsColumnVisibilityModel}
      sortModel={cancellationsSortModel}
      setSortModel={setCancellationsSortModel}
    />
  )
}

export default AdminPracticeCancellations