import React from 'react'
import { useAuthUser } from './AuthUserProvider'
import { useAPI } from './APIProvider'
import { useLogRocket } from './LogRocketProvider'

const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_API_KEY || ''

interface IZendeskContext {
  openZendesk: () => void
  closeZendesk: () => void
}

const ZendeskContext = React.createContext<IZendeskContext>({} as IZendeskContext)

const ZendeskProvider: React.FC = ({ children }) => {
  const { authUser$ } = useAuthUser()
  const { api } = useAPI()
  const { logRocketURL } = useLogRocket()

  const openZendesk = React.useCallback(async () => {
    if (!ZENDESK_API_KEY) return
    window.zE('messenger', 'open');
  }, [])

  const closeZendesk = React.useCallback(async () => {
    if (!ZENDESK_API_KEY) return
    window.zE('messenger', 'close');
  }, [])

  const loginZendesk = React.useCallback(async () => {
    if (!ZENDESK_API_KEY) return
    window.zE('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        const token = await api.zendeskLogin()
        callback(token)
      } catch (error) {
        console.error('Error logging in to Zendesk', error)
      }
    })
  }, [api])

  const logoutZendesk = React.useCallback(async () => {
    if (!ZENDESK_API_KEY) return
    window.zE('messenger', 'logoutUser')
  }, [])

  React.useEffect(() => {
    if (!ZENDESK_API_KEY) return
    const script = document.createElement('script');
    script.setAttribute('id', 'ze-snippet')
    script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`)
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script)
    }
  }, []);


  React.useEffect(() => {
    if (!ZENDESK_API_KEY) return
    const sub = authUser$.subscribe(user => {
      if (user) {
        loginZendesk()
      } else if (user === null) {
        logoutZendesk()
      }
    })
    return () => sub.unsubscribe()
  }, [authUser$])

  React.useEffect(() => {
    if (!ZENDESK_API_KEY) return
    if (!logRocketURL) return
    try {
      window.zE('messenger:set', 'conversationFields', [{ 
        id: '14172410660892', 
        value: logRocketURL,
      }])
    } catch (error) {
      console.error('Error setting conversation field', error)
    }
  }, [logRocketURL])

  const context = {
    openZendesk,
    closeZendesk
  }
  return <ZendeskContext.Provider value={context}>{children}</ZendeskContext.Provider>
}

function useZendesk() {
  const context = React.useContext(ZendeskContext)
  if (context === undefined) {
    throw new Error('useZendesk must be used within a ZendeskProvider')
  }
  return context
}

export { ZendeskProvider, useZendesk }