import React from 'react'
import Alert from '@mui/material/Alert'
import ErrorList from './ErrorList'
import { isEmpty } from 'lodash'

interface ErrorAlertProps {
  errors?: string[]
  variant?: "filled" | "standard" | "outlined"
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ errors = [], variant = 'filled' }) => {
  if (isEmpty(errors)) {
    return null
  }

  return (
    <Alert severity='error' variant={variant}>
      <ErrorList errors={errors} />
    </Alert>
  )
}

export default ErrorAlert