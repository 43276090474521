import { Alert, Box, MenuItem } from '@mui/material'
import FormField from 'components/FormField'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { FILE_LABEL_MAPPING } from 'types/constants'
import Loading from 'components/Loading'
import useNurseDocuments, { Selection } from 'hooks/useNurseDocuments'
import { useParams } from 'react-router'

const NurseDocumentsPage: React.FC = () => {
  const params = useParams()
  const {selection, setSelection, selectedFiles, loading} = useNurseDocuments(Number(params.nurseId))

  if (loading) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormField select value={selection} onChange={(e) => setSelection(e.target.value as Selection)}>
        <MenuItem value='gdc'>
          {get(FILE_LABEL_MAPPING, 'gdc')}
        </MenuItem>
        <MenuItem value='indemnity_insurance'>
          {get(FILE_LABEL_MAPPING, 'indemnity_insurance')}
        </MenuItem>
        <MenuItem value='immunizations'>
          {get(FILE_LABEL_MAPPING, 'immunizations')}
        </MenuItem>
        <MenuItem value='dbs'>
          {get(FILE_LABEL_MAPPING, 'dbs')}
        </MenuItem>
        <MenuItem value='infection_control'>
          {get(FILE_LABEL_MAPPING, 'infection_control')}
        </MenuItem>
        <MenuItem value='cpr'>
          {get(FILE_LABEL_MAPPING, 'cpr')}
        </MenuItem>
        <MenuItem value='right-to-work'>
          Right To Work
        </MenuItem>
      </FormField>
      {selectedFiles.map((file) => (
        file.content_type === 'application/pdf' ? (
          <Box key={file.id} component='embed' src={file.url} sx={{ height: '75vh' }} />
        ) : (
          <Box key={file.id} component='img' src={file.url} onContextMenu={(e: any) => e.preventDefault()} />
        )
      ))}
      {isEmpty(selectedFiles) ? (
        <Alert severity='info' variant='filled'>
          The nurse has not provided this document.
        </Alert>
      ) : null}
    </Box>
  )
}

export default NurseDocumentsPage