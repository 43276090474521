import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NurseProfileError from 'errors/NurseProfileError'
import React from 'react'
import { NurseProfileForm } from 'types/interfaces'
import update from 'immutability-helper'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import FormField from 'components/FormField'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context';

interface AccountSuspensionProps {
  open: boolean
  setOpen: (value: boolean) => void
}

const AccountSuspension: React.FC<AccountSuspensionProps> = ({ open, setOpen }) => {
  const { nurse, fetchNurse } = React.useContext(AdminNurseDetailContext)
  
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<NurseProfileForm>({})
  const [error, setError] = React.useState<NurseProfileError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const submit = async () => {
    if (!nurse) return
    try {
      setLoading(true)
      await api.editNurse(form, nurse.nurse_id)
      await fetchNurse()
      setOpen(false)
      showAlert('success', "Nurse updated successfully")
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error updating nurse')
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!nurse) return
    if (!isEmpty(form)) return
    setForm(update(form, {
      suspended_until: { $set: nurse.suspended_until?.toFormat('yyyy-LL-dd') },
      penalty_note: { $set: nurse.penalty_note ?? '' },
    }))
  }, [nurse])
  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Account Suspension</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormField
          type='date'
          InputLabelProps={{
            shrink: true,
          }}
          label='Suspended Until'
          value={form.suspended_until ?? ''}
          onChange={(e) => updateForm('suspended_until', e.target.value || null)}
          errors={error?.suspended_until}
        />
        <FormField 
          multiline
          minRows={4}
          label='Reason for Suspension (This will be displayed to the nurse)'
          value={form.penalty_note ?? ''}
          onChange={(e) => updateForm('penalty_note', e.target.value)}
          errors={error?.penalty_note}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={submit} disabled={loading}>
          { loading ? 'Please Wait ...' : 'Suspend Account' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AccountSuspension