import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface HeaderProp {
  text: string
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  children?: React.ReactNode
}

const Header: React.FC<HeaderProp> = ({ text, variant = 'h1', children }) => {
  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderBottom: 'thin solid black',
    }}>
      <Typography variant={variant} component='div' sx={{ flexGrow: 1 }}>{text}</Typography>
      {children}
    </Box>
  )
}

export default Header
