import React from 'react'
import { Notification } from 'logic/Notification'
import { Subscription } from 'rxjs'
import { useNurse } from 'contexts/NurseProvider'
import { useFirebase } from 'contexts/FirebaseProvider'
import { collection, query, where, writeBatch, getDocs } from "firebase/firestore";
import NotificationDisplay from './Display'

const NurseNotificationList: React.FC = () => {
  const { firestore } = useFirebase()
  const { nurse, notificationList$ } = useNurse()
  const [notifications, setNotifications] = React.useState<Notification[]>([])

  React.useEffect(() => {
    const sub = new Subscription()
    sub.add(notificationList$.subscribe(setNotifications))
    return () => sub.unsubscribe()
  }, [notificationList$])

  async function markRead() {
    const notifications = collection(firestore, 'notifications')
    const docs = await getDocs(query(notifications,
      where("nurse_id", "==", nurse?.nurse_id),
      where("unread_by_nurse", "==", true),
    ))
    const batch = writeBatch(firestore)
    docs.forEach(doc => {
      batch.update(doc.ref, { unread_by_nurse: false })
    })
    batch.commit()
  }

  const tz = nurse?.tz ?? 'Europe/London'
  const isUnread = (notification: Notification) => notification.unread_by_nurse
  const getMessage = (notification: Notification) => notification.nurse_message

  return <NotificationDisplay
    notifications={notifications}
    markRead={markRead}
    getMessage={getMessage}
    tz={tz}
    isUnread={isUnread}
  />
}

export default NurseNotificationList