import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import NurseDocumentFile from 'logic/NurseDocumentFile'
import { getFileTypeFromDocumentType } from 'logic/helpers'
import { DocumentType } from 'types/types'
import { VerifyDocumentForm } from 'types/interfaces'
import VerifyDocumentError from 'errors/VerifyDocumentError'
import update from 'immutability-helper'
import { getComplianceFromDocumentType } from 'components/DocumentUpload'
import JobEmployment from 'logic/JobEmployment'
import { DateTime } from 'luxon'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import { last } from 'lodash'
import { useParams } from 'react-router'

export default function useDocumentVerify() {
    const { nurse, fetchNurse } = React.useContext(AdminNurseDetailContext)
    const { api } = useAPI()
    const params = useParams()
    const { showAlert } = useSnackBarAlert()
    const [files, setFiles] = React.useState<NurseDocumentFile[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [deleting, setDeleting] = React.useState<boolean>(false)
    const [form, setForm] = React.useState<VerifyDocumentForm>({})
    const [error, setError] = React.useState<VerifyDocumentError>()
    const [futureBookings, setFutureBookings] = React.useState<JobEmployment[]>([])

    const updateForm = React.useCallback((name: string, value: any) => {
        setForm(update(form, { [name]: { $set: value } }))
        if (error) setError(update(error, { [name]: { $set: [] } }))
    }, [error, form])

    const docType = React.useMemo(() => last(window.location.pathname.split('/')) as DocumentType, [params.docType]);
    const fileType = React.useMemo(() => getFileTypeFromDocumentType(docType), [docType])
    const compliance = React.useMemo(() => getComplianceFromDocumentType(docType, nurse), [docType, nurse])

    const fetchFutureBookings = React.useCallback(async () => {
        setFutureBookings(await api.listJobEmployments({ 
            nurse_id: nurse.nurse_id, 
            fulfillment_statuses: ['scheduled'],
            from_date: DateTime.now().toFormat('yyyy-LL-dd')
        }))
    }, [api, nurse.nurse_id])

    const fetchFiles = React.useCallback(async () => {
        try {
            setLoading(true)
            setFiles(await api.listDocuments({ nurse_id: nurse.nurse_id, doc_type: fileType }))
        } catch (e) {
            showAlert('error', 'Error fetching files')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [api, fileType, nurse.nurse_id, showAlert])

    const deleteFile = React.useCallback(async (fileId: number) => {
        try {
            setDeleting(true)
            await api.deleteDocument(fileId)
            await fetchFiles()
            await fetchNurse()
        } catch (e) {
            showAlert('error', 'Error deleting file')
            console.error(e)
        } finally {
            setDeleting(false)
        }
    }, [api, fetchFiles, fetchNurse, showAlert])

    const scrollToFile = React.useCallback((fileId: number) => {
        window.document.getElementById(`file-${fileId}`)?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    const save = React.useCallback(async () => {
        try {
            setLoading(true)
            await api.verifyDocument(nurse.nurse_id, form)
            await fetchFiles()
            await fetchNurse()
        } catch (e) {
            if (e instanceof VerifyDocumentError) {
                setError(e)
            } else {
                console.error('uncaught exception', e)
            }
        } finally {
            setLoading(false)
        }
    }, [api, fetchFiles, fetchNurse, form, nurse.nurse_id])

    React.useEffect(() => {
        fetchFiles()
    }, [fetchFiles])

    React.useEffect(() => {
        fetchFutureBookings()
    }, [fetchFutureBookings])

    React.useEffect(() => {
        setForm({
            doc_type: docType,
            date: compliance.date,
            reason: compliance.reason,
            approved: compliance.approved,
        })
    }, [compliance, docType])

    return {
        docType,
        fileType,
        compliance,
        nurse,
        files,
        form,
        updateForm,
        error,
        loading,
        deleting,
        save,
        deleteFile,
        scrollToFile,
        futureBookings,
        fetchFiles,
        fetchNurse,
        setLoading,
    }
}