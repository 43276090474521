import { Box, Button, Paper } from '@mui/material'
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import { BillingAccountForm } from 'types/interfaces'

interface SetupCardProps {
    clientSecret: string
    form: BillingAccountForm
    onSuccess: () => void
    buttonText?: string
}

const SetupCard: React.FC<SetupCardProps> = ({ clientSecret, form, onSuccess, buttonText = 'Save Card' }) => {
    const { showAlert } = useSnackBarAlert()
    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = React.useState<boolean>(false)

    const save = React.useCallback(async () => {
        try {
            setLoading(true)
            const card = elements?.getElement(CardElement) || null
            if (!card || !stripe) return
            const payload = {
                payment_method: {
                    card: card,
                    billing_details: {
                        name: form.name,
                        email: form.email,
                    }
                }
            }
            const result = await stripe.confirmCardSetup(clientSecret, payload)
            if (result.error !== undefined) {
                showAlert('error', result.error.message)
                return
            }

            // redirect to billing page.
            showAlert('success', 'Card added successfully')
            onSuccess()
        } catch (e) {
            showAlert('error', 'Error adding card')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [clientSecret, elements, form.email, form.name, onSuccess, showAlert, stripe])

    const stripeCardOptions = {
        hidePostalCode: true,
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Paper sx={{ padding: 2 }}>
                <CardElement options={stripeCardOptions} />
            </Paper>
            <Button variant="contained" color="primary" onClick={save} disabled={loading}>
                {loading ? 'Please Wait ...' : buttonText}
            </Button>
        </Box>
    )
}

export default SetupCard