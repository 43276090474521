import React from 'react'
import { useAPI } from './APIProvider'
import { useFirebase } from './FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { useParams } from 'react-router'
import { filter, map, switchMap, from, share, Observable, Subscription } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import Job from 'logic/Job'
import { DateTime } from 'luxon';

interface IJobContext {
  job?: Job
  jobId: number
  reloadJob: () => Promise<void>
  notification$?: Observable<Notification>
}

const JobContext = React.createContext<IJobContext>({} as IJobContext)

const JobProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { api } = useAPI()
  const { firestore } = useFirebase()
  const [job, setJob] = React.useState<Job>()

  const jobId = React.useMemo(() => Number(params.jobId), [params.jobId])
  
  const reloadJob = React.useCallback(async () => {
    try {
      console.log('JobProvider: fetchJob')
      setJob(await api.getJob(jobId))
    } catch (e) {
      console.error(e)
      setJob(undefined)  // use null?
    }
  }, [api, jobId])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("job_id", "==", jobId)
    ),
    [jobId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ),
    [notificationQuery]
  )

  React.useEffect(() => {
    reloadJob()
  }, [reloadJob])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const reloadEvents = [
      'job_cancelled',
      'job_taken',
      'job_updated',
      'job_employment_cancelled',
      'timesheet_change_proposed',
      'timesheet_change_updated',
      'timesheet_change_deleted',
      'timesheet_change_approved',
      'timesheet_change_disapproved',
      'payment_requested',
      'payment_success',
      'payment_failed',
    ]
    const reload$ = notification$.pipe(
      filter(notification => reloadEvents.includes(notification.event)),
      filter(notification => notification.isAfter(timestamp)),
    )
    const sub = new Subscription()
    sub.add(reload$.subscribe(notif => notif.log()))
    sub.add(reload$.subscribe(reloadJob))
    return () => sub.unsubscribe()
  }, [notification$, reloadJob])

  const value = { job, jobId, reloadJob, notification$}
  return (
    <JobContext.Provider value={value}>
      {children}
    </JobContext.Provider>
  )
}

function useJob() {
  return React.useContext(JobContext)
}

export { JobProvider, useJob }