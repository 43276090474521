import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminJobDetailContext from 'pages/AdminJobDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminJobMatches: React.FC = () => {
  const {
    matchSearchForm,
    matchSelection,
    matchColumnVisibilityModel,
    matchSortModel,
    updateMatchSearchForm,
    setMatchSelection,
    setMatchColumnVisibilityModel,
    setMatchSortModel,
  } = React.useContext(AdminJobDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      exclude_filters={[
        'practice_group', 
        'practice'
      ]}
      onRowClick={onRowClick}
      form={matchSearchForm}
      updateForm={updateMatchSearchForm}
      selection={matchSelection}
      setSelection={setMatchSelection}
      columnVisibilityModel={matchColumnVisibilityModel}
      setColumnVisibilityModel={setMatchColumnVisibilityModel}
      sortModel={matchSortModel}
      setSortModel={setMatchSortModel}
    />
  )
}

export default AdminJobMatches