import { Box, Button, Paper, Typography } from '@mui/material'
import Check from '@mui/icons-material/Check'
import Error from '@mui/icons-material/Error'
import PendingActions from '@mui/icons-material/PendingActions'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { Observable, filter, startWith } from 'rxjs'
import { Notification } from 'logic/Notification'
import Nurse from 'logic/Nurse'
import { DateTime } from 'luxon'
import FormField from 'components/FormField'
import { trim } from 'lodash'

interface GDCRegistrationProps {
  nurseId: number
  notification$: Observable<Notification>
}

const GDCRegistration: React.FC<GDCRegistrationProps> = ({ nurseId, notification$ }) => {
  const { api } = useAPI()
  const [nurse, setNurse] = React.useState<Nurse>()
  const [gdcNumber, setGDCNumber] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchNurse = React.useCallback(async () => {
    setNurse(await api.getNurse(nurseId))
    setLoading(false)
  }, [api, nurseId])

  const save = React.useCallback(async () => {
    try {
      setLoading(true)
      await api.editNurse({ gdc_number: gdcNumber }, nurseId)
    } catch (e) {
      console.error(e)
    } finally {
      // not setLoading(false) because the nurse_updated notification with status refresh may take a while
      // setLoading(false) in fetchNurse instead.
    }
  }, [api, gdcNumber, nurseId])

  React.useEffect(() => {
    const timestamp = DateTime.now()
    const sub = notification$.pipe(
      filter(notif => ['nurse_updated'].includes(notif.event)),
      filter(notif => notif.isAfter(timestamp)),
      startWith(true),
    ).subscribe(fetchNurse)
    return () => sub.unsubscribe()
  }, [fetchNurse, notification$])

  const canInput = React.useMemo(() => {
    return nurse?.gdc_status === 'missing' || nurse?.gdc_status === 'disapproved'
  }, [nurse?.gdc_status])

  if (!nurse) return null

  let title = ''
  let backgroundColor = ''
  let body
  let icon

  if (nurse.gdc_status === 'approved') {
    title = "GDC Registration Verified"
    body = <Typography variant='body2'>Your number {nurse.gdc_number} has been located on the GDC Register</Typography>
    backgroundColor = "success.light"
    icon = <Check fontSize='large' sx={{ color: 'success.main' }} />
  } else if (nurse.gdc_status === 'disapproved') {
    title = "Failed to verify your GDC Number"
    body = <Typography variant='body2'>{nurse.gdc_reason}</Typography>
    backgroundColor = "error.light"
    icon = <Error fontSize='large' sx={{ color: 'error.main' }} />
  } else if (nurse.gdc_status === 'pending_verify') {
    title = `Our team member will verify your GDC Registration and notify you when ready`
    body = <Typography variant='body2'>{nurse.gdc_reason}</Typography>
    backgroundColor = 'warning.light'
    icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
  } else { // missing
    title = `Please enter your GDC Number`
    backgroundColor = 'background.paper'
    icon = <PendingActions fontSize='large' sx={{ color: 'black' }} />
  }

  return (
    <Paper sx={{
      padding: 1,
      display: 'grid',
      gridTemplateColumns: icon ? ['1fr 4fr', '1fr 9fr'] : '0 1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
        "icon title"
        "icon content"`,
      backgroundColor: backgroundColor,
    }}>
      <Box sx={{ gridArea: 'icon', justifySelf: 'center', alignSelf: 'center' }}>
        {icon}
      </Box>
      <Typography sx={{ gridArea: 'title' }} variant='h5'>{title}</Typography>
      <Box sx={{ gridArea: 'content' }}>
        {body}
        {canInput ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FormField label='GDC Number' value={gdcNumber} onChange={e => setGDCNumber(trim(e.target.value))} />
            <Button size='small' variant='contained' disabled={loading} onClick={save}>
            { loading ? 'Please Wait ...' : 'Submit' }
            </Button>
          </Box>
        ) : null}
      </Box>
    </Paper>
  )
}

export default GDCRegistration