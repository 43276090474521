import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import { JobSearchForm, JobEmploymentSearchForm, NurseSearchForm, ChartSearchForm, UserSearchForm } from 'types/interfaces'
import Practice from 'logic/Practice'
import React from 'react'

interface IAdminPracticeDetailContext {
  practice: Practice
  fetchPractice: () => Promise<void>

  // job tab
  jobSearchForm: JobSearchForm
  jobSelection: GridRowId[]
  jobColumnVisibilityModel: GridColumnVisibilityModel
  jobSortModel: GridSortModel
  updateJobSearchForm: (name: string, value: any) => void
  setJobSelection: (value: GridRowId[]) => void
  setJobColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setJobSortModel: (value: GridSortModel) => void

  // matches tab
  matchesSearchForm: JobEmploymentSearchForm
  matchesSelection: GridRowId[]
  matchesColumnVisibilityModel: GridColumnVisibilityModel
  matchesSortModel: GridSortModel
  updateMatchesSearchForm: (name: string, value: any) => void
  setMatchesSelection: (value: GridRowId[]) => void
  setMatchesColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setMatchesSortModel: (value: GridSortModel) => void

  // reviews tab
  reviewsSearchForm: JobEmploymentSearchForm
  reviewsSelection: GridRowId[]
  reviewsColumnVisibilityModel: GridColumnVisibilityModel
  reviewsSortModel: GridSortModel
  updateReviewsSearchForm: (name: string, value: any) => void
  setReviewsSelection: (value: GridRowId[]) => void
  setReviewsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setReviewsSortModel: (value: GridSortModel) => void

  // cancellations tab
  cancellationsSearchForm: JobEmploymentSearchForm
  cancellationsSelection: GridRowId[]
  cancellationsColumnVisibilityModel: GridColumnVisibilityModel
  cancellationsSortModel: GridSortModel
  updateCancellationsSearchForm: (name: string, value: any) => void
  setCancellationsSelection: (value: GridRowId[]) => void
  setCancellationsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setCancellationsSortModel: (value: GridSortModel) => void

  // blacklists tab
  blacklistsSearchForm: JobEmploymentSearchForm
  blacklistsSelection: GridRowId[]
  blacklistsColumnVisibilityModel: GridColumnVisibilityModel
  blacklistsSortModel: GridSortModel
  updateBlacklistsSearchForm: (name: string, value: any) => void
  setBlacklistsSelection: (value: GridRowId[]) => void
  setBlacklistsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setBlacklistsSortModel: (value: GridSortModel) => void

  // nearby nurses tab
  nearbyNurseSearchForm: NurseSearchForm
  nearbyNurseSelection: GridRowId[]
  nearbyNurseColumnVisibilityModel: GridColumnVisibilityModel
  nearbyNurseSortModel: GridSortModel
  updateNearbyNurseSearchForm: (name: string, value: any) => void
  setNearbyNurseSelection: (value: GridRowId[]) => void
  setNearbyNurseColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setNearbyNurseSortModel: (value: GridSortModel) => void

  // members tab
  userSearchForm: UserSearchForm
  userSelection: GridRowId[]
  userColumnVisibilityModel: GridColumnVisibilityModel
  userSortModel: GridSortModel
  updateUserSearchForm: (name: string, value: any) => void
  setUserSelection: (value: GridRowId[]) => void
  setUserColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setUserSortModel: (value: GridSortModel) => void

  // charts tab
  chartsSearchForm: ChartSearchForm
  updateChartsSearchForm: (name: string, value: any) => void
}

const AdminPracticeDetailContext = React.createContext<IAdminPracticeDetailContext>({} as IAdminPracticeDetailContext)

export default AdminPracticeDetailContext