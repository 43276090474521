import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Header from 'components/Header'
import { useNurse } from 'contexts/NurseProvider'
import DescriptionList from 'components/DescriptionList'
import DocumentUpload from 'components/DocumentUpload'
import { nurseProfileEditURL } from 'routes/urls'
import { useSearchParams } from 'react-router-dom'
import RightToWork from 'components/RightToWork'
import GDCRegistration from 'components/GDCRegistration'

const NurseProfile: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { nurse, notification$ } = useNurse()
  const extendedComplianceRef = useRef<HTMLElement>(null)
  const hygienistRef = useRef<HTMLElement>(null)
  const rightToWorkRef = useRef<HTMLElement>(null)
  const scrollTo = React.useMemo(() => searchParams.get('scrollTo'), [searchParams])

  React.useEffect(() => {
    if (scrollTo === 'extended-compliance') {
      extendedComplianceRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (scrollTo === 'right-to-work') {
      rightToWorkRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (scrollTo === 'hygienist') {
      hygienistRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollTo])

  if (!nurse) return null

  const basicInfo = [{
    label: 'First Name',
    value: nurse.first_name
  }, {
    label: 'Last Name',
    value: nurse.last_name
  }, {
    label: 'Address',
    value: nurse.address
  }, {
    label: 'Phone Number',
    value: nurse.phone_number
  }, {
    label: 'GDC Number',
    value: nurse.gdc_number
  }, {
    label: 'Travel Distance',
    value: `${nurse.radius_miles} miles`
  }]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '150vh' }}>
      <Header variant='h1' text='Profile Information'></Header>
      <Paper sx={{ padding: 1 }}>
        <DescriptionList data={basicInfo}></DescriptionList>
      </Paper>
      <Button
        variant="contained" color="primary"
        component="a" href={nurseProfileEditURL(nurse.nurse_id)}>
        Edit Profile
      </Button>
      <Header variant='h1' text='Documents'></Header>
      <GDCRegistration nurseId={nurse.nurse_id} notification$={notification$} />
      <DocumentUpload nurseId={nurse.nurse_id} fileType='indemnity_insurance' />
      <DocumentUpload nurseId={nurse.nurse_id} fileType='immunizations' />
      <DocumentUpload nurseId={nurse.nurse_id} fileType='dbs' />
      <Box ref={extendedComplianceRef} />
      <Header variant='h1' text='Extended Compliance'></Header>
      <DocumentUpload nurseId={nurse.nurse_id} fileType='cpr' />
      <DocumentUpload nurseId={nurse.nurse_id} fileType='infection_control' />
      <Box ref={hygienistRef} />
      <Header variant='h1' text='Hygienist'></Header>
      <DocumentUpload nurseId={nurse.nurse_id} fileType='resume' />
      <Box ref={rightToWorkRef} />
      <Header variant='h1' text='Right to Work in the UK'></Header>
      <RightToWork nurseId={nurse.nurse_id} notification$={notification$} />
    </Box>
  )
}

export default NurseProfile