import { useAPI } from 'contexts/APIProvider'
import { useFirebase } from 'contexts/FirebaseProvider'
import { v4 as uuidv4 } from 'uuid'
import { ref, uploadBytes, uploadString, StringFormat, getDownloadURL } from "firebase/storage"
import NurseDocumentFile from 'logic/NurseDocumentFile'
import React from 'react'
import { DocumentFileType } from 'types/types'
import Nurse from 'logic/Nurse'
import { Box, Button, Chip, Modal } from '@mui/material'
import Loading from 'components/Loading'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import FileUpload from './FileUpload'
import CameraUpload from './CameraUpload'

type ControlProps = {
    nurse: Nurse
    fileType: DocumentFileType
    file?: NurseDocumentFile
    fileCount: number
    onSuccess: () => Promise<void>
    onError: (error: string) => void
}

const Control: React.FC<ControlProps> = ({ nurse, fileType, file, fileCount, onSuccess, onError }) => {
    const { storage } = useFirebase()
    const { api } = useAPI()
    const [fileKey, setFileKey] = React.useState<string>()
    const [fileContentType, setFileContentTyle] = React.useState<string>()
    const [fileUrl, setFileUrl] = React.useState<string>()
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [uploading, setUploading] = React.useState<boolean>(false)
    const [deleting, setDeleting] = React.useState<boolean>(false)

    const sampleURL = React.useMemo(() => {
        return `/sample_documents/${fileType}.png`
    }, [fileType])

    const closeModal = React.useCallback(() => {
        setShowModal(false)
    }, [])

    const resetForm = React.useCallback(() => {
        setFileKey('')
        setFileContentTyle('')
        setFileUrl('')
    }, [])

    const isUploaded = React.useMemo(() => {
        return fileKey && fileContentType && fileUrl
    }, [fileKey, fileContentType, fileUrl])

    const upload = React.useCallback(async (data: Blob | Uint8Array | ArrayBuffer | string, contentType: string) => {
        // upload to firebase storage
        try {
            setUploading(true)
            const key = uuidv4()
            const fileRef = ref(storage, `${fileType}/${nurse.nurse_id}/${key}`)
            const metadata = {
                contentType: contentType,
                cacheControl: 'no-store',
            }
            if (typeof data === 'string') {
                await uploadString(fileRef, data, StringFormat.DATA_URL, metadata)
            } else {
                await uploadBytes(fileRef, data, metadata)
            }
            setFileKey(key)
            setFileContentTyle(contentType)
            setFileUrl(await getDownloadURL(fileRef))
        } catch (e: any) {
            onError('Error uploading file')
            console.error(e)
            resetForm()
            closeModal()
        } finally {
            setUploading(false)
        }
    }, [closeModal, fileType, nurse.nurse_id, onError, resetForm, storage])

    const confirm = React.useCallback(async () => {
        try {
            setUploading(true)
            await api.createDocument({
                nurse_id: nurse.nurse_id,
                doc_type: fileType,
                key: fileKey,
                page_num: fileCount + 1,
                content_type: fileContentType,
            })
            await onSuccess()
        } catch (e: any) {
            onError('Error creating document')
            console.error(e)
        } finally {
            setUploading(false)
            resetForm()
            closeModal()
        }
    }, [api, closeModal, fileContentType, fileCount, fileKey, fileType, nurse.nurse_id, onError, onSuccess, resetForm])

    const deleteDocument = React.useCallback(async () => {
        if (!file) return
        try {
            setDeleting(true)
            await api.deleteDocument(file.id)
            await onSuccess()
        } catch (e: any) {
            onError('Failed to delete document')
            console.error(e)
        } finally {
            setDeleting(false)
        }
    }, [api, file, onError, onSuccess])

    return (
        <React.Fragment>
            {file ? (
                <Chip
                    label={deleting ? 'Deleting...' : `Page ${file.page_num}`}
                    size="small"
                    variant="outlined"
                    icon={<DeleteIcon />}
                    onClick={deleteDocument}
                    disabled={deleting}
                />
            ) : (
                <Chip
                    label='Add Page'
                    size="small"
                    icon={<AddIcon />}
                    onClick={() => setShowModal(true)}
                />
            )}
            <Modal
                open={showModal}
                onClose={closeModal}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 1,
                }}>
                    {fileUrl ? fileContentType === 'application/pdf' ? (
                        <Box component='embed' src={fileUrl} sx={{
                            width: [350, 600],
                            height: [500, 700],
                        }} />
                    ) : (
                        <Box component='img' src={fileUrl} sx={{
                            maxWidth: [350, 600],
                            maxHeight: [500, 700],
                        }} />
                    ) : (
                        <Box component='img' src={sampleURL} sx={{
                            maxWidth: [350, 600],
                            maxHeight: [500, 700],
                        }} />
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                        {uploading ? (
                            <Loading />
                        ) : isUploaded ? (
                            <React.Fragment>
                                <Button variant='contained' onClick={confirm}>Confirm</Button>
                                <Button variant='contained' onClick={resetForm}>Retry Upload</Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FileUpload fileType={fileType} save={upload} />
                                <CameraUpload fileType={fileType} save={upload} />
                            </React.Fragment>
                        )}
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default Control