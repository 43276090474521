import React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PeopleIcon from '@mui/icons-material/People';
import BadgeIcon from '@mui/icons-material/Badge';
import {
  nurseJobsURL,
  nurseBillingURL,
  nurseAchievementsURL,
  nurseDocumentsPageURL,
  nurseAffiliatePageURL,
} from 'routes/urls'
import { useNurse } from 'contexts/NurseProvider';
import { useMatch, useNavigate } from 'react-router';

type Actions = 'jobs' | 'achievements' | 'payments' | 'affiliates' | 'documents'

const NurseBottomNav: React.FC = () => {
  const [action, setAction] = React.useState<Actions | null>('jobs');
  const { nurse } = useNurse()
  const navigate = useNavigate()

  const match_nurse_page_jobs = useMatch({path: '/nurse/:nurseId/jobs', end: false})
  const match_nurse_page_achievements = useMatch({path: '/nurse/:nurseId/achievements', end: false})
  const match_nurse_page_billing = useMatch({path: '/nurse/:nurseId/billing', end: false})
  const match_nurse_page_affiliate = useMatch({path: '/nurse/:nurseId/affiliate', end: false})
  const match_nurse_page_documents = useMatch({path: '/nurse/:nurseId/documents', end: false})

  const onClick = React.useCallback((event: React.SyntheticEvent, selectedAction: Actions) => {
    if (!nurse) return
    setAction(selectedAction);
    if (selectedAction === 'jobs') {
      navigate(nurseJobsURL(nurse.nurse_id))
    } else if (selectedAction === 'payments') {
      navigate(nurseBillingURL(nurse.nurse_id))
    } else if (selectedAction === 'achievements') {
      navigate(nurseAchievementsURL(nurse.nurse_id))
    } else if (selectedAction === 'affiliates' && nurse.is_affiliate) {
      navigate(nurseAffiliatePageURL(nurse.nurse_id, nurse.affiliate_id))
    } else if (selectedAction === 'documents') {
      navigate(nurseDocumentsPageURL(nurse.nurse_id))
    }
  }, [navigate, nurse])

  React.useEffect(() => {
    if (match_nurse_page_jobs) {
      setAction('jobs')
    } else if (match_nurse_page_billing) {
      setAction('payments')
    } else if (match_nurse_page_affiliate) {
      setAction('affiliates')
    } else if (match_nurse_page_achievements) {
      setAction('achievements')
    } else if (match_nurse_page_documents) {
      setAction('documents')
    } else {
      setAction(null)
    }
  }, [match_nurse_page_achievements, match_nurse_page_affiliate, match_nurse_page_billing, match_nurse_page_documents, match_nurse_page_jobs])

  if (!nurse) return null
  
  
  return (
    <BottomNavigation 
      sx={{ 
        justifyContent: 'space-evenly', 
        paddingRight: ['70px', 0] // allow space for chat button on mobile
      }}
      value={action}
      onChange={onClick}>
      <BottomNavigationAction label="Jobs" value="jobs" icon={<CalendarMonthIcon />} />
      <BottomNavigationAction label="Documents" value="documents" icon={<BadgeIcon />} />
      <BottomNavigationAction label="Achievements" value="achievements" icon={<EmojiEventsIcon />} />
      <BottomNavigationAction label="Payments" value="payments" icon={<CurrencyPoundIcon />} />
      {nurse.is_affiliate ? (
        <BottomNavigationAction label="Affiliates" value="affiliates" icon={<PeopleIcon />} />
      ) : null}
    </BottomNavigation>
  )
}

export default NurseBottomNav