import { Box } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import AdminAffiliateDetailContext from 'pages/AdminAffiliateDetail/context'
import React from 'react'

const AdminAffiliateProfile: React.FC = () => {
  const { affiliate } = React.useContext(AdminAffiliateDetailContext)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
        label: 'ID',
        value: affiliate.id.toString(),
      }, {
        label: 'Name',
        value: affiliate.name,
      }, {
        label: 'Code',
        value: affiliate.code,
      }, {
        label: 'Email',
        value: affiliate.user_email,
      }, {
        label: 'Campaign',
        value: affiliate.campaign_name,
      }, {
        label: 'Nurse ID',
        value: affiliate.nurse_id?.toString() ?? 'N/A'
      }, {
        label: 'Stripe ID',
        value: affiliate.stripe_user_id || 'N/A'
      }, {
        label: 'Payouts Enabled',
        value: affiliate.payouts_enabled ? 'Yes' : 'No',
      }]} />
    </Box>
  )
}

export default AdminAffiliateProfile