import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid'
import { AffiliateTransactionSearchForm, PracticeGroupSearchForm, PracticeSearchForm } from 'types/interfaces'
import React from 'react'
import Affiliate from 'logic/Affiliate'

interface IAdminAffiliateDetailContext {
  affiliate: Affiliate
  fetchAffiliate: () => Promise<void>

  // practice groups tab
  practiceGroupSearchForm: PracticeGroupSearchForm
  practiceGroupSelection: GridRowId[]
  practiceGroupColumnVisibilityModel: GridColumnVisibilityModel
  practiceGroupSortModel: GridSortModel
  updatePracticeGroupSearchForm: (name: string, value: any) => void
  setPracticeGroupSelection: (value: GridRowId[]) => void
  setPracticeGroupColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setPracticeGroupSortModel: (value: GridSortModel) => void

  // practices tab
  practiceSearchForm: PracticeSearchForm
  practiceSelection: GridRowId[]
  practiceColumnVisibilityModel: GridColumnVisibilityModel
  practiceSortModel: GridSortModel
  updatePracticeSearchForm: (name: string, value: any) => void
  setPracticeSelection: (value: GridRowId[]) => void
  setPracticeColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setPracticeSortModel: (value: GridSortModel) => void

  // transactions tab
  transactionsSearchForm: AffiliateTransactionSearchForm
  transactionsSelection: GridRowId[]
  transactionsColumnVisibilityModel: GridColumnVisibilityModel
  transactionsSortModel: GridSortModel
  updateTransactionsSearchForm: (name: string, value: any) => void
  setTransactionsSelection: (value: GridRowId[]) => void
  setTransactionsColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  setTransactionsSortModel: (value: GridSortModel) => void
}

const AdminAffiliateDetailContext = React.createContext<IAdminAffiliateDetailContext>({} as IAdminAffiliateDetailContext)

export default AdminAffiliateDetailContext