import Practice from 'logic/Practice'
import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
  GridValueGetterParams
} from '@mui/x-data-grid-pro';
import FormField from 'components/FormField'
import { isEmpty, uniqBy } from 'lodash';
import { useAPI } from 'contexts/APIProvider'
import { UserSearchForm } from 'types/interfaces'
import { adminUserUpdateURL } from 'routes/urls'
import { Link as RouterLink } from 'react-router-dom'
import { Observable, of } from 'rxjs'
import PracticeGroup from 'logic/PracticeGroup'
import UserInfo from 'logic/UserInfo'
import AutoCompleteSelectField from './AutoCompleteSelectField'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'

interface AdminUserTableProps {
  reload$?: Observable<boolean>
  onRowClick?: GridEventListener<"rowClick">
  actions?: React.ReactNode
  form: UserSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
  allowSelectPractice?: boolean
  allowSelectPracticeGroup?: boolean
}

const AdminUserTable: React.FC<AdminUserTableProps> = ({
  onRowClick,
  actions,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
  reload$,
  allowSelectPractice = true,
  allowSelectPracticeGroup = true,
}) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [users, setUsers] = React.useState<UserInfo[]>([])
  const [practices, setPractices] = React.useState<Practice[]>([])
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchUsers = React.useCallback(async () => {
    try {
      setLoading(true)
      const users = await api.listUsers(form)
      setUsers(users)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, form])

  const fetchPractices = React.useCallback(async (searchTerm: string = '') => {
    const practices = []
    if (form.practice_id) {
        const results = await api.listPractices({ practice_ids: [form.practice_id], is_enabled: true })
        practices.push(...results)
    }
    if (searchTerm) {
        const results = await api.listPractices({ name: searchTerm, is_enabled: true })
        practices.push(...results)
    }
    setPractices(uniqBy(practices, 'practice_id'))
  }, [api, form.practice_id])

  const fetchPracticeGroups = React.useCallback(async (searchTerm: string = '') => {
    const practiceGroups = []
    if (form.practice_group_id) {
        const results = await api.listPracticeGroup({ practice_group_ids: [form.practice_group_id] })
        practiceGroups.push(...results)
    }
    if (searchTerm) {
        const results = await api.listPracticeGroup({ name: searchTerm })
        practiceGroups.push(...results)
    }
    setPracticeGroups(uniqBy(practiceGroups, 'id'))
  }, [api])

  const deleteUser = React.useCallback(async (userId: number) => {
    await api.deleteUser(userId)
    fetchUsers()
  }, [api, fetchUsers])

  const viewUserClaims = React.useCallback(async (userId: number) => {
    const claims = await api.viewUserClaims(userId)
    showAlert('info', JSON.stringify(claims, null, 2))
  }, [api, showAlert])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchUsers())
    return () => sub?.unsubscribe()
  }, [fetchUsers, reload$])

  React.useEffect(() => {
    if (form.practice_id) {
        fetchPractices()
    }
    if (form.practice_group_id) {
        fetchPracticeGroups()
    }
  }, [form.practice_id, form.practice_group_id])

  const practiceSelectOptions = React.useMemo(() => {
    return practices.map((practice) => ({
      value: practice.practice_id,
      label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
    }))
  }, [practices])

  const practiceGroupSelectOptions = React.useMemo(() => {
    return practiceGroups.map((practiceGroup) => ({
      value: practiceGroup.id,
      label: practiceGroup.name,
    }))
  }, [practiceGroups])

  const columns: GridColDef<UserInfo>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Name',
    field: 'full_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Email',
    field: 'email',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Date Joined',
    field: 'date_joined',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<UserInfo>) => {
      return params.row.date_joined?.toJSDate()
    },
  }, {
    minWidth: 300,
    headerName: 'Practices',
    field: 'practices',
    type: 'string',
    renderCell: (params: GridRenderCellParams<UserInfo>) => {
      const user = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {user.practice_info.map((practice) => (
            <Chip key={practice.id} label={practice.name} size='small' />
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 300,
    headerName: 'Practice Groups',
    field: 'practice_groups',
    type: 'string',
    renderCell: (params: GridRenderCellParams<UserInfo>) => {
      const user = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {user.practice_group_info.map((practiceGroup) => (
            <Chip key={practiceGroup.id} label={practiceGroup.name} size='small' />
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 150,
    headerName: 'Actions',
    field: 'actions',
    sortable: false,
    renderCell: (params: GridRenderCellParams<UserInfo>) => {
      const user = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <IconButton component={RouterLink} to={adminUserUpdateURL(user.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => deleteUser(user.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => viewUserClaims(user.id)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      )
    }
  }], [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          name='search_term'
          label='Search by name or email'
          type='text'
          onChange={(e) => updateForm('search_term', e.target.value)}
          value={form.search_term}
        />
        <AutoCompleteSelectField
          label='Select a practice'
          sx={{ width: 250, display: allowSelectPractice ? 'block' : 'none' }}
          options={practiceSelectOptions}
          fetchOptions={fetchPractices}
          value={form.practice_id ?? ''}
          onChange={(value) => updateForm('practice_id', value)}
        />
        <AutoCompleteSelectField
          label='Select a practice group'
          sx={{ width: 250, display: allowSelectPracticeGroup ? 'block' : 'none' }}
          options={practiceGroupSelectOptions}
          fetchOptions={fetchPracticeGroups}
          value={form.practice_group_id ?? ''}
          onChange={(value) => updateForm('practice_group_id', value)}
        />
        <Button
          variant="contained"
          onClick={fetchUsers}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {loading ? (
        <Alert severity='info'>Please Wait ...</Alert>
      ) : isEmpty(users) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{users.length} records returned. {selection.length} selected</Alert>
          {/* <Box sx={{ height: '70vh', width: '98vw' }}> */}
          <DataGridPro
            rows={users}
            columns={columns}
            getRowId={(user) => user.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
          />
          {/* </Box> */}
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminUserTable

