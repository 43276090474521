import React from 'react'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router';
import { practiceGroupBillingAccountListURL } from 'routes/urls'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { usePracticeGroup } from 'contexts/PracticeGroupProvider';
import Header from 'components/Header';
import SetupBillingAccount from 'components/SetupBillingAccount';
import Loading from 'components/Loading';
import BillingAccount from 'logic/BillingAccount';
import { useAPI } from 'contexts/APIProvider';
import { PAYMENT_OPTIONS_FOR_CORPORATE_PRACTICES } from 'types/constants';

const PracticeGroupBillingAccountEdit: React.FC = () => {
  const params = useParams()
  const { practiceGroup } = usePracticeGroup()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()
  const [billingAccount, setBillingAccount] = React.useState<BillingAccount>()

  const billingAccountId = React.useMemo(() => Number(params.billingAccountId), [params.billingAccountId])

  const fetchBillingAccount = React.useCallback(async () => {
    setBillingAccount(await api.getBillingAccount(billingAccountId))
  }, [api, billingAccountId])

  const onSuccess = React.useCallback(() => {
    if (!practiceGroup) return
    showAlert('success', 'Billing account added successfully')
    navigate(practiceGroupBillingAccountListURL(practiceGroup.id))
  }, [navigate, practiceGroup, showAlert])

  React.useEffect(() => {
    fetchBillingAccount()
  }, [fetchBillingAccount])

  if (!practiceGroup || !billingAccount) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Add Billing Account' />
      <SetupBillingAccount
        practiceGroup={practiceGroup}
        billingAccount={billingAccount}
        onSuccess={onSuccess}
        bacsDebitSuccessURL={practiceGroupBillingAccountListURL(practiceGroup.id)}
        availablePaymentOptions={PAYMENT_OPTIONS_FOR_CORPORATE_PRACTICES}
      />
    </Box>
  )
}

export default PracticeGroupBillingAccountEdit