import BillingAccountFormError from 'errors/BillingAccountFormError'
import BillingAccount from 'logic/BillingAccount'
import Practice from 'logic/Practice'
import PracticeGroup from 'logic/PracticeGroup'
import React from 'react'
import { BillingAccountForm, SetupBillingAccountResponse } from 'types/interfaces'
import update from 'immutability-helper'
import PaymentMethod from 'logic/PaymentMethod'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import SetupCard from 'components/SetupBillingAccount/SetupCard'
import SetupBacsDebit from 'components/SetupBillingAccount/SetupBacsDebit'
import {
  Alert,
  Box,
  Link,
  Button,
} from '@mui/material'
import { isEmpty } from 'lodash'
import JobEmployment from 'logic/JobEmployment'
import ImpactedBookings from 'components/SetupBillingAccount/ImpactedBookings'
import BillingAccountSetupForm, { ADD_NEW } from 'components/SetupBillingAccount/SetupForm'
import { PaymentOption } from 'types/types'
import { useZendesk } from 'contexts/ZendeskProvider'
import ErrorList from 'components/ErrorList'

interface SetupBillingAccountProps {
  practice?: Practice
  practiceGroup?: PracticeGroup
  billingAccount?: BillingAccount
  onSuccess: () => void
  bacsDebitSuccessURL?: string
  buttonText?: string
  availablePaymentOptions: PaymentOption[]
}

const SetupBillingAccount: React.FC<SetupBillingAccountProps> = ({ 
  practice, 
  practiceGroup, 
  billingAccount, 
  onSuccess, 
  bacsDebitSuccessURL,
  availablePaymentOptions,
  buttonText = 'Save',
}) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { openZendesk } = useZendesk()
  const [form, setForm] = React.useState<BillingAccountForm>({
    practice_id: practice?.practice_id,
    practice_group_id: practiceGroup?.id,
    bacs_debit_success_url: bacsDebitSuccessURL,
  })
  const [error, setError] = React.useState<BillingAccountFormError>()
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [resp, setResp] = React.useState<SetupBillingAccountResponse>()

  const [bookings, setBookings] = React.useState<JobEmployment[]>()
  const [confirmImpactedBookings, setConfirmImpactedBookings] = React.useState<boolean>(false)

  const mode = React.useMemo(() => billingAccount ? 'edit' : 'create', [billingAccount])

  const updateForm = (name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  const fetchPaymentMethods = React.useCallback(async () => {
    if (!billingAccount?.customer_id) return
    try {
      const pms = await api.listPaymentMethods({
        customer_id: billingAccount.customer_id
      })
      setPaymentMethods(pms)
    } catch (e) {
      showAlert('error', 'Error fetching payment methods')
    }
  }, [api, billingAccount, showAlert])

  const fetchImpactedBookings = React.useCallback(async () => {
    if (!billingAccount?.customer_id) return []
    const results = await api.listJobEmployments({
      customer_id: billingAccount.customer_id,
      payment_statuses: [
        '' ,
        'practice_payment_error',
        'practice_payment_pending',
        'practice_invoice_void',
        'practice_invoice_payment_failed',
      ],
      new_payment_option: form.payment_option,
      is_cancelled: false,
    })
    setBookings(results)
    return results
  }, [api, billingAccount, form.payment_option])

  const save = React.useCallback(async () => {
    try {
      setLoading(true)

      if (form.payment_method_id === ADD_NEW) {
        form.payment_method_id = undefined
      }

      if (mode === 'create') {
        const resp = await api.createBillingAccount(form)
        if (resp?.next_action === 'none') {  
          onSuccess()
        } else {
          setResp(resp)
        }
      } else if (mode === 'edit')  {
        if (!billingAccount) return

        const isPaymentOptionChanged = billingAccount.payment_option !== form.payment_option
        
        if (isPaymentOptionChanged && !confirmImpactedBookings) {
          const results = await fetchImpactedBookings()
          if (!isEmpty(results)) {
            setConfirmImpactedBookings(true)
            return
          }
        }

        const resp = await api.updateBillingAccount(billingAccount.id, form)
        if (resp?.next_action === 'none') {  
          onSuccess()
        } else {
          setResp(resp)
        }
      }
    } catch (e) {
      if (e instanceof BillingAccountFormError) {
        setError(e)
      } else {
        showAlert('error', 'Error saving billing account')
      }
    } finally {
      setLoading(false)
    }
  }, [api, billingAccount, confirmImpactedBookings, fetchImpactedBookings, form, mode, onSuccess, showAlert])

  React.useEffect(() => {
    if (billingAccount) {
      setForm(update(form, {
        name: { $set: billingAccount.name },
        email: { $set: billingAccount.email },
        payment_option: { $set: billingAccount.payment_option },
        payment_method_id: { $set: billingAccount.payment_method_id },
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    fetchPaymentMethods()
  }, [fetchPaymentMethods])

  if (resp?.next_action === 'setup-card' && resp.client_secret) {
    return <SetupCard clientSecret={resp.client_secret} form={form} onSuccess={onSuccess} buttonText={buttonText} />
  } else if (resp?.next_action === 'setup-bacs-debit' && resp.url) {
    return <SetupBacsDebit url={resp.url} />
  } else if (confirmImpactedBookings ) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <ImpactedBookings bookings={bookings} oldPaymentOption={billingAccount?.payment_option} newPaymentOption={form.payment_option} />
        {error?.schema ? (
          <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
        ) : null}
        <Button variant="contained" color="primary" onClick={save} disabled={loading}>
          {loading ? 'Please Wait ...' : 'Confirm Change'}
        </Button>
        <Button variant='outlined' color='primary' onClick={() => setConfirmImpactedBookings(false)} disabled={loading}>
          Cancel
        </Button>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <BillingAccountSetupForm
          form={form}
          setForm={setForm}
          error={error}
          updateForm={updateForm}
          paymentMethods={paymentMethods}
          availablePaymentOptions={availablePaymentOptions}
        />
        <Alert variant='filled' severity="info">
          If you need a hand please <Link onClick={() => openZendesk()} sx={{ cursor: 'pointer', color: '#ffffff' }} >click here to chat to us</Link> or feel free to call us on 07745 522 577.
        </Alert>
        {error?.schema ? (
          <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
        ) : null}
        <Button variant="contained" color="primary" onClick={save} disabled={loading}>
          {loading ? 'Please Wait ...' : buttonText}
        </Button>
      </Box>
    )
  }
}

export default SetupBillingAccount