import {
  DocumentFileType,
} from '../types/types'

export default class NurseDocumentFile {
  id!: number
  nurse_id!: number
  doc_type!: DocumentFileType
  key!: string
  page_num!: number
  // reason!: string
  // approved!: boolean | null
  url?: string
  content_type!: string
  can_delete!: boolean
}