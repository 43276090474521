import { Expose } from 'class-transformer';

export default class JobEmploymentUpdateError {
  hourly_rate: string[] = []
  nurse_fees: string[] = []
  locumloop_fees: string[] = []
  start_time: string[] = []
  end_time: string[] = []
  lunch_break: string[] = []
  billable_duration: string[] = []
  charge_practice_after: string[] = []
  custom_status_nurse: string[] = []
  custom_status_practice: string[] = []
  admin_notes: string[] = []
  penalty: string[] = []
  penalty_note: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
}

