import { 
  Box, 
  Typography, 
  Tabs, 
  Tab,
  ButtonGroup,
  Chip,
  Button,
 } from '@mui/material'
import {
  affiliateTransactionListURL,
} from 'routes/urls'
import { Outlet, Link as RouterLink, useMatch, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import Loading from 'components/Loading'
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { AffiliateTransactionSearchForm, PracticeGroupSearchForm, PracticeSearchForm } from 'types/interfaces';
import update from 'immutability-helper'
import Affiliate from 'logic/Affiliate'
import AdminAffiliateDetailContext from 'pages/AdminAffiliateDetail/context'
import SplitButton from 'components/SplitButton'

const AdminAffiliateDetail: React.FC = () => {
  const { api } = useAPI()
  const params = useParams()
  const navigate = useNavigate()
  const [affiliate, setAffiliate] = React.useState<Affiliate>()
  const match = useMatch({ path: '/admin/affiliates/:affiliateId/:rest', caseSensitive: false, end: false })

  // Practice Groups
  const [practiceGroupSearchForm, setPracticeGroupSearchForm] = React.useState<PracticeGroupSearchForm>({
    affiliate_id: Number(params.affiliateId)
  })
  const [practiceGroupSelection, setPracticeGroupSelection] = React.useState<GridRowId[]>([])
  const [practiceGroupColumnVisibilityModel, setPracticeGroupColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [practiceGroupSortModel, setPracticeGroupSortModel] = React.useState<GridSortModel>([])

  const updatePracticeGroupSearchForm = React.useCallback((name: string, value: any) => {
    setPracticeGroupSearchForm(update(practiceGroupSearchForm, { [name]: { $set: value } }))
  }, [practiceGroupSearchForm])

  // Practices
  const [practiceSearchForm, setPracticeSearchForm] = React.useState<PracticeSearchForm>({
    affiliate_id: Number(params.affiliateId)
  })
  const [practiceSelection, setPracticeSelection] = React.useState<GridRowId[]>([])
  const [practiceColumnVisibilityModel, setPracticeColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [practiceSortModel, setPracticeSortModel] = React.useState<GridSortModel>([])

  const updatePracticeSearchForm = React.useCallback((name: string, value: any) => {
    setPracticeSearchForm(update(practiceSearchForm, { [name]: { $set: value } }))
  }, [practiceSearchForm])

  // Transactions
  const [transactionsSearchForm, setTransactionsSearchForm] = React.useState<AffiliateTransactionSearchForm>({
    affiliate_id: Number(params.affiliateId)
  })
  const [transactionsSelection, setTransactionsSelection] = React.useState<GridRowId[]>([])
  const [transactionsColumnVisibilityModel, setTransactionsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [transactionsSortModel, setTransactionsSortModel] = React.useState<GridSortModel>([])

  const updateTransactionsSearchForm = React.useCallback((name: string, value: any) => {
    setTransactionsSearchForm(update(transactionsSearchForm, { [name]: { $set: value } }))
  }, [transactionsSearchForm])

  // Init Page
  const fetchAffiliate = React.useCallback(async () => {
    setAffiliate(await api.getAffiliate(Number(params.affiliateId)))
  }, [api, params.affiliateId])

  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'profile'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchAffiliate()
  }, [fetchAffiliate])

  if (!affiliate) return <Loading />

  const contextValue = {
    affiliate,
    fetchAffiliate,

    // practice groups tab
    practiceGroupSearchForm,
    practiceGroupSelection,
    practiceGroupColumnVisibilityModel,
    practiceGroupSortModel,
    updatePracticeGroupSearchForm,
    setPracticeGroupSelection,
    setPracticeGroupColumnVisibilityModel,
    setPracticeGroupSortModel,

    // practices tab
    practiceSearchForm,
    practiceSelection,
    practiceColumnVisibilityModel,
    practiceSortModel,
    updatePracticeSearchForm,
    setPracticeSelection,
    setPracticeColumnVisibilityModel,
    setPracticeSortModel,

    // transactions tab
    transactionsSearchForm,
    transactionsSelection,
    transactionsColumnVisibilityModel,
    transactionsSortModel,
    updateTransactionsSearchForm,
    setTransactionsSelection,
    setTransactionsColumnVisibilityModel,
    setTransactionsSortModel,
  }
  return (
    <AdminAffiliateDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 1, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Typography variant='h1'>{ affiliate.name }</Typography>
          <Chip label={affiliate.code} />
          { affiliate.payouts_enabled ? (
            <Chip label='Ready to receive payouts' color='success' />
          ) : (
            <Chip label='Not ready to receive payouts' color='error' />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'view-as-user',
            label: 'View as user',
            onClick: () => navigate(affiliateTransactionListURL(affiliate.id))
          }]} />
        </Box>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label='Profile' value='profile' onClick={() => navigate('profile')} />
          <Tab label='Practice Groups' value='practice-groups' onClick={() => navigate('practice-groups')} />
          <Tab label='Practices' value='practices' onClick={() => navigate('practices')} />
          <Tab label='Transactions' value='transactions' onClick={() => navigate('transactions')} />
        </Tabs>
        <Outlet />
      </Box>
    </AdminAffiliateDetailContext.Provider>
  )
}

export default AdminAffiliateDetail