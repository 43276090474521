import React from 'react'
import { GridCsvExportOptions, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'

type ExportToolbarProps = {
  csvOptions?: GridCsvExportOptions
}

const ExportOnlyToolBar: React.FC<ExportToolbarProps> = ({ csvOptions }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={csvOptions} />
    </GridToolbarContainer>
  );
}

export default ExportOnlyToolBar
