import React from 'react'
import { DateTime, Interval, Duration } from 'luxon'
import { Subject, ReplaySubject } from 'rxjs'

const OneMonth = Duration.fromObject({ month: 1 })


export default function useCalendar(tz: string = 'Europe/London') {
  const [interval, setInterval] = React.useState<Interval>()
  const [interval$] = React.useState<Subject<Interval>>(new ReplaySubject<Interval>(1))

  React.useEffect(() => {
    toCurrentMonth()
  }, [tz, interval$])

  React.useEffect(() => {
    const sub = interval$.subscribe(setInterval)
    return () => sub.unsubscribe()
  }, [interval$])

  function toNextMonth() {
    if (interval !== undefined) {
      const val = Interval.after(interval.start.plus(OneMonth), OneMonth)
      interval$.next(val)
    }
  }

  function toPreviousMonth() {
    if (interval !== undefined) {
      const val = Interval.after(interval.start.minus(OneMonth), OneMonth)
      interval$.next(val)
    }
  }

  function toCurrentMonth() {
    const now = DateTime.local()
    const startOfMonth = DateTime.fromObject({
      year: now.year,
      month: now.month,
    }, { zone: tz })
    const val = Interval.after(startOfMonth, Duration.fromObject({ month: 1 }))
    interval$.next(val)
  }

  return { interval, interval$, toNextMonth, toPreviousMonth, toCurrentMonth }
}