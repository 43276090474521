import { Box, Button } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import AdminUserTable from 'components/AdminUserTable'
import { Link as RouterLink } from 'react-router-dom'
import { adminUserCreateURL } from 'routes/urls'

const AdminUserList: React.FC = () => {
  const { 
    userSearchForm, 
    userSelection, 
    userColumnVisibilityModel, 
    userSortModel, 
    updateUserSearchForm, 
    setUserSelection, 
    setUserColumnVisibilityModel, 
    setUserSortModel 
  } = useAdmin()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practice Users List' />
      <AdminUserTable
        form={userSearchForm}
        updateForm={updateUserSearchForm}
        selection={userSelection}
        setSelection={setUserSelection}
        columnVisibilityModel={userColumnVisibilityModel}
        setColumnVisibilityModel={setUserColumnVisibilityModel}
        sortModel={userSortModel}
        setSortModel={setUserSortModel}
        actions={
          <Button variant='contained' component={RouterLink} to={adminUserCreateURL()}>
            Create User
          </Button>
        }
      />
    </Box>
  )
}

export default AdminUserList

