import {
  Box,
  Alert,
  Typography,
  Link,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  DataGridProProps,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link as RouterLink } from 'react-router-dom'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { assign, chain, isEmpty } from 'lodash'
import JobEmployment from 'logic/JobEmployment'
import React from 'react'
import { JobEmploymentPaymentStatus } from 'types/types'
import { JobEmploymentSearchForm, PaymentRecord } from 'types/interfaces'
import { practiceViewJobURL } from 'routes/urls'
import { GridValueGetterParams, useGridApiContext } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import FormField from 'components/FormField';
import ExportOnlyToolBar from 'components/ExportToolbar'
import update from 'immutability-helper'
import { DateTime } from 'luxon';

interface PaymentsTableProps {
  payment_statuses?: JobEmploymentPaymentStatus[]
  practice_id?: number
  customer_id?: string
}

const GroupingCell: React.FC<GridRenderCellParams> = ({ id, field, rowNode }) => {
  const apiRef = useGridApiContext<GridApiPro>();

  const expanded = React.useMemo(() => {
    if (rowNode.type !== 'group') {
      return false
    }
    return rowNode.childrenExpanded
  }, [rowNode])

  const onClick = React.useCallback((event) => {
    if (rowNode.type !== 'group') {
      return;
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  }, [apiRef, field, id, rowNode])
  
  if (rowNode.type !== 'group') {
    return null
  }

  return (
    <IconButton onClick={onClick}>
      {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
    </IconButton>
  )
}

const Toolbar: React.FC = () => <ExportOnlyToolBar csvOptions={{ allColumns: true }} />

const PaymentsTable: React.FC<PaymentsTableProps> = ({ payment_statuses, practice_id, customer_id }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<JobEmploymentSearchForm>({
    year: DateTime.now().year,
    month: DateTime.now().day > 15 ? DateTime.now().month + 1 : DateTime.now().month,
  })
  const [employments, setEmployments] = React.useState<JobEmployment[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchEmployments = React.useCallback(async () => {
    try {
      setLoading(true)
      setEmployments(await api.listJobEmployments(assign({
        payment_statuses,
        practice_id,
        customer_id,
        prefetch_additional_charges: true,
        prefetch_refunds: true,
      }, form)))
    } catch (e) {
      showAlert('error', 'Error fetching payment records')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, customer_id, form, payment_statuses, practice_id, showAlert])

  React.useEffect(() => {
    fetchEmployments()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_statuses, practice_id, customer_id])

  const records: PaymentRecord[] = React.useMemo(() => {
    const results = []
    for (const employment of employments) {
      results.push(employment)
      for (const charge of employment.additional_charges) {
        results.push(charge)
      }
      for (const refund of employment.refunds) {
        results.push(refund)
      }
    }
    return (
      chain(results)
      .sortBy('record_sort_key')
      .value()
    )
  }, [employments])

  const columns: GridColDef<PaymentRecord>[] = React.useMemo(() => [{
    headerName: 'Job ID',
    field: 'employment_id',
    type: 'number',
  }, {
    minWidth: 150,
    headerName: 'Date',
    field: 'timestamp',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<PaymentRecord>) => {
      return params.row.timestamp.toFormat('LLL dd, yyyy')
    },
    renderCell: (params: GridRenderCellParams<PaymentRecord>) => {
      const record = params.row
      if (record.record_type === 'payment') {
        return (
          <Link component={RouterLink} to={practiceViewJobURL(record.practice_id, record.job_id)}>
            {record.timestamp_label(record.practice_tz)}
          </Link>
        )
      } else {
        return (
          <Typography variant='body2' paddingLeft={2}>
            {record.timestamp_label(record.practice_tz)}
          </Typography>
        )
      }
    }
  }, {
    minWidth: 170,
    headerName: 'Type',
    field: 'record_type',
    type: 'string',
    renderCell: (params: GridRenderCellParams<PaymentRecord>) => {
      const record = params.row
      if (record.record_type !== 'payment') {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>{record.record_type}</Typography>
            <Tooltip title={record.fee_correction_reason}>
              <ErrorOutlineIcon fontSize='small' />
            </Tooltip>
          </Box>
        )
      }
      return record.record_type
    }
  }, {
    minWidth: 200,
    headerName: 'Nurse',
    field: 'nurse_name',
    type: 'string'
  }, {
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Amount',
    field: 'amount',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<PaymentRecord>) => {
      return (params.row.amount / 100).toFixed(2)
    },
    renderCell: (params: GridRenderCellParams<PaymentRecord>) => {
      const record = params.row
      return record.amount_label
    }
  }, {
    minWidth: 100,
    headerName: 'Adjustment',
    field: 'adjustment',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<PaymentRecord>) => {
      return (params.row.adjustment / 100).toFixed(2)
    },
    renderCell: (params: GridRenderCellParams<PaymentRecord>) => {
      const record = params.row
      return record.adjustment_label
    }
  }, {
    minWidth: 500,
    headerName: 'Status',
    field: 'status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<PaymentRecord>) => {
      const record = params.row
      return record.status_label
    }
  }, {
    headerName: 'Invoice',
    field: 'invoice_number',
    type: 'string',
  }, {
    headerName: 'Paid At',
    field: 'paid_at',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<PaymentRecord>) => {
      return params.row.paid_at?.toFormat('LLL dd, yyyy') ?? ''
    },
  }], [])

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    width: 70,
    disableExport: true,
    renderCell: (params) => <GroupingCell {...params} />,
  };
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%', overflow: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <FormField
          size='small'
          variant='filled'
          name='practice'
          label='Practice'
          onChange={(e) => updateForm('practice', e.target.value)}
          value={form.practice ?? ''}
        />
        <FormField
          size='small'
          type='number'
          variant='filled'
          name='year'
          label='Year'
          onChange={(e) => updateForm('year', e.target.value)}
          value={form.year ?? ''}
        />
        <FormField
          size='small'
          type='number'
          variant='filled'
          name='month'
          label='Month (1-12)'
          onChange={(e) => updateForm('month', e.target.value)}
          value={form.month ?? ''}
        />
        <Button size='small' variant='contained' onClick={fetchEmployments}>Search</Button>
      </Box>
      {loading ? (
        <Alert severity='info'>Please Wait ...</Alert>
      ) : isEmpty(records) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <DataGridPro
          density='compact'
          treeData={true}
          groupingColDef={groupingColDef}
          getTreeDataPath={(row) => row.record_path}
          rows={records}
          columns={columns}
          getRowId={(row) => row.record_id}
          loading={loading}
          disableChildrenFiltering={true}
          disableChildrenSorting={true}
          disableRowSelectionOnClick={true}
          hideFooter={true}
          columnVisibilityModel={{
            invoice_number: false,
            employment_id: false,
            paid_at: false,
          }}
          slots={{ toolbar: Toolbar }}
        />
      )}
    </Box>
  )
}

export default PaymentsTable