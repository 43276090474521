import IssueInvoiceLateFee from 'pages/IssueInvoiceLateFee/form'
import React from 'react'
import { useParams } from 'react-router-dom'

const PracticeIssueInvoiceLateFee: React.FC = () => {
    const params = useParams()
    const practiceId = React.useMemo(() => Number(params.practiceId), [params.practiceId])
    return <IssueInvoiceLateFee practice_id={practiceId} />
}

export default PracticeIssueInvoiceLateFee