import { useAPI } from 'contexts/APIProvider'
import { usePractice } from 'contexts/PracticeProvider'
import SavingsReport from 'logic/SavingsReport'
import React, { ChangeEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Loading from 'components/Loading'

const PracticeSavings: React.FC = () => {
  const { practice } = usePractice()
  const { api } = useAPI()
  const [referenceRate, setReferenceRate] = React.useState<number | null>(30)
  const [savings, setSavings] = React.useState<SavingsReport>()

  async function fetchSavingsReport() {
    if (!practice || !referenceRate) return
    const results = await api.getSavingsReport(practice.practice_id, referenceRate)
    setSavings(results)
  }

  function onReferenceRateChanged(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value === '' ? null : Number(e.target.value)
    setReferenceRate(value)
  }

  React.useEffect(() => {
    fetchSavingsReport()
  }, [referenceRate])

  if (savings === undefined) return <Loading />

  return (
    <Box>
      <Typography variant='h1' sx={{ mb: 2 }}>Number of hours billed: {savings.hours_billed} hours</Typography>
      <Typography variant='h1' sx={{ mb: 2 }}>Total Cost: £{savings.total_spent}</Typography>
      <Typography variant='h6'>Compare locumloop spending with a traditional agency</Typography>
      <Input
        sx={{ mb: 2 }}
        type='number'
        value={referenceRate}
        onChange={onReferenceRateChanged}
        startAdornment={
          <InputAdornment position="start">
            <Typography>Traditional Agency Fee Per Hour (£)</Typography>
          </InputAdornment>
        }
      />
      <Typography>You would have paid £{savings.reference_spent} via a traditional agency</Typography>
      <Typography>You {savings.reference_diff_label} via locumloop</Typography>
    </Box>
  )
}

export default PracticeSavings