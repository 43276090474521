import { Box, Button, ButtonGroup, IconButton } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { usePracticeGroup } from 'contexts/PracticeGroupProvider'
import { Link as RouterLink } from 'react-router-dom'
import Practice from 'logic/Practice'
import React from 'react'
import {
  practiceJobsURL,
  practiceBillingURL,
  practiceProfileEditURL,
  practiceMemberListURL,
  practiceGroupPracticeAddURL
} from 'routes/urls'
import Loading from 'components/Loading';
import SetPracticeBillingAccount from 'pages/PracticeGroupPracticeList/SetPracticeBillingAccount';
import BillingAccount from 'logic/BillingAccount';

const PracticeGroupPracticeList: React.FC = () => {
  const { api } = useAPI()
  const { practiceGroup } = usePracticeGroup()
  const [practices, setPractices] = React.useState<Practice[]>([])
  const [billingAccounts, setBillingAccounts] = React.useState<BillingAccount[]>([])

  const fetchPractices = React.useCallback(async () => {
    if (!practiceGroup) return
    setPractices(await api.listPractices({ practice_group_id: practiceGroup.id }))
  }, [api, practiceGroup])

  const fetchBillingAccounts = React.useCallback(async () => {
    setBillingAccounts(await api.listBillingAccounts({ practice_group_id: practiceGroup?.id }))
  }, [api, practiceGroup?.id])

  React.useEffect(() => {
    fetchPractices()
  }, [fetchPractices])

  React.useEffect(() => {
    fetchBillingAccounts()
  }, [fetchBillingAccounts])

  if (!practiceGroup) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Practices'>
        <Button 
          variant='contained' 
          sx={{ marginBottom: 1 }}
          component={RouterLink} 
          to={practiceGroupPracticeAddURL(practiceGroup.id)}>
          Add Practice
        </Button>
      </Header>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Billing Account</TableCell>
              <TableCell>Pages</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {practices.map((practice) => (
              <TableRow key={practice.practice_id}>
                <TableCell>{ practice.practice_id }</TableCell>
                <TableCell>{ practice.practice_name }</TableCell>
                <TableCell>
                  <SetPracticeBillingAccount
                    practice={practice}
                    billingAccounts={billingAccounts}
                    reloadPractice={fetchPractices}
                  />
                </TableCell>
                <TableCell>
                  <ButtonGroup size='small'>
                    <IconButton 
                      component={RouterLink}
                      to={practiceProfileEditURL(practice.practice_id)}
                      target='_blank'
                    >
                      <AccountCircleIcon />
                    </IconButton>
                    <IconButton 
                      component={RouterLink}
                      to={practiceMemberListURL(practice.practice_id)}
                      target='_blank'
                    >
                      <PeopleIcon />
                    </IconButton>
                    <IconButton 
                      component={RouterLink}
                      to={practiceJobsURL(practice.practice_id)}
                      target='_blank'
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                    <IconButton 
                      component={RouterLink}
                      to={practiceBillingURL(practice.practice_id)}
                      target='_blank'
                    >
                      <CurrencyPoundIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PracticeGroupPracticeList