import React from 'react'
import Box from '@mui/material/Box'
import BillingAccountInfo from 'pages/PracticeBilling/BillingAccountInfo'
import { Tab, Tabs } from '@mui/material'
import PaymentsTable from 'components/PaymentsTable'
import { usePractice } from 'contexts/PracticeProvider'
import InvoicesTable from 'components/InvoicesTable'
import Loading from 'components/Loading'

type TAB = 
| 'payments-in-progress'
| 'payments-completed'
| 'invoices'

const PracticeBilling: React.FC = () => {
  const { practice } = usePractice()
  const [selectedTab, setSelectedTab] = React.useState<TAB>('payments-in-progress')

  if (!practice) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <BillingAccountInfo />
      <Tabs value={selectedTab} onChange={(e, tab) => setSelectedTab(tab)}>
        <Tab label='Payments in progress' value='payments-in-progress' />
        <Tab label='Payments completed' value='payments-completed' />
        <Tab label='Invoices' value='invoices' />
      </Tabs>
      {selectedTab === 'payments-completed' ? (
        <PaymentsTable practice_id={practice.practice_id} payment_statuses={[
          'practice_invoice_paid', 
          'practice_invoice_void', 
          'practice_paid'
        ]} />
      ) : selectedTab === 'payments-in-progress' ? (
        <PaymentsTable practice_id={practice.practice_id} payment_statuses={[
          'practice_invoice_finalized', 
          'practice_invoice_payment_failed',
          'practice_payment_pending', 
          'practice_payment_requested', 
          'practice_payment_error'
        ]} />
      ) : selectedTab === 'invoices' ? (
        <InvoicesTable billing_account_id={practice.billing_account_id} />
      ) : null}
    </Box>
  )
}

export default PracticeBilling