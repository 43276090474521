import React from 'react'
import API from '../logic/API'
import { useFirebase } from './FirebaseProvider'

interface IAPIContext {
  api: API
}

const APIContext = React.createContext<IAPIContext>({} as IAPIContext)

const APIProvider: React.FC = ({ children }) => {
  const { auth } = useFirebase()
  const base_url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'
  const api = React.useMemo(() => new API(auth, base_url), [])
  const value = { api }
  return <APIContext.Provider value={value}> {children} </APIContext.Provider>
}

function useAPI() {
  const context = React.useContext(APIContext)
  if (context === undefined) {
    throw new Error('useAPI must be used within a APIProvider')
  }
  return context
}

export { APIProvider, useAPI }