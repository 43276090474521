import { Box } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import AdminPracticeGroupTable from 'components/AdminPracticeGroupTable'
import PracticeGroup from 'logic/PracticeGroup'
import { adminPracticeGroupDetailURL } from 'routes/urls'

const AdminPracticeGroupList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    practiceGroupSearchForm, 
    practiceGroupSelection, 
    practiceGroupColumnVisibilityModel, 
    practiceGroupSortModel, 
    updatePracticeGroupSearchForm, 
    setPracticeGroupSelection, 
    setPracticeGroupColumnVisibilityModel, 
    setPracticeGroupSortModel 
  } = useAdmin()

  const onRowClick = React.useCallback((params: GridRowParams<PracticeGroup>) => {
    navigate(adminPracticeGroupDetailURL(params.row.id))
  }, [navigate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practice Group List'></Header>
      <AdminPracticeGroupTable
        onRowClick={onRowClick}
        form={practiceGroupSearchForm}
        updateForm={updatePracticeGroupSearchForm}
        selection={practiceGroupSelection}
        setSelection={setPracticeGroupSelection}
        columnVisibilityModel={practiceGroupColumnVisibilityModel}
        setColumnVisibilityModel={setPracticeGroupColumnVisibilityModel}
        sortModel={practiceGroupSortModel}
        setSortModel={setPracticeGroupSortModel}
      />
    </Box>
  )
}

export default AdminPracticeGroupList