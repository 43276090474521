import { Autocomplete, SxProps, TextField } from '@mui/material'
import { debounce, isEmpty } from 'lodash'
import React from 'react'
import ErrorList from './ErrorList'

interface AutoCompleteSelectOption<T> {
    value: T
    label: string
}

interface AutoCompleteMultiSelectFieldProps<T> {
    label: string
    options: AutoCompleteSelectOption<T>[]
    fetchOptions: (searchText: string) => Promise<void>
    values?: T[]
    errors?: string[]
    onChange: (values: T[]) => void
    sx?: SxProps
}

const AutoCompleteMultiSelectField = <T,>({ label, options, fetchOptions, values, errors, onChange, sx }: AutoCompleteMultiSelectFieldProps<T>) => {
    const [searchTerm, setSearchTerm] = React.useState<string>('')

    const debouncedFetchOptions = React.useMemo(() => {
        return debounce(fetchOptions, 500)
    }, [fetchOptions])

    const selectedOption = React.useMemo(() => {
        return options?.filter(option => values?.includes(option.value)) ?? []
    }, [options, values])

    React.useEffect(() => {
        debouncedFetchOptions(searchTerm)
    }, [debouncedFetchOptions, searchTerm])
    
    return (
        <Autocomplete
            freeSolo
            // autoComplete
            // includeInputInList
            // filterSelectedOptions
            multiple={true}
            filterOptions={(x) => x}
            sx={sx}
            options={options ?? []}
            renderInput={(params) => {
                if (!isEmpty(errors)) {
                    const errorlist = <ErrorList errors={errors} />
                    return <TextField error {...params} helperText={errorlist} />
                } else {
                    return <TextField {...params} label={label} />
                }
            }}
            value={selectedOption}
            onChange={(event, selectedOption) => {
                onChange(selectedOption?.map(option => (option as AutoCompleteSelectOption<T>).value))
            }}
            inputValue={searchTerm ?? ''}
            onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
            }}
        />
    )
}

export default AutoCompleteMultiSelectField

