import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

type ErrorListProps = {
  errors?: string[]
}

type FormFieldProps = TextFieldProps & ErrorListProps

const FormField: React.FC<FormFieldProps> = (props) => {
  const errors = props.errors
  if (!isEmpty(errors)) {
    const errorlist = <ErrorList errors={props.errors} />
    return <TextField error {...props} helperText={errorlist} />
  } else {
    return (
      <TextField {...props} />
    )
  }
}

export default FormField