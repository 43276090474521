import AdminChart from 'components/AdminChart'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'

const AdminNurseCharts: React.FC = () => {
  const { chartsSearchForm, updateChartsSearchForm } = React.useContext(AdminNurseDetailContext)
  return (
    <AdminChart 
      form={chartsSearchForm} 
      updateForm={updateChartsSearchForm} 
      reportTypes={['match-cancellation', 'match-fulfillment-speed']}
      allowSelectPractice={false} 
      allowSelectPracticeGroup={false} 
    />
  )
}

export default AdminNurseCharts