import React from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Achievement from 'logic/Achievement'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { isEmpty } from 'lodash'
import EarningsReport from 'logic/EarningsReport'
import Loading from 'components/Loading'

const NurseAchievements: React.FC = () => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const [achievements, setAchievements] = React.useState<Achievement[]>([])
  const [selectedAchievement, setSelectedAchievement] = React.useState<Achievement>()
  const [earnings, setEarningsReport] = React.useState<EarningsReport>()

  const fetchAchievements = React.useCallback(async () => {
    if (nurse?.nurse_id === undefined) return
    // update the cached paygrades first, so we get the latest achievements
    await api.updateNurseAchievements(nurse.nurse_id)
    const results = await api.listNurseAchievements(nurse.nurse_id)
    const selected = results.find(result => result.is_current)
    setAchievements(results)
    setSelectedAchievement(selected)
  }, [api, nurse?.nurse_id])

  const fetchEarningsReport = React.useCallback(async () => {
    if (nurse?.nurse_id === undefined) return
    const results = await api.getEarningsReport(nurse.nurse_id, 10)
    setEarningsReport(results)
  }, [api, nurse?.nurse_id])

  React.useEffect(() => {
    fetchAchievements()
  }, [fetchAchievements])

  React.useEffect(() => {
    fetchEarningsReport()
  }, [fetchEarningsReport])

  if (isEmpty(achievements)) return <Loading />
  if (selectedAchievement === undefined) return <Loading />

  return (
    <Box sx={{
      display: ['flex', 'grid'],
      flexDirection: 'column',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 2fr',
      gridTemplateAreas: `
      'list detail'
      'list report'`,
      gap: 1
    }}>
      <Box sx={{
        gridArea: 'list',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}>
        {achievements.map(achievement => (
          <Box key={achievement.rate} sx={{
            position: 'relative',
            flexBasis: 'calc(15% - 10px)',
            border: '3px solid',
            margin: '5px',
            borderRadius: '50%',
            borderColor: achievement.borderColor,
            boxSizing: 'border-box',
            '&::before': {
              content: '""',
              display: 'block',
              paddingTop: '100%',
            }
          }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <IconButton sx={{ padding: 0 }} onClick={_ => setSelectedAchievement(achievement)}>
                {achievement.lockIcon}
              </IconButton>
              <Typography variant='caption'>{achievement.rate_label}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Paper sx={{
        gridArea: 'detail',
        padding: 2,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
        'rate_range'
        'progress'
        'hours_range'`,
        alignItems: 'center',
      }}>
        <Box sx={{
          gridArea: 'rate_range',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography>{selectedAchievement.rate_label}</Typography>
          <Typography>{selectedAchievement.next_rate_label}</Typography>
        </Box>
        <Box sx={{
          gridArea: 'progress',
          backgroundColor: 'secondary.light',
          width: '100%',
          height: [20, '100%'],
          borderRadius: 6,
        }}>
          <Box sx={{
            width: `${selectedAchievement.percentage_achieved}%`,
            height: '100%',
            backgroundColor: 'secondary.main',
            borderRadius: 6,
          }} />
        </Box>
        <Box sx={{
          gridArea: 'hours_range',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography>{selectedAchievement.min_hours} hrs</Typography>
          <Typography>You have completed {selectedAchievement.hours_worked} hrs</Typography>
          <Typography>{selectedAchievement.max_hours} hrs</Typography>
        </Box>
      </Paper>
      {earnings ? (
        <Paper sx={{
          gridArea: 'report',
          padding: 2,
        }}>
          <Typography variant='h1' sx={{ mb: 2 }}>Your total earnings: £{earnings.total_earned}</Typography>
        </Paper>
      ) : null}

    </Box>
  )
}

export default NurseAchievements