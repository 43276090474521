import React from 'react'
import AdminNurseDetailContext from '../context'
import { DocumentType } from 'types/types'
import Loading from 'components/Loading'
import { Box } from '@mui/material'
import { Outlet, useNavigate } from 'react-router'
import { adminNurseDocumentVerifyURL } from 'routes/urls'
import { 
    getComplianceFromDocumentType,
    ComplianceChip
} from 'components/DocumentUpload'


const DOC_TYPES: DocumentType[] = [
    'gdc',
    'indemnity_insurance',
    'hepatitis_b_vaccination',
    'hepatitis_c',
    'tb',
    'hiv',
    'dbs',
    'pvg',
    'infection_control',
    'cpr',
    'resume',
]

const AdminNurseDocumentList: React.FC = () => {
    const { nurse } = React.useContext(AdminNurseDetailContext)
    const navigate = useNavigate()

    const compliances = React.useMemo(() => {
        if (!nurse) return []
        return DOC_TYPES.map(docType => getComplianceFromDocumentType(docType, nurse))
    }, [nurse])

    if (!nurse) {
        return <Loading />
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                {compliances.map(compliance => (
                    <ComplianceChip 
                        key={compliance.docType} 
                        compliance={compliance} 
                        onClick={() => navigate(adminNurseDocumentVerifyURL(nurse.nurse_id, compliance.docType))} 
                        size='small'
                    />
                ))}
            </Box>
            <Outlet />
        </Box>
    )
}

export default AdminNurseDocumentList