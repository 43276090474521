import { Box } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { adminJobDetailURL } from 'routes/urls'
import Job from 'logic/Job'
import AdminJobTable from 'components/AdminJobTable'

const AdminJobList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
  } = useAdmin()

  const onRowClick = React.useCallback((params: GridRowParams<Job>) => {
    navigate(adminJobDetailURL(params.row.job_id))
  }, [navigate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Jobs List' />
      <AdminJobTable
        onRowClick={onRowClick}
        form={jobSearchForm}
        updateForm={updateJobSearchForm}
        selection={jobSelection}
        setSelection={setJobSelection}
        columnVisibilityModel={jobColumnVisibilityModel}
        setColumnVisibilityModel={setJobColumnVisibilityModel}
        sortModel={jobSortModel}
        setSortModel={setJobSortModel}
      />
    </Box>
  )
}

export default AdminJobList