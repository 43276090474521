import React from 'react'

export default function useWidth() {
  // https://stackoverflow.com/questions/58222004/how-to-get-parent-width-height-in-react-using-hooks
  const [width, setWidth] = React.useState<number>()
  const ref = React.useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])
  return { ref, width }
}