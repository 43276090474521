import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import update from 'immutability-helper';
import { useAPI } from 'contexts/APIProvider'
import { LoqateAddress, NurseProfileForm } from 'types/interfaces';
import NurseProfileError from 'errors/NurseProfileError';
import Header from 'components/Header'
import FormField from 'components/FormField'
import AddressSearch from 'components/AddressSearch'
import ErrorPaper from 'components/ErrorAlert'
import Loading from 'components/Loading'
import NurseSignupContext from './context'
import { isEmpty, round } from 'lodash';

const NurseSignupBasicInfoEdit: React.FC = () => {
  const { toNextStep, nurse } = React.useContext(NurseSignupContext)
  const { api } = useAPI()
  const [form, setForm] = React.useState<NurseProfileForm>({});
  const [error, setError] = React.useState<NurseProfileError>();
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function save() {
    if (!nurse) return
    try {
      setLoading(true)
      await api.editNurse(form, nurse.nurse_id)
      toNextStep()
    } catch (e: any) {
      if (e instanceof NurseProfileError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!nurse) return
    setForm((form) => update(form, {
      'address': { $set: nurse.address },
      'latitude': { $set: nurse.latitude },
      'longitude': { $set: nurse.longitude },
      'line1': { $set: nurse.line1 },
      'line2': { $set: nurse.line2 },
      'city': { $set: nurse.city },
      'county': { $set: nurse.county },
      'country': { $set: nurse.country },
      'postcode': { $set: nurse.postcode },
      'first_name': { $set: nurse.first_name },
      'last_name': { $set: nurse.last_name },
      'phone_number': { $set: nurse.phone_number },
      'radius_miles': { $set: nurse.radius_miles ? round(nurse.radius_miles) : 20 },
    }))
  }, [nurse])

  if (isEmpty(form)) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Basic Information'></Header>
      <FormField
        name='first_name'
        label='First Name'
        onChange={(e) => updateForm('first_name', e.target.value)}
        value={form.first_name}
        errors={error?.first_name}
      />
      <FormField
        name='last_name'
        label='Last Name'
        onChange={(e) => updateForm('last_name', e.target.value)}
        value={form.last_name}
        errors={error?.last_name}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      <FormField
        name='phone_number'
        label='Phone Number'
        onChange={(e) => updateForm('phone_number', e.target.value)}
        value={form.phone_number}
        errors={error?.phone_number}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='radius_miles'
        label='Willing to travel up to (miles)'
        select
        onChange={(e) => updateForm('radius_miles', e.target.value)}
        value={form.radius_miles}
        errors={error?.radius}>
          <MenuItem value={5}>5 miles</MenuItem>
          <MenuItem value={10}>10 miles</MenuItem>
          <MenuItem value={15}>15 miles</MenuItem>
          <MenuItem value={20}>20 miles</MenuItem>
          <MenuItem value={30}>30 miles</MenuItem>
          <MenuItem value={40}>40 miles</MenuItem>
      </FormField>
      <ErrorPaper errors={error?.schema}></ErrorPaper>
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait' : 'Next'}
      </Button>
    </Box>
  )
}

export default NurseSignupBasicInfoEdit