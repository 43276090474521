import { Box, Button } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { NurseLocationReportItem, NurseLocationReportSearchForm } from 'types/interfaces'
import { 
  DataGridPro, 
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import ReportSelectBox from 'pages/AdminReports/ReportSelectBox'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import update from 'immutability-helper'
import FormField from 'components/FormField'

const renderCell = (params: GridRenderCellParams<NurseLocationReportItem>) => {
  const item = params.row
  const pct = item.total_nurses > 0 ? (params.value / item.total_nurses * 100).toFixed(2) : 0
  return `${params.value} (${pct}%)`
}

const NurseLocationReport: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [form, setForm] = React.useState<NurseLocationReportSearchForm>({})
  const [items, setItems] = React.useState<NurseLocationReportItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchReport = React.useCallback(async () => {
    try {
      setLoading(true)
      setItems(await api.getNurseLocationReport(form))
    } catch (e) {
      showAlert('error', 'Error loading report')
    } finally {
      setLoading(false)
    }
  }, [api, form, showAlert])

  React.useEffect(() => {
    fetchReport()
  }, [])

  const columns: GridColDef<NurseLocationReportItem>[] = React.useMemo(() => [{
    minWidth: 400,
    headerName: 'Name',
    field: 'location_name',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Postcode',
    field: 'location_address',
    type: 'string',
  }, {
    minWidth: 150,
    headerName: 'Metro Area',
    field: 'location_metro_area',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Total Nurse',
    field: 'total_nurses',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Approved (basic) Nurse',
    field: 'approved_nurses',
    type: 'number',
    renderCell: renderCell,
  }, {
    minWidth: 200,
    headerName: 'Approved (extended) Nurse',
    field: 'extended_approved_nurses',
    type: 'number',
    renderCell: renderCell,
  }, {
    minWidth: 200,
    headerName: 'Unapproved Nurse',
    field: 'unapproved_nurses',
    type: 'number',
    renderCell: renderCell,
  }, {
    minWidth: 200,
    headerName: 'RTW Approved Nurse',
    field: 'rtw_approved_nurses',
    type: 'number',
    renderCell: renderCell,
  }, {
    minWidth: 200,
    headerName: 'RTW Unapproved Nurse',
    field: 'rtw_unapproved_nurses',
    type: 'number',
    renderCell: renderCell,
  }], [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Nurse Count by Postcodes'>
        <ReportSelectBox />
      </Header>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          name='metro_area'
          label='Metro Area'
          onChange={(e) => updateForm('metro_area', e.target.value)}
          value={form.metro_area ?? ''}/>
        <Button
          variant="contained"
          onClick={fetchReport}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      <DataGridPro 
        rows={items}
        columns={columns}
        getRowId={(item) => item.location_name}
      />
    </Box>
  )
}

export default NurseLocationReport