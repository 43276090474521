import React from 'react'
import get from 'lodash/get'
import { AddressForm, LoqateAddress } from 'types/interfaces'
import FormField from 'components/FormField'
import Paper from '@mui/material/Paper'
import { isEmpty } from 'lodash'
import AddressFormError from 'errors/AddressFormError'
import update from 'immutability-helper'

interface AddressSearchProps {
  label: string
  form: AddressForm
  error?: AddressFormError
  setForm: (form: AddressForm) => void
}

const AddressSearch: React.FC<AddressSearchProps> = ({ label, form, error, setForm }) => {
  const [loqateErrors, setLoqateErrors] = React.useState<string[]>([])
  const longitude = React.useRef(null);
  const latitude = React.useRef(null);
  
  React.useEffect(() => {
    const pca = get(window, 'pca');
    const fields = [
      { element: 'search_address', field: '', mode: pca.fieldMode.SEARCH },
      { element: "latitude", field: "{Latitude}", mode: pca.fieldMode.POPULATE },
      { element: "longitude", field: "{Longitude}", mode: pca.fieldMode.POPULATE },
    ];
    const options = {
      key: process.env.REACT_APP_LOQATE_KEY || '',
      setCountryByIP: false,
      countries: {
        codesList: "GBR"
      }
    }
    const control = new pca.Address(fields, options);
    control.listen("populate", (address: LoqateAddress) => {
      const lng = get(longitude.current, 'value')
      const lat = get(latitude.current, 'value')
      setForm(update(form, {
        address: { $set: address.Label },
        latitude: { $set: lat },
        longitude: { $set: lng },
        line1: { $set: address.Line1 },
        line2: { $set: address.Line2 },
        city: { $set: address.City },
        county: { $set: address.Province },
        country: { $set: address.CountryName },
        postcode: { $set: address.PostalCode }
      }))
    })
    control.listen("error", (error: string) => setLoqateErrors([error]))
    return () => control.destroy()
  }, [form, setForm])

  const searchAddressErrors = React.useMemo(() => {
    if (!isEmpty(loqateErrors)) {
      return loqateErrors
    } else if (error?.has_errors) {
      return ['Please type your postcode in the address box above, a dropdown will appear, then select your address from the dropdown.']
    } else {
      return []
    }
  }, [loqateErrors, error])

  return (
    <React.Fragment>
      <FormField
        name='search_address'
        label={label}
        errors={searchAddressErrors}
        helperText='Type in your postcode and then select from dropdown box'
      />
      <input type='hidden' name='latitude' ref={latitude}></input>
      <input type='hidden' name='longitude' ref={longitude}></input>
      {form.address ? <Paper sx={{ whiteSpace: 'pre-line', p: 1 }}>{form.address}</Paper> : null}
    </React.Fragment>
  )
}

export default AddressSearch