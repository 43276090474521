import { Box, Button, Typography } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { useJobEmployment } from 'contexts/JobEmploymentProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import SubmitReviewError from 'errors/SubmitReviewError'
import React from 'react'
import { SubmitReviewForm } from 'types/interfaces'
import update from 'immutability-helper'
import Header from 'components/Header'
import Loading from 'components/Loading'
import CheckboxSelectField from 'components/CheckboxSelectField'
import FormField from 'components/FormField'
import BlacklistedNurse from 'logic/BlacklistedNurse'
import { isEmpty } from 'lodash'

const ReviewNurse: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { je, reloadJobEmployment } = useJobEmployment()
  const [blacklistedNurses, setBlacklistedNurses] = React.useState<BlacklistedNurse[]>([])
  const [form, setForm] = React.useState<SubmitReviewForm>({ review_rating: 1 })
  const [error, setError] = React.useState<SubmitReviewError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchBlacklistedNurses = React.useCallback(async () => {
    if (!je) return
    setBlacklistedNurses(await api.listBlacklistNurse({ 
      practice_id: je.practice_id,
      nurse_id: je.nurse_id,
     }))
  }, [api, je])

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const submit = React.useCallback(async () => {
    if (!je) return
    try {
      setLoading(true)
      await api.submitReview(je.id, form)
      if (form.review_rating === 4 && isEmpty(blacklistedNurses)) {
        // if poor rating and nurse not yet blacklisted, add nurse to blacklist
        await api.addBlacklistNurse({
          nurse_id: je.nurse_id,
          practice_id: je.practice_id,
          employment_id: je.id,
        })
      }
      if (form.review_rating !== 4 && !isEmpty(blacklistedNurses)) {
        // if NOT poor rating but nurse is blacklisted, remove nurse from blacklist
        await api.deleteBlacklistNurse(blacklistedNurses[0].id)
      }
      await reloadJobEmployment()  // would also reload blacklisted nurses
      showAlert('success', 'Your review is submitted. Thanks for your feedback!')
    } catch (e) {
      if (e instanceof SubmitReviewError) {
        setError(e)
      }
    } finally {
      setLoading(false)
    }
  }, [api, blacklistedNurses, form, je, reloadJobEmployment, showAlert])

  React.useEffect(() => {
    if (!je) return
    setForm(update(form, {
      review_rating: { $set: je.review_rating ?? 1 },
      review_notes: { $set: je.review_notes },
    }))
  }, [je])

  React.useEffect(() => {
    fetchBlacklistedNurses()
  }, [fetchBlacklistedNurses])

  if (!je) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text={`How's your experience with ${je.nurse_name}?`}></Header>
      <CheckboxSelectField 
        onChange={(value) => updateForm('review_rating', value)}
        value={form.review_rating}
        options={[{
          value: 1,
          label: 'Excellent',
        }, {
          value: 2,
          label: 'Good',
        }, {
          value: 3,
          label: 'Average',
        }, {
          value: 4,
          label: 'Poor (this nurse will no longer receive job notifications for your practice)',
        }]} />
      <Typography variant='body1'>The nurse will not see your review. It is only for locumloop's internal evaluation purpose.</Typography>
      <FormField 
        label='Anything else would you like to share?' 
        multiline
        rows={4}
        value={form.review_notes ?? ''}
        errors={error?.review_notes}
        onChange={(e) => updateForm('review_notes', e.target.value)}
      />
      <Button 
        variant='contained' 
        color='primary'
        disabled={loading}
        onClick={submit}
      >
        {loading ? 'Please Wait ...' : 'Submit Review'}
      </Button>
    </Box>
  )
}

export default ReviewNurse