import JobEmployment from 'logic/JobEmployment'
import React from 'react'

interface IAdminEmploymentDetailContext {
  employment: JobEmployment
  fetchEmployment: () => Promise<void>
}

const AdminEmploymentDetailContext = React.createContext<IAdminEmploymentDetailContext>({} as IAdminEmploymentDetailContext)

export default AdminEmploymentDetailContext