import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { usePractice } from 'contexts/PracticeProvider'
import { useAPI } from 'contexts/APIProvider'
import PracticeProfileError from 'errors/PracticeProfileError'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { practiceJobsURL } from 'routes/urls'

const PracticeContractAgreement: React.FC = () => {
  const { practice } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [loading, setLoading] = React.useState<boolean>(false)

  async function agree() {
    if (practice) {
      try {
        setLoading(true)
        await api.editPractice({ agreed_contracts: true }, practice.practice_id)
        window.location.replace(practiceJobsURL(practice.practice_id));
      } catch (e: any) {
        if (e instanceof PracticeProfileError) {
          showAlert('error', 'You must agree to our terms and conditions if you wish to work with us.')
        } else {
          console.error('unhandled exception', e)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant='h1'>We have updated our terms and conditions. </Typography>
      <Typography variant='body1'>
        Please review the following contracts: <br/>
        <a href='/legal/locumloop_practice.pdf' target='_blank' rel='noopener noreferrer'>Agreement between practice and locumloop</a> <br/>
        <a href='/legal/practice_nurse.pdf' target='_blank' rel='noopener noreferrer'>Agreement between practice and nurse</a> <br/>
      </Typography>
      <Button 
        variant='contained'
        onClick={agree} 
        disabled={loading}>
          {loading ? 'Please Wait ...' : 'I Agree'}
      </Button>
    </Box>
  )
}

export default PracticeContractAgreement