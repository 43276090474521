import React from 'react'
import { Box, Alert, Button } from '@mui/material'
import Header from 'components/Header'
import FormField from 'components/FormField'
import AddressSearch from 'components/AddressSearch'
import ErrorList from 'components/ErrorList'
import { PracticeGroupPracticeForm } from 'types/interfaces'
import PracticeGroupPracticeError from 'errors/PracticeGroupPracticeError'
import update from 'immutability-helper'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { usePracticeGroup } from 'contexts/PracticeGroupProvider'
import { useNavigate } from 'react-router'
import { practiceGroupPracticeListURL } from 'routes/urls'
import { useAuthUser } from 'contexts/AuthUserProvider'

const PracticeGroupAddPractice: React.FC = () => {
  const { refreshUser } = useAuthUser()
  const { practiceGroup } = usePracticeGroup()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()
  const [form, setForm] = React.useState<PracticeGroupPracticeForm>({})
  const [error, setError] = React.useState<PracticeGroupPracticeError>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const save = React.useCallback(async () => {
    if (!practiceGroup) return
    try {
      setLoading(true)
      await api.createPracticeGroupPractice({ practice_group_id: practiceGroup.id, ...form})
      await refreshUser(true)  // user claims is changed to grant practice_group users access to the new practice.
      showAlert('success', 'New Practice Created')
      navigate(practiceGroupPracticeListURL(practiceGroup.id))
    } catch (e) {
      if (e instanceof PracticeGroupPracticeError) {
        setError(e)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, form, navigate, practiceGroup, showAlert])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Add Practice' />
      <FormField
        name='name'
        label='Practice Name'
        onChange={(e) => updateForm('name', e.target.value)}
        value={form.name}
        errors={error?.name}
      />
      <FormField
        name='phone_for_inquiries'
        label='Phone Number (General Inquiries)'
        onChange={(e) => updateForm('phone_for_inquiries', e.target.value)}
        value={form.phone_for_inquiries}
        errors={error?.phone_for_inquiries}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='phone_for_notifications'
        label='Phone Number (to receive SMS notifications)'
        onChange={(e) => updateForm('phone_for_notifications', e.target.value)}
        value={form.phone_for_notifications}
        errors={error?.phone_for_notifications}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='email_for_inquiries'
        label='Email (General Inquiries)'
        onChange={(e) => updateForm('email_for_inquiries', e.target.value)}
        value={form.email_for_inquiries}
        errors={error?.email_for_inquiries}
      />
      <FormField
        name='email_for_notifications'
        label='Email (to receive notifications)'
        onChange={(e) => updateForm('email_for_notifications', e.target.value)}
        value={form.email_for_notifications}
        errors={error?.email_for_notifications}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button
        variant="contained"
        color="primary"
        onClick={save}
        disabled={loading}>
        {loading ? 'Please Wait ...' : 'Create Practice'}
      </Button>
    </Box>
  )
}

export default PracticeGroupAddPractice