import { Expose, Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class NursePayout {
  amount!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  arrival_date!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created!: DateTime

  status!: string

  arrival_date_label(tz: string) {
    return this.arrival_date.setZone(tz).toFormat('LLL dd yyyy')
  }

  created_date_label(tz: string) {
    return this.created.setZone(tz).toFormat('LLL dd yyyy')
  }

  @Expose()
  get amount_label() {
    return `£${this.amount / 100}`
  }
}