import React from 'react'
import { useNavigate } from 'react-router'
import DescriptionList from 'components/DescriptionList'
import Header from 'components/Header'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import { useNurse } from 'contexts/NurseProvider'
import useTakeJob from 'hooks/useTakeJob'
import Button from '@mui/material/Button';
import { nurseJobsURL } from 'routes/urls'
import { useJob } from 'contexts/JobProvider'
import { Alert, Box } from '@mui/material'
import Loading from 'components/Loading'


const NurseTakeJob: React.FC = () => {
  const navigate = useNavigate()
  const { nurse } = useNurse()
  const { takeJob, takeJobInProgress } = useTakeJob()
  const { job } = useJob()

  function goBack() {
    if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
  }

  function save() {
    if (job !== undefined) takeJob(job)
  }

  if (job === undefined || !nurse) return null
  if (takeJobInProgress) return <Loading />

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h3' text={job.date_label(nurse.tz)}>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
        label: 'Practice',
        value: nurse.approved ? job.practice_label : 'A Practice Nearby'
      }, {
        label: 'Role',
        value: job.job_role
      }, {
        label: 'Address',
        value: nurse.approved ? job.address : 'Will be displayed once your account is approved'
      }, {
        label: 'Phone Number',
        value: nurse.approved ? job.phone_number : 'Will be displayed once your account is approved'
      }, {
        label: 'Date',
        value: job.date_label(nurse.tz)
      }, {
        label: 'Time',
        value: job.time_label(nurse.tz)
      }, {
        label: 'Lunch Break',
        value: job.lunch_break_label,
      }, {
        label: 'Estimated Earnings',
        value: job.estimated_earnings_detail(nurse.paygrade_rate)
      }, {
        label: 'Number of nurses needed',
        value: job.outstanding.toString(),
      }]}></DescriptionList>
      {job.description ? (
        <DescriptionList labelWidth='30%' valueWidth='70%' data={[{
          label: 'Description',
          value: job.description
        }]}></DescriptionList>
      ) : null}
      <Alert severity='info' variant='filled'>
        Payment Schedule: {job.payment_duration_label}<br/>
        <Box sx={{ whiteSpace: 'pre-line' }}>
          {job.payment_duration_explanation}
        </Box>
      </Alert>
      {job.can_take ? (
        <Button variant="contained" color="primary" onClick={save} disabled={takeJobInProgress}>
          {takeJobInProgress ? 'Please Wait ...' : 'Take Job'}
        </Button>
      ) : (
        <Alert severity="warning" variant='filled'>{ job.cannot_take_reason }</Alert>
      )}
    </Paper>
  )
}

export default NurseTakeJob