import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  MenuItem,
  Button,
} from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import BillingAccount from 'logic/BillingAccount'
import Invoice from 'logic/Invoice'
import React from 'react'
import update, { extend } from 'immutability-helper'
import { InvoiceLateFeeForm, IssueInvoiceLateFeeForm } from 'types/interfaces'
import Loading from 'components/Loading'
import Header from 'components/Header'
import FormField from 'components/FormField'
import { assign, chain, isEmpty } from 'lodash'
import { fromCentsToPounds, fromPountsToCents } from 'logic/helpers'
import CheckboxFormField from 'components/CheckboxFormField'
import { useNavigate } from 'react-router'
import { adminInvoiceListURL } from 'routes/urls'

extend('$auto', function (value, object) {
  return object ?
    update(object, value) :
    update({}, value);
});

interface IssueInvoiceLateFeeProps {
  practice_id?: number
  practice_group_id?: number
}

interface IForm extends InvoiceLateFeeForm {
  selected?: boolean
}

interface IFormSet {
  billing_account_id?: number
  description?: string
  late_fees?: Record<string, IForm>
}

const IssueInvoiceLateFee: React.FC<IssueInvoiceLateFeeProps> = ({ practice_id, practice_group_id }) => {
  const [billingAccounts, setBillingAccounts] = React.useState<BillingAccount[]>([])
  const [invoices, setInvoices] = React.useState<Invoice[]>([])
  const [form, setForm] = React.useState<IFormSet>({})
  const [loading, setLoading] = React.useState<boolean>(false)

  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()

  const updateBillingAccountId = React.useCallback((billing_account_id: number) => {
    setForm(update(form, {
      billing_account_id: { $set: billing_account_id },
    }))
  }, [form])

  const updateInvoiceMemo = React.useCallback((description: string) => {
    setForm(update(form, {
      description: { $set: description },
    }))
  }, [form])

  const updateInvoiceSelection = React.useCallback((invoice_id: number, selected: boolean) => {
    setForm(update(form, {
      late_fees: {
        $auto: {
          [invoice_id]: {
            $auto: { 
              selected: { $set: selected },
              parent_invoice_id: { $set: invoice_id },
            }
          }
        }
      }
    }))
  }, [form])

  const updateLateFeeAmount = React.useCallback((invoice_id: number, amount: number) => {
    setForm(update(form, {
      late_fees: {
        $auto: {
          [invoice_id]: {
            $auto: { 
              amount: { $set: amount} 
            }
          }
        }
      }
    }))
  }, [form])

  const updateLateFeeDescription = React.useCallback((invoice_id: number, description: string) => {
    setForm(update(form, {
      late_fees: {
        $auto: {
          [invoice_id]: {
            $auto: { 
              description: { $set: description } 
            }
          }
        }
      }
    }))
  }, [form])

  const selectedBillingAccountId = React.useMemo(() => form.billing_account_id, [form.billing_account_id])

  const fetchBillingAccounts = React.useCallback(async () => {
    try {
      setLoading(true)
      setBillingAccounts(await api.listBillingAccounts({ practice_id, practice_group_id }))
    } catch (e) {
      console.error(e)
      showAlert('error', 'Failed to fetch billing accounts')
    } finally {
      setLoading(false)
    }
  }, [api, practice_id, practice_group_id, showAlert])

  const fetchInvoices = React.useCallback(async () => {
    try {
      setLoading(true)
      setInvoices(await api.listInvoices({ 
        billing_account_id: selectedBillingAccountId, 
        is_job_payments: true,
        status: 'open'
      }))
    } catch (e) {
      console.error(e)
      showAlert('error', 'Failed to fetch invoices')
    } finally {
      setLoading(false)
    }
  }, [api, selectedBillingAccountId, showAlert])

  const submitData: IssueInvoiceLateFeeForm = React.useMemo(() => {
    const late_fees = (
      chain(form.late_fees)
      .values()
      .filter((late_fee) => late_fee.selected ?? false)
      .value()
    )
    return assign({}, form, { late_fees })
  }, [form])

  const submit = React.useCallback(async () => {
    try {
      setLoading(true)
      await api.issueInvoiceLateFee(submitData)
      showAlert('success', 'Late Fee Invoice is issued successfully')
      navigate(adminInvoiceListURL())
    } catch (e) {
      console.error(e)
      showAlert('error', 'Failed to issue late fee invoices')
    } finally {
      setLoading(false)
    }
  }, [api, navigate, showAlert, submitData])

  React.useEffect(() => {
    fetchBillingAccounts()
  }, [fetchBillingAccounts])

  React.useEffect(() => {
    fetchInvoices()
  }, [fetchInvoices])

  React.useEffect(() => {
    updateBillingAccountId(billingAccounts[0]?.id)
  }, [billingAccounts, updateBillingAccountId])

  React.useEffect(() => {
    const initialLateFees = (
      chain(invoices)
      .filter((invoice) => invoice.is_overdue)
      .keyBy((invoice) => invoice.id)
      .mapValues((invoice) => ({
        selected: true,
        parent_invoice_id: invoice.id,
        amount: (invoice.amount_remaining * 0.1),
        description: `Late Fee for Invoice ${invoice.invoice_number}`,
      }))
      .value()
    )
    setForm(update(form, { late_fees: { $set: initialLateFees } }))
    // only pre-populate the form once when invoices are loaded initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices])

  if (loading) return <Loading />
  if (isEmpty(billingAccounts)) return <Alert severity='info'>Billing account not setup yet.</Alert>

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Issue Late Fee Invoice' />
      <FormField
        select
        name='billing_account'
        label='Billing Account'
        onChange={(e) => updateBillingAccountId(Number(e.target.value))}
        value={selectedBillingAccountId ?? ''}>
        {billingAccounts.map((ba) => (
          <MenuItem key={ba.id} value={ba.id}>{ba.name}</MenuItem>
        ))}
      </FormField>
      {isEmpty(invoices) ? (
        <Alert severity='info'>No invoices found</Alert>
      ) : (
        <React.Fragment>
        <FormField 
          name='description'
          label='Invoice Memo'
          value={form.description ?? ''}
          onChange={(e) => updateInvoiceMemo(e.target.value)}
        />
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Selected</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Invoice Amount</TableCell>
              <TableCell>Late Fees Issued Previously</TableCell>
              <TableCell>Late Fee Amount</TableCell>
              <TableCell>Late Fee Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((record) => (
              <TableRow key={record.id}>
                <TableCell data-label='Selected'>
                  <CheckboxFormField
                    onChange={(e) => updateInvoiceSelection(record.id, e.target.checked)}
                    checked={form.late_fees?.[record.id]?.selected ?? false}
                  />
                </TableCell>
                <TableCell data-label='Invoice'>{record.invoice_number}</TableCell>
                <TableCell data-label='Sent At'>{record.created_at_label()}</TableCell>
                <TableCell data-label='Status'>{record.status_label}</TableCell>
                <TableCell data-label='Amount'>{record.amount_label}</TableCell>
                <TableCell data-label='Late Fees Issued Previously'>{record.late_fees_label}</TableCell>
                <TableCell data-label='Late Fee Amount'>
                  <FormField
                    sx={{ width: 150 }}
                    type='number'
                    name='amount'
                    label='Amount'
                    value={fromCentsToPounds(form.late_fees?.[record.id]?.amount)}
                    onChange={(e) => updateLateFeeAmount(record.id, fromPountsToCents(e.target.value) ?? 0)}
                  />
                </TableCell>
                <TableCell data-label='Late Fee Description'>
                  <FormField
                    sx={{ width: 400 }}
                    name='description'
                    label='Description'
                    value={form.late_fees?.[record.id]?.description ?? ''}
                    onChange={(e) => updateLateFeeDescription(record.id, e.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          variant="contained"
          onClick={submit}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Issue Late Fee Invoice'}
        </Button>
        </React.Fragment>
      )}
    </Box>
  )
}

export default IssueInvoiceLateFee