import React from 'react'
import { useAPI } from './APIProvider'
import { useParams } from 'react-router'
import { useAuthUser } from './AuthUserProvider';
import PracticeGroup from 'logic/PracticeGroup'
import { useFirebase } from './FirebaseProvider'
import { collectionChanges } from 'rxfire/firestore'
import { collection, query, where } from "firebase/firestore";
import { filter, map, switchMap, from, share, Observable } from 'rxjs'
import { plainToClass } from 'class-transformer'
import { Notification } from 'logic/Notification'
import { DateTime } from 'luxon';

interface IPracticeGroupContext {
  practiceGroup?: PracticeGroup | null
  reloadPracticeGroup: () => Promise<void>
  paymentMethodNotification$: Observable<Notification>
}

const PracticeGroupContext = React.createContext<IPracticeGroupContext>({} as IPracticeGroupContext)

const PracticeGroupProvider: React.FC = ({ children }) => {
  const params = useParams()
  const { firestore } = useFirebase()
  const { api } = useAPI()
  const { authUser } = useAuthUser()
  const [practiceGroup, setPracticeGroup] = React.useState<PracticeGroup | null>()

  const practiceGroupId = React.useMemo(() => Number(params.practiceGroupId), [params.practiceGroupId]) 

  const reloadPracticeGroup = React.useCallback(async () => {
    if (authUser) {
      try {
        console.log('PracticeGroupProvider: fetchPracticeGroup')
        setPracticeGroup(await api.getPracticeGroup(practiceGroupId))
      } catch (e) {
        console.error(e)
        setPracticeGroup(null)
      }
    } else if (authUser === null) {
      setPracticeGroup(null)
    }
  }, [authUser, api, practiceGroupId])

  const notificationQuery = React.useMemo(
    () => query(
      collection(firestore, 'notifications'),
      where("practice_group_id", "==", practiceGroupId)
    ), 
    [firestore, practiceGroupId]
  )

  const notification$ = React.useMemo(
    () => collectionChanges(notificationQuery, { events: ['added'] }).pipe(
      switchMap(changes => from(changes)),
      map(change => plainToClass(Notification, change.doc.data())),
      share(),
    ), 
    [notificationQuery]
  )

  const paymentMethodNotification$ = React.useMemo(() => {
    const timestamp = DateTime.now()
    return notification$.pipe(
      filter(notification => notification.event === 'payment_method_attached' || notification.event === 'payment_method_detached'),
      filter(notification => notification.isAfter(timestamp)),
    )
  }, [notification$])

  React.useEffect(() => {
    reloadPracticeGroup()
  }, [reloadPracticeGroup])

  const value = { 
    practiceGroup, 
    reloadPracticeGroup,
    paymentMethodNotification$,
  }
  return (
    <PracticeGroupContext.Provider value={value}>
      {children}
    </PracticeGroupContext.Provider>
  )
}

function usePracticeGroup() {
  const context = React.useContext(PracticeGroupContext)
  if (context === undefined) {
    throw new Error('usePracticeGroup must be used within a PracticeGroupProvider')
  }
  return context
}

export { PracticeGroupProvider, usePracticeGroup }