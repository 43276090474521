import { Button, InputAdornment, Paper } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import FormField from 'components/FormField'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { fromCentsToPounds, fromPountsToCents } from 'logic/helpers'
import JobEmployment from 'logic/JobEmployment'
import React from 'react'

interface ChargeLateFeeProps {
  open: boolean
  setOpen: (value: boolean) => void
}

const ChargeLateFee: React.FC<ChargeLateFeeProps> = ({ open, setOpen }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [jeId, setJeId] = React.useState<number>()
  const [je, setJe] = React.useState<JobEmployment>()
  const [amount, setAmount] = React.useState<number>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchJobEmployment = React.useCallback(async () => {
    if (!jeId) return
    setJe(await api.getJobEmployment(jeId))
  }, [api, jeId])

  const submit = React.useCallback(async () => {
    if (!jeId) return
    try {
      setLoading(true)
      await api.chargeNurseLateCancelFee(jeId, { amount: amount })
      setOpen(false)
      showAlert('success', "Late fee charged")
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error charging late fee')
    } finally {
      setLoading(false)
    }
  }, [amount, api, jeId, setOpen, showAlert])
  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Charge Late Fee</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormField 
          label='Enter Match ID'
          type='number'
          value={jeId}
          onChange={(e) => setJeId(Number(e.target.value))}
        />
        <Button variant='outlined' onClick={fetchJobEmployment}>
          Find Match
        </Button>
        {je ? (
          <React.Fragment>
            <Paper sx={{ padding: 1 }}>
              Practice: {je.practice_name} <br />
              Nurse: {je.nurse_name} <br />
              Job Date: {je.date_label(je.nurse_tz)} <br />
            </Paper>
            <FormField
              label='Late Fee Amount'
              type='number'
              onChange={(e) => setAmount(fromPountsToCents(e.target.value))}
              value={fromCentsToPounds(amount)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyPoundIcon />
                  </InputAdornment>
                ),
              }}
            />
          </React.Fragment>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={submit} disabled={loading}>
          { loading ? 'Please Wait ...' : 'Charge Late Fee' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChargeLateFee