import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import CalendarControl from './Control'
import { Interval } from 'luxon'

interface CalendarProps {
  children: React.ReactNode
  interval?: Interval
  toNextMonth: () => void
  toPreviousMonth: () => void
  toCurrentMonth: () => void
}

const Calendar: React.FC<CalendarProps> = ({ children, interval, toNextMonth, toPreviousMonth, toCurrentMonth }) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: 0,
    }}>
      <CalendarControl
        sx={{ gridColumn: '1 / span 7' }}
        interval={interval}
        toNextMonth={toNextMonth}
        toPreviousMonth={toPreviousMonth}
        toCurrentMonth={toCurrentMonth} />
      {days.map(day => (
        <Typography key={day} variant='body2' sx={{ textAlign: 'center' }}>{day}</Typography>
      ))}
      {children}
    </Box>
  )
}

export default Calendar