import React from 'react'
import { FormControlLabel, RadioGroup, Radio, Link, Box, Typography } from '@mui/material'
import { approvalBooleanToString, approvalStringToBoolean } from 'logic/helpers'
import FormField from 'components/FormField'
import { VerifyDocumentForm } from 'types/interfaces'
import { DocumentType } from 'types/types'
import { useDialog } from 'contexts/DialogProvider'

type VerifyFormProps = {
    docType: DocumentType
    form: VerifyDocumentForm
    updateForm: (field: string, value: any) => void
    error: any
}

const VerifyForm: React.FC<VerifyFormProps> = ({ form, updateForm, error, docType }) => {
    const { showDialog } = useDialog()
    
    const hasExpiryDate = React.useMemo(() => ['indemnity_insurance', 'infection_control', 'cpr'].includes(docType), [docType])

    const openApprovalRulesDialog = React.useCallback(() => {
        showDialog('How to approve nurse documents', (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant='body1'>Basic Approval</Typography>
                <ul>
                    <li>GDC Certificate</li>
                    <li>Enhanced DBS</li>
                    <li>Hepatitis B Vaccination</li>
                    <li>Indemnity Insurance</li>
                    <li>Right to work</li>
                </ul>
                <Typography variant='body1'>Extended Approval</Typography>
                <ul>
                    <li>All the requirements for basic approval</li>
                    <li>Infection Control</li>
                    <li>CPR</li>
                </ul>
                <Typography variant='body1'>Scotland Approval</Typography>
                <ul>
                    <li>GDC Certificate</li>
                    <li>Scotland PVG (England's enhanced DBS is NOT OK)</li>
                    <li>Hepatitis B Vaccination</li>
                    <li>Hepatitis C antibody serology test</li>
                    <li>Tuberculosis BCG vaccination / Evidence of BCG scar / results of mantoux test / TB blood test result</li>
                    <li>HIV antibody serology test</li>
                    <li>Indemnity Insurance</li>
                    <li>Right to work</li>
                </ul>
                <Typography variant='body1'>Hygienist</Typography>
                <ul>
                    <li>All the requirements for basic approval</li>
                    <li>Resume / CV</li>
                </ul>
            </Box>
        ))
    }, [showDialog])
    
    return (
        <React.Fragment>
            <RadioGroup row
                value={approvalBooleanToString(form.approved)}
                onChange={(e, value) => updateForm('approved', approvalStringToBoolean(value))}>
                <FormControlLabel value='not-set' control={<Radio color='warning' />} label="Unset" />
                <FormControlLabel value='approved' control={<Radio color='success' />} label="Approve" />
                <FormControlLabel value='disapproved' control={<Radio color='error' />} label="Disapprove" />
            </RadioGroup>
            <Link onClick={openApprovalRulesDialog} sx={{ cursor: 'pointer' }}>
                Learn about our approval rules
            </Link>
            {!form.approved ? (
                <FormField
                    name='reason'
                    label='Reason'
                    multiline
                    rows={4}
                    onChange={(e) => updateForm('reason', e.target.value)}
                    value={form.reason}
                    errors={error?.reason}
                />
            ) : null}
            {hasExpiryDate ? (
                <FormField
                    name='date'
                    label='Expiry Date'
                    type='date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => updateForm('date', e.target.value)}
                    value={form.date ?? ''}
                />
            ) : null}
        </React.Fragment>
    )
}

export default VerifyForm