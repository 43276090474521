import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Check from '@mui/icons-material/Check'
import Error from '@mui/icons-material/Error'
import PendingActions from '@mui/icons-material/PendingActions'
import Approval from '@mui/icons-material/Approval'
import { isEmpty } from 'lodash'
import NurseSignupContext from 'pages/NurseSignup/context'
import { useAPI } from 'contexts/APIProvider'
import { getStripeURL } from 'logic/helpers'

const StripeProgress: React.FC = () => {
  const { nurse } = React.useContext(NurseSignupContext)
  const { api } = useAPI()
  const [stripeURL, setStripeURL] = React.useState<string>()

  const fetchStripeURL = React.useCallback(async () => {
    if (!nurse) return null
    setStripeURL(await getStripeURL(api, nurse))
  }, [api, nurse])

  React.useEffect(() => {
    fetchStripeURL()
  }, [fetchStripeURL])

  if (!nurse) return null

  
  let icon, title, body, backgroundColor, showStripeButton
  let stripeButtonColor: 'inherit' | 'primary' | 'error' = 'inherit'
  let stripeButtonText = ''

  switch (nurse.stripe_step) {
    case 'COMPLETED':
      icon = <Check fontSize='large' sx={{ color: 'success.main' }} />
      title = 'Payment setup completed'
      body = <Typography variant='body2'>
        Your account can now receive payments
      </Typography>
      backgroundColor = 'success.light'
      showStripeButton = false
      break
    case 'SUBMITTED':
      if (!isEmpty(nurse.pending_stripe_requirements)) {
        icon = <Error fontSize='large' sx={{ color: 'error.main' }} />
        title = 'Payment setup in progress'
        body = (
          <React.Fragment>
            <Typography variant='body2'>
              Additional documents are needed.
            </Typography>
            <List dense disablePadding>
              {nurse.pending_stripe_requirements.map((req, i) => (
                <ListItem key={i} dense disableGutters disablePadding>
                  <Typography variant='body2'>{req}</Typography>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )
        backgroundColor = 'error.light'
        showStripeButton = true
        stripeButtonColor = 'error'
        stripeButtonText = 'Click here to provide the documents'
      } else {
        icon = <Approval fontSize='large' sx={{ color: 'warning.main' }} />
        title = 'Payment setup in progress'
        body = <Typography variant='body2'>
          Stripe is setting up your billing account. You will be notified when ready.
        </Typography>
        backgroundColor = 'warning.light'
        showStripeButton = false
      }
      break
    case 'PROCESSING':
      icon = <Approval fontSize='large' sx={{ color: 'warning.main' }} />
      title = 'Payment setup in progress'
      body = <Typography variant='body2'>
        Stripe is setting up your billing account.
      </Typography>
      backgroundColor = 'warning.light'
      showStripeButton = true
      stripeButtonText = 'If you have not completed your payment setup, click here'
      break
    case 'NOT_STARTED':
      icon = <PendingActions fontSize='large' sx={{ color: 'primary.main' }} />
      title = 'Payment setup required.'
      body = <Typography variant='body2'>
        In order to receive payments, we need to setup your billing account with Stripe, our payments processor.
      </Typography>
      backgroundColor = 'background.paper'
      showStripeButton = true
      stripeButtonText = 'Begin payment setup'
      stripeButtonColor = 'primary'
      break
  }

  return (
    <Paper sx={{
      padding: 1,
      display: 'grid',
      gridTemplateColumns: ['1fr 4fr', '1fr 9fr'],
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
      "icon title"
      "icon content"
      "icon button"`,
      backgroundColor: backgroundColor
    }}>
      <Box sx={{ gridArea: 'icon', justifySelf: 'center', alignSelf: 'center' }}>
        {icon}
      </Box>
      <Typography sx={{ gridArea: 'title' }} variant='h5'>{title}</Typography>
      <Box sx={{ gridArea: 'content' }}>
        {body}
      </Box>
      <Button
        sx={{ display: showStripeButton ? 'block' : 'none', gridArea: 'button' }}
        disabled={!stripeURL}
        color={stripeButtonColor}
        component="a" href={stripeURL}>
        {!stripeURL ? 'Please Wait ...' : stripeButtonText}
      </Button>
      <Box sx={{ gridArea: 'buttons', display: 'flex', alignItems: 'center', gap: 1 }}></Box>
    </Paper>
  )
}

export default StripeProgress