import React from 'react'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { Navigate, useLocation } from 'react-router'
import { homeURL, loginURL } from './urls'

const IsAdmin: React.FC = ({ children }) => {
  const location = useLocation()
  const { authUser, logout } = useAuthUser()

  if (authUser === undefined) {
    return null
  } else if (authUser === null) {
    return <Navigate replace to={loginURL(location.pathname)} />
  } else if (authUser.isStaff) {
    return <React.Fragment>{children}</React.Fragment>
  } else {
    const redirectURL = homeURL(authUser)
    if (redirectURL) {
      return <Navigate replace to={redirectURL} />
    } else {
      logout()
      return <Navigate replace to={loginURL()} />
    }
  }
}

export default IsAdmin