import { Button } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'
import AdminJobTable from 'components/AdminJobTable'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import APIError from 'errors/APIError'
import Job from 'logic/Job'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobDetailURL } from 'routes/urls'
import { Subject, startWith } from 'rxjs'

const AdminPracticeJobs: React.FC = () => {
  const {
    practice,
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
  } = React.useContext(AdminPracticeDetailContext)

  const { api } = useAPI()
  const navigate = useNavigate()
  const { showAlert } = useSnackBarAlert()

  const reload$ = React.useMemo(() => new Subject<boolean>(), [])

  const onRowClick = React.useCallback((params: GridRowParams<Job>) => {
    navigate(adminJobDetailURL(params.row.job_id))
  }, [navigate])

  const publishJob = React.useCallback(async () => {
    try {
      if (practice.vetted_at === null) {
        // vet practice and publish all jobs
        await api.vetPractice(practice.practice_id)
      } else {
        // just publish all jobs
        await api.publishJobs(practice.practice_id)
      }
      reload$.next(true)
      showAlert('success', 'Job Published')
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    }
  }, [api, practice.practice_id, practice.vetted_at, reload$, showAlert])
  
  return (
    <React.Fragment>
      <AdminJobTable
        filters={['fulfillment_statuses', 'payment_statuses', 'timesheet_statuses', 'from_date', 'to_date']}
        reload$={reload$.pipe(startWith(true))}
        actions={
          <Button variant='contained' onClick={publishJob}>Publish All Jobs</Button>
        }
        onRowClick={onRowClick}
        form={jobSearchForm}
        updateForm={updateJobSearchForm}
        selection={jobSelection}
        setSelection={setJobSelection}
        columnVisibilityModel={jobColumnVisibilityModel}
        setColumnVisibilityModel={setJobColumnVisibilityModel}
        sortModel={jobSortModel}
        setSortModel={setJobSortModel}
      />
    </React.Fragment>
  )
}

export default AdminPracticeJobs