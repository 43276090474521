import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'
import { InvoiceStatus } from 'types/types'

export default class InvoiceLateFee {
    id!: number
    parent_invoice_id!: number
    pay_via_invoice_id!: number
    amount!: number
    description!: string
    invoice_item_id!: string
    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    created_at!: DateTime | null

    // annotate invoice
    parent_invoice_number!: string
    parent_invoice_status!: InvoiceStatus
    pay_via_invoice_number!: string
    pay_via_invoice_status!: InvoiceStatus
    
    created_at_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd') {
        return this.created_at?.setZone(tz).toFormat(format) ?? 'N/A'
    }

    get late_fee_amount_label() {
        return `£${(this.amount / 100).toFixed(2)}`
    }
}