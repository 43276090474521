import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminNurseJobs: React.FC = () => {
  const {
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
  } = React.useContext(AdminNurseDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      exclude_filters={['nurse']}
      onRowClick={onRowClick}
      form={jobSearchForm}
      updateForm={updateJobSearchForm}
      selection={jobSelection}
      setSelection={setJobSelection}
      columnVisibilityModel={jobColumnVisibilityModel}
      setColumnVisibilityModel={setJobColumnVisibilityModel}
      sortModel={jobSortModel}
      setSortModel={setJobSortModel}
    />
  )
}

export default AdminNurseJobs