import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

const Loading: React.FC = () => {
  return (
    <Box>
      <Typography>Please Wait ...</Typography>
      <LinearProgress />
    </Box>
  )
}

export default Loading