import React from 'react'
import DescriptionList from 'components/DescriptionList'
import Header from 'components/Header'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import JobEmployment from 'logic/JobEmployment'
import { usePractice } from 'contexts/PracticeProvider'
import { useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useAPI } from 'contexts/APIProvider'
import { isEmpty } from 'lodash'
import { practiceJobsURL, practiceEditJobURL, practiceCancelJobURL, practiceProposeTimeSheetChangeURL, practiceViewTimeSheetURL, reviewNurseURL, practiceViewNurseDocumentsURL, practiceAddJobURL } from 'routes/urls'
import { Box, Paper, Alert, Button, IconButton, Link } from '@mui/material'
import { useJob } from 'contexts/JobProvider'
import useApproveTimeSheetChange from 'hooks/useApproveTimeSheetChange'
import Loading from 'components/Loading'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useZendesk } from 'contexts/ZendeskProvider'
import { DateTime } from 'luxon'

const PracticeViewJob: React.FC = () => {
  const navigate = useNavigate()
  const { authUser } = useAuthUser()
  const { api } = useAPI()
  const { openZendesk } = useZendesk()
  const { practice } = usePractice()
  const { job } = useJob()
  const { approveTimeSheetChange, approveTimeSheetChangeInProgress, rejectTimeSheetChange } = useApproveTimeSheetChange()
  const [jobEmployments, setJobEmployments] = React.useState<JobEmployment[]>()

  const fetchEmployments = React.useCallback(async () => {
    if (job === undefined) return
    setJobEmployments(await api.listJobEmployments({ job_id: job.job_id }))
  }, [api, job])

  const canAdd = React.useMemo(() => {
    if (!job || !authUser) {
      return false
    }
    return job.start_at.startOf('day') >= DateTime.now().startOf('day')
  }, [authUser, job])

  const canEdit = React.useMemo(() => {
    if (!job || !authUser) {
      return false
    }
    else if (job.can_edit) {
      return authUser.isStaff || isEmpty(jobEmployments)
    }
    return false
  }, [authUser, job, jobEmployments])

  React.useEffect(() => {
    fetchEmployments()
  }, [fetchEmployments])

  if (job === undefined || !practice) {
    return null
  }

  if (approveTimeSheetChangeInProgress) return <Loading />

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Header variant='h3' text={job.date_label(practice.tz)}>
          <IconButton onClick={_ => navigate(practiceJobsURL(practice.practice_id))}>
            <CancelOutlined />
          </IconButton>
        </Header>
        <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <DescriptionList labelWidth='40%' valueWidth='60%' data={[{
            label: 'Time',
            value: job.time_label(practice.tz)
          }, {
            label: 'Lunch Break',
            value: job.lunch_break_label,
          }, {
            label: 'Number of Nurses Required',
            value: job.headcount.toString()
          }, {
            label: 'Role',
            value: job.job_role
          }]}></DescriptionList>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: ['column', 'column', 'row'] }}>
            {canAdd ? (
              <Button
                variant="contained" 
                color="primary"
                component={RouterLink}
                to={practiceAddJobURL(practice.practice_id, job.start_at.toFormat('yyyy-LL-dd'))}>
                  Add Another Job
                </Button>
            ) : null}
            {canEdit ? (
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={practiceEditJobURL(practice.practice_id, job.job_id)}>
                Edit Job
              </Button>
            ) : null}
            {job?.can_cancel ? (
              <Button
                variant="outlined" 
                color="error"
                component={RouterLink}
                to={practiceCancelJobURL(practice.practice_id, job.job_id)}>
                Cancel Job
              </Button>
            ) : null}
          </Box>
        </Paper>
        {job.status_alert}
        <Header variant='h3' text='Nurses Hired'></Header>
        {jobEmployments === undefined ? (
          <Loading />
        ) : isEmpty(jobEmployments) ? (
          <Alert severity="info">No nurse hired yet.</Alert>
        ) : (
          jobEmployments.map(employment => (
            <Paper key={employment.id} sx={{
              padding: 1,
              display: ['flex', 'flex', 'flex', 'grid'],
              flexDirection: 'column',
              gridTemplateColumns: '1fr auto',
              gridTemplateRows: '1fr auto',
              gridTemplateAreas: `
              'description buttons'
              'status status'`,
              gap: 1
            }}>
              <Box sx={{ gridArea: 'description' }}>
                <DescriptionList labelWidth='30%' valueWidth='70%' data={[{
                  label: 'Name',
                  value: employment.nurse_name
                }, {
                  label: 'GDC Number',
                  value: employment.nurse_gdc_number
                }, {
                  label: 'Time',
                  value: employment.time_label(employment.practice_tz)
                }, {
                  label: 'Lunch Break',
                  value: employment.lunch_break_label
                }]}></DescriptionList>
                { !employment.is_ended ? (
                  <DescriptionList data={[{
                    label: 'Phone',
                    value: employment.nurse_phone_number,
                  }]} />
                ) : null }
                {
                  employment.payment_error_label ? (
                    <DescriptionList data={[{
                      label: 'Payment Error',
                      value: employment.payment_error_label
                    }]}></DescriptionList>
                  ) : null
                }
                {
                  employment.payment_status ? (
                    <DescriptionList data={[{
                      label: 'Billable Hours',
                      value: employment.billable_duration_label
                    }, {
                      label: 'Hourly Rate',
                      value: employment.hourly_rate_label
                    }, {
                      label: 'Nurse Fees',
                      value: employment.nurse_fees_label
                    }, {
                      label: 'Locumloop Fees',
                      value: employment.locumloop_fees_label
                    }, {
                      label: 'Total Fees',
                      value: employment.total_fees_label
                    }]}></DescriptionList>
                  ) : null
                }
              </Box>
              <Box sx={{ gridArea: 'status' }}>
                {employment.status_alert('practice', openZendesk)}
              </Box>
              <Box sx={{ gridArea: 'buttons', display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center' }}>
                {employment.fulfillment_status !== 'cancelled' ? (
                  <Button
                    component={RouterLink}
                    variant="contained" color="primary"
                    to={practiceViewTimeSheetURL(practice.practice_id, employment.id)}>
                    View Timesheet
                  </Button>
                ) : null}
                {employment.timesheet_status === 'timesheet_pending_practice_approval' ? (
                  <React.Fragment>
                    <Button 
                      onClick={() => approveTimeSheetChange(employment.latest_timesheet_change_id)}
                      variant="contained" color="success">
                      Accept Changes
                    </Button>
                    <Button 
                      onClick={() => rejectTimeSheetChange()}
                      variant="contained" color="error">
                      Reject Changes
                    </Button>
                  </React.Fragment>
                ) : null}
                {employment.can_propose_timesheet_change ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={practiceProposeTimeSheetChangeURL(practice.practice_id, employment.id)}>
                    Propose TimeSheet Changes
                  </Button>
                ) : null}
                {employment.can_review ? (
                  <Button
                    variant='contained'
                    color='primary'
                    component={RouterLink}
                    to={reviewNurseURL(practice.practice_id, employment.id)}>
                    Rate the Nurse
                  </Button>
                ) : null}
                {employment.can_view_documents ? (
                  <Button
                    variant='contained'
                    color='primary'
                    component={RouterLink}
                    to={practiceViewNurseDocumentsURL(practice.practice_id, employment.id, employment.nurse_id)}>
                    View Documents
                  </Button>
                ) : null}
              </Box>
            </Paper>
          ))
        )}
      </Box>
    </React.Fragment >
  )
}

export default PracticeViewJob