import { Type, Transform } from 'class-transformer'
import { fromKmToMiles } from 'logic/helpers'
import { Duration, DateTime } from 'luxon'
import { PRACTICE_STEP_COMPLETED, PRACTICE_STEP_POST_JOBS } from 'types/constants'
import { PaymentOption, PracticeLateFeePolicy, Role } from 'types/types'

export default class Practice {
  firebase_id!: string
  practice_id!: number
  practice_name!: string
  address!: string
  line1!: string
  line2!: string
  city!: string
  county!: string
  country!: string
  postcode!: string
  district!: string
  latitude?: number
  longitude?: number
  email_for_inquiries!: string
  email_for_notifications!: string
  phone_for_inquiries!: string
  phone_for_notifications!: string
  tz!: string
  locumloop_fees!: number  // in cents
  locumloop_hygienist_fees!: number  // in cents
  enabled!: boolean
  agreed_contracts!: boolean
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  // first member
  first_member_user_id!: number
  first_member_user_first_name!: string
  first_member_user_last_name!: string
  first_member_user_email!: string
  first_member_position!: string

  // affiliate
  affiliate_id!: number | null
  affiliate_campaign_id!: number | null
  affiliate_campaign_name!: string | null
  affiliate_name!: string | null
  affiliate_code!: string | null
  affiliate_nurse_id!: number | null

  // jobs count
  jobs_total!: number
  jobs_last_30_days!: number
  jobs_last_90_days!: number
  jobs_upcoming!: number
  jobs_taken_total!: number
  jobs_taken_last_30_days!: number
  jobs_taken_last_90_days!: number
  jobs_taken_upcoming!: number

  // practice group
  practice_group_id!: number
  practice_group_name!: string

  // billing info
  is_stripe_ready!: boolean
  not_stripe_ready_reason!: string
  billing_account_id!: number
  stripe_customer_id!: string
  payment_method_id!: number
  payment_option!: PaymentOption
  max_payment_days!: number

  // late cancellation policy
  late_cancellation_policy!: PracticeLateFeePolicy

  // only available if listPractice with nurse_id
  // shows distance between the nurse and the practice
  distance_km!: number | null

  // default job options
  start_time!: string
  end_time!: string
  description!: string
  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  lunch_break!: Duration

  // vetting
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  vetted_at!: DateTime | null

  get latlng() {
    if (!this.latitude || !this.longitude) {
        return null
    }
    return {
        lat: this.latitude,
        lng: this.longitude
    }
}

  get defaultJobOptionLabel() {
    let start_time = DateTime.fromFormat(this.start_time, 'HH:mm:ss').toFormat('h:mm a')
    let end_time = DateTime.fromFormat(this.end_time, 'HH:mm:ss').toFormat('h:mm a')
    return `from ${start_time} to ${end_time}`
  }

  get location_label() {
    return `${this.city} (${this.district})`
  }

  locumloop_fees_label(role: Role) {
    if (role === 'hygienist') {
      return `£${this.locumloop_hygienist_fees / 100}`
    }
    return `£${this.locumloop_fees / 100}`
  }

  get first_member_label() {
    return `${this.first_member_user_first_name} ${this.first_member_user_last_name} (${this.first_member_user_email})`
  }

  get distance_miles() {
    return this.distance_km !== null ? fromKmToMiles(this.distance_km) : null
  }

  get distance_label() {
    return this.distance_miles !== null ? `${this.distance_miles} miles` : 'N/A'
  }

  get signup_at_label() {
    const countdown = this.created_at.diffNow().negate().shiftTo('days', 'hours', 'minutes').toHuman({ unitDisplay: 'short' })
    const dt = this.created_at.toFormat('ccc, LLL dd')
    return `${countdown} ago (${dt})`
  }

  get signup_at_color() {
    return this.created_at.diffNow().as('hours') < -1 ? 'success' : 'error'
  }

  get signup_step() {
    return this.is_stripe_ready ? PRACTICE_STEP_COMPLETED : PRACTICE_STEP_POST_JOBS
  }

  get contacts_label() {
    return `${this.email_for_inquiries} (Email for Inquiries)
    ${this.email_for_notifications} (Email for Notifications)
    ${this.phone_for_inquiries} (Phone for Inquiries)
    ${this.phone_for_notifications} (Phone for Notifications)
    `
  }
}