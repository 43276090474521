import React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Button from '@mui/material/Button'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import CancelIcon from '@mui/icons-material/Cancel'
import CameraSwitch from '@mui/icons-material/Cameraswitch'
import Modal from '@mui/material/Modal'
import Webcam from "react-webcam"
import IUpload from './IUpload'

const CAMERA_FORMAT = 'image/jpeg'

type FacingMode = 'user' | 'environment'

const CameraUpload: React.FC<IUpload> = ({ save }) => {
  const webcamRef = React.useRef<Webcam>(null)
  const [showCamera, setShowCamera] = React.useState<boolean>(false)
  const [facing, setFacing] = React.useState<FacingMode>('user')

  const openCamera = React.useCallback(() => {
    setShowCamera(true)
  }, [])

  const closeCamera = React.useCallback(() => {
    setShowCamera(false)
  }, [])

  const switchCamera = React.useCallback(() => {
    setFacing(facing === 'user' ? 'environment' : 'user')
  }, [facing])

  const takePicture = React.useCallback(() => {
    const file = webcamRef?.current?.getScreenshot()
    if (file) save(file, CAMERA_FORMAT)
    closeCamera()
  }, [webcamRef, save, closeCamera])

  return (
    <React.Fragment>
      <Button
        size='small'
        variant="contained"
        endIcon={<PhotoCamera />}
        onClick={openCamera}>
        Take Picture
      </Button>
      <Modal
        hideBackdrop
        open={showCamera}
        onClose={closeCamera}
      >
        <Box sx={{
          position: 'absolute',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat={CAMERA_FORMAT}
            videoConstraints={{
              facingMode: facing
            }}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}>
            <Fab color="primary" onClick={takePicture}>
              <PhotoCamera />
            </Fab>
            <Fab onClick={switchCamera}>
              <CameraSwitch />
            </Fab>
            <Fab onClick={closeCamera}>
              <CancelIcon />
            </Fab>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default CameraUpload