import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import { useAuthUser } from 'contexts/AuthUserProvider'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
    adminPracticeListURL,
    adminNurseListURL,
    adminJobListURL,
    adminJobEmploymentListURL,
    adminPracticeGroupListURL,
    adminAffiliateListURL,
    adminPracticeReportURL,
    adminInvoiceListURL,
    adminUserListURL,
  } from 'routes/urls'

const AdminTopNav: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuthUser()
  const logo = process.env.PUBLIC_URL + '/logo-rectangle.png'

  function goToRoot() {
    navigate('/')
  }

  return (
    <AppBar position="static" color='inherit'>
      <Toolbar variant='regular' disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box component='img' src={logo} alt='logo' sx={{ height: ['1.5rem', '2.5rem'], cursor: 'pointer' }} onClick={goToRoot}></Box>
        </Box>
        <Button variant="text" component={Link} to={adminUserListURL()}>Practice Users</Button>
        <Button variant="text" component={Link} to={adminPracticeGroupListURL()}>Practice Groups</Button>
        <Button variant="text" component={Link} to={adminPracticeListURL()}>Practices</Button>
        <Button variant="text" component={Link} to={adminNurseListURL()}>Nurses</Button>
        <Button variant="text" component={Link} to={adminJobListURL()}>Jobs</Button>
        <Button variant="text" component={Link} to={adminJobEmploymentListURL()}>Matches</Button>
        <Button variant="text" component={Link} to={adminInvoiceListURL()}>Invoices</Button>
        <Button variant="text" component={Link} to={adminAffiliateListURL()}>Affiliates</Button>
        <Button variant="text" component={Link} to={adminPracticeReportURL()}>Reports</Button>
        {/* <Button variant="text" component={Link} to={adminCommunicationsURL()}>Communications</Button> */}
        {/* <Button variant="text" component={Link} to={adminReviewListURL()}>Reviews</Button> */}
        <IconButton onClick={logout}>
            <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AdminTopNav