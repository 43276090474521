import { GridRowParams } from '@mui/x-data-grid'
import AdminPracticeTable from 'components/AdminPracticeTable'
import Practice from 'logic/Practice'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminPracticeDetailURL } from 'routes/urls'

const AdminNurseNearbyPractice: React.FC = () => {
  const {
    nearbyPracticeSearchForm,
    nearbyPracticeSelection,
    nearbyPracticeColumnVisibilityModel,
    nearbyPracticeSortModel,
    updateNearbyPracticeSearchForm,
    setNearbyPracticeSelection,
    setNearbyPracticeColumnVisibilityModel,
    setNearbyPracticeSortModel,
  } = React.useContext(AdminNurseDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<Practice>) => {
    navigate(adminPracticeDetailURL(params.row.practice_id))
  }, [navigate])

  return (
    <AdminPracticeTable
      onRowClick={onRowClick}
      form={nearbyPracticeSearchForm}
      updateForm={updateNearbyPracticeSearchForm}
      selection={nearbyPracticeSelection}
      setSelection={setNearbyPracticeSelection}
      columnVisibilityModel={nearbyPracticeColumnVisibilityModel}
      setColumnVisibilityModel={setNearbyPracticeColumnVisibilityModel}
      sortModel={nearbyPracticeSortModel}
      setSortModel={setNearbyPracticeSortModel}
    />
  )
}

export default AdminNurseNearbyPractice