import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import AddCircle from '@mui/icons-material/AddCircle'
import { PracticeDay } from 'logic/PracticeDay'
import React from 'react'
import { usePractice } from 'contexts/PracticeProvider'
import { useNavigate } from 'react-router'
import { practiceAddJobURL } from 'routes/urls'
import useWidth from 'hooks/useWidth'
import useAddJob from 'hooks/useAddJob'

interface PracticeCalendarDayProps {
  day: PracticeDay
  useDefaultJobOptions: boolean
}

const PracticeCalendarDay: React.FC<PracticeCalendarDayProps> = ({ day, useDefaultJobOptions }) => {
  const navigate = useNavigate()
  const { practice } = usePractice()
  const { addJob } = useAddJob()
  const { ref, width } = useWidth()
  const [loading, setLoading] = React.useState<boolean>(false)
  
  const clickURL = practice ? day.clickURL(practice.practice_id) : null

  async function onAddJob() {
    if (practice) {
      if (!useDefaultJobOptions) {
        navigate(practiceAddJobURL(practice.practice_id, day.date.toFormat('yyyy-LL-dd')))
      } else {
        try {
          setLoading(true)
          const data = {
            practice_id: practice.practice_id,
            date: day.date.toFormat('yyyy-LL-dd'),
            start_time: practice.start_time,
            end_time: practice.end_time,
            lunch_break: practice.lunch_break.as('minute'),
            headcount: 1,
            description: practice.description
          }
          const jobId = await addJob(data)
          if (jobId === undefined) setLoading(false)
        } catch (e) {
          console.error(e)
          setLoading(false)
        }
      }
    }
  }

  function onClick() {
    if (clickURL !== null) navigate(clickURL)
  }

  React.useEffect(() => {
    // there may be a delay before firestore is updated, so we always unset loading when there are jobs
    if (day.has_jobs) setLoading(false)
  }, [day])

  return (
    <div onClick={onClick}>
      <Box ref={ref} sx={{
        textAlign: 'center',
        padding: 0,
        cursor: clickURL === null ? 'none' : 'pointer'
      }}>
        {loading ? (
          <CircularProgress size={30} sx={{ marginTop: [0, 0, 1] }} />
        ) : day.canAddJob ? (
          <IconButton onClick={onAddJob}>
            <AddCircle sx={{
              fontSize: width ? width * 0.5 : '3em'
            }} />
          </IconButton>
        ) : (
          <Typography sx={{
            paddingTop: width ? width * 0.01 : '1px',
            fontSize: width ? width * 0.2 : '3em',
          }}>{day.label}</Typography>
        )}
      </Box>
    </div>
  )
}

export default PracticeCalendarDay