import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class PracticeMember {
  id!: number
  practice_id!: number
  practice_name!: string
  user_id!: number
  user_name!: string
  user_email!: string
  position!: string
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime
}