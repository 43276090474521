import React from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'
import useWidth from 'hooks/useWidth'

interface CalendarDayProps {
  children?: React.ReactNode
  dayOfMonth: string
  weekDayNumber: number  // from 0 to 6, including invalid dates
  weekNumber: number
  sx?: SxProps
}

const CalendarDay: React.FC<CalendarDayProps> = ({ children, dayOfMonth, weekDayNumber, weekNumber, sx = {} }) => {
  const { ref, width } = useWidth()

  return (
    <Box sx={{
      position: 'relative',
      borderWidth: '1px',
      borderColor: 'primary.main',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      borderLeftWidth: weekDayNumber === 0 ? 1 : 0,
      borderTopWidth: weekNumber === 0 ? 1 : 0,
      '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '100%'
      }
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0, left: 0,
        height: '100%', width: '100%',
        ...sx
      }}>
        <Box ref={ref} sx={{
          textAlign: 'center',
          fontSize: width ? width * 0.2 : '3em'
        }}>{dayOfMonth}</Box>
        {children}
      </Box>
    </Box>
  )
}

export default CalendarDay