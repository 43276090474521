import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from '@mui/material'
import React from 'react'
import Header from 'components/Header'
import { useParams } from 'react-router'
import InvoiceLateFee from 'logic/InvoiceLateFee'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import Loading from 'components/Loading'
import { isEmpty } from 'lodash'

const AdminInvoiceLateFeeList: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const params = useParams()
  const [lateFees, setLateFees] = React.useState<InvoiceLateFee[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const invoiceId = React.useMemo(() => Number(params.invoiceId), [params.invoiceId])

  const fetchLateFees = React.useCallback(async () => {
    try {
      setLoading(true)
      setLateFees(await api.listInvoiceLateFees({ parent_invoice_id: invoiceId }))
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error fetching late fees')
    } finally {
      setLoading(false)
    }
  }, [api, invoiceId, showAlert])

  React.useEffect(() => {
    fetchLateFees()
  }, [fetchLateFees])

  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Late fees for this Invoice'></Header>
      {isEmpty(lateFees) ? (
        <Alert severity='info'>No late fees found for this invoice</Alert>
      ) : (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Parent Invoice</TableCell>
              <TableCell>Pay via Invoice</TableCell>
              <TableCell>Late Fee Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lateFees.map((record) => (
              <TableRow key={record.id}>
                <TableCell data-label='Parent Invoice'>{record.parent_invoice_number}</TableCell>
                <TableCell data-label='Pay via Invoice'>{record.pay_via_invoice_number}</TableCell>
                <TableCell data-label='Late Fee Amount'>{record.late_fee_amount_label}</TableCell>
                <TableCell data-label='Description'>{record.description}</TableCell>
                <TableCell data-label='Created At'>{record.created_at_label()}</TableCell>
                <TableCell data-label='Payment Status'>{record.pay_via_invoice_status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default AdminInvoiceLateFeeList