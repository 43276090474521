import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import { useAuthUser } from 'contexts/AuthUserProvider'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { practiceGroupBillingAccountListURL, practiceGroupPaymentsURL, practiceGroupPracticeListURL, practiceGroupTimesheetListURL } from 'routes/urls';

const PracticeGroupTopNav: React.FC = () => {
  const navigate = useNavigate();
  const { authUser, logout } = useAuthUser()
  const logo = process.env.PUBLIC_URL + '/logo-rectangle.png'

  function goToRoot() {
    navigate('/')
  }

  const practiceGroupId = authUser?.practiceGroupIds[0] ?? 1

  return (
    <AppBar position="static" color='inherit'>
      <Toolbar variant='regular' disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box component='img' src={logo} alt='logo' sx={{ height: ['1.5rem', '2.5rem'], cursor: 'pointer' }} onClick={goToRoot}></Box>
        </Box>
        <Button variant="text" component={Link} to={practiceGroupPaymentsURL(practiceGroupId)}>Payment History</Button>
        <Button variant='text' component={Link} to={practiceGroupTimesheetListURL(practiceGroupId)}>Timesheets</Button>
        <Button variant="text" component={Link} to={practiceGroupPracticeListURL(practiceGroupId)}>Practices</Button>
        <Button variant="text" component={Link} to={practiceGroupBillingAccountListURL(practiceGroupId)}>Billing Accounts</Button>
        <IconButton onClick={logout}>
            <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default PracticeGroupTopNav