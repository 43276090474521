import { Expose } from 'class-transformer'
import { round } from 'lodash'

export default class EarningsReport {
  hours_worked!: number
  total_earned!: number
  reference_earned!: number
  reference_diff!: number

  @Expose()
  get reference_diff_label() {
    if (this.reference_diff >= 0) {
      return `£${round(this.reference_diff, 2)} more`
    } else {
      return `£${Math.abs(round(this.reference_diff, 2))} less`
    }
  }
}
