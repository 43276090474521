import { Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'
import { NotificationEventType } from 'types/types'
import { Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { nurseViewJobEmploymentURL, practiceViewJobURL } from 'routes/urls'

export class Notification {
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  timestamp!: DateTime

  event!: NotificationEventType
  unread_by_practice!: boolean
  unread_by_nurse!: boolean

  // notification could have different additional properties based on the event type
  [x: string]: any

  date_label(tz: string) {
    return this.timestamp.setZone(tz).toFormat('LLL dd yyyy h:mm a')
  }

  get job_date_label() {
    return DateTime.fromFormat(this.job_date, 'yyyy-MM-dd').toFormat('LLL dd, yyyy')
  }

  get resolution() {
    const total_fees = (this.nurse_fees ?? 0) + (this.locumloop_fees ?? 0)
    const nurse_fees_label = `£${this.nurse_fees / 100}`
    const locumloop_fees_label = `£${this.locumloop_fees / 100}`
    let resolution = 'N/A'
    if (this.cancel_by && total_fees > 0) {
      resolution = `The job will be cancelled. The practice will be charged as follows. (Nurse: ${nurse_fees_label}, Locumloop: ${locumloop_fees_label})`
    } else if (this.cancel_by && total_fees === 0) {
      resolution = 'The job will be cancelled. There will be no fees.'
    } else if (total_fees > 0) {
      resolution = `The job will proceed. The practice will be charged as follows. (Nurse: ${nurse_fees_label}, Locumloop: ${locumloop_fees_label})`
    } else if (total_fees === 0) {
      resolution = 'The job will proceed. There will be no fees.'
    }
    return resolution
  }

  get nurse_message() {
    switch (this.event) {
      case 'job_employment_cancelled':
        return (
          <Typography>
            Your job on {this.job_date_label} with {this.practice_name} is cancelled by {this.cancel_by === 'nurse' ? 'you' : this.cancel_by}
            <br/>
            <Link component={RouterLink} to={nurseViewJobEmploymentURL(this.nurse_id, this.je_id)}>Click here for more info</Link>
          </Typography>
        )
      case 'payment_success':
        return (
          <Typography>
            Payment completed for your job on {this.job_date_label} (Practice: {this.practice_name}).
            <br/>
            <Link component={RouterLink} to={nurseViewJobEmploymentURL(this.nurse_id, this.je_id)}>Click here for more info</Link>
          </Typography>
        )
      case 'payment_failed':
        return (
          <Typography>
            Payment failed for your job on {this.job_date_label} (Practice: {this.practice_name}). <br/>
            We'll be in touch with the practice to resolve this payment issue.
            <br/>
            <Link component={RouterLink} to={nurseViewJobEmploymentURL(this.nurse_id, this.je_id)}>Click here for more info</Link>
          </Typography>
        )
      default:
        return null
    }
  }

  get practice_message() {
    switch (this.event) {
      case 'job_taken':
        return (
          <Typography>
            Your job posting on {this.job_date_label} has been accepted by nurse ({this.nurse_name})
            <br/>
            <Link component={RouterLink} to={practiceViewJobURL(this.practice_id, this.job_id)}>Click here for more info</Link>
          </Typography>
        )
      case 'job_employment_cancelled':
        return (
          <Typography>
            Your job on {this.job_date_label} has been cancelled by {this.cancel_by === 'practice' ? 'you' : this.cancel_by}.
            <br/>
            <Link component={RouterLink} to={practiceViewJobURL(this.practice_id, this.job_id)}>Click here for more info</Link>
          </Typography>
        )
        case 'payment_success':
          return (
            <Typography>
              Payment completed for your job on {this.job_date_label} (Nurse: {this.nurse_name}).
              <br/>
              <Link component={RouterLink} to={practiceViewJobURL(this.practice_id, this.job_id)}>Click here for more info</Link>
            </Typography>
          )
        case 'payment_failed':
          return (
            <Typography>
              There is an error processing payment for your job on {this.job_date_label} (Nurse: {this.nurse_name}).
              Please update your payment method as soon as possible.
              <br/>
              <Link component={RouterLink} to={practiceViewJobURL(this.practice_id, this.job_id)}>Click here for more info</Link>
            </Typography>
          )
      default:
        return null
    }
  }

  isAfter(value: DateTime) {
    return this.timestamp.diff(value).as('seconds') > 0
  }

  log() {
    console.log(`Notification Event: ${this.event} (${this.timestamp.toISO()})`)
  }
}