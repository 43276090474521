import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import { practiceSignupURL, nurseSignupURL } from 'routes/urls'
import { useAuthUser } from 'contexts/AuthUserProvider'

const PrelaunchPage: React.FC = () => {
  const { authUser } = useAuthUser()

  let url
  if (authUser?.isNurse) {
    url = nurseSignupURL()
  } else if (authUser?.isPractice) {
    url = practiceSignupURL()
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 3
    }}>
      <Box component='img' src={process.env.PUBLIC_URL + '/thumbsup.png'} alt="thumbsup" sx={{
        maxWidth: [200]
      }}></Box>
      <Typography variant='h1'>
        Thanks for signing up to locumloop, we can't wait to work with you!
      </Typography>
      <Typography variant='body1'>
        We are currently only operating in London but we will be expanding to cover new areas. Please tell others in your area to sign up and we'll be there as soon as we can! <br/>
        Please feel free to also follow our <Link href='http://instagram.com/locumloop'>Instagram</Link> and <Link href='http://facebook.com/locumloop'>Facebook</Link> accounts to stay up to date.
      </Typography>
      {url ? (
        <Button component={RouterLink} to={url}>If you haven't finished signing up, click here to continue</Button>
      ) : null}
    </Box>
  )
}

export default PrelaunchPage