import React from 'react'
import { Box, Button } from '@mui/material';
import PracticeSignupContext from './context';
import Header from 'components/Header';
import SetupBillingAccount from 'components/SetupBillingAccount';
import Loading from 'components/Loading';
import { practiceSignupURL } from 'routes/urls';
import { PaymentOption } from 'types/types';
import { PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES } from 'types/constants';
import { chain, difference } from 'lodash';


const PracticeSignupPaymentDetails: React.FC = () => {
  const { practice, billingAccount, toNextStep, toPreviousStep, emitGTMEvent, jobs } = React.useContext(PracticeSignupContext)

  const onSuccess = React.useCallback(() => {
    emitGTMEvent('practice-signup-enter-payment-details')
    toNextStep()
  }, [emitGTMEvent, toNextStep])

  const availablePaymentOptions: PaymentOption[] = React.useMemo(() => {
    const allowInvoiceManualPay = (
      chain(jobs ?? [])
      .every((job) => job.is_possible_to_vet_practice_before_publishing_job)
      .value()
    )
    if (allowInvoiceManualPay) {
      return PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES
    } else {
      return difference(PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES, [
        'invoice_weekly_pay_manually',
        'invoice_biweekly_pay_manually',
        'invoice_monthly_pay_manually',
      ])
    }
  }, [jobs])

  if (!practice) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Enter Payment Details'></Header>
      <SetupBillingAccount 
        practice={practice}
        billingAccount={billingAccount ?? undefined}
        onSuccess={onSuccess}
        bacsDebitSuccessURL={practiceSignupURL()}
        buttonText='Next'
        availablePaymentOptions={availablePaymentOptions}
      />
      <Button variant="outlined" color="primary" onClick={toPreviousStep}>Back</Button>
    </Box>
  )
}

export default PracticeSignupPaymentDetails