import React from 'react'
import { useNavigate } from 'react-router';
import { nurseJobsURL } from 'routes/urls'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NurseSignupContext from './context';
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider';
import { getStripeURL } from 'logic/helpers'
import Loading from 'components/Loading';
import StripeProgress from 'components/StripeProgress';

const NurseSignupStripeSetup: React.FC = () => {
  const { toNextStep, toPreviousStep, nurse, emitGTMEvent } = React.useContext(NurseSignupContext)
  const { api } = useAPI()
  const navigate = useNavigate()
  const [stripeURL, setStripeURL] = React.useState<string>()

  const fetchStripeURL = React.useCallback(async () => {
    if (!nurse?.nurse_id) return null
    setStripeURL(await getStripeURL(api, nurse))
  }, [api, nurse])

  React.useEffect(() => {
    fetchStripeURL()
  }, [nurse?.nurse_id])

  function onSkip() {
    if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
  }

  function goToStripe() {
    if (stripeURL) {
      emitGTMEvent('nurse-signup-begin-stripe-setup')
      window.open(stripeURL, "_self");
    }
  }

  const canNext = nurse?.payouts_enabled ?? false

  if (!nurse) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Receiving Payments'></Header>
      <Typography variant='body1'>
        In order for you to receive payments from practices, you need to set up an account with Stripe, our payments processor. <br/>
        Your account will not be charged by locumloop or the practice.
      </Typography>
      {nurse.stripe_step === 'NOT_STARTED' ? (
        <Button
          disabled={!stripeURL}
          variant="contained" color="primary"
          onClick={goToStripe}>
          {!stripeURL ? 'Please Wait ...' : 'Payment Setup'}
        </Button>
      ) : (
        <StripeProgress />
      )}
      {canNext ? (
        <Button variant="contained" color="primary" onClick={toNextStep}>Next</Button>
      ) : null}
      <Button variant="outlined" color="primary" onClick={toPreviousStep}>Back</Button>
      <Button variant="outlined" color="primary" onClick={onSkip}>Skip</Button>
    </Box>
  )
}

export default NurseSignupStripeSetup