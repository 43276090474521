import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { 
  practiceGroupBillingAccountAddURL,
  practiceGroupBillingAccountEditURL,
} from 'routes/urls'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Header from 'components/Header'
import EditIcon from '@mui/icons-material/Edit'
import { useAPI } from 'contexts/APIProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { usePracticeGroup } from 'contexts/PracticeGroupProvider';
import Loading from 'components/Loading';
import { Chip } from '@mui/material'
import { Subscription, startWith } from 'rxjs'
import BillingAccount from 'logic/BillingAccount'

const PracticeGroupBillingAccountList: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()
  const { practiceGroup, paymentMethodNotification$ } = usePracticeGroup()
  const [billingAccounts, setBillingAccounts] = React.useState<BillingAccount[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchBillingAccounts = React.useCallback(async () => {
    try {
      setLoading(true)
      setBillingAccounts(await api.listBillingAccounts({ practice_group_id: practiceGroup?.id }))
    } catch (e) {
      showAlert('error', 'Error fetching billing accounts')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, practiceGroup?.id, showAlert])

  React.useEffect(() => {
    const sub = new Subscription()
    const reload$ = paymentMethodNotification$.pipe(startWith(true))
    sub.add(reload$.subscribe(fetchBillingAccounts))
    return () => sub.unsubscribe()
  }, [fetchBillingAccounts, paymentMethodNotification$])

  if (!practiceGroup) return null
  if (loading) return <Loading />
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Billing Accounts'>
        <Button 
          variant='contained' 
          sx={{ marginBottom: 1 }}
          component={RouterLink} 
          to={practiceGroupBillingAccountAddURL(practiceGroup.id)}>
          Add Billing Account
        </Button>
      </Header>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Payment Option</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingAccounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{ account.name }</TableCell>
                <TableCell>{ account.paymentOptionLabel }</TableCell>
                <TableCell>{ account.payment_method_label }</TableCell>
                <TableCell>
                  { !account.is_ready_to_process_payment ? (
                    <Chip color='error' label={account.not_ready_to_process_payment_reason} />
                  ) : (
                    <Chip color='success' label='OK' />
                  ) }
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => navigate(practiceGroupBillingAccountEditURL(practiceGroup.id, account.id))}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PracticeGroupBillingAccountList