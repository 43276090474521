import { Paper, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import DescriptionList from 'components/DescriptionList'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import React from 'react'
import { adminNurseDetailURL, adminPracticeDetailURL } from 'routes/urls'

const AdminEmploymentPeople: React.FC = () => {
  const { employment } = React.useContext(AdminEmploymentDetailContext)
  
  return (
    <Paper sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='40%' valueWidth='60%' rowGap={1} data={[{
        label: 'Nurse Name',
        value: (
          <Link component={RouterLink} to={adminNurseDetailURL(employment.nurse_id)}>
            { employment.nurse_name }
          </Link>
        ),
      }, {
        label: 'Nurse Email',
        value: employment.nurse_email,
      }, {
        label: 'Nurse Phone',
        value: employment.nurse_phone_number,
      }, {
        label: 'Nurse GDC Number',
        value: employment.nurse_gdc_number,
      }, {
        label: 'Practice Name',
        value: (
          <Link component={RouterLink} to={adminPracticeDetailURL(employment.practice_id)}>
            { employment.practice_name }
          </Link>
        ),
      }, {
        label: 'Practice Phone',
        value: employment.practice_phone_number,
      }, {
        label: 'Practice Address',
        value: employment.practice_address,
        sx: { whiteSpace: 'pre-line' }
      }]} />
    </Paper>
  )
}

export default AdminEmploymentPeople