import { MenuItem } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import FormField from 'components/FormField'
import Practice from 'logic/Practice'
import React from 'react'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import BillingAccount from 'logic/BillingAccount'

interface SetPracticeBillingAccountProps {
  practice: Practice
  billingAccounts: BillingAccount[]
  reloadPractice: () => Promise<void>
}

const SetPracticeBillingAccount: React.FC<SetPracticeBillingAccountProps> = ({ practice, billingAccounts, reloadPractice }) => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  
  const updateBillingAccount = React.useCallback(async (billingAccountId: number) => {
    try {
      await api.linkBillingAccount({
        practice_id: practice.practice_id,
        billing_account_id: billingAccountId,
      })
      await reloadPractice()
    } catch (e) {
      showAlert('error', 'An error occurred when linking billing account with practice')
      console.error(e)
    }
  }, [api, practice.practice_id, reloadPractice, showAlert])

  return (
    <FormField
      sx={{ width: 300 }}
      name='billing_account_id'
      label='Select Billing Account'
      select
      onChange={(e) => updateBillingAccount(Number(e.target.value))}
      value={practice.billing_account_id ?? ''}
    >
      {billingAccounts.map((account) => (
        <MenuItem key={account.id} value={account.id}>
          {account.name} ({account.paymentOptionLabel})
        </MenuItem>
      ))}
    </FormField>
  )
}

export default SetPracticeBillingAccount