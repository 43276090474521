import { useAPI } from 'contexts/APIProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import APIError from 'errors/APIError'
import { chain } from 'lodash'
import NurseDocumentFile from 'logic/NurseDocumentFile'
import React from 'react'
import { DocumentFileType } from 'types/types'

export type Selection = DocumentFileType | 'right-to-work'

interface SelectedFile {
  id: number
  url?: string
  content_type: string
}

export default function useNurseDocuments(nurseId?: number) {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { authUser } = useAuthUser()
  const [selection, setSelection] = React.useState<Selection>('gdc')
  const [files, setFiles] = React.useState<NurseDocumentFile[]>([])
  const [rtwURL, setRtwURL] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchFiles = React.useCallback(async () => {
    if (!nurseId) return
    try {
      setLoading(true)
      setFiles(await api.listDocuments({ nurse_id: nurseId }))
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, nurseId, showAlert])

  const fetchRtwURL = React.useCallback(async () => {
    if (!nurseId) return
    if (!authUser) return
    
    // don't show RTW document to practice (only show to nurse herself or staff)
    if (['practice', 'practice-group'].includes(authUser.userType)) return
    try {
      setLoading(true)
      const result = await api.getNurseRightToWorkDocumentLink(nurseId)
      setRtwURL(result.url)
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, authUser, nurseId, showAlert])

  const selectedFiles: SelectedFile[] = React.useMemo(() => {
    if (selection === 'right-to-work') {
      return [{
        id: 1,
        url: rtwURL,
        content_type: 'image/jpeg',
      }]
    } else {
      return (
        chain(files)
        .filter(file => file.doc_type === selection)
        .sortBy(file => file.page_num)
        .value()
      )
    }
  }, [files, rtwURL, selection])

  React.useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  React.useEffect(() => {
    fetchRtwURL()
  }, [fetchRtwURL])

  return {
    selection,
    setSelection,
    selectedFiles,
    loading,
  }
}