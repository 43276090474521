import { Expose, Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class NurseTransaction {
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  net!: number
  status!: string
  type!: string
  description!: string
  balance!: number

  date_label(tz: string) {
    return this.created_at.setZone(tz).toFormat('LLL dd yyyy h:mm a')
  }

  @Expose()
  get amount_label() {
    return `£${this.net / 100}`
  }

  @Expose()
  get balance_label() {
    return `£${this.balance / 100}`
  }
}