import React from 'react'
import { initializeApp } from 'firebase/app'
import { Firestore, getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { Auth, getAuth, connectAuthEmulator } from "firebase/auth"
import { FirebaseStorage, getStorage, connectStorageEmulator } from "firebase/storage"

interface IFirebaseContext {
  auth: Auth
  firestore: Firestore
  storage: FirebaseStorage
}

const FirebaseContext = React.createContext<IFirebaseContext>({} as IFirebaseContext)

const FirebaseProvider: React.FC = ({ children }) => {
  const app = React.useMemo(() => initializeApp({
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
  }), [])
  
  const auth = React.useMemo(() => {
    const conn = getAuth(app)
    // Connect auth emulator if EMULATOR_HOST is set
    const emulatorHost = process.env.REACT_APP_EMULATOR_HOST
    if (emulatorHost) {
      const authPort = process.env.REACT_APP_EMULATOR_AUTH_PORT
      connectAuthEmulator(conn, `http://${emulatorHost}:${authPort}`);
    }
    return conn
  }, [app])

  const firestore = React.useMemo(() => {
    const conn = getFirestore(app)
    const emulatorHost = process.env.REACT_APP_EMULATOR_HOST
    if (emulatorHost) {
      const firestorePort = process.env.REACT_APP_EMULATOR_FIRESTORE_PORT
      connectFirestoreEmulator(conn, emulatorHost, Number(firestorePort))
    }
    return conn
  }, [app])

  const storage = React.useMemo(() => {
    const conn = getStorage(app)
    const emulatorHost = process.env.REACT_APP_EMULATOR_HOST
    if (emulatorHost) {
      const storagePort = process.env.REACT_APP_EMULATOR_STORAGE_PORT
      connectStorageEmulator(conn, emulatorHost, Number(storagePort))
    }
    return conn
  }, [app])

  const value = { auth, firestore, storage }
  return <FirebaseContext.Provider value={value}> {children} </FirebaseContext.Provider>
}

function useFirebase() {
  const context = React.useContext(FirebaseContext)
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }
  return context
}

export { FirebaseProvider, useFirebase }