import AdminChart from 'components/AdminChart'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'

const AdminPracticeCharts: React.FC = () => {
  const { chartsSearchForm, updateChartsSearchForm } = React.useContext(AdminPracticeDetailContext)
  return (
    <AdminChart 
      form={chartsSearchForm} 
      updateForm={updateChartsSearchForm} 
      allowSelectPractice={false} 
      allowSelectPracticeGroup={false} 
    />
  )
}

export default AdminPracticeCharts