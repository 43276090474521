import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class Affiliate {
  id!: number
  name!: string
  code!: string
  campaign_id!: number
  campaign_name!: string
  nurse_id!: number | null
  user_id!: number
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  stripe_user_id!: string
  payouts_enabled!: boolean
  details_submitted!: boolean 

  // user info
  user_email!: string
  user_name!: string

  // annotated info
  num_practices!: number
  metro_area!: string
}