import { GridRowParams } from '@mui/x-data-grid'
import AdminJobEmploymentTable from 'components/AdminJobEmploymentTable'
import JobEmployment from 'logic/JobEmployment'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'
import { useNavigate } from 'react-router'
import { adminJobEmploymentDetailURL } from 'routes/urls'

const AdminNurseReviews: React.FC = () => {
  const {
    reviewsSearchForm,
    reviewsSelection,
    reviewsColumnVisibilityModel,
    reviewsSortModel,
    updateReviewsSearchForm,
    setReviewsSelection,
    setReviewsColumnVisibilityModel,
    setReviewsSortModel,
  } = React.useContext(AdminNurseDetailContext)

  const navigate = useNavigate()

  const onRowClick = React.useCallback((params: GridRowParams<JobEmployment>) => {
    navigate(adminJobEmploymentDetailURL(params.row.id))
  }, [navigate])

  
  return (
    <AdminJobEmploymentTable
      exclude_filters={['nurse']}
      onRowClick={onRowClick}
      form={reviewsSearchForm}
      updateForm={updateReviewsSearchForm}
      selection={reviewsSelection}
      setSelection={setReviewsSelection}
      columnVisibilityModel={reviewsColumnVisibilityModel}
      setColumnVisibilityModel={setReviewsColumnVisibilityModel}
      sortModel={reviewsSortModel}
      setSortModel={setReviewsSortModel}
    />
  )
}

export default AdminNurseReviews