import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class CashBalanceTransaction {
  billing_account_id!: number
  billing_account_name!: string
  billing_account_email!: string
  practice_group_id!: number
  practice_group_name!: string
  transaction_id!: string
  amount!: number
  ending_balance!: number
  transaction_type!: string
  reference!: string
  invoice_id!: number
  invoice_description!: string
  invoice_number!: string
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  transaction_created_at!: DateTime
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  invoice_created_at!: DateTime
  
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  invoice_finalized_at!: DateTime

  get amount_label() {
    return `£${(this.amount / 100).toFixed(2)}`
  }

  get ending_balance_label() {
    return `£${(this.ending_balance / 100).toFixed(2)}`
  }

  transaction_date_label(tz: string = 'Europe/London') {
    return this.transaction_created_at.setZone(tz).toFormat('ccc, LLL dd h:mm a')
  }
}