import AdminChart from 'components/AdminChart'
import React from 'react'
import { ChartSearchForm } from 'types/interfaces'
import update from 'immutability-helper'
import Header from 'components/Header'
import ReportSelectBox from './ReportSelectBox'
import { Box } from '@mui/material'

const ChartsPage: React.FC = () => {
    const [form, setForm] = React.useState<ChartSearchForm>({})
    const updateForm = React.useCallback((name: string, value: any) => {
        setForm(update(form, { [name]: { $set: value } }))
    }, [form])
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
            <Header text='Charts'>
                <ReportSelectBox />
            </Header>
            <AdminChart form={form} updateForm={updateForm} />
        </Box>
    )
}

export default ChartsPage