import { Expose, Transform, Type } from 'class-transformer'
import { DateTime } from 'luxon'

export class PracticeGroupTransaction {
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime
  description!: string
  amount!: number
  amount_refunded!: number
  status!: string

  practice_name!: string
  nurse_name!: string

  date_label(tz: string) {
    return this.created_at.setZone(tz).toFormat('LLL dd yyyy h:mm a')
  }

  @Expose()
  get amount_label() {
    return `£${this.amount / 100}`
  }

  @Expose()
  get refund_label() {
    return `£${this.amount_refunded / 100}`
  }
}