import { Box, Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useAPI } from 'contexts/APIProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import APIError from 'errors/APIError'
import BlacklistedNurse from 'logic/BlacklistedNurse'
import React from 'react'
import Header from 'components/Header';
import AddNurseToBlacklist from 'pages/PracticeBlacklist/AddNurseToBlacklist';

const PracticeBlacklist: React.FC = () => {
  const { practice } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [nurses, setNurses] = React.useState<BlacklistedNurse[]>([])
  const [showDialog, setShowDialog] = React.useState<boolean>(false)

  const fetchNurses = React.useCallback(async () => {
    if (!practice) return
    setNurses(await api.listBlacklistNurse({ practice_id: practice.practice_id }))
  }, [api, practice])

  const deleteBlacklistedNurse = React.useCallback(async (blacklistedNurse: BlacklistedNurse) => {
    try {
      await api.deleteBlacklistNurse(blacklistedNurse.id)
      await fetchNurses()
      showAlert('success', `${blacklistedNurse.nurse_full_name} is added to your blacklist`)
    } catch (e) {
      if (e instanceof APIError) {
        showAlert('error', e.message)
      }
      console.error(e)
    }
  }, [api, fetchNurses, showAlert])

  React.useEffect(() => {
    fetchNurses()
  }, [fetchNurses])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header text='Blacklisted Nurses' />
      {nurses.map((nurse) => (
        <Box key={nurse.id} sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          borderBottom: 'solid thin lightgray'
        }}>
          <Box sx={{ flexGrow: 1 }}>{ nurse.nurse_full_name }</Box>
          <IconButton onClick={() => deleteBlacklistedNurse(nurse)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button variant='contained' color='primary' onClick={() => setShowDialog(true)}>
        Add Nurse to Blacklist
      </Button>
      <AddNurseToBlacklist 
        open={showDialog} 
        onClose={() => setShowDialog(false)} 
        reloadBlacklist={fetchNurses}
      />
    </Box>
  )
}

export default PracticeBlacklist