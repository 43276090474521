import { Expose } from 'class-transformer';
import { concat } from 'lodash'

export default class TakeJobError {
  job_id: string[] = []
  nurse_id: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []

  @Expose()
  get errorlist() {
    return concat(this.schema, this.job_id, this.nurse_id)
  }
}