import { useAPI } from 'contexts/APIProvider'
import { usePractice } from 'contexts/PracticeProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { AddJobForm } from 'types/interfaces'
import { practiceBillingAccountSetupURL } from 'routes/urls'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

export default function useAddJob() {
  const { showAlert } = useSnackBarAlert()
  const { practice } = usePractice()
  const { api } = useAPI()

  async function addJob(form: AddJobForm) {
    if (!practice) {
      return
    }

    if (!practice.is_stripe_ready) {
      const url = practiceBillingAccountSetupURL(practice.practice_id)
      const alertContent = (
        <Box>
          <Typography>Setup your payment preference before you post a job</Typography>
          <Button variant='contained'
            size='small'
            color='warning'
            component={Link}
            to={url}>
            Click here to setup payments
          </Button>
        </Box>
      )
      showAlert('error', alertContent)
    } else {
      return await api.addJob(form)
    }
  }

  return { addJob }
}